import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Tenant, TenantFinances } from "../models";
import { BaseService } from "./base.service";

@Injectable()
export class TenantService extends BaseService{
    //private tenantQueryUrl = this.baseQueryUrl() + "/Tenant/";
    private tenantQueryUrl = this.baseApiUrl() + "/tenant/";

    getTenantById(id: string): Observable<any>{
        const url = `${this.tenantQueryUrl}${id}`;
        const headers = this.getHeader();
        return this.getHttpClient().get<Tenant>(url, { headers: headers })
    }

    getTenantFinances(userId: string): Observable<any>{
        const url = `${this.tenantQueryUrl}tenantFinances/${userId}`;
        const headers = this.getHeader();
        return this.getHttpClient().get<TenantFinances>(url, { headers: headers })
    }
}