import { createAction, props } from "@ngrx/store";
import { Broker, Brokerage, User } from "src/app/shared/models";

export const queryBrokerById = createAction(
    '[Broker] query Broker by id',
    props<{id: string}>()
);

export const queryBrokerByIdSuccess = createAction(
    '[Broker] query Broker by id Success',
    props<{broker: Broker}>()
);

export const queryBrokerByIdFailure = createAction(
    '[Broker] query Broker by id Failure',
    props<{error: any}>()
);


export const addBroker = createAction(
    '[Broker] add Broker',
    props<{propertyId: string, broker: Broker}>()
);

export const addBrokerWithUser = createAction(
    '[Broker] add Broker with User',
    props<{propertyId: string, broker: Broker}>()
);

export const addBrokerSuccess = createAction(
    '[Broker] add Broker Success'
);

export const addBrokerFailure = createAction(
    '[Broker] add Broker Failure',
    props<{error: any}>()
);

export const updateBroker = createAction(
    '[Broker] update Broker',
    props<{propertyId: string, broker: Broker}>()
);

export const updateBrokerWithUser = createAction(
    '[Broker] update Broker with User',
    props<{propertyId: string, broker: Broker}>()
);

export const updateBrokerSuccess = createAction(
    '[Broker] update Broker Success'
);

export const updateBrokerFailure = createAction(
    '[Broker] update Broker Failure',
    props<{error: any}>()
);

export const initCurrentBroker = createAction(
    '[Broker] init current Broker'
);

export const queryUserForBroker = createAction(
    '[Broker] query User for Broker',
    props<{phoneNumber: string}>()
);

export const queryUserForBrokerSuccess = createAction(
    '[Broker] query User for Broker Success',
    props<{user: User}>()
);

export const queryUserForBrokerFailure = createAction(
    '[Broker] query User for Broker Failure',
    props<{error: any}>()
);

export const getBrokerage = createAction(
    '[Broker] getBrokerage',
    props<{userId: string}>()
);

export const getBrokerageSuccess = createAction(
    '[Broker] getBrokerage Success',
    props<{brokerage: Brokerage}>()
);

export const getBrokerageFailure = createAction(
    '[Broker] getBrokerageFailure',
    props<{error: any}>()
);