<div class="container-fluid loading-container">
    <div class="row">
        <!-- <div class="col-md-5"></div> -->
        <div class="col-md-12 center">
            <!-- <h1 class="rentofly">Rento<span class="secondary-color">fly</span></h1> -->
            <mat-spinner  [diameter]="40"></mat-spinner>

            <!-- <h4 class="title-sub-text">Tenant > Payment > Ranking</h4> -->
        </div>
    </div>
</div>
<!-- <div class="container loading-container-placeholder">
    <div class="row">
        <div class="col-md-12">
            &nbsp;
        </div>
    </div>
</div> -->