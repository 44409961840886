<footer >
    <!-- <hr>
    <div class="row">
        <div class="col-md-5 footer-para-div">
            <p><i class="fa fa-copyright"></i> 2021 | Sona Tech Labs Pvt Ltd. All rights reserved. | Beta Version</p>
        </div>
        <div class="col-md-7 footer-icon-div">
            <a href="https://www.youtube.com/channel/UCeAKf0Or8Tx_UlKegdEXpag" class="social-link" target="_blank">
                <img src="../../../../assets/images/yt.png" width="40" height="33">
            </a>
            <a href="https://mobile.twitter.com/rentofly" class="social-link" target="_blank">
                <img src="../../../../assets/images/twitter.png" width="35" height="35" style="margin-right: 5px;">
            </a>
            <a href="https://www.facebook.com/rentofly/" class="social-link" target="_blank">
                <img src="../../../../assets/images/fb.png" width="30" height="30">
            </a>
            <a href="https://www.linkedin.com/company/rentofly" class="social-link" target="_blank">
                <img src="../../../../assets/images/linkedin.png" width="40" height="40">
            </a>
            <a href="https://www.instagram.com/rentofly/" class="social-link" target="_blank">
                <img src="../../../../assets/images/Instagram.svg" width="35" height="37">
            </a>

        </div>
    </div> -->
    <rentofly-footer-links></rentofly-footer-links>
</footer>