import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { User } from "../../shared/models";
import { UserState } from "../reducers";
import * as fromUsers from '../selectors/users.selector';
import * as userActions from "../actions/users.action";

export interface IUsersStoreFacade {
    selectAllUsers$: Observable<User[]>;
    isUserLoading$: Observable<boolean>;
    getCurrentUser$: Observable<User>;
    noUserResult$: Observable<boolean>;
    editingUser$: Observable<boolean>;

    queryUserByPhoneNumber(phoneNumber: string): void;
    addUser(user: User): void;
    updateUser(id:string, changes: Partial<User>): void;
}

@Injectable()
export class UsersStoreFacade implements IUsersStoreFacade {

    get selectAllUsers$() {
        return this.store.select(fromUsers.selectAll);
    }

    get isUserLoading$() {
        return this.store.select(fromUsers.isUserLoading$)
    }

    get noUserResult$() {
        return this.store.select(fromUsers.noUserResult$)
    }

    get getCurrentUser$() {
        return this.store.select(fromUsers.getCurrentUser$)
    }

    get editingUser$() {
        return this.store.select(fromUsers.editingUser$)
    }

    constructor(
        private readonly store: Store<UserState>,
    ){}

    // createUser(user: User) {
    //     this.store.dispatch(userActions.createUser({user}));
    // }

    // queryUserByPhoneNumber(phoneNumber: string) {
    //     this.store.dispatch(userActions.queryUserByPhoneNumber({phoneNumber}));
    // }

    // updateUsers(id:string, changes: Partial<User>){
    //     this.store.dispatch(userActions.updateUsers({id, changes}));
    // }

    //new
    queryUserByPhoneNumber(phoneNumber: string) {
        this.store.dispatch(userActions.queryUserByPhoneNumber({phoneNumber}));
    }
    addUser(user: User) {
        this.store.dispatch(userActions.addUser({user}));
    }
    updateUser(id: string, user: Partial<User>) {
        this.store.dispatch(userActions.updateUser({id: id, changes: user}));
    }
}