import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CreateDashboardState } from "..";
import { dealAdapter, DealState } from "../reducers";

export const getCreateDashboardState = createFeatureSelector<CreateDashboardState>('dashboard');
export const getDealState = createSelector(
    getCreateDashboardState,
    (state: CreateDashboardState) => state.deal
);

export const currentDeal$ = createSelector(getDealState, (state: DealState) => state.currentDeal);
export const currentDealLoading$ = createSelector(getDealState, (state: DealState) => state.currentDealLoading);
export const dealEditing$ = createSelector(getDealState, (state: DealState) => state.dealEditing);