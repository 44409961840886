import { createAction, props } from "@ngrx/store";
import { Email, Tenant, TenantFinances, User } from "src/app/shared/models";
import { EmailWhatsapp } from "src/app/shared/models/email-whatsapp.model";

export const queryTenantById = createAction(
    '[Tenant] query Tenant by id',
    props<{id: string}>()
);

export const queryTenantByIdSuccess = createAction(
    '[Tenant] query Tenant by id Success',
    props<{tenant: Tenant}>()
);

export const queryTenantByIdFailure = createAction(
    '[Tenant] query Tenant by id Failure',
    props<{error: any}>()
);

export const addTenant = createAction(
    '[Tenant] add Tenant',
    props<{propertyId: string, tenant: Tenant}>()
);

export const addTenantWithUser = createAction(
    '[Tenant] add Tenant with User',
    props<{propertyId: string, tenant: Tenant}>()
);

export const addTenantSuccess = createAction(
    '[Tenant] add Tenant Success'
);

export const addTenantFailure = createAction(
    '[Tenant] add Tenant Failure',
    props<{error: any}>()
);

export const updateTenant = createAction(
    '[Tenant] update Tenant',
    props<{propertyId: string, tenant: Tenant, emails: EmailWhatsapp[]}>()
);

export const updateTenantWithUser = createAction(
    '[Tenant] update Tenant with user',
    props<{propertyId: string, tenant: Tenant, emails: EmailWhatsapp[]}>()
);

export const updateTenantSuccess = createAction(
    '[Tenant] update Tenant Success'
);

export const updateTenantFailure = createAction(
    '[Tenant] update Tenant Failure',
    props<{error: any}>()
);

export const initCurrentTenant = createAction(
    '[Tenant] init current Tenant'
);

export const queryUserForTenant = createAction(
    '[Tenant] query User for Tenant',
    props<{phoneNumber: string}>()
);

export const queryUserForTenantSuccess = createAction(
    '[Tenant] query User for Tenant Success',
    props<{user: User}>()
);

export const queryUserForTenantFailure = createAction(
    '[Tenant] query User for Tenant Failure',
    props<{error: any}>()
);

export const updateTenantConsent = createAction(
    '[Tenant] update Tenant consent',
    props<{id: string, status: string, emails: EmailWhatsapp[]}>()
);

export const updateTenantConsentSuccess = createAction(
    '[Tenant] update Tenant consent Success'
);

export const updateTenantConsentFailure = createAction(
    '[Tenant] update Tenant consent Failure',
    props<{error: any}>()
);

export const getTenantFinances = createAction(
    '[Tenant] getTenantFinances',
    props<{userId: string}>()
);

export const getTenantFinancesSuccess = createAction(
    '[Tenant] getTenantFinances Success',
    props<{tenantFinances: TenantFinances}>()
);

export const getTenantFinancesFailure = createAction(
    '[Tenant] getTenantFinancesFailure',
    props<{error: any}>()
);