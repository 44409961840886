<head>
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <meta name="description"
    content="Manage your rental property with ease using our AI-powered platform. Get personalized recommendations, flexible rent payment options, automated reminders, and centralized document management. Trust and transparency are at the core of our platform, making each rental transaction secure and hassle-free. Simplify your rental journey today.">
  <meta name="keywords"
    content="Rental finance management, Property management software, Tenant management system, Rent payment processing, Property listing management, Lease agreement management, Online rent payment, Rental property accounting, Tenant screening, Rent collection automation, Maintenance request management, Property owner dashboard, Real estate software, Online property management, Property management platform, Rental management app, Property management tool, Automated rent collection, Tenant communication platform">
  <meta name="robots" content="index, follow">
</head>
<rentofly-loading *ngIf="isServicesLoading"></rentofly-loading>
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-Y3D2LBP9KV"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag() { dataLayer.push(arguments); }
  gtag('js', new Date());

  gtag('config', 'G-Y3D2LBP9KV');
</script>
<div class="page-header">
  <div class="row align-items-center">
    <div class="col-md-12">
      <div class="page-header-title">
        <h5 class="headeText">Services</h5>
        <div class="heading-line"></div>
      </div>
    </div>
  </div>
</div>
<p-card class="add-property-card">
<div *ngIf="showServices">
  <div class="row">
    <div class="col-md-8">
      <p-dataView #dv [value]="serviceNames" [paginator]="paginatorValue" [rows]="5" filterBy="title" layout="grid">
        <ng-template pTemplate="header">
          <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
            <div class="add-service-btn" (click)="openaddServicesDailog()">
              <i class="pi pi-plus service-icon"></i> Add Service
            </div>
            <span class="p-input-icon-left p-mb-2 p-mb-md-0 serachSpan">
              <i class="pi pi-search"></i>
              <input type="search" class="serach-input" pInputText placeholder="Search" (input)="dv.filter($event.target.value)">
            </span>
          </div>
        </ng-template>
        <ng-template  let-service pTemplate="gridItem">
              <div class="p-col-12 md:col-4 mobile-card">
                <div class="product-grid-item card services-card">
                  <div class="product-grid-item-top">
                    <div class="row">
                      <div class="col-md-8 cardHeaderClass">
                        <span>{{service.title}}</span>
                      </div>
                      <div class="col-md-4 switchClass">
                        <!-- <mat-slide-toggle
                          class="example-margin"
                          [color]="color"
                          [checked]="checked"
                          [disabled]="disabled">
                        </mat-slide-toggle> -->
                        <i class="pi pi-circle-on" pTooltip="{{service.active ? 'Active' : 'Inactive'}}" [class.active]="service.active" 
                        tooltipPosition="bottom"
                        [class.inActive]="!service.active"
                        
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div class="product-grid-item-content">
                    <img>
                  </div>
                  <div class="product-grid-item-bottom">
                    <div class="row">
                        <div class="col-md-12 priceClass">
                            <span>{{service.price  | currency:'INR'}}</span>
                        </div>
                    </div>
                    <div class="row">
                      <div class="col-md-8 ratingStar">
                        <i class="pi pi-star starColor"></i> 
                        <span>{{service.rating}}</span>
                        <span class="ratingSubText">({{service.reviews}} review)</span>
                      </div>
                      <div class="col-md-4 arrowDiv">
                        <i class="pi pi-arrow-circle-right arrowIcon" (click)="editService(service)"></i> 
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </ng-template>
      </p-dataView>
    </div>
    <div class="col-md-4">
      <div class="edit-btn-div">
        <!-- <div class="edit-btn">
          <i class="pi pi-pencil service-icon"></i> Edit Services
        </div> -->
      </div>
      <div class="card">
        <div>
          <h2 class="revenueClass">Revenue</h2>
          <span class="indicator-class">*Indicative graph</span>
        </div>
        <h2 class="revenueAmount">{{revenueAmount | currency:'INR'}}L</h2>
        <span class="revenueProgress" style="color:green">{{revenueProgress | currency:'INR'}}L vs Last month</span>
          <p-chart type="bar" [data]="basicData"></p-chart>
      </div>
      <div class="card" style="margin-top: 20px;">
        <div>
          <span class="indicator-class">*Indicative graph</span>
        </div>
        <p-chart type="line" height="300" [data]="lineGraphData"></p-chart>
      </div>
    </div>
  </div>
  <!-- <div class="row graph-mobile-view">
    <div class="col-md-12">
      <div class="card">
        <div>
          <span class="indicator-class">*Indicative graph</span>
        </div>
        <p-chart type="line" height="300" [data]="lineGraphData"></p-chart>
      </div>
    </div>
  </div> -->
</div>

<!-- <p-dialog header="Add Services" [(visible)]="showAddServiceDailog" (onHide)="onDailogClose()"
[style]="{width: '50vw'}" [baseZIndex]="10000" [modal]="true" [closable]="true" class="addServicesDailog">
  
</p-dialog> -->

<div *ngIf="!showServices">
  <div class="row form-row">
    <div class="col-md-12">
      <form [formGroup]="addServicesForm" class="service-info">
        <div class="form-group toggleBtn">
            <mat-slide-toggle
              class="example-margin"
              formControlName="active"
              pTooltip="{{addServicesForm.controls['active'].value ? 'Active' : 'Inactive'}}"
              tooltipPosition="bottom"
              [disabled]="disabled">
            </mat-slide-toggle>
        </div>
        <div class="form-group">
          <p class="info-header radio-para-div" style="margin-right: 42px;">Category : </p>
          <p-dropdown [options]="categories" formControlName="serviceType" placeholder="Select Category" optionLabel="name"
            (onChange)="onCategoryChange()"></p-dropdown>
        </div>
        <div class="form-group">
          <!-- <span class="p-float-label contact-span span-space">
            <input id="float-input-name" class="contact-input" type="text" formControlName="title"
              pInputText>
            <label for="float-input-name" class="input-label">Service Name</label>
          </span> -->
          <p class="info-header radio-para-div">Service Name : </p>
          <p-dropdown [options]="serviceTypes" formControlName="title" placeholder="Select Service" optionLabel="name"
            ></p-dropdown>
          <div *ngIf="title!.invalid && showServiceValidationMessage" class="rentofly-validation-msg ">
            <div>
              Service Name is required.
            </div>
          </div>
        </div>
        <div class="form-group">
          <span class="p-float-label contact-span span-space">
            <input id="float-input-pin inputnumber" class="contact-input" type="text" pKeyFilter="num"
              pattern="^[1-9][0-9]*$" oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" maxlength="6"
              formControlName="pincode" pInputText (blur)="queryAddressByZipCode(propertyZipCode.value)">
            <label for="float-input-pin" class="input-label">PIN Code</label>
          </span>
          <div *ngIf="propertyZipCode!.invalid && showServiceValidationMessage" class="rentofly-validation-msg ">
            <div>
              PIN Code is required.
            </div>
          </div>
        </div>
        <div class="form-group">
          <span class="p-float-label contact-span span-space">
            <input id="float-input-city" class="contact-input" type="text" disabled formControlName="location" pInputText> 
            <label for="float-input-city" class="input-label">City</label>
          </span>
          <div *ngIf="propertyState!.invalid && showServiceValidationMessage" class="rentofly-validation-msg ">
              <div>
                  City is required.
              </div>
          </div>
        </div>
        <div class="form-group" *ngIf="(currentAddressLoading$ | async)">
          <rentofly-loading></rentofly-loading>
        </div>
        <div class="form-group">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <span>&#8377;</span>
            </span>
            <span class="p-float-label">
              <input type="number" id="rentPerMonth" class="contact-input" pInputText formControlName="price"
                OnlyNumber="true">
              <label for="inputgroup" class="input-label">Price</label>
            </span>
          </div>
          <div *ngIf="price!.invalid && showServiceValidationMessage" class="rentofly-validation-msg ">
            <div>
              Price is required.
            </div>
          </div>
        </div>        
        <div class="form-group">
          <p class="service-timing-header">Service Timing : </p>
            <div class="p-field-checkbox timing-div">
              <p-checkbox name="fullTimeCheck" value="24X7" formControlName="everyday" inputId="ny"
              (onChange)="fullTimeSelected()" [disabled]="workingDays.length != '0' && !fullTime" binary="true"></p-checkbox>
              <label class="timing-label" for="ny">24X7</label>
            </div>
          <div class="weekDays">
            <p-toggleButton  [class.dayBtn]="workingDays.includes('Sun')" [disabled]="fullTime" (click)="workingWeekDays('Sun')" onLabel="Sun" offLabel="Sun"></p-toggleButton>
            <p-toggleButton  [class.dayBtn]="workingDays.includes('Mon')" [disabled]="fullTime" (click)="workingWeekDays('Mon')" onLabel="Mon" offLabel="Mon"></p-toggleButton>
            <p-toggleButton  [class.dayBtn]="workingDays.includes('Tue')" [disabled]="fullTime" (click)="workingWeekDays('Tue')" onLabel="Tue" offLabel="Tue"></p-toggleButton>
            <p-toggleButton  [class.dayBtn]="workingDays.includes('Wed')" [disabled]="fullTime" (click)="workingWeekDays('Wed')" onLabel="Wed" offLabel="Wed"></p-toggleButton>
            <p-toggleButton  [class.dayBtn]="workingDays.includes('Thus')" [disabled]="fullTime" (click)="workingWeekDays('Thus')" onLabel="Thus" offLabel="Thus"></p-toggleButton>
            <p-toggleButton  [class.dayBtn]="workingDays.includes('Fri')" [disabled]="fullTime" (click)="workingWeekDays('Fri')" onLabel="Fri" offLabel="Fri"></p-toggleButton>
            <p-toggleButton  [class.dayBtn]="workingDays.includes('Sat')" [disabled]="fullTime" (click)="workingWeekDays('Sat')" onLabel="Sat" offLabel="Sat"></p-toggleButton>
          </div>
          <div>
            <input placeholder="from" class="clockInput" [disabled]="fullTime" [ngxTimepicker]='fromTimePicker' formControlName="fromTime">
            <ngx-material-timepicker #fromTimePicker></ngx-material-timepicker>
            <input placeholder="to" class="clockInput" [disabled]="fullTime" [ngxTimepicker]='toTimePicker' formControlName="toTime">
            <ngx-material-timepicker #toTimePicker></ngx-material-timepicker>
            <!-- <p-calendar  formControlName="fromTime" [disabled]="fullTime" [timeOnly]="true" hourFormat= '12' inputId="timeonly"></p-calendar> <span style="margin: 0px 5px;">to</span>
            <p-calendar  formControlName="toTime" [disabled]="fullTime" [timeOnly]="true" hourFormat= '12' inputId="timeonly"></p-calendar> -->
          </div>
        </div>
        <div class="form-group">
          <span class="p-float-label">
          <textarea cols="30" id="float-input" formControlName="description" pinputtextarea="" rows="5" 
            class="p-inputtextarea p-inputtext p-component p-filled service-text-area"
            (focusout)="onFocusOut()" [ngClass]="{'p-filled':filled}"></textarea>
          <label for="float-input">Description</label>
        </span>
        </div>
      </form>
      <div class="action-btn">
        <!-- <button type="button" class="cancelService" (click)="cancel()">Cancel</button> -->
        <!-- <button type="button" *ngIf="!edit" class="draftService" (click)="saveAsDraft()">Save as Draft</button> -->
        <button type="button" *ngIf="!edit" class="saveService" (click)="addServices()">Add Service</button>
        <button type="button" *ngIf="edit" class="cancelService" (click)="updateServices('delete')">Delete</button>
        <button type="button" *ngIf="edit" class="saveService" [disabled]="!(addServicesForm.valid && addServicesForm.dirty)" (click)="updateServices('update')">Update</button>
      </div>
      <div class="back-btn">
        <a (click)="cancel()">Back</a>
      </div>
    </div>
    
  </div>
   
</div>
</p-card>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>