import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UUID } from 'angular2-uuid';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { Owner, Property, Tenant, StakeholderType, Broker, User } from 'src/app/shared/models';
import { LocalStorageService } from 'src/app/shared/services';
import { BrokerStoreFacade } from 'src/app/store/facades/broker.store-facade';
import { OwnerStoreFacade } from 'src/app/store/facades/owner.store-facade';
import { PropertyStoreFacade } from 'src/app/store/facades/property.store-facade';
import { TenantStoreFacade } from 'src/app/store/facades/tenant.store-facade';

@Component({
  selector: 'rentofly-stakeholder-info',
  templateUrl: './stakeholder-info.component.html',
  styleUrls: ['./stakeholder-info.component.scss']
})
export class StakeholderInfoComponent implements OnInit, OnDestroy {

  @Input()
  currentProperty: Property;

  @Output()
  continue: EventEmitter<any> = new EventEmitter();

  @Output()
  back: EventEmitter<any> = new EventEmitter();

  @Output()
  exit: EventEmitter<any> = new EventEmitter();

  @Output()
  saveAndExit: EventEmitter<any> = new EventEmitter();

  
  personId;
  stakeholderType;
  brokerDetail;
  showBroker:boolean = false;
  detailComplete:boolean = true;
  ownerReadonly = true;
  brokerReadonly = true;
  tenantReadonly = true;
  ownerInfoForm: FormGroup;
  tenantInfoForm: FormGroup;
  brokerInfoForm: FormGroup;
  showStakeholderInfoValidationMessage = false;
  currentOwner: Owner;
  currentTenant: Tenant;
  currentBroker: Broker;
  currentUser: User;
  currentOwnerLoading$: Observable<boolean>;
  currentUserForOwnerLoading$: Observable<boolean>;
  currentUserForTenantLoading$: Observable<boolean>;
  currentUserForBrokerLoading$: Observable<boolean>;
  currentTenantLoading$: Observable<boolean>;
  currentBrokerLoading$: Observable<boolean>;
  editingBroker$: Observable<boolean>;
  editingTenant$: Observable<boolean>;
  editingOwner$: Observable<boolean>;
  editingProperty$: Observable<boolean>;
  activeState: boolean[] = [false, false, false];
  constructor(
    private readonly ownerFacade: OwnerStoreFacade,
    private readonly tenantFacade: TenantStoreFacade,
    private readonly brokerFacade: BrokerStoreFacade,
    private readonly propertyFacade: PropertyStoreFacade,
    private readonly localStorageService: LocalStorageService,
    private readonly snackBar: MatSnackBar,
  ) { 
  }
  ngOnDestroy(): void {
    
  }

  get ownerMobileNo() {
    return this.ownerInfoForm!.get('ownerMobileNo');
  }

  get ownerEmailAddress() {
    return this.ownerInfoForm!.get('ownerEmailAddress');
  }

  get ownerName() {
    return this.ownerInfoForm!.get('ownerName');
  }

  get ownerPanNo() {
    return this.ownerInfoForm!.get('ownerPanNo');
  }

  get ownerAadhar() {
    return this.ownerInfoForm!.get('ownerAadhar');
  }
  get tenantMobileNo() {
    return this.tenantInfoForm!.get('tenantMobileNo');
  }

  get tenantEmailAddress() {
    return this.tenantInfoForm!.get('tenantEmailAddress');
  }

  get tenantName() {
    return this.tenantInfoForm!.get('tenantName');
  }

  get tenantAadharNo() {
    return this.tenantInfoForm!.get('tenantAadharNo');
  }

  get tenantPanNo() {
    return this.tenantInfoForm!.get('tenantPanNo');
  }
  get brokerMobileNo() {
    return this.brokerInfoForm!.get('brokerMobileNo');
  }
  get brokerName() {
    return this.brokerInfoForm!.get('brokerName');
  }
  get brokerEmail() {
    return this.brokerInfoForm!.get('brokerEmail');
  }
  get brokerPanNo() {
    return this.brokerInfoForm!.get('brokerPanNo');
  }
  get brokerAadhar() {
    return this.brokerInfoForm!.get('brokerAadhar');
  }
  get brokerId() {
    return this.brokerInfoForm!.get('brokerId');
  }

  ngOnInit(): void {
    this.currentUser = this.localStorageService.getObjectFromLocalStorage('current-user');
    if(this.currentProperty.ownerId)
    {
      this.ownerFacade.queryOwnerById(this.currentProperty.ownerId);
      this.stakeholderType = this.currentProperty.stakeholderType;
      if(this.currentProperty.stakeholderType == 0 ||  this.currentProperty.stakeholderType == 2){
        this.personId = 0;
      }
      else{
        this.personId = this.currentProperty.stakeholderType;
      }
      this.brokerDetail = this.currentProperty.stakeholderType;
      this.detailComplete = false;
      if(this.currentProperty.stakeholderType == 0 || this.currentProperty.stakeholderType == 2)
      {
        this.showBroker = true;
        this.activeState = [false,true,false];
      }
      if(this.currentProperty.stakeholderType == 1 || this.currentProperty.stakeholderType == 2){
        this.activeState = [true,false,false];
      }
    }
    this.ownerFacade.currentOwner$.subscribe((ownerData) => {
      this.currentOwner = ownerData;
      this.initOwnerForm(ownerData);
    })

    if(this.currentProperty.tenantId)
    {
      this.tenantFacade.queryTenantById(this.currentProperty.tenantId);
     
    }
    this.tenantFacade.currentTenant$.subscribe((tenantData) => {
      this.currentTenant = tenantData;
      this.initTenantForm(tenantData);
    })
    this.currentOwnerLoading$ = this.ownerFacade.currentOwnerLoading$;
    this.currentTenantLoading$ = this.tenantFacade.currentTenantLoading$;
    this.currentBrokerLoading$ = this.brokerFacade.currentBrokerLoading$;

    if(this.currentProperty.brokerId)
    {
      this.brokerFacade.queryBrokerById(this.currentProperty.brokerId);
     
    }
    this.brokerFacade.currentBroker$.subscribe((brokerData) => {
      this.currentBroker = brokerData;
      this.initBrokerForm(brokerData);
    });
    this.editingBroker$ = this.brokerFacade.editingBroker$;
    this.editingTenant$ = this.tenantFacade.editingTenant$;
    this.editingOwner$ = this.ownerFacade.editingOwner$;
    this.editingProperty$ = this.propertyFacade.propertyEditing$;
  }

  onContinue(ownerFormValue, tenantFormValue, brokerFormValue): void{
    this.showStakeholderInfoValidationMessage = false;
    if(this.ownerInfoForm.invalid || this.tenantInfoForm.invalid)
    {
      this.showStakeholderInfoValidationMessage = true;
      this.activeState = [true,true,true]
      return;
    }
    if((this.stakeholderType == 1 || this.stakeholderType == 2) && this.brokerInfoForm.invalid)
    {
      this.showStakeholderInfoValidationMessage = true;
      this.activeState = [true,true,true]
      return;
    }
    let formSaved = this.saveForm(ownerFormValue, tenantFormValue, brokerFormValue);
    if(!formSaved)
    {
      return;
    }
    combineLatest(
      [this.editingBroker$, 
      this.editingOwner$,
      this.editingTenant$,
    this.editingProperty$]
    ).subscribe(([editingBroker, editingOwner, editingTenant, editingProperty]) => {
      if(!editingOwner && !editingTenant && !editingProperty && (!editingBroker || this.stakeholderType != 1))
      {
        this.continue.emit(3);
      }
    })
    
  }

  onBack(): void{
    this.back.emit(1);
  }

  onExit(): void {
    this.exit.emit(1);
  }

  onSaveAndExit(ownerFormValue, tenantFormValue, brokerFormValue): void{
    this.showStakeholderInfoValidationMessage = false;
    if(this.ownerInfoForm.invalid || this.tenantInfoForm.invalid)
    {
      this.showStakeholderInfoValidationMessage = true;
      this.activeState = [true,true,true]
      return;
    }
    if((this.stakeholderType == 1 || this.stakeholderType == 2) && this.brokerInfoForm.invalid)
    {
      this.showStakeholderInfoValidationMessage = true;
      this.activeState = [true,true,true]
      return;
    }
    let formSaved = this.saveForm(ownerFormValue, tenantFormValue, brokerFormValue);
    if(!formSaved)
    {
      return;
    }
    combineLatest(
      [this.editingBroker$, 
      this.editingOwner$,
      this.editingTenant$,
    this.editingProperty$]
    ).subscribe(([editingBroker, editingOwner, editingTenant, editingProperty]) => {
      if(!editingOwner && !editingTenant && !editingProperty && (!editingBroker || this.stakeholderType != 1))
      {
        this.saveAndExit.emit(1);
      }
    });
  }

  private initOwnerForm(owner: Owner){
    if(owner && owner?.emailAddress != null){
      this.ownerReadonly = true;
    }
    else{
      this.ownerReadonly = false;
    }
    this.ownerInfoForm = new FormGroup({
      ownerMobileNo: new FormControl(owner?.phoneNumber ? +(owner.phoneNumber.replace("+91","")) :  '', [
        Validators.required,
        Validators.min(1000000000),
        Validators.max(9999999999)
      ]),
      ownerName: new FormControl(owner?.name ?? '', [
        Validators.required
      ]),
      ownerEmailAddress: new FormControl(owner?.emailAddress ?? '', [
        Validators.required,
        Validators.email
      ]),
      ownerPanNo: new FormControl(owner?.pan ?? '', [
        Validators.minLength(10),
        Validators.maxLength(10)
      ]),
      ownerAadhar: new FormControl(owner?.aadhar ?? '', [
        Validators.minLength(12),
        Validators.maxLength(12)
      ])
    });
    
    if(owner?.phoneNumber == this.currentUser.phoneNumber)
    {
      this.ownerPanNo.setValidators([
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]);
      this.ownerAadhar.setValidators([
        // Validators.required,
        // Validators.minLength(12),
        // Validators.maxLength(12)
      ]);
    }
  }

  private initTenantForm(tenant: Tenant){
    if(tenant && tenant?.emailAddress != null){
      this.tenantReadonly = true;
    }
    else{
      this.tenantReadonly = false;
    }
    this.tenantInfoForm = new FormGroup({
      
      tenantMobileNo: new FormControl(tenant?.phoneNumber ? +(tenant.phoneNumber.replace("+91","")) :  '', [
        Validators.required,
        Validators.min(1000000000),
        Validators.max(9999999999)
      ]),
      tenantEmailAddress: new FormControl(tenant?.emailAddress ?? '', [
        Validators.required,
        Validators.email
      ]),
      tenantName: new FormControl(tenant?.name ?? '', [
        Validators.required
      ]),
      tenantAadharNo: new FormControl(tenant?.tenantAadhar ? +tenant?.tenantAadhar : '', [
        Validators.minLength(12),
        Validators.maxLength(12)
      ]),
      tenantPanNo: new FormControl(tenant?.tenantPanCard ?? '', [
        Validators.minLength(10),
        Validators.maxLength(10)
      ]),
      tenantDetails: new FormControl(tenant?.tenantDetails ?? '', [])
    });
    if(tenant?.phoneNumber == this.currentUser.phoneNumber)
    {
      this.tenantPanNo.setValidators([
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]);
      this.tenantAadharNo.setValidators([
        Validators.required,
        Validators.min(100000000000),
        Validators.max(999999999999)
      ]);
    }
  }

  private initBrokerForm(broker: Broker){
    if(broker && broker?.emailAddress != null){
      this.brokerReadonly = true;
    }
    else{
      this.brokerReadonly = false;
    }
    this.brokerInfoForm = new FormGroup({
      brokerMobileNo: new FormControl(broker?.phoneNumber ? +(broker.phoneNumber.replace("+91","")) :  '', [
        Validators.required,
        Validators.min(1000000000),
        Validators.max(9999999999)
      ]),
      brokerName: new FormControl(broker?.name ?? '', [
        Validators.required
      ]),
      brokerEmail: new FormControl(broker?.emailAddress ?? '', [
        Validators.required,
        Validators.email
      ]),
      brokerPanNo: new FormControl(broker?.pan ?? '', [
        Validators.minLength(10),
        Validators.maxLength(10)
      ]),
      brokerAadhar: new FormControl(broker?.aadhar ?? '', [
        Validators.minLength(12),
        Validators.maxLength(12)
      ]),
      brokerId: new FormControl(broker?.brokerCode ?? '', [])
    })
  }

  private saveForm(ownerFormValue, tenantFormValue, brokerFormValue): boolean
  {
    if(ownerFormValue.ownerMobileNo == tenantFormValue.tenantMobileNo)
    {
      this.snackBar.open('The Owner and Tenant cannot be the same user.', "X", {
        duration: 10000,
        panelClass: ['snackbar-failure']
      });
      // document.getElementById("tenantMobileNo").focus();
      return false;
    }

    if(ownerFormValue.ownerMobileNo == brokerFormValue.brokerMobileNo)
    {
      this.snackBar.open('The Owner and Broker cannot be the same user.', "X", {
        duration: 10000,
        panelClass: ['snackbar-failure']
      });
      // document.getElementById("ownerMobileNo").focus();
      return false;
    }

    if(tenantFormValue.tenantMobileNo == brokerFormValue.brokerMobileNo)
    {
      this.snackBar.open('The Tenant and Broker cannot be the same user.', "X", {
        duration: 10000,
        panelClass: ['snackbar-failure']
      });
      // document.getElementById("tenantMobileNo").focus();
      return false;
    }
    
    if(this.stakeholderType == 0)
    {
      this.propertyFacade.updateProperty({
        ...this.currentProperty,
        stakeholderType: this.stakeholderType,
        brokerId: null
      });
    }
    else{
      this.propertyFacade.updateProperty({
        ...this.currentProperty,
        stakeholderType: this.stakeholderType
      });
    }
    
    if(this.ownerInfoForm.dirty)
    {
      this.saveOwner(ownerFormValue);
    }
    
    if(this.tenantInfoForm.dirty)
    {
      this.saveTenant(tenantFormValue);
    }
    
    if(this.brokerInfoForm.dirty)
    {
      this.saveBroker(brokerFormValue);
    }
    return true;
  }

  private saveOwner(ownerFormvalue)
  {
    if(this.currentProperty.ownerId)
    {
      var updateOwner: Owner = {
        id: this.currentOwner.id,
        userId: this.currentOwner.userId,
        phoneNumber: "+91"+ownerFormvalue.ownerMobileNo.toString(),
        pan: ownerFormvalue.ownerPanNo,
        // ownerAadhar: ownerFormvalue.ownerAadhar,
        name: ownerFormvalue.ownerName,
        ownerConsentStatus: 'Pending',
        emailAddress: ownerFormvalue.ownerEmailAddress,
        rejectReason: this.currentOwner.rejectReason
      }
      this.ownerFacade.updateOwner(this.currentProperty.id, updateOwner);
    }
    else{
      var newOwner: Owner = {
        id: UUID.UUID(),
        phoneNumber: "+91"+ownerFormvalue.ownerMobileNo.toString(),
        pan: ownerFormvalue.ownerPanNo,
        // ownerAadhar: ownerFormvalue.ownerAadhar,
        ownerConsentStatus: 'Pending',
        name: ownerFormvalue.ownerName,
        emailAddress: ownerFormvalue.ownerEmailAddress,
        propertyId: this.currentProperty.id
      }
      this.ownerFacade.addOwner(this.currentProperty.id, newOwner);
    }
  }

  private saveTenant(tenantFormvalue)
  {
    if(this.currentProperty.tenantId)
    {
      var updateTenant: Tenant = {
        id: this.currentTenant.id,
        userId: this.currentTenant.userId,
        phoneNumber: "+91"+tenantFormvalue.tenantMobileNo.toString(),
        tenantPanCard: tenantFormvalue.tenantPanNo,
        tenantAadhar: tenantFormvalue.tenantAadharNo.toString(),
        name: tenantFormvalue.tenantName,
        tenantConsentStatus: 'Pending',
        emailAddress: tenantFormvalue.tenantEmailAddress,
        tenantDetails: tenantFormvalue.tenantDetails,
        rejectReason: this.currentTenant.rejectReason
      }
      this.tenantFacade.updateTenant(this.currentProperty.id, updateTenant);
    }
    else{
      var newTenant: Tenant = {
        id: UUID.UUID(),
        phoneNumber: "+91"+tenantFormvalue.tenantMobileNo.toString(),
        tenantPanCard: tenantFormvalue.tenantPanNo,
        tenantAadhar: tenantFormvalue.tenantAadharNo.toString(),
        tenantConsentStatus: 'Pending',
        name: tenantFormvalue.tenantName,
        emailAddress: tenantFormvalue.tenantEmailAddress,
        tenantDetails: tenantFormvalue.tenantDetails,
        propertyId: this.currentProperty.id
      }
      this.tenantFacade.addTenant(this.currentProperty.id, newTenant);
    }
  }

  private saveBroker(brokerFormValue)
  {
    if(this.currentProperty.brokerId)
    {
      var updateBroker: Broker = {
        id: this.currentBroker.id,
        userId: this.currentBroker.userId,
        phoneNumber: "+91"+brokerFormValue.brokerMobileNo.toString(),
        name: brokerFormValue.brokerName,
        emailAddress: brokerFormValue.brokerEmail,
        brokerCode: brokerFormValue.brokerId,
        pan: brokerFormValue.brokerPanNo,
        aadhar: brokerFormValue.brokerAadhar
      }
      this.brokerFacade.updateBroker(this.currentProperty.id, updateBroker);
    }
    else{
      var newBroker: Broker = {
        id: UUID.UUID(),
        phoneNumber: "+91"+brokerFormValue.brokerMobileNo.toString(),
        name: brokerFormValue.brokerName,
        emailAddress: brokerFormValue.brokerEmail,
        brokerCode: brokerFormValue.brokerId,
        pan: brokerFormValue.brokerPanNo,
        aadhar: brokerFormValue.brokerAadhar,
        propertyId: this.currentProperty.id
      }
      this.brokerFacade.addBroker(this.currentProperty.id, newBroker);
    }
  }

  radioBtnDetail(){
    this.brokerDetail = -1;
    if(this.personId == StakeholderType.Owner) {
      this.showBroker = true;
      this.stakeholderType = 0;
      this.detailComplete = true;
    }
    else{
      this.stakeholderType = 1;
      this.showBroker = false;
      this.detailComplete = false;
      this.activeState = [true,false,false]
    }
  }
  getBrokerDetail(type) {
    this.stakeholderType = type;
    if(this.stakeholderType == 0)
    {
      this.activeState = [false,true,false]
    }
    else{
      this.activeState = [true,false,false]
    }
    this.detailComplete = false;
  }


  getUserByMobileNumber(mobileNo: string, userType: string){
    if(userType == 'owner')
    {
      this.getUserForOwner(mobileNo);
    }
    if(userType == 'tenant')
    {
      this.getUserForTenant(mobileNo);
    }
    if(userType == 'broker')
    {
      this.getUserForBroker(mobileNo);
    }
  }

  getUserForOwner(mobileNo)
  {
    let ownerMobile = '+91'+mobileNo;
    if(this.currentOwner?.phoneNumber != ownerMobile)
    {
      this.ownerFacade.queryUserForOwner(ownerMobile);
    }
    this.currentUserForOwnerLoading$ = this.ownerFacade.currentUserForOwnerLoading$;
    this.ownerFacade.currentUserForOwner$.subscribe((data: User) =>{
      if(data)
      {
        let updatedOwner: Owner = {
          id: this.currentOwner?.id,
          userId: data.id,
          phoneNumber: data.phoneNumber,
          name: data.name,
          emailAddress: data.emailAddress
        }
        this.currentOwner = {
          ...updatedOwner
        }
        this.initOwnerForm(this.currentOwner);
        this.ownerInfoForm.markAsDirty();
      } else{
        let updatedOwner: Owner = {
          id: this.currentOwner?.id,
          phoneNumber: mobileNo
        }
        this.currentOwner = {
          ...updatedOwner
        }
        this.initOwnerForm(this.currentOwner);
      }
      
    })
  }

  getUserForTenant(mobileNo)
  {
    let tenantMobile = '+91'+mobileNo;
    if(this.currentTenant?.phoneNumber != tenantMobile)
    {
      this.tenantFacade.queryUserForTenant(tenantMobile);
    }
    this.currentUserForTenantLoading$ = this.tenantFacade.currentUserForTenantLoading$;
    this.tenantFacade.currentUserForTenant$.subscribe((data: User) =>{
      if(data)
      {
        let updatedTenant: Tenant = {
          id: this.currentTenant?.id,
          userId: data.id,
          phoneNumber: data.phoneNumber,
          name: data.name,
          emailAddress: data.emailAddress
        }
        this.currentTenant = {
          ...updatedTenant
        }
        this.initTenantForm(this.currentTenant);
        this.tenantInfoForm.markAsDirty();
      } else{
        let updatedTenant: Tenant = {
          id: this.currentTenant?.id,
          phoneNumber: mobileNo
        }
        this.currentTenant = {
          ...updatedTenant
        }
        this.initTenantForm(this.currentTenant);
      }
      
    })
  }

  getUserForBroker(mobileNo)
  {
    if(!mobileNo)
    {
      this.brokerInfoForm.reset();
      return;
    }
    let brokerMobile = '+91'+mobileNo;
    if(this.currentBroker?.phoneNumber != brokerMobile)
    {
      this.brokerFacade.queryUserForBroker(brokerMobile);
    }
    this.currentUserForBrokerLoading$ = this.brokerFacade.currentUserForBrokerLoading$;
    this.brokerFacade.currentUserForBroker$.subscribe((data: User) =>{
      if(data)
      {
        let updatedBroker: Broker = {
          id: this.currentBroker?.id,
          userId: data.id,
          phoneNumber: data.phoneNumber,
          name: data.name,
          emailAddress: data.emailAddress
        }
        this.currentBroker = {
          ...updatedBroker
        }
        this.initBrokerForm(this.currentBroker);
        this.brokerInfoForm.markAsDirty();
      } else{
        let updatedBroker: Broker = {
          id: this.currentBroker?.id,
          phoneNumber: mobileNo
        }
        this.currentBroker = {
          ...updatedBroker
        }
        this.initBrokerForm(this.currentBroker);
      }
      
    })
  }
}
