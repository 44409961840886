import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { BrokerService, PropertyService } from "src/app/shared/services";
import * as brokerActions from "../actions/broker.action";
import { catchError, map, switchMap } from "rxjs/operators";
import { Broker, Brokerage, User } from "../../shared/models";
import { of } from "rxjs";
import { UserService } from "src/app/shared/services/user.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class BrokerEffects{
    constructor(
        private readonly actions$: Actions,
        private readonly propertyService: PropertyService,
        private readonly userService: UserService,
        private readonly brokerService: BrokerService,
        private _snackBar: MatSnackBar,
    ){}

    getBrokerById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(brokerActions.queryBrokerById),
            switchMap((action) => 
                this.brokerService.getBrokerById(action.id).pipe(
                    map((data: Broker) => brokerActions.queryBrokerByIdSuccess({broker: data})),
                    catchError((error: any) => {
                        if(error.status != 404)
                        {
                            this._snackBar.open('The action performed was not successful. Please contact admin.', "X", {
                                duration: 10000,
                                panelClass: ['snackbar-failure']
                            });
                        }
                        return of(brokerActions.queryBrokerByIdFailure(error))
                    }))
                )
            )
    );

    addBroker$ = createEffect(() =>
    this.actions$.pipe(
        ofType(brokerActions.addBroker),
        switchMap((action)=>
            this.userService.getUserByPhoneNumber(action.broker.phoneNumber).pipe(
                map((data) => {
                    if(data.message)
                    {
                        var updateUser: User = {
                            ...data.message,
                            name: data.message?.name ?? action.broker.name,
                            emailAddress: data.message?.emailAddress ?? action.broker.emailAddress
                        }
                        this.userService.updateUser(data.message.id, updateUser).subscribe();
                        let propertyId = action.propertyId;
                        let broker: Broker = {
                            ...action.broker,
                            userId: data.message.id
                        }
                        return brokerActions.addBrokerWithUser({propertyId, broker})
                    } else{
                        let propertyId = action.propertyId;
                        let broker: Broker = {
                            ...action.broker,
                            userId: null
                        }
                        return brokerActions.addBrokerWithUser({propertyId, broker})
                    }
                }),
                catchError((error: any) => {
                    if(error?.status == "404")
                    {
                        let propertyId = action.propertyId;
                        let broker = {
                            ...action.broker,
                            userId: null
                        }
                        return of(brokerActions.addBrokerWithUser({propertyId, broker}))
                    }
                    else{
                        this._snackBar.open('The action performed was not successful. Please contact admin.', "X", {
                            duration: 10000,
                            panelClass: ['snackbar-failure']
                        });
                    }
                })   
            )
        ),
        switchMap((action) => 
            this.propertyService.addBroker(action.propertyId, action.broker).pipe(
                map((data: Broker) => {
                    return brokerActions.addBrokerSuccess()
                }),
                catchError((error: any) => {
                    this._snackBar.open('Adding Broker was not successful. Please contact admin.', "X", {
                        duration: 10000,
                        panelClass: ['snackbar-failure']
                    });
                    return of(brokerActions.addBrokerFailure(error))
                }))
            )
        )
    );

    updateBroker$ = createEffect(() =>
    this.actions$.pipe(
        ofType(brokerActions.updateBroker),
        switchMap((action)=>
            this.userService.getUserByPhoneNumber(action.broker.phoneNumber).pipe(
                map((data) => {
                    if(data.message)
                    {
                        var updateUser: User = {
                            ...data.message,
                            name: data.message?.name ?? action.broker.name,
                            emailAddress: data.message?.emailAddress ?? action.broker.emailAddress
                        }
                        this.userService.updateUser(data.message.id, updateUser).subscribe();
                        let propertyId = action.propertyId;
                        let broker: Broker = {
                            ...action.broker,
                            userId: data.message.id
                        }
                        return brokerActions.updateBrokerWithUser({propertyId, broker})
                    } else{
                        let propertyId = action.propertyId;
                        let broker: Broker = {
                            ...action.broker,
                            userId: null
                        }
                        return brokerActions.updateBrokerWithUser({propertyId, broker})
                    }
                }),
                catchError((error: any) => {
                    if(error?.status == "404")
                    {
                        let propertyId = action.propertyId;
                        let broker = {
                            ...action.broker,
                            userId: null
                        }
                        return of(brokerActions.updateBrokerWithUser({propertyId, broker}))
                    }
                    else{
                        this._snackBar.open('Updating Broker was not successful. Please contact admin.', "X", {
                            duration: 10000,
                            panelClass: ['snackbar-failure']
                        });
                    }
                })   
            )
        ),
        switchMap((action) => 
            this.propertyService.updateBroker(action.propertyId, action.broker).pipe(
                map((data: Broker) => {
                    return brokerActions.updateBrokerSuccess()
                }),
                catchError((error: any) => {
                    this._snackBar.open('Updating Broker was not successful. Please contact admin.', "X", {
                        duration: 10000,
                        panelClass: ['snackbar-failure']
                    });
                    return of(brokerActions.updateBrokerFailure(error))
                }))
            )
        )
    );

    getUserForBroker$ = createEffect(() =>
        this.actions$.pipe(
            ofType(brokerActions.queryUserForBroker),
            switchMap((action) => 
                this.userService.getUserByPhoneNumber(action.phoneNumber).pipe(
                    map((data) => brokerActions.queryUserForBrokerSuccess({user: data.message})),
                    catchError((error: any) => {
                        return of(brokerActions.queryUserForBrokerFailure(error))
                    }))
                )
            )
    );

    getBrokerage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(brokerActions.getBrokerage),
            switchMap((action) => 
                this.brokerService.getBrokerage(action.userId).pipe(
                    map((data: Brokerage) => brokerActions.getBrokerageSuccess({brokerage: data})),
                    catchError((error: any) => {
                        return of(brokerActions.getBrokerageFailure(error))
                    }))
                )
            )
    );
}