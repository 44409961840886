import { state } from "@angular/animations";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CreateDashboardState } from "..";
import { partnerAdapter, PartnerState } from "../reducers";

export const getCreateDashboardState = createFeatureSelector<CreateDashboardState>('dashboard');
export const getPartnerState = createSelector(
    getCreateDashboardState,
    (state: CreateDashboardState) => state.partner
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = partnerAdapter.getSelectors(getPartnerState);

export const currentPartner$ = createSelector(getPartnerState, (state: PartnerState) => state.currentPartner);
export const currentPartnerLoading$ = createSelector(getPartnerState, (state: PartnerState) => state.currentPartnerLoading);
export const error$ = createSelector(getPartnerState, (state: PartnerState) => state.error);
export const partnerRequest$ = createSelector(getPartnerState, (state: PartnerState) => state.partnerRequest);
export const partnerLoading$ = createSelector(getPartnerState, (state: PartnerState) => state.partnerLoading);
export const noPartnerResult$ = createSelector(getPartnerState, (state: PartnerState) => state.noPartnerResult);
export const totalPartners$ = createSelector(getPartnerState, (state: PartnerState) => state.totalPartners);
export const updatePartnerLoading$ = createSelector(getPartnerState, (state: PartnerState) => state.updatePartnerLoading);

export const approvedPartners$ = createSelector(getPartnerState, (state: PartnerState) => state.approvedPartners);
export const approvedPartnersLoading$ = createSelector(getPartnerState, (state: PartnerState) => state.approvedPartnersLoading);
export const pendingPartners$ = createSelector(getPartnerState, (state: PartnerState) => state.pendingPartners);
export const pendingPartnersLoading$ = createSelector(getPartnerState, (state: PartnerState) => state.pendingPartnersLoading);