<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <h1>{{confirmMessageHeader}}</h1>
        </div>
        <div class="col-md-12">
            <form [formGroup]="dateForm" class="date-info">
                <div class="form-group">
                    <label for="inputgroup" class="input-label">{{confirmMessageContent}}</label><br />
                    <div class="form-group date-field-group">
                        <label for="inputgroup" class="input-label">Notice End Date</label>
                        <input [matDatepicker]="noticeEndDatepicker" value="" id="noticeEndDate" formControlName="noticeEndDate" class="form-control date-picker contact-input" placeholder="DD/MM/YYYY" required readonly>
                        <mat-datepicker-toggle matSuffix [for]="noticeEndDatepicker" class="date-picker-icon"></mat-datepicker-toggle>
                        <mat-datepicker #noticeEndDatepicker></mat-datepicker>
                        <div *ngIf="noticeEndDate!.invalid && showDateValidationMessage" class="rentofly-validation-msg">
                            <div>
                                Notice End Date is required.
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-md-12 action">
            <div>
                <button class="rentofly-primary rentofly-modal-button" (click)="onConfirm(dateForm.value)">{{confirmButtonText}}</button>
                <a class="cancel-link" (click)="dialogRef.close(false)">Cancel</a>
            </div>
        </div>
    </div>
</div>