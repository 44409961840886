import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as userActions from "../actions/users.action";
import { catchError, map, switchMap } from "rxjs/operators";
import { User } from "../../shared/models";
import { of } from "rxjs";
import { UserService } from "src/app/shared/services/user.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class UsersEffects{
    constructor(
        private readonly actions$: Actions,
        private readonly dfs: AngularFirestore,
        private readonly userService: UserService,
        private _snackBar: MatSnackBar,
    ){}

    queryUserByPhoneNumber$ = createEffect(() =>
         this.actions$.pipe(
            ofType(userActions.queryUserByPhoneNumber),
            switchMap((action) => 
                this.userService.getUserByPhoneNumber(action.phoneNumber).pipe(
                    map((data) => userActions.queryUserByPhoneNumberSuccess({user: data.message})),
                    catchError((error: any) => {
                        if(error.status != 404)
                        {
                            this._snackBar.open('The action performed was not successful. Please contact admin.', "X", {
                                duration: 10000,
                                panelClass: ['snackbar-failure']
                            });
                        }
                        return of(userActions.queryUserByPhoneNumberFailure(error))
                    }))
                )
            )
    );

    addUser$ = createEffect(() =>
         this.actions$.pipe(
            ofType(userActions.addUser),
            switchMap((action) => 
                this.userService.addUser(action.user).pipe(
                    map((data: string) => userActions.queryUserByPhoneNumber({phoneNumber: data})),
                    catchError((error: any) => {
                        this._snackBar.open('Add user was not successful. Please contact admin.', "X", {
                            duration: 10000,
                            panelClass: ['snackbar-failure']
                        });
                        return of(userActions.addUserFailure(error))
                    }))
                )
            )
    );

    updateUser$ = createEffect(() =>
         this.actions$.pipe(
            ofType(userActions.updateUser),
            switchMap((action) => 
                this.userService.updateUser(action.id, action.changes).pipe(
                    map((data: string) => userActions.updateUserSuccess()),
                    catchError((error: any) => {
                        this._snackBar.open('Update user was not successful. Please contact admin.', "X", {
                            duration: 10000,
                            panelClass: ['snackbar-failure']
                        });
                        return of(userActions.updateUserFailure(error))
                    }))
                )
            )
    );
}
