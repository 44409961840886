import { createAction, props } from "@ngrx/store";
import { Deal, Email, UpdateDeal } from "src/app/shared/models";
import { EmailWhatsapp } from "src/app/shared/models/email-whatsapp.model";

export const queryDealById = createAction(
    '[Deal] query Deal by id',
    props<{id: string}>()
);

export const queryDealByIdSuccess = createAction(
    '[Deal] query Deal by id Success',
    props<{deal: Deal}>()
);

export const queryDealByIdFailure = createAction(
    '[Deal] query Deal by id Failure',
    props<{error: any}>()
);

export const addDeal = createAction(
    '[Deal] add Deal',
    props<{propertyId: string, deal: Deal, initiateDeal: boolean, emails: EmailWhatsapp[]}>()
);

export const addDealSuccess = createAction(
    '[Deal] add Deal Success'
);

export const addDealFailure = createAction(
    '[Deal] add Deal Failure',
    props<{error: any}>()
);

export const updateDeal = createAction(
    '[Deal] update Deal',
    props<{propertyId: string, deal: Deal, initiateDeal: boolean, emails: EmailWhatsapp[]}>()
);

export const updateDealSuccess = createAction(
    '[Deal] update Deal Success'
);

export const updateDealFailure = createAction(
    '[Deal] update Deal Failure',
    props<{error: any}>()
);

export const initCurrentDeal = createAction(
    '[Deal] init current Deal'
);

export const updateDealStatus = createAction(
    '[Deal] update Deal status',
    props<{id: string, status: string, emails: EmailWhatsapp[]}>()
);

export const updateDealStatusSuccess = createAction(
    '[Deal] update Deal status Success'
);

export const updateDealStatusFailure = createAction(
    '[Deal] update Deal status Failure',
    props<{error: any}>()
);

export const updateNoticeDateAndStatus = createAction(
    '[Deal] update Notice Date and status',
    props<{id: string, updateDeal: UpdateDeal, emails: EmailWhatsapp[]}>()
);

export const updateNoticeDateAndStatusSuccess = createAction(
    '[Deal] update Notice Date and status Success'
);

export const updateNoticeDateAndStatusFailure = createAction(
    '[Deal] update Notice Date and status Failure',
    props<{error: any}>()
);