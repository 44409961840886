import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, Message } from 'primeng/api';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/shared/services';
import { ServicesInfoService } from 'src/app/shared/services/services-info.service';
import { AddressStoreFacade } from 'src/app/store/facades';

@Component({
  selector: 'app-services-info',
  templateUrl: './services-info.component.html',
  styleUrls: ['./services-info.component.scss']
})
export class ServicesInfoComponent implements OnInit {
public serviceNames: object[] = [];
public checked1 = false;
public basicData: any;
public lineGraphData: any;
public color  = 'green';
public checked = true;
public disabled = false;
public revenueAmount = '0.00';
public revenueProgress = '0.0';
public showAddServiceDailog = false;
public addServicesForm: FormGroup;
public showServiceValidationMessage = false;
public categories = [];
public services = [];
public weekDays = [];
public currentPin;
public currentAddressLoading$: Observable<boolean>;
public currentUser:any;
public showServices = true;
public filled = false;
public edit = false;
public serviceId = ''
day1 = false;
day2 = false;
day3 = false;
day4 = false;
day5 = false;
day6 = false;
day7 = false;
workingDays = [];
public fullTime = false;
public isServicesLoading = true;
msgs: Message[] = [];
position: string;
public paginatorValue = false;
public serviceTypes = [];

  constructor(
    private readonly addressFacade : AddressStoreFacade,
    private servicesInfoService: ServicesInfoService,
    private readonly localStorageService: LocalStorageService,
    private confirmationService: ConfirmationService) { }

  get propertyZipCode() {
    return this.addServicesForm!.get('pincode');
  }
  get title() {
    return this.addServicesForm!.get('title');
  }
  get propertyState() {
    return this.addServicesForm!.get('location');
  }
  get price() {
    return this.addServicesForm!.get('price');
  }

  ngOnInit(): void {
    this.currentUser = this.localStorageService.getObjectFromLocalStorage('current-user');
    this.fetchAllServices();
    this.initForm();
    this.fetachServiceName();
    this.weekDays = [
      {name: 'Sunday'},
      {name: 'Monday'},
      {name: 'Tuesday'},
      {name: 'Wednesday'},
      {name: 'Thursday'},
      {name: 'Friday'},
      {name: 'Saturday'}
    ]

    this.basicData = {
        labels: ['Service 1', 'Service 2', 'Service 3', 'Service 4', 'Service 5', 'Service 6'],
        datasets: [
            {
                label: 'Revenue',
                backgroundColor: '#80808099',
                data: [2650, 590, 8000, 810, 5600, 550]
            },
        ]
    };
    this.lineGraphData = {
        labels: ['Day 5', 'Day 10', 'Day 15', 'Day 20', 'Day 25', 'Day 30'],
        datasets: [
            {
                
                fill: true,
                label: 'Progress',
                data: [50, 590, 800, 810, 1560, 2550],
                backgroundColor: '#80808099'
            },
        ]
    };

    // this.categories = [
    //   {name: 'Beauty'},
    //   {name: 'Home Services'},
    //   {name: 'Fitness'},
    //   {name: 'Others'}
    // ]
  }

  fetachServiceName(){
    this.servicesInfoService.fetchServiceType({}).subscribe(resp =>{
      this.categoriesAndServices(resp.message)
    })
  }

  categoriesAndServices(data){
    for(let item of data){
      this.categories.push({name: item.serviceType});
      let data1 = item.description.replace(/\"“,/g,"").replace(/ “/g,'').replace(/“/g,'').replace(/”/g,'').replace(/\"/g,'').replace(/^\s+/g, '');
      this.services.push({name:item.serviceType, types:data1.split(',')})
    }
  }

  onCategoryChange(){
    this.serviceTypes = [];
    for(let item of this.services){
      if(item.name == this.addServicesForm.get('serviceType').value.name){
        for(let data of item.types){
          this.serviceTypes.push({name:data})
        }
      }
    }
  }

  serviceName(data){
    for(let item of data){
      let data = item.description.split("\",,");
      this.services.push({name:item.serviceType, types:data})
      console.log(this.services)
    }
  }

  checkPaginator(data){
    if(data.length > 9){
      this.paginatorValue = true;
    }else{
      this.paginatorValue = false;
    }
  }
  fetchAllServices(){
    let data= {
      service:{},
      user:{
        id:this.currentUser.id
      }
    }
    this.servicesInfoService.getAllServices(data).subscribe(resp =>{
      if(resp.isSuccess){
        this.serviceNames = resp.message
        this.isServicesLoading = false;
        this.checkPaginator(this.serviceNames)
      }
    })
  }

  workingWeekDays(value){
    
    if(this.workingDays.includes(value)){
      let index = this.workingDays.indexOf(value)
      this.workingDays.splice(index, 1);
    } else{
      this.workingDays.push(value);
    }
    console.log(this.workingDays)
  }

  private initForm(){
    this.addServicesForm = new FormGroup({
      title: new FormControl('',[Validators.required]),
      serviceType: new FormControl('',[Validators.required]),
      pincode: new FormControl('',[Validators.required]),
      location: new FormControl('',[Validators.required]),
      price: new FormControl('',[Validators.required]),
      workingHours: new FormControl(''),
      description: new FormControl(''),
      fromTime: new FormControl(''),
      toTime: new FormControl(''),
      everyday: new FormControl(''),
      active: new FormControl(true)
    })
  }
  openaddServicesDailog(){
    this.showServices = false;
    this.addServicesForm.get('active').patchValue(true)
    this.workingDays = [];
  }
  cancel(){
    this.showServices = true;
    this.edit = false;
    this.addServicesForm.reset();
    this.workingDays = [];
    this.fullTime = true;
    this.filled = false;
    this.showServiceValidationMessage = false;
  }

  editService(data){
    //this.addServicesForm.patchValue(data);
    this.serviceId = data.id;
    this.showServices = false;
    this.edit = true;
    let workingDays = JSON.parse(data.workingHours)

    setTimeout(() => {
      this.addServicesForm.get('serviceType').patchValue({name:data.serviceType});
      this.onCategoryChange();
      this.addServicesForm.get('title').patchValue({name:data.title});
      this.addServicesForm.get('pincode').patchValue(data.pincode);
      this.addServicesForm.get('location').patchValue(data.location);
      this.addServicesForm.get('price').patchValue(data.price);
      this.addServicesForm.get('description').patchValue(data.description);
      this.addServicesForm.get('active').patchValue(data.active);
      if(workingDays.days.length == 7){
        this.addServicesForm.get('everyday').patchValue(true)
        this.fullTime = false;
        this.fullTimeSelected()
      }else{
        this.fullTime = false;
      }
    }, 200);
    this.filled = true;
    this.addServicesForm.get('fromTime').patchValue(workingDays.fromTime);
    this.addServicesForm.get('toTime').patchValue(workingDays.toTime);
    for(let item of workingDays.days){
      this.workingWeekDays(item)
    }
    
  }

  updateServices(action){
       
    this.isServicesLoading = true;
    let updatedData = {
      services: [{
        id: this.serviceId,
        title: this.addServicesForm.get('title').value.name,
        serviceType: this.addServicesForm.get('serviceType').value.name,
        description: this.addServicesForm.get('description').value,
        price: this.addServicesForm.get('price').value,
        workingHours:JSON.stringify({days:this.workingDays, 
          fromTime:this.addServicesForm.get('fromTime').value,
          toTime:this.addServicesForm.get('toTime').value
        }),
        location: this.addServicesForm.get('location').value,
        pincode: this.addServicesForm.get('pincode').value,
        userType:'test',
        active: this.addServicesForm.get('active').value
      }],
      user:{
        id: this.currentUser.id,
        username:this.currentUser.name,
        email:this.currentUser.emailAddress,
        contact:this.currentUser.phoneNumber
      }
    }

    if(action == 'delete'){
      this.confirmationService.confirm({
        message: 'Do you want to delete this service?',
        header: 'Delete Confirmation',
        icon: '',
        accept: () => {
          updatedData.services[0]['deletedAt'] = Date.now();
          this.update(updatedData);
        },
        reject: () => {
          this.isServicesLoading = false;
        }
    });
  } else {
    this.update(updatedData);
  }
}

  update(data){
    this.servicesInfoService.updateRentoflyServices(data).subscribe(resp =>{
          this.addServicesForm.reset();
          this.workingDays = [];
          this.serviceId = '';
          this.edit = false;
          this.showServices = true;
          this.filled = false;
          this.fullTime = true;
          this.showServiceValidationMessage = false
          this.fetchAllServices();
    })
  }

  addServices(){
    if(this.addServicesForm.invalid){
      this.showServiceValidationMessage = true;
    }else{
      this.isServicesLoading = true;
    let serviceData = {
      service: {
        title: this.addServicesForm.get('title').value.name,
        serviceType: this.addServicesForm.get('serviceType').value.name,
        description: this.addServicesForm.get('description').value,
        price: this.addServicesForm.get('price').value,
        workingHours:JSON.stringify({days:this.workingDays, 
          fromTime:this.addServicesForm.get('fromTime').value,
          toTime:this.addServicesForm.get('toTime').value
        }),
        location: this.addServicesForm.get('location').value,
        pincode: this.addServicesForm.get('pincode').value,
        active: this.addServicesForm.get('active').value,
        userType:'test'
      },
      user:{
        id: this.currentUser.id,
        username:this.currentUser.name,
        email:this.currentUser.emailAddress,
        contact:this.currentUser.phoneNumber
      }
    }
    this.servicesInfoService.addRentoflyServices(serviceData).subscribe(resp =>{
      this.fetchAllServices();
      this.addServicesForm.reset();
      this.workingDays = [];
      this.filled = false;
      this.showServiceValidationMessage = false
    })
    this.showServices = true;
  }
  }

  fullTimeSelected(){
    this.fullTime = !this.fullTime;
    if(this.fullTime){
      this.workingDays = ['Sun','Mon','Tue','Wed','Thus','Fri','Sat']
    } else{
      this.workingDays = []
    }
  }
  onDailogClose(){
    this.addServicesForm.reset();
  }

  queryAddressByZipCode(propertyZipCode){
    if(this.currentPin == propertyZipCode)
    {
      return;
    }
    this.currentPin = propertyZipCode;
    if(this.propertyZipCode.valid)
    {
      this.addressFacade.queryAddressByPincode(propertyZipCode);
      this.currentAddressLoading$ = this.addressFacade.currentAddressLoading$;
      this.addressFacade.currentAddress$.subscribe((data) => {
        if(data)
        {
          this.addServicesForm.get('location').patchValue(data.District)
          // this.currentAddress = data;
          // this.propertyCity.setValue(data.district);
          // this.propertyState.setValue(data.state);
        }
      })
    }
  }
  onFocusOut(){
    if(this.addServicesForm.get('description').value != null){
      this.filled = true;
    }else{
      this.filled = false;
    }
  }
}

