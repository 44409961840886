import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services';

@Component({
  selector: 'rentofly-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  isAuthenticated: boolean;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if ((evt instanceof NavigationEnd)) {
        if(evt.url == "/login")
        {
          this.isAuthenticated = false
        }
        else{
          this.isAuthenticated = this.authService.isAuthenticated();
        }
      }
    });
  }

}
