<head>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="description" content="Manage your rental property with ease using our AI-powered platform. Get personalized recommendations, flexible rent payment options, automated reminders, and centralized document management. Trust and transparency are at the core of our platform, making each rental transaction secure and hassle-free. Simplify your rental journey today.">
    <meta name="keywords" content="Rental finance management, Property management software, Tenant management system, Rent payment processing, Property listing management, Lease agreement management, Online rent payment, Rental property accounting, Tenant screening, Rent collection automation, Maintenance request management, Property owner dashboard, Real estate software, Online property management, Property management platform, Rental management app, Property management tool, Automated rent collection, Tenant communication platform">
    <meta name="robots" content="index, follow">
</head>
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-Y3D2LBP9KV"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-Y3D2LBP9KV');
</script>
<!-- Google tag (gtag.js) -->
<rentofly-loading *ngIf="(isUserLoading$ | async) || (editingUser$ | async)"></rentofly-loading>
<div class="container">
    <div class="row" *ngIf="!(isUserLoading$ | async)">
        <div class="col-md-12">
            <div style="text-align: center;">
                <h4 class="profile-header">Profile</h4>
            </div>
            <div class="heading"></div>
            <div class="row">
                <div class="col-md-12 profile-header-img"></div>
            </div>
            <div class="row">
                <div class="col-md-12 ratingtext" *ngIf="ratingAsOwner !== ''">
                    <span class="ratingSpan">Rating as a Owner</span>: <span class="ratingSpan2">{{ratingAsOwner | number:'1.1-2'}}</span>
                </div>
                <div class="col-md-12 ratingtext" style="margin-bottom: 20px;" *ngIf="ratingAsTenant !== ''">
                    <span class="ratingSpan">Rating as a Tenant</span>: <span class="ratingSpan2">{{ratingAsTenant | number:'1.1-2'}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="!(isUserLoading$ | async)">
        <div class="col-md-12">
            <p-card class="profile-page add-property-card">
                <div class="container">
                    <div class="row form-row">
                        <div class="col-md-12">
                            <form [formGroup]="profileForm" class="profile">
                                <div class="form-group">
                                    <span class="p-float-label contact-span ">
                                        <input id="float-input name" class="contact-input" type="text" formControlName="name" pInputText> 
                                        <label for="float-input">Name</label>
                                    </span>
                                    <div *ngIf="name!.invalid && showProfileValidationMessage" class="rentofly-validation-msg">
                                        <div>
                                            Name is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <span class="p-float-label contact-span ">
                                        <input id="float-input mobileNo" class="contact-input" type="text" formControlName="mobileNo" pInputText> 
                                        <label for="float-input">Mobile #</label>
                                    </span>
                                    <div *ngIf="mobileNo!.invalid && showProfileValidationMessage" class="rentofly-validation-msg">
                                        <div>
                                            Mobile # is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <span class="p-float-label contact-span ">
                                        <input id="float-input emailAddress" class="contact-input" type="text" formControlName="emailAddress" pInputText> 
                                        <label for="float-input">Email Address</label>
                                    </span>
                                    <div *ngIf="emailAddress!.invalid && showProfileValidationMessage" class="rentofly-validation-msg">
                                        <div>
                                            Email Address is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <span class="p-float-label contact-span ">
                                        <input id="float-input zipCode" class="contact-input" type="text" formControlName="zipCode" pKeyFilter="num"
                                        maxlength="6" pInputText> 
                                        <label for="float-input">PIN Code</label>
                                    </span>
                                    <div *ngIf="zipCode!.invalid && showProfileValidationMessage" class="rentofly-validation-msg">
                                        <div>
                                            PIN Code is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <span class="p-float-label contact-span ">
                                        <input id="float-input panNo" class="contact-input" type="text" maxlength = "10" formControlName="panNo" pInputText> 
                                        <label for="float-input">PAN Card</label>
                                    </span>
                                    <div *ngIf="!(panNo!.invalid && showProfileValidationMessage)" class="maskedInformation"><span>PAN # will be masked and not visible to others</span></div>
                                    <div *ngIf="panNo!.invalid && showProfileValidationMessage" class="rentofly-validation-msg">
                                        <div>
                                            PAN Card is invalid.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <span class="p-float-label contact-span ">
                                        <input id="float-input aadharNo" class="contact-input" type="number" maxlength = "12" formControlName="aadharNo" pInputText> 
                                        <label for="float-input">Aadhar Card</label>
                                    </span>
                                    <div *ngIf="!(aadharNo!.invalid && showProfileValidationMessage)"class="maskedInformation"><span>Aadhar # will be masked and not visible to others</span></div>
                                    <div *ngIf="aadharNo!.invalid && showProfileValidationMessage" class="rentofly-validation-msg">
                                        <div>
                                            Aadhar is invalid.
                                        </div>
                                    </div>
                                </div>
                                <div class="action">
                                    <button class="rentofly-tertiary" (click)="onSave(profileForm.value)">Save</button>
                                    <button class="rentofly-secondary" (click)="onExit()">Exit</button><br />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </p-card>
        </div>
    </div>
</div>