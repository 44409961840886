import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "../services";

@Injectable()
export class AdminLoginAuthGuard implements CanActivate {
  constructor(public router: Router,
    private readonly authService: AuthService) {}
  canActivate(): boolean {
    if (this.authService.isAdminAuthenticated()) {
      this.router.navigate(['/admin-dashboard']);
      return false;
    }
    return true;
  }
}