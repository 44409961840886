import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ContactUsTemplate } from '../shared/emailTemplates';
import { Email } from '../shared/models';
import { EmailService } from '../shared/services';
import { EmailWhatsapp } from '../shared/models/email-whatsapp.model';


@Component({
  selector: 'rentofly-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactUsForm: FormGroup;
  showContactValidationMessage;
  sendingEmail = false;
  constructor(
    private readonly emailService: EmailService,
    private _snackBar: MatSnackBar,
  ) {
    this.contactUsForm = new FormGroup({
      fullName: new FormControl('', [
        Validators.required
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      contactNumber: new FormControl('', [
        Validators.required
      ]),
      additionalDetail: new FormControl('', [
        Validators.required
      ]),
    })
   }

  ngOnInit(): void {
  }

  get fullName() {
    return this.contactUsForm!.get('fullName');
  }

  get email() {
    return this.contactUsForm!.get('email');
  }

  get contactNumber() {
    return this.contactUsForm!.get('contactNumber');
  }

  get additionalDetail() {
    return this.contactUsForm!.get('additionalDetail');
  }

  sendEmail(contactUsForm)
  {
    this.showContactValidationMessage = false;
    if(this.contactUsForm.invalid)
    {
      this.showContactValidationMessage = true;
      return;
    }
    let contactTemplate = ContactUsTemplate;
    contactTemplate = contactTemplate.replace('{Customer Name}', contactUsForm.fullName);
    contactTemplate = contactTemplate.replace('{Customer Email}', contactUsForm.email);
    contactTemplate = contactTemplate.replace('{Customer Mobile Number}', contactUsForm.contactNumber);
    contactTemplate = contactTemplate.replace('{Customer Enquiry/Feedback}', contactUsForm.additionalDetail);
    // let email: Email = {
    //   to: environment.emailUsers.infoEmail,
    //   from: environment.emailUsers.infoEmail,
    //   subject: `Customer Contact: ${contactUsForm.email}`,
    //   htmlBody: contactTemplate
    // }
    let email : EmailWhatsapp = {
      senderName: 'Team rentofly',
      receiverName: environment.emailUsers.infoEmailName,
      receiverEmail: environment.emailUsers.infoEmail,
      type: "",
      template: "contact_us",
      messageParameters: {
        subjectTextValue: "Rentofly - Partner Verification Completed",
        text: contactUsForm.additionalDetail,
        userName: 'Team',
        requesterNumber: contactUsForm.contactNumber,
      }
}
    this.sendingEmail = true;
    
      this.emailService.sendEmail(email).pipe(
        map(
          (response) =>{
            this.sendingEmail = false;
            this._snackBar.open('Thank you for contacting us. We will be in touch soon.', "X", {
              duration: 10000,
              panelClass: ['snackbar-success']
            });
            this.contactUsForm.reset();
          }),
          catchError((error) => {
            this.sendingEmail = false;
            this._snackBar.open('There was something wrong. Please try again.', "X", {
              duration: 10000,
              panelClass: ['snackbar-failure']
            });
            return error;
          })
        ).subscribe();
  }

}
