import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AddressService } from "src/app/shared/services";
import * as addressActions from "src/app/store/actions/address.action";
import { catchError, map, switchMap } from "rxjs/operators";
import { Address } from "src/app/shared/models";
import { of } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class AddressEffects{
    constructor(
        private readonly actions$: Actions,
        private readonly addressService: AddressService,
        private _snackBar: MatSnackBar,
    ){}

    getAddressByPincode$ = createEffect(() =>
        this.actions$.pipe(
            ofType(addressActions.queryAddressByPincode),
            switchMap((action) => 
                this.addressService.getAddressByPinCode(action.pincode).pipe(
                    map((data) => addressActions.queryAddressByPincodeSuccess({address: data.message})),
                    catchError((error: any) => {
                        this._snackBar.open('There was some error retreiving address. Please enter manually.', "X", {
                            duration: 10000,
                            panelClass: ['snackbar-failure']
                        });
                        return of(addressActions.queryAddressByPincodeFailure(error))
                    }))
                )
            )
    );
}