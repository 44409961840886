import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Deal } from "../models";
import { BaseService } from "./base.service";

@Injectable()
export class DealService extends BaseService {
    //private dealQueryUrl = this.baseQueryUrl() + "/Deal/";
    private dealQueryUrl = this.baseApiUrl() + "/Deal/";
    public initiateData;
    public dealInfoData : BehaviorSubject<any> = new BehaviorSubject(null);
   
    getDealById(id: string): Observable<Deal>{
        const url = `${this.dealQueryUrl}${id}`;
        const headers = this.getHeader();
        return this.getHttpClient().get<Deal>(url, { headers: headers }).pipe(
            map((response: Deal) => response)
        );
    }
}