import { createEntityAdapter, Dictionary, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import { Deal } from "src/app/shared/models";
import { addDeal, addDealFailure, addDealSuccess, initCurrentDeal, queryDealById, queryDealByIdFailure, queryDealByIdSuccess, updateDeal, updateDealFailure, updateDealStatus, updateDealStatusFailure, updateDealStatusSuccess, updateDealSuccess, updateNoticeDateAndStatus, updateNoticeDateAndStatusFailure, updateNoticeDateAndStatusSuccess } from "../actions";

export function getDealReducer(state: DealState | undefined, action: Action) {
    return dealReducer(state, action);
}

export const dealAdapter = createEntityAdapter<Deal>();

export interface DealState extends EntityState<Deal> {
    entities: Dictionary<Deal>;
    error: any;
    currentDeal: Deal;
    currentDealLoading: boolean;
    dealEditing: boolean;
}

export const initialDealState: DealState = dealAdapter.getInitialState({
    entities: [],
    error: null,
    currentDeal: null,
    currentDealLoading: false,
    dealEditing: false
});

export const dealReducer = createReducer(
    initialDealState,

    on(queryDealById, (state) => {
        return  {
            ...state,
            currentDealLoading: true,
            currentDeal: null
        };
    }),

    on(queryDealByIdSuccess, (state, {deal}) => {
        return  {
            ...state,
            currentDealLoading: false,
            currentDeal: deal
        };
    }),

    on(queryDealByIdFailure, (state, {error}) => {
        return  {
            ...state,
            currentDealLoading: false,
            error
        };
    }),

    on(addDeal, (state, {propertyId, deal}) => {
        return  {
            ...state,
            currentDeal: deal,
            dealEditing: true
        };
    }),

    on(addDealFailure, (state, {error}) => {
        return  {
            ...state,
            error,
            dealEditing: false
        };
    }),

    on(addDealSuccess, (state) => {
        return  {
            ...state,
            dealEditing: false
        };
    }),

    on(updateDeal, (state, {propertyId, deal}) => {
        return  {
            ...state,
            currentDeal: deal,
            dealEditing: true
        };
    }),

    on(updateDealFailure, (state, {error}) => {
        return  {
            ...state,
            error,
            dealEditing: false
        };
    }),

    on(updateDealSuccess, (state) => {
        return  {
            ...state,
            dealEditing: false
        };
    }),

    on(initCurrentDeal, (state) => {
        return  {
            ...initialDealState
        };
    }),

    on(updateDealStatus, (state) => {
        return {
            ...state,
            dealEditing: true
        }
    }),

    on(updateDealStatusSuccess, (state) => {
        return {
            ...state,
            dealEditing: false
        }
    }),

    on(updateDealStatusFailure, (state) => {
        return {
            ...state,
            dealEditing: false
        }
    }),

    on(updateNoticeDateAndStatus, (state) => {
        return {
            ...state,
            dealEditing: true
        }
    }),

    on(updateNoticeDateAndStatusSuccess, (state) => {
        return {
            ...state,
            dealEditing: false
        }
    }),

    on(updateNoticeDateAndStatusFailure, (state) => {
        return {
            ...state,
            dealEditing: false
        }
    })
)