import { createEntityAdapter, Dictionary, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import { Owner, OwnerFinances, User } from "src/app/shared/models";
import { addOwner, addOwnerFailure, addOwnerSuccess, getOwnerFinances, getOwnerFinancesFailure, getOwnerFinancesSuccess, initCurrentOwner, queryOwnerById, queryOwnerByIdFailure, queryOwnerByIdSuccess, queryUserForOwner, queryUserForOwnerFailure, queryUserForOwnerSuccess, updateOwner, updateOwnerFailure, updateOwnerSuccess } from "../actions";

export function getOwnerReducer(state: OwnerState | undefined, action: Action) {
    return ownerReducer(state, action);
}

export const ownerAdapter = createEntityAdapter<Owner>();

export interface OwnerState extends EntityState<Owner> {
    entities: Dictionary<Owner>;
    error: any;
    currentOwner: Owner;
    currentOwnerLoading: boolean;
    currentUserForOwner: User;
    currentUserForOwnerLoading: boolean;
    editingOwner: boolean;
    ownerFinances: OwnerFinances;
}

export const initialOwnerState: OwnerState = ownerAdapter.getInitialState({
    entities: [],
    error: null,
    currentOwner: null,
    currentOwnerLoading: false,
    currentUserForOwner: null,
    currentUserForOwnerLoading: false,
    editingOwner: false,
    ownerFinances: null
});

export const ownerReducer = createReducer(
    initialOwnerState,

    on(queryOwnerById, (state) => {
        return  {
            ...state,
            currentOwnerLoading: true,
            currentOwner: null
        };
    }),

    on(queryOwnerByIdSuccess, (state, {owner}) => {
        return  {
            ...state,
            currentOwnerLoading: false,
            currentOwner: owner
        };
    }),

    on(queryOwnerByIdFailure, (state, {error}) => {
        return  {
            ...state,
            currentOwnerLoading: false,
            error
        };
    }),

    on(addOwner, (state, {propertyId, owner}) => {
        return  {
            ...state,
            currentOwner: owner,
            editingOwner: true
        };
    }),

    on(addOwnerSuccess, (state) => {
        return  {
            ...state,
            editingOwner: false
        };
    }),

    on(addOwnerFailure, (state, {error}) => {
        return  {
            ...state,
            error,
            editingOwner: false
        };
    }),

    on(updateOwner, (state, {propertyId, owner}) => {
        return  {
            ...state,
            currentOwner: owner,
            editingOwner: true
        };
    }),

    on(updateOwnerSuccess, (state) => {
        return  {
            ...state,
            editingOwner: false
        };
    }),

    on(updateOwnerFailure, (state, {error}) => {
        return  {
            ...state,
            error,
            editingOwner: false
        };
    }),

    on(initCurrentOwner, (state) => {
        return  {
            ...initialOwnerState
        };
    }),

    on(queryUserForOwner, (state) => {
        return  {
            ...state,
            currentUserForOwnerLoading: true,
            currentUserForOwner: null
        };
    }),

    on(queryUserForOwnerSuccess, (state, {user}) => {
        return  {
            ...state,
            currentUserForOwnerLoading: false,
            currentUserForOwner: user
        };
    }),

    on(queryUserForOwnerFailure, (state, {error}) => {
        return  {
            ...state,
            currentUserForOwnerLoading: false,
            currentUserForOwner: null,
            error
        };
    }),

    on(getOwnerFinances, (state) => {
        return  {
            ...state,
            ownerFinances: null
        };
    }),

    on(getOwnerFinancesSuccess, (state, {ownerFinances}) => {
        return  {
            ...state,
            ownerFinances
        };
    }),

    on(getOwnerFinancesFailure, (state, {error}) => {
        return  {
            ...state,
            error
        };
    }),
)