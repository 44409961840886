<head>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="description" content="Manage your rental property with ease using our AI-powered platform. Get personalized recommendations, flexible rent payment options, automated reminders, and centralized document management. Trust and transparency are at the core of our platform, making each rental transaction secure and hassle-free. Simplify your rental journey today.">
    <meta name="keywords" content="Rental finance management, Property management software, Tenant management system, Rent payment processing, Property listing management, Lease agreement management, Online rent payment, Rental property accounting, Tenant screening, Rent collection automation, Maintenance request management, Property owner dashboard, Real estate software, Online property management, Property management platform, Rental management app, Property management tool, Automated rent collection, Tenant communication platform">
    <meta name="robots" content="index, follow">
</head>
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-Y3D2LBP9KV"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-Y3D2LBP9KV');
</script>
<!-- Google tag (gtag.js) -->
<div class="container admin-login">
    <div class="row">
        <div class="col-md-4">&nbsp;</div>
        <div class="col-md-4 login-box">
            <h2>Rentofly Admin Console</h2>
            <form [formGroup]="adminLoginForm" class="admin-login-form">
                <div class="form-group admin-login-main-div">
                    <label class="input-label">Email</label>
                    <input id="email" class="admin-login-input" type="text" formControlName="email" pInputText>
                    <div *ngIf="email!.invalid && showLoginValidationMessage" class="rentofly-login-validation-msg ">
                        <div>
                            Email is invalid.
                        </div>
                    </div>
                </div>
                <br />
                <div class="form-group admin-login-main-div">
                    <label class="input-label">Password</label>
                    <input id="password" class="admin-login-input" type="password" formControlName="password" pInputText>
                    <div *ngIf="password!.invalid && showLoginValidationMessage" class="rentofly-login-validation-msg ">
                        <div>
                            Password is invalid.
                        </div>
                    </div>
                </div>
                <br />
                <div class="action">
                    <button class="rentofly-tertiary partner-confirm-btn" (click)="adminLogin(adminLoginForm.value)">Login</button>
                </div>
            </form>
        </div>
        <div class="col-md-4">&nbsp;</div>
    </div>
</div>