import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PropertyInfo, PropertyListRequest } from 'src/app/shared/models';
import { LocalStorageService, WindowService } from 'src/app/shared/services';
import { PropertyStoreFacade } from 'src/app/store/facades/property.store-facade';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'rentofly-rented-property',
  templateUrl: './rented-property.component.html',
  styleUrls: ['./rented-property.component.scss']
})
export class RentedPropertyComponent implements OnInit {
  totalProperties$: Observable<number>;
  pageSize$: Observable<number>;
  pageIndex$: Observable<number>;
  propertyListRequest: PropertyListRequest;
  allProperties$: Observable<PropertyInfo[]>;
  isPropertyLoading$: Observable<boolean>;
  currentUser: any;
  public displayedColumns: string[] = ['propertyName', 'propertyStatus', 'rentPerMonth', 'ownerName', 'tenantName', 'brokerName'];
  constructor(
    public router: Router,
    private readonly propertyFacade: PropertyStoreFacade,
    private readonly localStorageService: LocalStorageService,
    private readonly windowService: WindowService
  ) { 
    
  }

  ngOnInit(): void {
    if(this.windowService.isMobileBrowser()){
      this.displayedColumns = ['propertyName', 'propertyStatus', 'rentPerMonth'];
    }
    this.propertyFacade.setPropertyListRequest({
      pageIndex: 0,
      pageSize: 10,
      orderBy: '',
      orderByColumn: null
    });
    this.currentUser = this.localStorageService.getObjectFromLocalStorage('current-user');
    this.propertyFacade.queryPaginatedRentedProperty(this.currentUser.id);
    this.totalProperties$ = this.propertyFacade.getTotalProperties$;
    this.pageSize$ = this.propertyFacade.pageSize$;
    this.pageIndex$ = this.propertyFacade.pageIndex$;
    this.propertyFacade.propertyListRequest$.subscribe((data) => {
      if(data)
      {
        this.propertyListRequest = data;
      }
    })
    this.allProperties$ = this.propertyFacade.selectAllProperties$;
    this.isPropertyLoading$ = this.propertyFacade.isPropertyLoading$;
  }

  viewProperty(propertyId: string){
    this.router.navigate(['dashboard/view-property-info'], { queryParams: { propertyIdentifier: propertyId }});
  }

  onPageChanged(pageEvent: PageEvent){
    let propertyListRequest = {
      ...this.propertyListRequest,
      pageIndex: pageEvent.pageIndex,
      pageSize: pageEvent.pageSize
    }
    this.propertyFacade.setPropertyListRequest(propertyListRequest);
    this.propertyFacade.queryPaginatedRentedProperty(this.currentUser.id);
  }

  isMobileBrowser(): boolean {
    return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 1000 ) );
  }

  refreshList() {
    this.propertyFacade.queryPaginatedRentedProperty(this.currentUser.id);
  }

  getPropertyStatus(propertyInfo: PropertyInfo){
    if(propertyInfo.dealStatus == 'Termination in Progress')
    {
      return 'Deal Termination in Progress';
    }
    if(propertyInfo.dealStatus == 'Notice Period')
    {
      return 'On notice period';
    }
    return 'Rented';
  }

  sortChange(sortState: Sort){
    let propertyListRequest = {
      ...this.propertyListRequest,
      orderBy: sortState.direction,
      orderByColumn: sortState.direction ? sortState.active : null
    }
    this.propertyFacade.setPropertyListRequest(propertyListRequest);
    this.propertyFacade.queryPaginatedRentedProperty(this.currentUser.id);
  }
}
