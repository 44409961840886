import { Injectable } from "@angular/core";
import { User } from "../models";
import { catchError, map, switchMap } from "rxjs/operators";
import { of, Observable } from "rxjs";
import { BaseService } from "./base.service";

@Injectable()
export class UserService extends BaseService{
    private userQueryUrl = this.baseQueryUrl() + "/Users/";
    private userCommandUrl = this.baseCommandUrl() + "/Users/";
    private userApiUrl = this.baseApiUrl() + "/user/";

    getUserByPhoneNumber(phoneNumber: string): Observable<any | User> {
        const url = this.userApiUrl + "getUserByPhoneNumber/" + phoneNumber;
        const headers = this.getHeader();
        return this.getHttpClient().get<any>(url, { headers: headers }).pipe(
            map((response) => response)
        );
    }

    addUser(user: User): Observable<any | string>{
        const url = this.userCommandUrl + "addUser";
        const headers = this.getHeader();
        return this.getHttpClient().post(url, user, {headers: headers}).pipe(
            map((response) => user.phoneNumber),
            catchError((error) => {
                console.log(error);
                return error;
            })
        );
    }

    updateUser(id: string, user: Partial<User>): Observable<any | string>{
        const url = this.userCommandUrl + "updateUser/" + id;
        const headers = this.getHeader();
        return this.getHttpClient().put(url, user, {headers: headers}).pipe(
            map((response) => user.phoneNumber),
            catchError((error) => {
                console.log(error);
                return error;
            })
        );
    }
}