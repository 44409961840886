import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'rentofly-confirm-date-box',
  templateUrl: './confirm-date-box.component.html',
  styleUrls: ['./confirm-date-box.component.scss']
})
export class ConfirmDateBoxComponent implements OnInit {
  public confirmMessageHeader: string;
  public confirmMessageContent: string;
  public confirmButtonText: string;
  public dateFieldValue: Date;
  dateForm: FormGroup;
  showDateValidationMessage: boolean;
  
  constructor(public dialogRef: MatDialogRef<ConfirmDateBoxComponent>) { }

  get noticeEndDate() {
    return this.dateForm!.get('noticeEndDate');
  }

  ngOnInit(): void {
    console.log(this.dateFieldValue);
    console.log(this.confirmButtonText);
    this.dateForm = new FormGroup({
      noticeEndDate: new FormControl(this.dateFieldValue, [])
    });
  }

  onConfirm(dateForm){
    this.showDateValidationMessage = false;
    if(this.dateForm.invalid){
      this.showDateValidationMessage = true;
      return;
    }
    this.dialogRef.close(dateForm)
  }

}
