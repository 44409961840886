import { Injectable } from "@angular/core";
import * as fromDeal from '../selectors/deal.selector';
import * as dealActions from "../actions/deal.action";
import { Store } from "@ngrx/store";
import { DealState } from "../reducers";
import { Deal, Email, UpdateDeal } from "src/app/shared/models";
import { Observable } from "rxjs";
import { EmailWhatsapp } from "src/app/shared/models/email-whatsapp.model";

export interface IDealStoreFacade {

    //getCurrentDeal
    currentDealLoading$: Observable<boolean>;
    currentDeal$: Observable<Deal>;
    dealEditing$: Observable<boolean>;

    queryDealById(id: string): void;
    addDeal(propertyId: string, deal: Deal, initiateDeal: boolean, emails: EmailWhatsapp[]): void;
    updateDeal(idpropertyId: string, deal: Deal, initiateDeal: boolean, emails: EmailWhatsapp[]): void;
    initCurrentDeal(): void;
    updateDealStatus(id: string, status: string, emails: EmailWhatsapp[]): void;
    updateNoticeDateAndStatus(id: string, updateDeal: UpdateDeal, emails: EmailWhatsapp[]): void;
}

@Injectable()
export class DealStoreFacade implements IDealStoreFacade {

    get currentDealLoading$(){
        return this.store.select(fromDeal.currentDealLoading$);
    }

    get currentDeal$(){
        return this.store.select(fromDeal.currentDeal$);
    }

    get dealEditing$(){
        return this.store.select(fromDeal.dealEditing$);
    }

    constructor(
        private readonly store: Store<DealState>,
    ){}

    queryDealById(id:string){
        this.store.dispatch(dealActions.queryDealById({id}));
    }

    addDeal(propertyId: string, deal: Deal, initiateDeal: boolean, emails: EmailWhatsapp[]): void{
        this.store.dispatch(dealActions.addDeal({propertyId, deal, initiateDeal, emails}));
    }
    
    updateDeal(propertyId: string, deal: Deal, initiateDeal: boolean, emails: EmailWhatsapp[]): void{
        this.store.dispatch(dealActions.updateDeal({propertyId, deal, initiateDeal, emails}));
    }
    initCurrentDeal(): void {
        this.store.dispatch(dealActions.initCurrentDeal());
    }

    updateDealStatus(id: string, status: string, emails: EmailWhatsapp[] = []): void{
        this.store.dispatch(dealActions.updateDealStatus({id, status, emails}));
    }

    updateNoticeDateAndStatus(id: string, updateDeal: UpdateDeal, emails: EmailWhatsapp[] = []): void{
        this.store.dispatch(dealActions.updateNoticeDateAndStatus({id, updateDeal, emails}));
    }
}