import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CreateDashboardState } from "..";
import { attachmentsAdapter, AttachmentsState } from "../reducers";

export const getCreateDashboardState = createFeatureSelector<CreateDashboardState>('dashboard');
export const getAttachmentsState = createSelector(
    getCreateDashboardState,
    (state: CreateDashboardState) => state.attachments
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = attachmentsAdapter.getSelectors(getAttachmentsState);

export const isAttachmentsLoading$ = createSelector(getAttachmentsState, (state: AttachmentsState) => state.loading);
export const progress$ = createSelector(getAttachmentsState, (state: AttachmentsState) => state.progress);
export const attachmentsError$ = createSelector(getAttachmentsState, (state: AttachmentsState) => state.error);

export const linkLoading$ = createSelector(getAttachmentsState, (state: AttachmentsState) => state.linkLoading);
export const link$ = createSelector(getAttachmentsState, (state: AttachmentsState) => state.link);
export const newAttachmentUrl$ = createSelector(getAttachmentsState, (state: AttachmentsState) => state.newAttachmentUrl);