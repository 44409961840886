import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Partner, PartnerRequest } from 'src/app/shared/models';
import { WindowService } from 'src/app/shared/services';
import { PartnerStoreFacade } from 'src/app/store/facades';

@Component({
  selector: 'rentofly-approved-partner-dashboard',
  templateUrl: './approved-partner-dashboard.component.html',
  styleUrls: ['./approved-partner-dashboard.component.scss']
})
export class ApprovedPartnerDashboardComponent implements OnInit {

  isPartnerLoading$: Observable<boolean>;
  totalPartners: number;
  allPartners$: Observable<Partner[]>;
  partnerRequest: PartnerRequest;
  public displayedColumns: string[] = ['name', 'phoneNumber', 'emailAddress', 'pinCode', 'status'];
  
  constructor(
    private readonly partnerFacade: PartnerStoreFacade,
    private readonly windowService: WindowService,
    private readonly router: Router,
  ) { }

  ngOnInit(): void {
    if(this.windowService.isMobileBrowser()){
      this.displayedColumns = ['name', 'emailAddress', 'status'];
    }
    this.partnerFacade.setPartnerRequest({
      pageIndex: 0,
      pageSize: 10,
      orderBy: '',
      orderByColumn: null,
      status: 'Approved'
    });
    this.isPartnerLoading$ = this.partnerFacade.partnerLoading$;
    this.partnerFacade.queryPartners();
    this.partnerFacade.totalPartners$.subscribe((data) =>{
      this.totalPartners = data;
    });
    this.allPartners$ = this.partnerFacade.selectAllPartners$;
    this.partnerFacade.partnerRequest$.subscribe((data)=>{
      if(data)
      {
        this.partnerRequest = data;
      }
    })

  }

  sortChange(sortState: Sort){
    let partnerRequest = {
      ...this.partnerRequest,
      orderBy: sortState.direction,
      orderByColumn: sortState.direction ? sortState.active : null
    }
    this.partnerFacade.setPartnerRequest(partnerRequest);
    this.partnerFacade.queryPartners();
  }

  isMobileBrowser(): boolean {
    return this.windowService.isMobileBrowser();
  }

  onPageChanged(pageEvent: PageEvent){
    let partnerRequest = {
      ...this.partnerRequest,
      pageIndex: pageEvent.pageIndex,
      pageSize: pageEvent.pageSize
    }
    this.partnerFacade.setPartnerRequest(partnerRequest);
    this.partnerFacade.queryPartners();
  }

  refreshList() {
    this.partnerFacade.queryPartners();
  }

  viewPartner(partnerId)
  {
    this.router.navigate(['/admin-partner-view'], { queryParams: { partnerIdentifier: partnerId }});
  }

}
