import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EmailService, OwnerService, PropertyService } from "src/app/shared/services";
import * as ownerActions from "../actions/owner.action";
import { catchError, map, switchMap } from "rxjs/operators";
import { Owner, OwnerFinances, User } from "../../shared/models";
import { of } from "rxjs";
import { UserService } from "src/app/shared/services/user.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class OwnerEffects{
    constructor(
        private readonly actions$: Actions,
        private readonly propertyService: PropertyService,
        private readonly userService: UserService,
        private readonly ownerService: OwnerService,
        private readonly emailService: EmailService,
        private _snackBar: MatSnackBar,
    ){}

    getOwnerById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ownerActions.queryOwnerById),
            switchMap((action) => 
                this.ownerService.getOwnerById(action.id).pipe(
                    map((data) => ownerActions.queryOwnerByIdSuccess({owner: data.message})),
                    catchError((error: any) => {
                        if(error.status != 404)
                        {
                            this._snackBar.open('The action performed was not successful. Please contact admin.', "X", {
                                duration: 10000,
                                panelClass: ['snackbar-failure']
                            });
                        }
                        return of(ownerActions.queryOwnerByIdFailure(error))
                    }))
                )
            )
    );

    addOwner$ = createEffect(() =>
    this.actions$.pipe(
        ofType(ownerActions.addOwner),
        switchMap((action)=>
            this.userService.getUserByPhoneNumber(action.owner.phoneNumber).pipe(
                map((data) => {
                    //if else condition added to fix delete user issue 
                    if(data.message)
                    {
                        var updateUser: User = {
                            ...data.message,
                            name: data.message?.name ?? action.owner.name,
                            emailAddress: data.message?.emailAddress ?? action.owner.emailAddress
                        }
                        this.userService.updateUser(data.message.id, updateUser).subscribe();
                        let propertyId = action.propertyId;
                        let owner: Owner = {
                            ...action.owner,
                            userId: data.message.id
                        }
                        return ownerActions.addOwnerWithUser({propertyId, owner})
                    } else{
                        let propertyId = action.propertyId;
                        let owner: Owner = {
                            ...action.owner,
                            userId: null
                        }
                        return ownerActions.addOwnerWithUser({propertyId, owner})
                    }
                }),
                catchError((error: any) => {
                    if(error?.status == "404")
                    {
                        let propertyId = action.propertyId;
                        let owner = {
                            ...action.owner,
                            userId: null
                        }
                        return of(ownerActions.addOwnerWithUser({propertyId, owner}))
                    }
                    else{
                        this._snackBar.open('The action performed was not successful. Please contact admin.', "X", {
                            duration: 10000,
                            panelClass: ['snackbar-failure']
                        });
                    }
                })   
            )
        ),
        switchMap((action) => 
            this.propertyService.addOwner(action.propertyId, action.owner).pipe(
                map((data: Owner) => {
                    return ownerActions.addOwnerSuccess()
                }),
                catchError((error: any) => {
                    this._snackBar.open('Adding Owner was not successful. Please contact admin.', "X", {
                        duration: 10000,
                        panelClass: ['snackbar-failure']
                    });
                    return of(ownerActions.addOwnerFailure(error))
                }))
            )
        )
    );

    updateOwner$ = createEffect(() =>
    this.actions$.pipe(
        ofType(ownerActions.updateOwner),
        switchMap((action)=>
            this.userService.getUserByPhoneNumber(action.owner.phoneNumber).pipe(
                map((data) => {
                    //if else condition added to fix delete user issue 
                    if(data.message)
                    {
                        var updateUser: User = {
                            ...data.message,
                            name: data.message?.name ?? action.owner.name,
                            emailAddress: data.message?.emailAddress ?? action.owner.emailAddress
                        }
                        this.userService.updateUser(data.message.id, updateUser).subscribe();
                        let propertyId = action.propertyId;
                        let emails = action.emails;
                        let owner: Owner = {
                            ...action.owner,
                            userId: data.message.id
                        }
                        return ownerActions.updateOwnerWithUser({propertyId, owner, emails})
                    } else{
                        let propertyId = action.propertyId;
                        let emails = action.emails;
                        let owner: Owner = {
                            ...action.owner,
                            userId: null
                        }
                        return ownerActions.updateOwnerWithUser({propertyId, owner, emails})
                    }
                }),
                catchError((error: any) => {
                    if(error?.status == "404")
                    {
                        let propertyId = action.propertyId;
                        let emails = action.emails;
                        let owner = {
                            ...action.owner,
                            userId: null
                        }
                        return of(ownerActions.updateOwnerWithUser({propertyId, owner, emails}))
                    }
                    else{
                        this._snackBar.open('Updating Owner was not successful. Please contact admin.', "X", {
                            duration: 10000,
                            panelClass: ['snackbar-failure']
                        });
                    }
                })   
            )
        ),
        switchMap((action) => 
            this.propertyService.updateOwner(action.propertyId, action.owner).pipe(
                map((data: Owner) => {
                    if(action.emails?.length > 0)
                    {
                        action.emails.forEach((data) => {
                            this.emailService.sendEmail(data).subscribe();
                        });
                    }
                    return ownerActions.updateOwnerSuccess()
                }),
                catchError((error: any) => {
                    this._snackBar.open('Updating Owner was not successful. Please contact admin.', "X", {
                        duration: 10000,
                        panelClass: ['snackbar-failure']
                    });
                    return of(ownerActions.updateOwnerFailure(error))
                }))
            )
        )
    );

    getUserForOwner$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ownerActions.queryUserForOwner),
            switchMap((action) => 
                this.userService.getUserByPhoneNumber(action.phoneNumber).pipe(
                    map((data) => ownerActions.queryUserForOwnerSuccess({user: data.message})),
                    catchError((error: any) => {
                        return of(ownerActions.queryUserForOwnerFailure(error))
                    }))
                )
            )
    );

    updateOwnerConsent$ = createEffect(() =>
    this.actions$.pipe(
        ofType(ownerActions.updateOwnerConsent),
        switchMap((action) => 
            this.propertyService.updateOwnerConsent(action.id, action.status).pipe(
                map(() => {
                    action.emails.forEach((data) => {
                        this.emailService.sendEmail(data).subscribe();
                    });
                    return ownerActions.updateOwnerConsentSuccess()
                }),
                catchError((error: any) => {
                    this._snackBar.open('Updating Owner Consent was not successful. Please contact admin.', "X", {
                        duration: 10000,
                        panelClass: ['snackbar-failure']
                    });
                    return of(ownerActions.updateOwnerConsentFailure(error))
                }))
            )
        )
    );

    getOwnerFinances$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ownerActions.getOwnerFinances),
            switchMap((action) => 
                this.ownerService.getOwnerFinances(action.userId).pipe(
                    map((data) => ownerActions.getOwnerFinancesSuccess({ownerFinances: data.message})),
                    catchError((error: any) => {
                        return of(ownerActions.getOwnerFinancesFailure(error))
                    }))
                )
            )
    );
}