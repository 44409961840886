import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AttachmentsService, EmailService, OwnerService, PropertyService } from "src/app/shared/services";
import * as ownerActions from "../actions/owner.action";
import { catchError, finalize, map, switchMap } from "rxjs/operators";
import { FileUploadModel, Owner, User } from "../../shared/models";
import { of } from "rxjs";
import { UserService } from "src/app/shared/services/user.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireStorage } from "@angular/fire/storage";
import * as attachmentActions from '../actions/attachments.action';
import { AttachmentsStoreFacade } from "../facades";

@Injectable()
export class AttachmentsEffects{
    constructor(
        private readonly actions$: Actions,
        private readonly attachmentsService: AttachmentsService,
        private readonly storage: AngularFireStorage,
        private readonly attachmentsFacade: AttachmentsStoreFacade
    ){}

    createAttachment$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(attachmentActions.createAttachment),
            switchMap((data) => {
                const filePath = `${data.attachment.propertyId}/${data.attachment.name}`;
                const storageRef = this.storage.ref(filePath);
                const uploadTask = this.storage.upload(filePath, data.file);

                uploadTask.snapshotChanges().pipe(
                    finalize(() => {
                      storageRef.getDownloadURL().subscribe(downloadURL => {
                        const newAttachmentObject = Object.assign({url: downloadURL}, data.attachment);
                        this.attachmentsFacade.addAttachment(newAttachmentObject);
                        // this.attachmentsService.createAttachment(newAttachmentObject).subscribe();
                      });
                    })
                  ).subscribe();
                return uploadTask.percentageChanges();
            }),
            map((progress) => {
                return attachmentActions.createAttachmentSuccess({progress})
            }),
            catchError(() => {
                return of(attachmentActions.createAttachmentFailure({error: 'error'}))
            })
        )
    });

    createPartnerAttachment$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(attachmentActions.createPartnerAttachment),
            switchMap((data) => {
                const filePath = `${data.attachment.partnerId}/${data.attachment.id}`;
                const storageRef = this.storage.ref(filePath);
                const uploadTask = this.storage.upload(filePath, data.file);

                uploadTask.snapshotChanges().pipe(
                    finalize(() => {
                      storageRef.getDownloadURL().subscribe(downloadURL => {
                        const newAttachmentObject = Object.assign({url: downloadURL}, data.attachment);
                        this.attachmentsFacade.addPartnerAttachment(newAttachmentObject);
                        // this.attachmentsService.createAttachment(newAttachmentObject).subscribe();
                      });
                    })
                  ).subscribe();
                return uploadTask.percentageChanges();
            }),
            map((progress) => {
                return attachmentActions.createPartnerAttachmentSuccess({progress})
            }),
            catchError(() => {
                return of(attachmentActions.createPartnerAttachmentFailure({error: 'error'}))
            })
        )
    });

    addAttachment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(attachmentActions.addAttachment),
            switchMap((action) => 
                this.attachmentsService.createAttachment(action.attachment).pipe(
                    map(() => attachmentActions.getAttachmentsByPropertyId({propertyId: action.attachment.propertyId})),
                    catchError((error: any) => {
                        return of(attachmentActions.addAttachmentFailure(error))
                    }))
                )
            )
    );

    addPartnerAttachment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(attachmentActions.addPartnerAttachment),
            switchMap((action) => 
                this.attachmentsService.createAttachment(action.attachment).pipe(
                    map(() => attachmentActions.getAttachmentsByPartnerId({partnerId: action.attachment.partnerId})),
                    catchError((error: any) => {
                        return of(attachmentActions.addPartnerAttachmentFailure(error))
                    }))
                )
            )
    );

    getAttachmentsByPropertyId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(attachmentActions.getAttachmentsByPropertyId),
            switchMap((action) => 
                this.attachmentsService.getAttachmentsByPropertyId(action.propertyId).pipe(
                    map((data) => attachmentActions.getAttachmentsByPropertyIdSuccess({attachments: data.message})),
                    catchError((error: any) => {
                        return of(attachmentActions.getAttachmentsByPropertyIdFailure(error))
                    }))
                )
            )
    );

    getAttachmentsByPartnerId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(attachmentActions.getAttachmentsByPartnerId),
            switchMap((action) => 
                this.attachmentsService.getAttachmentsByPartnerId(action.partnerId).pipe(
                    map((data: FileUploadModel[]) => attachmentActions.getAttachmentsByPartnerIdSuccess({attachments: data})),
                    catchError((error: any) => {
                        return of(attachmentActions.getAttachmentsByPartnerIdFailure(error))
                    }))
                )
            )
    );

    deleteAttachmentsById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(attachmentActions.deleteAttachmentsById),
            switchMap((action) => 
                this.attachmentsService.deleteAttachmentById(action.id).pipe(
                    map((id: string) => attachmentActions.deleteAttachmentsByIdSuccess({id})),
                    catchError((error: any) => {
                        return of(attachmentActions.deleteAttachmentsByIdFailure(error))
                    }))
                )
            )
    );

    createTempAttachment$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(attachmentActions.createTempAttachment),
            switchMap((data) => {
                const filePath = `${data.attachment.propertyId}/${data.attachment.name}`;
                const storageRef = this.storage.ref(filePath);
                const uploadTask = this.storage.upload(filePath, data.file);

                uploadTask.snapshotChanges().pipe(
                    finalize(() => {
                      storageRef.getDownloadURL().subscribe(downloadURL => {
                        this.attachmentsFacade.addTempAttachmentLink(downloadURL);
                      });
                    })
                  ).subscribe();
                return uploadTask.percentageChanges();
            }),
            map((progress) => {
                return attachmentActions.createTempAttachmentSuccess({progress})
            }),
            catchError(() => {
                return of(attachmentActions.createTempAttachmentFailure({error: 'error'}))
            })
        )
    });
}