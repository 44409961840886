import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { JwtHelperService } from '@auth0/angular-jwt';
import { of } from "rxjs";
import { LocalStorageService } from "./local-storage.service";

@Injectable()
export class AuthService{
    constructor(
        private firebaseAuth: AngularFireAuth,
        private readonly localStorageService: LocalStorageService,
        @Inject(PLATFORM_ID) private readonly platformId: any,
        private jwtHelper: JwtHelperService
    ) {}

    isAuthenticated() {
        const jwtHelper = new JwtHelperService();
        let user = null;
        if (isPlatformBrowser(this.platformId)) {
            user = JSON.parse(this.localStorageService.getFromLocalStorage('dashboard-user'));
        }
        let accessToken = user?.stsTokenManager?.accessToken;
        let decodedToken = jwtHelper.decodeToken(accessToken);
        if (this.jwtHelper.isTokenExpired(accessToken)) {
            this.localStorageService.removeFromLocalStorage('dashboard-user');
            return false;
        } 

        if(user && user.uid === decodedToken?.user_id && user.phoneNumber)
        {
            return true;
        }
        this.firebaseAuth.signOut();
        return false;
    }

    getAccessToken(){
        let user = null;
        if (isPlatformBrowser(this.platformId)) {
            user = JSON.parse(this.localStorageService.getFromLocalStorage('dashboard-user'));
        }
        return user?.stsTokenManager?.accessToken;
    }

    isAdminAuthenticated() {
        const jwtHelper = new JwtHelperService();
        let user = null;
        if (isPlatformBrowser(this.platformId)) {
            user = JSON.parse(this.localStorageService.getFromLocalStorage('dashboard-user'));
        }
        let accessToken = user?.stsTokenManager?.accessToken;
        let decodedToken = jwtHelper.decodeToken(accessToken);
        if (this.jwtHelper.isTokenExpired(accessToken)) {
            this.localStorageService.removeFromLocalStorage('dashboard-user');
            return false;
        } 
        
        if(user && user.uid === decodedToken?.user_id && user.email)
        {
            return true;
        }
        this.firebaseAuth.signOut();
        return false;
    }
}