import { createAction, props } from "@ngrx/store";
import { Address } from "src/app/shared/models";

export const queryAddressByPincode = createAction(
    '[Address] query Address by pincode',
    props<{pincode: string}>()
);

export const queryAddressByPincodeSuccess = createAction(
    '[Address] query Address by pincode Success',
    props<{address: Address[]}>()
);

export const queryAddressByPincodeFailure = createAction(
    '[Address] query Address by pincode Failure',
    props<{error: any}>()
);