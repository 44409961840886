import { createEntityAdapter, Dictionary, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import { Address } from "src/app/shared/models";
import { queryAddressByPincode, queryAddressByPincodeFailure, queryAddressByPincodeSuccess } from "../actions";

export function getAddressReducer(state: AddressState | undefined, action: Action) {
    return addressReducer(state, action);
}

export const addressAdapter = createEntityAdapter<Address>();

export interface AddressState extends EntityState<Address> {
    entities: Dictionary<Address>;
    error: any;
    currentAddress: Address;
    currentAddressLoading: boolean;
}

export const initialAddressState: AddressState = addressAdapter.getInitialState({
    entities: [],
    error: null,
    currentAddress: null,
    currentAddressLoading: false
});

export const addressReducer = createReducer(
    initialAddressState,

    on(queryAddressByPincode, (state) => {
        return  addressAdapter.removeAll({
            ...state,
            currentAddressLoading: true,
            currentAddress: null,
            error: null
        });
    }),

    on(queryAddressByPincodeSuccess, (state, {address}) => {
        return  addressAdapter.addMany(address,{
            ...state,
            currentAddressLoading: false,
            currentAddress: address && address.length > 0 ? address[0] : null,
            error: null
        });
    }),

    on(queryAddressByPincodeFailure, (state, {error}) => {
        return  {
            ...state,
            currentAddressLoading: false,
            error
        };
    }),
)