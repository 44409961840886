import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UUID } from 'angular2-uuid';
import { User } from 'src/app/shared/models';
import { LocalStorageService } from 'src/app/shared/services';
import { UsersStoreFacade } from 'src/app/store/facades/users.store-facade';

@Component({
  selector: 'app-dashboard-home',
  templateUrl: './dashboard-home.component.html',
  styleUrls: ['./dashboard-home.component.scss']
})
export class DashboardHomeComponent implements OnInit {

  viewTabStyle = 'active-style';
  addTabStyle = 'inactive-style';
  loading = true;
  currentUser: User;
  newPropertyId = UUID.UUID()
  addElementDisabled = false;
  index:number;
  constructor(
    public router: Router,
    private readonly localStorageService: LocalStorageService,
    private readonly userFacade: UsersStoreFacade,
  ) { 
    this.router.events.subscribe((evt) => {
      if ((evt instanceof NavigationEnd)) {
        if(evt.url.includes("/add"))
        {
          this.changeStyle("add");
        }
        else if(evt.url.includes("/view")){
          this.changeStyle("view");
        }
        if(evt.url.includes("/add-property"))
        {
          
          this.addElementDisabled = true;
          this.index = 1;
        }
        else{
          
          this.addElementDisabled = false;
          this.index = 0;
        }
      }
    });
  }

  ngOnInit(): void {
    let userCreds = JSON.parse(this.localStorageService.getFromLocalStorage('dashboard-user'));
    this.userFacade.queryUserByPhoneNumber(userCreds.phoneNumber);
    let userNoResult = this.userFacade.noUserResult$;
    let currentUser = this.userFacade.getCurrentUser$;
    userNoResult.subscribe(data => {
      if(data)
      {
        let user: User = {
          id: UUID.UUID(),
          firebaseUserId: userCreds.uid,
          phoneNumber: userCreds.phoneNumber,
          createdDate: new Date(),
        }
        this.localStorageService.addObjectToLocalStorage('current-user',user);
        this.userFacade.addUser(user);
      }
    })
    currentUser.subscribe(data => {
      if(data)
      {
        let user: User = {
          id: data.id,
          firebaseUserId: data.firebaseUserId,
          phoneNumber: data.phoneNumber,
          createdDate: data.createdDate,
          emailAddress: data.emailAddress,
          partnerStatus: data.partnerStatus
        }
        this.localStorageService.addObjectToLocalStorage('current-user',user);
        this.currentUser = data;
        this.loading = false;
      }
    })
  }

  changeStyle(tabName: string): void {
    if(tabName == "view"){
      this.viewTabStyle = 'active-style';
      this.addTabStyle = 'inactive-style';
    }
    if(tabName == "add"){
      this.addTabStyle = 'active-style';
      this.viewTabStyle = 'inactive-style';
    }
  }
 
}
