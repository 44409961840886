import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Owner, OwnerFinances } from "../models";
import { BaseService } from "./base.service";

@Injectable()
export class OwnerService extends BaseService{
    //private ownerQueryUrl = this.baseQueryUrl() + "/Owner/";
    private ownerQueryUrl = this.baseApiUrl() + "/Owner/";

    getOwnerById(id: string): Observable<any>{
        const url = `${this.ownerQueryUrl}${id}`;
        const headers = this.getHeader();
        return this.getHttpClient().get<Owner>(url, { headers: headers })
    }

    getOwnerFinances(userId: string): Observable<any>{
        const url = `${this.ownerQueryUrl}ownerFinances/${userId}`;
        const headers = this.getHeader();
        return this.getHttpClient().get<OwnerFinances>(url, { headers: headers })
    }
}