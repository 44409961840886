import { createAction, props } from "@ngrx/store";
import { Email, Owner, OwnerFinances, User } from "src/app/shared/models";
import { EmailWhatsapp } from "src/app/shared/models/email-whatsapp.model";

export const queryOwnerById = createAction(
    '[Owner] query Owner by id',
    props<{id: string}>()
);

export const queryOwnerByIdSuccess = createAction(
    '[Owner] query Owner by id Success',
    props<{owner: Owner}>()
);

export const queryOwnerByIdFailure = createAction(
    '[Owner] query Owner by id Failure',
    props<{error: any}>()
);

export const addOwner = createAction(
    '[Owner] add Owner',
    props<{propertyId: string, owner: Owner}>()
);

export const addOwnerWithUser = createAction(
    '[Owner] add Owner with User',
    props<{propertyId: string, owner: Owner}>()
);

export const addOwnerSuccess = createAction(
    '[Owner] add Owner Success'
);

export const addOwnerFailure = createAction(
    '[Owner] add Owner Failure',
    props<{error: any}>()
);

export const updateOwner = createAction(
    '[Owner] update Owner',
    props<{propertyId: string, owner: Owner, emails: EmailWhatsapp[]}>()
);

export const updateOwnerWithUser = createAction(
    '[Owner] update Owner with User',
    props<{propertyId: string, owner: Owner, emails: EmailWhatsapp[]}>()
);

export const updateOwnerSuccess = createAction(
    '[Owner] update Owner Success'
);

export const updateOwnerFailure = createAction(
    '[Owner] update Owner Failure',
    props<{error: any}>()
);

export const initCurrentOwner = createAction(
    '[Owner] init current Owner'
);

export const queryUserForOwner = createAction(
    '[Owner] query User for Owner',
    props<{phoneNumber: string}>()
);

export const queryUserForOwnerSuccess = createAction(
    '[Owner] query User for Owner Success',
    props<{user: User}>()
);

export const queryUserForOwnerFailure = createAction(
    '[Owner] query User for Owner Failure',
    props<{error: any}>()
);

export const updateOwnerConsent = createAction(
    '[Owner] update Owner consent',
    props<{id: string, status: string, emails: Email[]}>()
);

export const updateOwnerConsentSuccess = createAction(
    '[Owner] update Owner consent Success'
);

export const updateOwnerConsentFailure = createAction(
    '[Owner] update Owner consent Failure',
    props<{error: any}>()
);

export const getOwnerFinances = createAction(
    '[Owner] getOwnerFinances',
    props<{userId: string}>()
);

export const getOwnerFinancesSuccess = createAction(
    '[Owner] getOwnerFinances Success',
    props<{ownerFinances: OwnerFinances}>()
);

export const getOwnerFinancesFailure = createAction(
    '[Owner] getOwnerFinancesFailure',
    props<{error: any}>()
);