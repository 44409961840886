<head>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="description" content="Manage your rental property with ease using our AI-powered platform. Get personalized recommendations, flexible rent payment options, automated reminders, and centralized document management. Trust and transparency are at the core of our platform, making each rental transaction secure and hassle-free. Simplify your rental journey today.">
    <meta name="keywords" content="Rental finance management, Property management software, Tenant management system, Rent payment processing, Property listing management, Lease agreement management, Online rent payment, Rental property accounting, Tenant screening, Rent collection automation, Maintenance request management, Property owner dashboard, Real estate software, Online property management, Property management platform, Rental management app, Property management tool, Automated rent collection, Tenant communication platform">
    <meta name="robots" content="index, follow">
</head>
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-Y3D2LBP9KV"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-Y3D2LBP9KV');
</script>
<!-- Google tag (gtag.js) -->
<rentofly-loading *ngIf="(isPropertyLoading$ | async)"></rentofly-loading>
<div *ngIf="!showWelcomePopup && !(isPropertyLoading$ | async)">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col-md-12">
                <div class="page-header-title">
                    <h5 class="headeText">Dashboard</h5>
                    <div class="heading-line"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="row main-row">
        <div class="col-md-3">
            <div class="row">
                <div class="col-md-12">
                    <div class="col-sm-auto p-r-0">
                        <p-avatar icon="pi pi-user" styleClass="p-mr-2" size="medium" [style]="{'background-color':'#2196F3', 'color': '#ffffff'}" shape="circle"></p-avatar>
                        <h4 class="reviewerHeader">{{userName}}</h4>
                    </div>
                </div>
            </div>
            <div class="row deskView">
                <div class="" *ngIf="ratingAsOwner !== ''">
                    <div class="col">
                        <h6 class="m-b-15 nameFont">Rating as Owner</h6>
                        <span class="stars" title="{{ratingAsOwner | number:'1.1-1'}} out of 10">
                            <span [ngStyle] ="{ 'width': getStars(ratingAsOwner) }"></span>
                        </span>
                        <!-- <span class="feedbackRating">{{ratingAsOwner | number:'1.1-1'}} out of 10</span> -->
                    </div>
                </div>
                <div class="" *ngIf="ratingAsTenant !== ''">
                    <div class="col">
                        <h6 class="m-b-15 nameFont">Rating as Tenant</h6>
                        <span class="stars" title="{{ratingAsTenant | number:'1.1-1'}} out of 10">
                            <span [ngStyle] ="{ 'width': getStars(ratingAsTenant) }"></span>
                        </span>
                        <!-- <span class="feedbackRating">{{ratingAsTenant | number:'1.1-1'}} out of 10</span> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-9">
            <div class="row">
                <div class="col-md-4">
                    <rentofly-loading *ngIf="availablePropertyDataLoading$ | async"></rentofly-loading>
                    <div class="card1 support-bar overflow-hidden" *ngIf="!(availablePropertyDataLoading$ | async)">
                        <div class="card-body card-head">
                            <span class="main-text">Available</span>
                            <span class="hMargin text-a-green" (click)="viewProperty('available-property')">{{availablePropertyData?.totalProperties}}</span>
                            <!-- <p class="pMargin">Total number of Available Property.</p> -->
                        </div>
                        <div class="">
                            <div class="row text-center">
                                <div class="col">
                                    <span class="main-text-inner">Pending consent</span>
                                    <h4 class="m-0" (click)="viewProperty('available-property')">{{availablePropertyData?.pendingProperties}}</h4>
                                </div>
                                <div class="col">
                                    <span class="main-text-inner">Draft agreement</span>
                                    <h4 class="m-0" (click)="viewProperty('available-property')">{{availablePropertyData?.draftProperties}}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card2 support-bar overflow-hidden" *ngIf="!(rentedPropertyDataLoading$ | async)">
                        <div class="card-body card-head">
                            <span class="main-text">Rented</span>
                            <span class="hMargin text-r-blue" (click)="viewProperty('rented-property')">{{rentedPropertyData.totalProperties}}</span>
                        </div>
                        <div>
                            <div class="row text-center">
                                <div class="col">
                                    <span class="main-text-inner">Rent</span>
                                    <h4 class="m-0" (click)="viewProperty('rented-property')">{{rentedPropertyData.rentedProperties}}</h4>
                                </div>
                                <div class="col">
                                    <span class="main-text-inner">Termination Initiated</span>
                                    <h4 class="m-0" (click)="viewProperty('rented-property')">{{rentedPropertyData.terminationProgressProperties}}</h4>
                                </div>
                                <div class="col">
                                    <span class="main-text-inner">Notice<br> Period</span>
                                    <h4 class="m-0" (click)="viewProperty('rented-property')">{{rentedPropertyData.noticePeriodProperties}}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card3 support-bar overflow-hidden" *ngIf="!(terminatedPropertyDataLoading$ | async)">
                        <div class="card-body card-head">
                            <span class="main-text">Historic Records</span>
                            <span class="hMargin text-t-red" (click)="viewProperty('terminated-property')">{{terminatedPropertyData.totalProperties}}</span>
                        </div>
                        <div class=" bg-c-red ">
                            <div class="row text-center">
                                <div class="col">
                                    <span class="main-text-inner">Terminated</span>
                                    <h4 class="m-0 ">4</h4>
                                </div>
                                <div class="col">
                                    <span class="main-text-inner">Archived</span>
                                    <h4 class="m-0 ">0</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row overdue-row">
        <div class="col-md-4">
            <h4 class="chart-name">Overdues</h4>
        </div>
        <div class="col-md-4">
            <span class="finance-span-1">{{ownerFinances?.totalRentDue | currency:'INR'}}</span>
            <span class="finance-span-2">Rent due</span>
        </div>
        <div class="col-md-4">
            <span class="finance-span-1">{{tenantFinances?.totalRentDue | currency:'INR'}}</span>
            <span class="finance-span-2">Deposite due</span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <p-chart type="doughnut" [data]="ownerData" ></p-chart>
            <h4 class="chart-name">Owner Finance</h4>
        </div>
        <div class="col-md-4">
            <p-chart type="doughnut" [data]="brokerageData"></p-chart>
            <h4 class="chart-name">Brokerage</h4>
        </div>
        <div class="col-md-4">
            <p-chart type="doughnut" [data]="tenantFinanceData"></p-chart>
            <h4 class="chart-name">Tenant Finances</h4>
        </div>
    </div>
</div>
<p-dialog header="Welcome to Rentofly!" [(visible)]="showWelcomePopup" [closable]="false" class="popUpWidth">
    <p>Please add the property details you would like to rent.</p>
    <ng-template pTemplate="footer">
        <button type="button" class="rentofly-primary" (click)="addProperty()">Ok</button>
    </ng-template>
</p-dialog>