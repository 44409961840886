import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'rpayr-designcomponent-ui';
  windowScrolled: boolean = false;
  loginPage = false;

  constructor(private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: Document,
  )
  {}

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.scrollToTop();
      if(evt.url !== '/login'){
        this.loginPage = true;
      } else {
        this.loginPage = false;
      }
    });
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (isPlatformBrowser(this.platformId)) {
      if (document.documentElement.scrollTop > 800 || document.body.scrollTop > 100) {
          this.windowScrolled = true;
      } 
    else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop < 800 || document.body.scrollTop < 100) {
          this.windowScrolled = false;
      }
    }
  }

  scrollToTop()
  {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({top: 0, behavior: 'smooth'})
    }
  }

}
