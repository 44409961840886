import { Injectable } from "@angular/core";
import { catchError, map } from "rxjs/operators";
import { Email } from "../models";
import { BaseService } from "./base.service";
import { EmailWhatsapp } from "../models/email-whatsapp.model";

@Injectable()
export class EmailService extends BaseService{
    private emailCommandUrl = this.baseCommandUrl() + "/Email/";
    private communicationUrl = this.baseReferralCodeUrl() + "/comm/";

    sendEmail(email){
        //const url = this.emailCommandUrl + "sendEmail";
        const url = this.communicationUrl + "sendComm";
        const headers = this.getHeader();
        return this.getHttpClient().post(url, email, {headers: headers}).pipe(
            map((response) =>email),
            catchError((error) => {
                return error;
            })
        );
    }
}