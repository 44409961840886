import { Injectable } from '@angular/core';
import { BaseService } from "./base.service";
import { Observable, BehaviorSubject } from 'rxjs';
import { Payment } from '../models';

@Injectable({
  providedIn: 'root'
})
export class PaymentService  extends BaseService{

  private paymentUrl = this.basePaymentUrl() + "/payment/";
  private headers = this.getHeader();

  getPaymentHistory(data): Observable<any>{
    return this.getHttpClient().get(this.paymentUrl + "history/" + data, { headers: this.headers });
  }

  addPayment(paymentDetails: Payment): Observable<any>{
    return this.getHttpClient().post(this.paymentUrl + "addPayment", paymentDetails, {headers: this.headers})
  }

  getReceipt(id){
    // return this.getHttpClient().get(this.paymentUrl + "receipt/" + id, {headers: this.headers});
    window.open(this.paymentUrl + "receipt/" + id, '_blank');
  }

  updateStatus(paymentData): Observable<any>{
    return this.getHttpClient().post(this.paymentUrl + `updatePayment/status`,
    paymentData, {headers: this.headers})
  }
}
