import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { Email, PaginatedPartner, Partner, PartnerRequest } from "src/app/shared/models";
import { PartnerState } from "..";
import * as fromPartner from '../selectors/partner.selector';
import * as partnerActions from "../actions/partner.action";
import { EmailWhatsapp } from "src/app/shared/models/email-whatsapp.model";

export interface IPartnerStoreFacade {
    selectAllPartners$: Observable<Partner[]>;
    currentPartnerLoading$: Observable<boolean>;
    currentPartner$: Observable<Partner>;
    partnerError$: Observable<any>;
    partnerRequest$: Observable<PartnerRequest>;
    partnerLoading$: Observable<boolean>;
    noPartnerResult$: Observable<boolean>;
    totalPartners$: Observable<number>;
    updatePartnerLoading$: Observable<boolean>;
    approvedPartners$: Observable<PaginatedPartner>;
    approvedPartnersLoading$: Observable<boolean>;
    pendingPartners$: Observable<PaginatedPartner>;
    pendingPartnersLoading$: Observable<boolean>;

    queryPartnerById(id: string): void;
    queryPartnerByPhoneNumber(phoneNumber: string): void;
    queryPartnerByPinCode(pinCode: number): void;
    addPartner(partner: Partner, emails: EmailWhatsapp[]): void;
    updatePartner(partner: Partner): void;
    deletePartner(id: string): void;
    setPartnerRequest(partnerRequest: PartnerRequest): void;
    queryPartners(): void;
}

@Injectable()
export class PartnerStoreFacade implements IPartnerStoreFacade {
    get selectAllPartners$() {
        return this.store.select(fromPartner.selectAll);
    }

    get currentPartnerLoading$() {
        return this.store.select(fromPartner.currentPartnerLoading$);
    }

    get currentPartner$() {
        return this.store.select(fromPartner.currentPartner$);
    }

    get partnerError$() {
        return this.store.select(fromPartner.error$);
    }

    get partnerRequest$() {
        return this.store.select(fromPartner.partnerRequest$)
    }

    get partnerLoading$() {
        return this.store.select(fromPartner.partnerLoading$)
    }

    get noPartnerResult$() {
        return this.store.select(fromPartner.noPartnerResult$)
    }

    get totalPartners$() {
        return this.store.select(fromPartner.totalPartners$)
    }

    get updatePartnerLoading$() {
        return this.store.select(fromPartner.updatePartnerLoading$);
    }

    get approvedPartners$(){
        return this.store.select(fromPartner.approvedPartners$);
    }

    get approvedPartnersLoading$(){
        return this.store.select(fromPartner.approvedPartnersLoading$);
    }

    get pendingPartners$(){
        return this.store.select(fromPartner.pendingPartners$);
    }

    get pendingPartnersLoading$(){
        return this.store.select(fromPartner.pendingPartnersLoading$);
    }

    constructor(
        private readonly store: Store<PartnerState>,
    ){}

    queryPartners() {
        this.store.dispatch(partnerActions.queryPartners());
    }

    queryApprovedPartners() {
        this.store.dispatch(partnerActions.queryApprovedPartners());
    }

    queryPendingPartners() {
        this.store.dispatch(partnerActions.queryPendingPartners());
    }

    queryPartnerById(id: string) {
        this.store.dispatch(partnerActions.queryPartnerById({id}));
    }

    queryPartnerByPhoneNumber(phoneNumber: string) {
        this.store.dispatch(partnerActions.queryPartnerByPhoneNumber({phoneNumber}));
    }

    queryPartnerByPinCode(pinCode: number) {
        this.store.dispatch(partnerActions.queryPartnersByPinCode({pinCode}));
    }

    addPartner(partner: Partner, emails: EmailWhatsapp[]) {
        this.store.dispatch(partnerActions.addPartner({partner, emails}));
    }

    updatePartner(partner: Partner) {
        this.store.dispatch(partnerActions.updatePartnerById({partner}));
    }

    deletePartner(id: string) {
        this.store.dispatch(partnerActions.deletePartnerById({id}));
    }

    setPartnerRequest(partnerRequest: PartnerRequest): void{
        this.store.dispatch(partnerActions.setPartnerRequest({partnerRequest}));
    }
}