import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import 'firebase/auth';
import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { LocalStorageService, WindowService } from '../shared/services';
import { PhoneNumber, User } from '../shared/models';
import { environment } from 'src/environments/environment';
import { countries } from '../shared/data-store';
import { CountdownComponent } from 'ngx-countdown';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isPlatformBrowser } from '@angular/common';
import { ReferralCodeService } from '../shared/services/referral-code.service';
import { UserService } from '../shared/services/user.service';
import { UUID } from 'angular2-uuid';
import { UsersStoreFacade } from '../store/facades';

@Component({
  selector: 'app-new-home-page',
  templateUrl: './new-home-page.component-latest.html',
  styleUrls: ['./new-home-page.component.scss'],
})
export class NewHomePageComponent implements OnInit {
  windowRef: any;
  phoneNumber = new PhoneNumber();
  verificationCode: string;
  user: any;

  public countries: any = countries;
  public loginForm: FormGroup;

  public showPhoneValidationMessage = false;
  public showOtpValidationMessage = false;
  public products = [];
  public features = [];
  public itemPerpage = 3;
  public itemScroll = 3;
  disableOtp = false;
  enableLogin = false;
  requestOtp = false;
  verifyLogin = false;
  linkClass = 'send-otp';
  loadingLogin = false;
  public responsiveOptions = [];
  public isMobile = false;
  public displayWelcomepopup = false;
  public beMemberFlag = false;
  public showOtp = false;
  public device = false;
  public worksData: any[];
  public showOtpPopup = false;
  public showReferral = false;
  public popUpHeader = 'OTP Verification';
  public referralCode;
  public paymentMode;
  public selectedBank;
  public banks = [];
  public showPaymentMode = false;
  public showOtpSection = true;
  public otpInput1;
  public otpInput2;
  public otpInput3;
  public otpInput4;
  public otpInput5;
  public otpInput6;

  @ViewChild('loginCaptcha') public loginCaptcha: ElementRef;
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  @ViewChild('howItWorks') public howItWorks: ElementRef;

  get phonePrefix() {
    return this.loginForm!.get('phonePrefix');
  }

  get loginMobileNo() {
    return this.loginForm!.get('loginMobileNo');
  }

  get password() {
    return this.loginForm!.get('password');
  }

  constructor(
    private readonly firebaseAuth: AngularFireAuth,
    private readonly windowService: WindowService,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router,
    private _snackBar: MatSnackBar,
    private readonly route: ActivatedRoute,
    private userService: UserService,
    private referralCodeService: ReferralCodeService,
    private readonly userFacade: UsersStoreFacade,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    // this.route.queryParams.pipe(
    //   ).subscribe((params) => {
    //     console.log(params)
    //     if(params?.feature)
    //     {
    //         this.localStorageService.addToLocalStorage("feature-toggle", params?.feature);
    //     } else{
    //       this.localStorageService.removeFromLocalStorage("feature-toggle");
    //     }
    //   })

    this.loginForm = new FormGroup({
      phonePrefix: new FormControl('+91', []),
      loginMobileNo: new FormControl('', [
        Validators.required,
        Validators.min(1000000000),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.max(999999),
      ]),
    });
    (this.products = [
      {
        name: 'Maharashtra',
        link: 'https://efilingigr.maharashtra.gov.in/ereg/',
      },
      { name: 'Delhi', link: 'https://doris.delhigovt.nic.in/' },
      { name: 'Karnataka', link: 'https://kaverionline.karnataka.gov.in/' },
      { name: 'Tamilnadu', link: 'https://www.tenancy.tn.gov.in/' },
      { name: 'Gujarat', link: 'https://garvi.gujarat.gov.in/' },
      { name: 'Telangana', link: 'https://registration.telangana.gov.in/' },
    ]),
      (this.features = [
        { src: '/assets/images/new-img/movers-packer.svg' },
        { src: '/assets/images/new-img/furtniture-rent.svg' },
        { src: '/assets/images/new-img/gardner.svg' },
        { src: '/assets/images/new-img/car-wash.svg' },
        { src: '/assets/images/new-img/carpenter.svg' },
        { src: '/assets/images/new-img/plumber.svg' },
        { src: '/assets/images/new-img/painter.svg' },
        { src: '/assets/images/new-img/cook.svg' },
      ]);
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3,
      },
      {
        breakpoint: '768px',
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1,
      },
    ];

    this.worksData = [
      {
        status: 'Enter Property Details',
        desc: 'Add your property in less than a minute by filling out simple form with basic details.',
        icon: '1',
        color: '#9C27B0',
        image: 'game-controller.jpg',
      },
      {
        status: 'Add Stakeholders',
        desc: 'Add the details of Owner/ Broker/ Tenant',
        icon: '2',
        color: '#673AB7',
      },
      {
        status: 'Approve Rental Deal',
        desc: 'Stakeholders will receive message notification to provide consent on the deal and they can Approve/ Reject the deal.',
        icon: '3',
        color: '#FF9800',
      },
      {
        status: 'Sign Agreement',
        desc: 'Download the drafted agreement and follow the recommended steps to complete the agreement process.',
        icon: '4',
        color: '#607D8B',
      },
    ];
  }

  ngOnInit(): void {
    this.displayWelcomepopup = true;
    this.windowRef = this.windowService.windowRef;
    this.device = this.windowService.isMobileBrowser();
    this.banks = [
      { name: 'HDFC', code: 'HDFC' },
      { name: 'ICICI', code: 'ICICI' },
      { name: 'State Bank of India', code: 'SBI' },
      { name: 'Union Bank of India', code: 'UBI' },
    ];
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
        } else {
          entry.target.classList.remove('show');
        }
      });
    });
    const hiddenElement = document.querySelectorAll('.hide');
    hiddenElement.forEach((element) => {
      observer.observe(element);
    });
  }

  ngAfterViewInit() {
    if (firebase.apps.length < 2) {
      firebase.initializeApp(environment.firebaseConfig);
    }
    try {
      this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        this.loginCaptcha.nativeElement,
        {
          size: 'invisible',
          callback: (response) => {},
        }
      );
    } catch (error) {
      this.disableOtp = false;
    }
  }

  beMember() {
    if (this.windowService.isMobileBrowser()) {
      this.beMemberFlag = false;
      this.isMobile = true;
    } else {
      this.beMemberFlag = true;
      this.isMobile = false;
    }
    //this.isMobile = this.windowService.isMobileBrowser();
  }
  // closeLoginDailog(){
  //   this.isMobile = true;
  // }

  isMobileBrowser(): boolean {
    return this.windowService.isMobileBrowser();
  }

  closeDailog() {
    this.displayWelcomepopup = false;
  }

  formRequest(event) {
    if (event.keyCode === 13 && this.disableOtp) {
      this.verifyOtp();
    } else if (event.keyCode === 13 && !this.disableOtp) {
      this.sendOtpRequest();
    }
  }

  sendOtpRequest(resend = false) {
    if (resend) {
      this.linkClass = 'send-otp-disabled';
    }
    this.showPhoneValidationMessage = false;
    if (this.loginForm.controls.loginMobileNo.status == 'INVALID') {
      this.showPhoneValidationMessage = true;
      return;
    }
    this.requestOtp = true;
    this.showOtp = true;
    this.firebaseAuth
      .signInWithPhoneNumber(
        this.phonePrefix.value.toString() + this.loginMobileNo.value.toString(),
        this.windowRef.recaptchaVerifier
      )
      .then((confirmationResult) => {
        this.disableOtp = true;
        this.requestOtp = false;
        this.enableLogin = true;
        this.loginForm.controls.password.reset();
        this.loginForm.controls.password.setErrors(null);
        this.windowRef.confirmationResult = confirmationResult;
      })
      .catch((error) => {
        console.log(error);
        this.disableOtp = false;
        this.requestOtp = false;
        this.loginForm.controls.password.reset();
        this.loginForm.controls.password.setErrors(null);
        this._snackBar.open(error, 'X', {
          duration: 10000,
          panelClass: ['snackbar-failure'],
        });
      });
  }

  verifyOtp() {
    // if(!this.enableLogin)
    // {
    //   this.loginForm.controls.password.setErrors({'incorrect': true});
    // }
    // if(this.loginForm.controls.password.status  == "INVALID")
    // {
    //   this.showOtpValidationMessage = true;
    //   return;
    // }
    //this.verifyLogin = true;
    //this.showOtpValidationMessage = false;
    //this.loadingLogin = true;
    let passwd = `${this.otpInput1}${this.otpInput2}${this.otpInput3}${this.otpInput4}${this.otpInput5}${this.otpInput6}`;
    this.windowRef.confirmationResult
      .confirm(passwd.toString().padStart(6, '0'))
      .then((userCredentials) => {
        this.loadingLogin = false;
        this.localStorageService.addToLocalStorage(
          'dashboard-user',
          JSON.stringify(userCredentials.user)
        );
        this.getUserId(userCredentials.user);
        //this.router.navigate(['/']);
      })
      .catch((error) => {
        console.log(error);
        this.loadingLogin = false;
        this.linkClass = 'send-otp';
        this.disableOtp = false;
        this.enableLogin = false;
        this.requestOtp = false;
        this.verifyLogin = false;
        this.showOtp = false;
        this._snackBar.open(error, 'X', {
          duration: 10000,
          panelClass: ['snackbar-failure'],
        });
      });
  }

  checkForReferralUse(userId) {
    this.referralCodeService.checkAvailibility(userId).subscribe((data) => {
      if (data.isSuccess) {
        this.router.navigate(['/']);
      } else {
        //this.router.navigate(['/referral']);
        this.showOtpSection = false;
        this.showReferral = true;
        this.popUpHeader = 'Get Started..';
      }
      this.localStorageService.addToLocalStorage(
        'availability',
        data.isSuccess
      );
    });
  }

  payMoneyToGetOtp() {
    this.popUpHeader = 'Choose Payment Method';
    this.showPaymentMode = true;
    this.showReferral = false;
    this.showOtpSection = false;
  }

  getUserId(userCreds) {
    this.userService
      .getUserByPhoneNumber(userCreds.phoneNumber)
      .subscribe((data) => {
        if (data.message) {
          this.checkForReferralUse(data.message.id);
        } else {
          let user: User = {
            id: UUID.UUID(),
            firebaseUserId: userCreds.uid,
            phoneNumber: userCreds.phoneNumber,
            createdDate: new Date(),
          };
          this.localStorageService.addObjectToLocalStorage(
            'current-user',
            user
          );
          this.userFacade.addUser(user);
          setTimeout(() => {
            this.router.navigate(['/referral']);
          }, 200);
        }
      });
  }
  public howItWorksStruc(): void {
    this.howItWorks.nativeElement.scrollIntoView({
      behavior: 'smooth',
      inline: 'start',
    });
  }

  public isCarouselEnabled() {
    if (isPlatformBrowser(this.platformId)) {
      return true;
    }
    return false;
  }
  digitValidate(ele) {
    console.log(ele.value);
    ele.value = ele.value.replace(/[^0-9]/g, '');
  }
  tabChange(val) {
    let ele = document.querySelectorAll('input');
    if (ele[val - 1].value != '') {
      ele[val].focus();
    } else if (ele[val - 1].value == '') {
      ele[val - 2].focus();
    }
  }

  downloadApp() {
    window.open(
      // 'https://apps.apple.com/us/app/rentofly/id6473688330',
      'https://onelink.to/8pf293',
      '_blank'
    );
  }
}
