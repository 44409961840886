import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  Deal,
  Owner,
  Broker,
  PaginatedProperty,
  Property,
  Tenant,
  ViewProperty,
  UpdateDeal,
  AdditionalPropertyDetail,
  PropertyListRequest,
  AvailablePropertyData,
  RentedPropertyData,
  TerminatedPropertyData,
} from '../models';
import { PaginatedPropertyInfo } from '../models/property-info.model';
import { BaseService } from './base.service';

@Injectable()
export class PropertyService extends BaseService {
  private propertyApiUrl = this.baseApiUrl() + '/Property/';
  private propertyQueryUrl = this.baseQueryUrl() + '/Property/';
  private propertyCommandUrl = this.baseCommandUrl() + '/Property/';

  addProperty(property: Property): Observable<any | string> {
    const url = this.propertyCommandUrl + 'add';
    const headers = this.getHeader();
    return this.getHttpClient()
      .post(url, property, { headers: headers })
      .pipe(
        map((response) => property.id),
        catchError((error) => {
          console.log(error);
          return error;
        })
      );
  }

  updateProperty(property: Property): Observable<any | string> {
    const url = this.propertyCommandUrl + 'update';
    const headers = this.getHeader();
    return this.getHttpClient()
      .put(url, property, { headers: headers })
      .pipe(
        map((response) => property.id),
        catchError((error) => {
          console.log(error);
          return error;
        })
      );
  }

  getPaginatedProperty(
    userId: string,
    offset: number,
    pageSize: number
  ): Observable<any | PaginatedPropertyInfo> {
    const url = `${this.propertyQueryUrl}getPaginatedProperty/${userId}?pageSize=${pageSize}&offset=${offset}`;
    const headers = this.getHeader();
    return this.getHttpClient()
      .get<PaginatedPropertyInfo>(url, { headers: headers })
      .pipe(map((response: PaginatedPropertyInfo) => response));
  }

  getPaginatedAvailableProperty(
    userId: string,
    offset: number,
    pageSize: number
  ): Observable<any | PaginatedPropertyInfo> {
    const url = `${this.propertyApiUrl}available/${userId}?pageSize=${pageSize}&offset=${offset}&orderBy=''&sortOrder=desc`;
    const headers = this.getHeader();
    return this.getHttpClient()
      .get<PaginatedPropertyInfo>(url, { headers: headers })
      .pipe(map((response: PaginatedPropertyInfo) => response));
  }

  getPaginatedAndSortedAvailableProperty(
    userId: string,
    propertyListRequest: PropertyListRequest
  ): Observable<any | PaginatedPropertyInfo> {
    const url = `${this.propertyApiUrl}available/${userId}?pageSize=${propertyListRequest?.pageSize}&offset=${propertyListRequest?.pageIndex}&orderBy=${propertyListRequest?.orderByColumn}&sortOrder=${propertyListRequest?.orderBy !== '' ? propertyListRequest?.orderBy : 'desc'}`;
    const headers = this.getHeader();
    return this.getHttpClient()
      .get<PaginatedPropertyInfo>(url, {
        headers: headers,
      })
      .pipe(map((response: PaginatedPropertyInfo) => response));
  }

  getPaginatedRentedProperty(
    userId: string,
    offset: number,
    pageSize: number
  ): Observable<any | PaginatedPropertyInfo> {
    const url = `${this.propertyApiUrl}rented/${userId}?pageSize=${pageSize}&offset=${offset}`;
    const headers = this.getHeader();
    return this.getHttpClient()
      .get<PaginatedPropertyInfo>(url, { headers: headers })
      .pipe(map((response: PaginatedPropertyInfo) => response));
  }

  getPaginatedAndSortedRentedProperty(
    userId: string,
    propertyListRequest: PropertyListRequest
  ): Observable<any | PaginatedPropertyInfo> {
    const url = `${this.propertyApiUrl}rented/${userId}?pageSize=${propertyListRequest?.pageSize}&offset=${propertyListRequest?.pageIndex}&orderBy=${propertyListRequest?.orderByColumn}&sortOrder=${propertyListRequest?.orderBy !== '' ? propertyListRequest.orderBy : 'desc'}`;
    const headers = this.getHeader();
    return this.getHttpClient()
      .get<PaginatedPropertyInfo>(url, {
        headers: headers,
      })
      .pipe(map((response: PaginatedPropertyInfo) => response));
  }

  getPaginatedTerminatedProperty(
    userId: string,
    offset: number,
    pageSize: number
  ): Observable<any | PaginatedPropertyInfo> {
    const url = `${this.propertyApiUrl}terminated/${userId}?pageSize=${pageSize}&offset=${offset}`;
    const headers = this.getHeader();
    return this.getHttpClient()
      .get<PaginatedPropertyInfo>(url, { headers: headers })
      .pipe(map((response: PaginatedPropertyInfo) => response));
  }

  getPaginatedAndSortedTerminatedProperty(
    userId: string,
    propertyListRequest: PropertyListRequest
  ): Observable<any | PaginatedPropertyInfo> {
    const url = `${this.propertyApiUrl}terminated/${userId}?pageSize=${propertyListRequest?.pageSize}&offset=${propertyListRequest?.pageIndex}&orderBy=${propertyListRequest?.orderByColumn}&sortOrder=${propertyListRequest?.orderBy !== '' ? propertyListRequest.orderBy : 'desc'}`;
    const headers = this.getHeader();
    return this.getHttpClient()
      .get<PaginatedPropertyInfo>(url, {
        headers: headers,
      })
      .pipe(map((response: PaginatedPropertyInfo) => response));
  }

  getPaginatedPropertyUsingCreatedBy(
    id: string,
    offset: number,
    pageSize: number
  ): Observable<any | PaginatedProperty> {
    const url = `${this.propertyQueryUrl}GetPaginatedPropertyUsingCreatedBy/${id}?pageSize=${pageSize}&offset=${offset}`;
    const headers = this.getHeader();
    return this.getHttpClient()
      .get<PaginatedProperty>(url, { headers: headers })
      .pipe(map((response: PaginatedProperty) => response));
  }

  getPropertyById(propertyId: string): Observable<any | Property> {
    const url = `${this.propertyApiUrl}${propertyId}`;
    const headers = this.getHeader();
    return this.getHttpClient()
      .get<Property>(url, { headers: headers })
      .pipe(map((response: Property) => response));
  }

  getViewPropertyById(propertyId: string): Observable<any | ViewProperty> {
    const url = `${this.propertyApiUrl}view/${propertyId}`;
    const headers = this.getHeader();
    return this.getHttpClient()
      .get<ViewProperty>(url, { headers: headers })
      .pipe(
        map((response: ViewProperty) => response),
        catchError((error) => {
          console.log(error);
          return error;
        })
      );
  }

  //owner

  addOwner(propertyId: string, owner: Owner): Observable<any | string> {
    const url = `${this.propertyCommandUrl}${propertyId}/owner/add`;
    const headers = this.getHeader();
    return this.getHttpClient()
      .post(url, owner, { headers: headers })
      .pipe(
        map((response) => owner.id),
        catchError((error) => {
          console.log(error);
          return error;
        })
      );
  }

  updateOwner(propertyId: string, owner: Owner): Observable<any | string> {
    const url = `${this.propertyCommandUrl}${propertyId}/owner/update`;
    const headers = this.getHeader();
    return this.getHttpClient()
      .put(url, owner, { headers: headers })
      .pipe(
        map((response) => owner.id),
        catchError((error) => {
          console.log(error);
          return error;
        })
      );
  }

  //tenant

  addTenant(propertyId: string, tenant: Tenant): Observable<any | string> {
    const url = `${this.propertyCommandUrl}${propertyId}/tenant/add`;
    const headers = this.getHeader();
    return this.getHttpClient()
      .post(url, tenant, { headers: headers })
      .pipe(
        map((response) => tenant.id),
        catchError((error) => {
          console.log(error);
          return error;
        })
      );
  }

  updateTenant(propertyId: string, tenant: Tenant): Observable<any | string> {
    const url = `${this.propertyCommandUrl}${propertyId}/tenant/update`;
    const headers = this.getHeader();
    return this.getHttpClient()
      .put(url, tenant, { headers: headers })
      .pipe(
        map((response) => tenant.id),
        catchError((error) => {
          console.log(error);
          return error;
        })
      );
  }

  //deal

  addDeal(propertyId: string, deal: Deal): Observable<any | string> {
    const url = `${this.propertyCommandUrl}${propertyId}/deal/add`;
    const headers = this.getHeader();
    return this.getHttpClient()
      .post(url, deal, { headers: headers })
      .pipe(
        map((response) => deal.id),
        catchError((error) => {
          console.log(error);
          return error;
        })
      );
  }

  updateDeal(propertyId: string, deal: Deal): Observable<any | string> {
    const url = `${this.propertyCommandUrl}${propertyId}/deal/update`;
    const headers = this.getHeader();
    return this.getHttpClient()
      .put(url, deal, { headers: headers })
      .pipe(
        map((response) => deal.id),
        catchError((error) => {
          console.log(error);
          return error;
        })
      );
  }
  //broker

  addBroker(propertyId: string, broker: Broker): Observable<any | string> {
    const url = `${this.propertyCommandUrl}${propertyId}/broker/add`;
    const headers = this.getHeader();
    return this.getHttpClient()
      .post(url, broker, { headers: headers })
      .pipe(
        map((response) => broker.id),
        catchError((error) => {
          console.log(error);
          return error;
        })
      );
  }

  updateBroker(propertyId: string, broker: Broker): Observable<any | string> {
    const url = `${this.propertyCommandUrl}${propertyId}/broker/update`;
    const headers = this.getHeader();
    return this.getHttpClient()
      .put(url, broker, { headers: headers })
      .pipe(
        map((response) => broker.id),
        catchError((error) => {
          console.log(error);
          return error;
        })
      );
  }

  updateDealStatus(dealId: string, status: string): Observable<any | string> {
    const url = `${this.propertyCommandUrl}deal/${dealId}/updateStatus`;
    const headers = this.getHeader();
    return this.getHttpClient()
      .post(url, `\"${status}\"`, { headers: headers })
      .pipe(
        map((response) => dealId),
        catchError((error) => {
          console.log(error);
          return error;
        })
      );
  }

  updateNoticeDateAndStatus(
    dealId: string,
    updateDeal: UpdateDeal
  ): Observable<any | string> {
    const url = `${this.propertyCommandUrl}deal/${dealId}/updateNoticeDateAndStatus`;
    const headers = this.getHeader();
    return this.getHttpClient()
      .post(url, updateDeal, { headers: headers })
      .pipe(
        map((response) => dealId),
        catchError((error) => {
          console.log(error);
          return error;
        })
      );
  }

  updateOwnerConsent(
    ownerId: string,
    status: string
  ): Observable<any | string> {
    const url = `${this.propertyCommandUrl}owner/${ownerId}/updateConsent`;
    const headers = this.getHeader();
    return this.getHttpClient()
      .post(url, `\"${status}\"`, { headers: headers })
      .pipe(
        map((response) => ownerId),
        catchError((error) => {
          console.log(error);
          return error;
        })
      );
  }

  updateTenantConsent(
    tenantId: string,
    status: string
  ): Observable<any | string> {
    const url = `${this.propertyCommandUrl}tenant/${tenantId}/updateConsent`;
    const headers = this.getHeader();
    return this.getHttpClient()
      .post(url, `\"${status}\"`, { headers: headers })
      .pipe(
        map((response) => tenantId),
        catchError((error) => {
          console.log(error);
          return error;
        })
      );
  }

  getFurnitureTypes(): Observable<any> {
    return this.getHttpClient().get('/assets/json/furnitureType.json');
  }

  addAdditionalDetails(
    addtionalDetails: AdditionalPropertyDetail
  ): Observable<any | string> {
    const url = this.propertyCommandUrl + 'additionalDetails';
    const headers = this.getHeader();
    return this.getHttpClient()
      .post(url, addtionalDetails, { headers: headers })
      .pipe(
        map((response) => addtionalDetails.id),
        catchError((error) => {
          console.log(error);
          return error;
        })
      );
  }

  updateAdditionalDetails(
    addtionalDetails: AdditionalPropertyDetail
  ): Observable<any | string> {
    const url = this.propertyCommandUrl + 'additionalDetails';
    const headers = this.getHeader();
    return this.getHttpClient()
      .put(url, addtionalDetails, { headers: headers })
      .pipe(
        map((response) => addtionalDetails.id),
        catchError((error) => {
          console.log(error);
          return error;
        })
      );
  }

  getAdditionalDetailsProperty(
    propertyId: string
  ): Observable<any | AdditionalPropertyDetail> {
    const url = `${this.propertyApiUrl}additionalDetails/${propertyId}`;
    const headers = this.getHeader();
    return this.getHttpClient()
      .get<AdditionalPropertyDetail>(url, { headers: headers })
      .pipe(map((response: AdditionalPropertyDetail) => response));
  }

  getAvailablePropertyData(
    userId: string
  ): Observable<any | AvailablePropertyData> {
    const url = `${this.propertyApiUrl}availablePropertyData/${userId}`;
    const headers = this.getHeader();
    return this.getHttpClient()
      .get<AvailablePropertyData>(url, {
        headers: headers,
      })
      .pipe(map((response: AvailablePropertyData) => response));
  }

  getRentedPropertyData(userId: string): Observable<any | RentedPropertyData> {
    const url = `${this.propertyApiUrl}rentedPropertyData/${userId}`;
    const headers = this.getHeader();
    return this.getHttpClient()
      .get<RentedPropertyData>(url, { headers: headers })
      .pipe(map((response: RentedPropertyData) => response));
  }
  getTerminatedPropertyData(
    userId: string
  ): Observable<any | TerminatedPropertyData> {
    const url = `${this.propertyApiUrl}terminatedPropertyData/${userId}`;
    const headers = this.getHeader();
    return this.getHttpClient()
      .get<TerminatedPropertyData>(url, { headers: headers })
      .pipe(map((response: TerminatedPropertyData) => response));
  }

  terminateProperty(propertyId: string): Observable<any | string> {
    const url = `${this.propertyCommandUrl}${propertyId}/terminate`;
    const headers = this.getHeader();
    return this.getHttpClient()
      .post(url, null, { headers: headers })
      .pipe(
        map((response) => propertyId),
        catchError((error) => {
          console.log(error);
          return error;
        })
      );
  }
}
