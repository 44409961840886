import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Address } from "../models";
import { BaseService } from "./base.service";

@Injectable()
export class AddressService extends BaseService{
    //private addressQueryUrl = this.baseQueryUrl() + "/Address/";
    private addressQueryUrl = this.baseApiUrl() + "/utils/";

    getAddressByPinCode(pincode: string): Observable<any>{
        const url = `${this.addressQueryUrl}${pincode}`;
        const headers = this.getHeader();
        return this.getHttpClient().get<Address[]>(url, { headers: headers })
    }
}