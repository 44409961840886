import { Injectable } from "@angular/core";
import * as fromAttachments from '../selectors/attachments.selector';
import * as attachmentsActions from "../actions/attachments.action";
import { Store } from "@ngrx/store";
import { AttachmentsState } from "../reducers";
import { FileUploadModel, PartnerFileUploadModel } from "src/app/shared/models";
import { Observable } from "rxjs";

export interface IAttachmentsStoreFacade {
    attachmentsLoading$: Observable<boolean>;
    attachmentsProgress$: Observable<number>;
    getAttachments$: Observable<FileUploadModel[]>;
    attachmentError$: Observable<any>;
    link$: Observable<string>;
    linkLoading$: Observable<boolean>;
    newAttachmentUrl$: Observable<string>;

    createAttachment(fileUploadModel: FileUploadModel, file: File): void;
    createAttachmentForPartner(fileUploadModel: PartnerFileUploadModel, file: File): void;
    deleteAttachmentById(id: string): void;
    getAttachmentsByPropertyId(propertyId: string): void;
    addAttachment(fileUploadMiode: FileUploadModel): void;
    addPartnerAttachment(attachment: PartnerFileUploadModel): void;
    createTempAttachment(attachment: FileUploadModel, file: File): void;
    addTempAttachmentLink(link: string): void;
}

@Injectable()
export class AttachmentsStoreFacade implements IAttachmentsStoreFacade {

    constructor(
        private readonly store: Store<AttachmentsState>,
    ){}

    get attachmentsLoading$(){
        return this.store.select(fromAttachments.isAttachmentsLoading$);
    }

    get attachmentsProgress$(){
        return this.store.select(fromAttachments.progress$);
    }

    get getAttachments$(){
        return this.store.select(fromAttachments.selectAll);
    }

    get attachmentError$(){
        return this.store.select(fromAttachments.attachmentsError$);
    }

    get link$(){
        return this.store.select(fromAttachments.link$);
    }

    get linkLoading$(){
        return this.store.select(fromAttachments.linkLoading$);
    }

    get newAttachmentUrl$(){
        return this.store.select(fromAttachments.newAttachmentUrl$);
    }

    createAttachment(attachment: FileUploadModel, file: File){
        this.store.dispatch(attachmentsActions.createAttachment({attachment, file}));
    }

    createAttachmentForPartner(attachment: PartnerFileUploadModel, file: File){
        this.store.dispatch(attachmentsActions.createPartnerAttachment({attachment, file}));
    }

    deleteAttachmentById(id: string): void{
        this.store.dispatch(attachmentsActions.deleteAttachmentsById({id}));
    }

    getAttachmentsByPropertyId(propertyId: string): void{
        this.store.dispatch(attachmentsActions.getAttachmentsByPropertyId({propertyId}));
    }

    addAttachment(attachment: FileUploadModel): void{
        this.store.dispatch(attachmentsActions.addAttachment({attachment}));
    }

    addPartnerAttachment(attachment: PartnerFileUploadModel): void{
        this.store.dispatch(attachmentsActions.addPartnerAttachment({attachment}));
    }

    createTempAttachment(attachment: FileUploadModel, file: File): void{
        this.store.dispatch(attachmentsActions.createTempAttachment({attachment, file}));
    }

    addTempAttachmentLink(link: string): void{
        this.store.dispatch(attachmentsActions.addTempAttachmentLink({link}));
    }
}