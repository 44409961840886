<head>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="description" content="Manage your rental property with ease using our AI-powered platform. Get personalized recommendations, flexible rent payment options, automated reminders, and centralized document management. Trust and transparency are at the core of our platform, making each rental transaction secure and hassle-free. Simplify your rental journey today.">
    <meta name="keywords" content="Rental finance management, Property management software, Tenant management system, Rent payment processing, Property listing management, Lease agreement management, Online rent payment, Rental property accounting, Tenant screening, Rent collection automation, Maintenance request management, Property owner dashboard, Real estate software, Online property management, Property management platform, Rental management app, Property management tool, Automated rent collection, Tenant communication platform">
    <meta name="robots" content="index, follow">
</head>
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-Y3D2LBP9KV"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-Y3D2LBP9KV');
</script>
<!-- Google tag (gtag.js) -->
<form [formGroup]="paymentInfoForm" *ngIf="!loader">
    <div class="form-group">
        <p class="payment-label type-para">Payment Type:</p>
        <p-dropdown [options]="paymentTypesOptions" class="payment-drop-down" (onChange)="paymentTypeSelect()" formControlName="paymentType" placeholder="Select" optionLabel="name" required></p-dropdown>
        <p class="rentofly-validation-msg" *ngIf="showErrorMessages && paymentType!.invalid">
            Payment Type is required.
        </p>
    </div>
    <div class="form-group">
        <p class="payment-label frequency-para">Frequency:</p>
        <p-dropdown [options]="frequencyOptions" class="payment-drop-down" (onChange)="paymentFrequencySelect()" formControlName="frequencyType" placeholder="Select" optionLabel="name" required></p-dropdown>
        <p class="rentofly-validation-msg" *ngIf="showErrorMessages && frequencyType!.invalid">
            Frequency is required.
        </p>
    </div>
    <div class="form-group">
        <p class="payment-label period-para-from">Period From:</p>
        <input [matDatepicker]="startDatepicker" (focus)="startDatepicker.open()" (dateChange)="fromDateChange()" [min]="fromMin" [max]="fromMax" value="" id="startDate" formControlName="paymentPeriodFrom" class="form-control date-picker contact-input" placeholder="DD/MM/YYYY">
        <mat-datepicker-toggle matSuffix [for]="startDatepicker" class="date-picker-icon"></mat-datepicker-toggle>
        <mat-datepicker #startDatepicker></mat-datepicker>
        <p class="rentofly-validation-msg" *ngIf="showErrorMessages && paymentPeriodFrom!.invalid">
            Period From is required.
        </p>
    </div>
    <div class="form-group">
        <p class="payment-label period-para-to">Period To:</p>
        <input [matDatepicker]="endDatepicker" (focus)="endDatepicker.open()" value="" id="endDate" [min]="toMin" [max]="toMax" formControlName="paymentPeriodTo" (dateChange)="toDateChange()" class="form-control date-picker contact-input" placeholder="DD/MM/YYYY"
            required>
        <mat-datepicker-toggle matSuffix [for]="endDatepicker" class="date-picker-icon"></mat-datepicker-toggle>
        <mat-datepicker #endDatepicker></mat-datepicker>
        <p class="rentofly-validation-msg" *ngIf="showErrorMessages && paymentPeriodTo!.invalid">
            Period To is required.
        </p>
    </div>
    <div class="form-group">
        <p class="payment-label amount-para">Amount:</p>
        <div class="p-inputgroup amount-field">
            <span class="p-inputgroup-addon">
                <span>&#8377;</span>
            </span>
            <span class="p-float-label">
                <input type="number" id="rentPerMonth" class="contact-input" pInputText formControlName="paymentAmount" 
                    OnlyNumber="true" (focusout)="calculatePayment()" required> 
            </span>
        </div>
        <p *ngIf="showText" class="payInfoPara">Your {{paymentInfoForm.controls.frequencyType.value.code}} Payment {{paymentInfoForm.controls.paymentAmount.value}}</p>
        <p *ngIf="showCalculatedText" [ngClass]="{'redColor':redColor, 'greenColor': greenColor}" class="payInfoPara">Your are paying {{totalPayMoney}}</p>
        <p class="rentofly-validation-msg" *ngIf="showErrorMessages && paymentAmount!.invalid">
            Amount is required.
        </p>
    </div>
    <div class="form-group" *ngIf="user !== 'Owner'">
        <p class="payment-label mode-para">Mode of Payment:</p>
        <p-dropdown [options]="paymentOptions" class="payment-drop-down" formControlName="paymentMode" required placeholder="Select" optionLabel="name"></p-dropdown>
        <p class="rentofly-validation-msg" *ngIf="showErrorMessages && paymentMode!.invalid">
            Mode of Payment is required.
        </p>
    </div>
    <div class="form-group" *ngIf="showAttachment()">
        <p class="payment-label ref-para">Payment Ref Id:</p>
        <div class="p-inputgroup amount-field">
            <span class="p-float-label">
                <input type="text" id="paymentRefId" class="contact-input" pInputText formControlName="paymentRefId"> 
            </span>
        </div>
    </div>
    <div class="form-group" *ngIf="showAttachment()">
        <input #file type="file" id="attachment" (change)="onFileChange($event)" style="display: none;" (click)="OnFileClick($event)">
        <p>
            <button type="button" for="attachment" class="rentofly-primary rentofly-modal-button attachment-button" (click)="file.click()">
                Add Attachment
            </button>
        </p>
        <p *ngIf="this.newAttachment">
            {{this.newAttachment.name}} <a (click)=clearAttachment() class='clear'>Clear</a>
        </p>
        <p class="rentofly-validation-msg" *ngIf="this.errorMessage">
            {{this.errorMessage}} <a (click)=clearAttachment() class='clear'>Clear</a>
        </p>
    </div>
    <div class="action form-group" style="text-align: center;">
        <button class="rentofly-secondary" (click)="resetForm()">Reset</button>&nbsp;
        <button class="rentofly-primary" (click)="addPayment(paymentInfoForm.value)">{{user !== 'Owner'?'Pay':'Request'}}</button>
    </div>
</form>
<rentofly-loading *ngIf="loader"></rentofly-loading>
<mat-table [dataSource]="allPaymentDetails" class="property-table" *ngIf="!loader">
    <ng-container matColumnDef="paymentType">
        <mat-header-cell class="payment-type-header" *matHeaderCellDef> Payment Type </mat-header-cell>
        <mat-cell class="payment-type-cell" *matCellDef="let element">
            <a (click)="viewPaymentDetail(element)" class="property-link">{{element.paymentType}}</a>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="amount">
        <mat-header-cell *matHeaderCellDef> Amount </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.amount | currency:'INR'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="frequency">
        <mat-header-cell *matHeaderCellDef> Frequency </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.paymentFrequency}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="period">
        <mat-header-cell class="payment-period-header" *matHeaderCellDef> Period </mat-header-cell>
        <mat-cell class="payment-period-cell" *matCellDef="let element">{{getFormattedDate(element.fromDate) | date : 'dd/MM/YY'}} - {{getFormattedDate(element.toDate) | date : 'dd/MM/YY'}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
        <mat-header-cell class="payment-status-header" *matHeaderCellDef> Status </mat-header-cell>
        <mat-cell class="payment-status-cell" *matCellDef="let element">
            <a (click)="viewPaymentDetail(element)">
                <i class="material-icons greenColor" *ngIf="element.status === 'Approved'" title="{{element.status}}">check_circle_outline</i>
                <i class="material-icons amberColor" *ngIf="element.status === 'Awaiting Approval' && user !== 'Owner'" title="{{element.status}}">radio_button_checked</i>
                <i class="material-icons blueColor" *ngIf="element.status === 'Awaiting Approval' && user === 'Owner'" title="{{element.status}}">radio_button_checked</i>
                <i class="material-icons redColor" *ngIf="element.status === 'Rejected'" title="{{element.status}}">highlight_off</i>
                <i class="material-icons yellowColor" *ngIf="element.status === 'Payment Requested'" title="{{element.status}}">radio_button_checked</i>
            </a>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="receipt">
        <mat-header-cell class="payment-receipt-header" *matHeaderCellDef>
            <span *ngIf="isMobileBrowserEnabled()"><i class="pi pi-file-pdf pdf-icon pdf-icon-header"></i> </span>
            <span *ngIf="!isMobileBrowserEnabled()">Receipt </span>
        </mat-header-cell>
        <mat-cell class="payment-receipt-cell" *matCellDef="let element"><i class="pi pi-file-pdf pdf-icon pdf-icon-value" *ngIf="element.status == 'Approved' " (click)="getPaymentReceipt(element.id)"></i></mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let myRowData; columns: displayedColumns"></mat-row>
</mat-table>
<span *ngIf="(allPaymentDetails.length == 0) && !loader" class="no-records">No records to view</span>
<p-dialog header="Payment Details"  [(visible)]="showPaymentDailog" class="paymentDailog paymentDetailDailog">
    <div class="row">
        <div class="col-md-6 alignRight">
            <label>Payment Type:</label>
        </div>
        <div class="col-md-3 col-3-width">
            <span>{{dailogData?.paymentType}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 alignRight">
            <label>Frequency:</label>
        </div>
        <div class="col-md-3 col-3-width">
            <span>{{dailogData?.paymentFrequency}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 alignRight">
            <label>Period From:</label>
        </div>
        <div class="col-md-3 col-3-width">
            <span>{{getFormattedDate(dailogData?.fromDate) | date : 'dd/MM/YY'}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 alignRight">
            <label>Period To:</label>
        </div>
        <div class="col-md-3 col-3-width">
            <span>{{getFormattedDate(dailogData?.toDate) | date : 'dd/MM/YY'}}</span>
        </div>
    </div>
    <div class="row" *ngIf="dailogData?.status != 'Payment Requested'  || (dailogData?.status == 'Payment Requested' && user == 'Owner')">
        <div class="col-md-6 alignRight">
            <label>Amount:</label>
        </div>
        <div class="col-md-3 col-3-width">
            <span>{{dailogData?.amount | currency:'INR'}}</span>
        </div>
    </div>
    <div class="row" *ngIf="dailogData?.status != 'Payment Requested' || (dailogData?.status == 'Payment Requested' && user == 'Owner')">
        <div class="col-md-6 alignRight">
            <label>Status:</label>
        </div>
        <div class="col-md-3 col-3-width">
            <span>{{dailogData?.status}}</span>
        </div>
    </div>
    <div class="row" *ngIf="dailogData?.status != 'Payment Requested' || (dailogData?.status == 'Payment Requested' && user == 'Owner')">
        <div class="col-md-6 alignRight">
            <label>Mode of Payment:</label>
        </div>
        <div class="col-md-3 col-3-width">
            <span>{{dailogData?.modeOfPayment}}</span>
        </div>
    </div>

    <form [formGroup]="popupAttachForm" *ngIf="dailogData?.status == 'Payment Requested' && user !== 'Owner'">
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-6 alignRight">
                <p class="payment-label mode-para">Amount:</p>
            </div>
            <div class="col-md-3 popupAmountDiv">
                <input type="number" id="rentPerMonth2" pInputText formControlName="paymentAmount" required
                    OnlyNumber="true" class="popupAmountField">
                    <p class="rentofly-validation-msg" *ngIf="showPopupErrorMessages && popupAttachForm.controls['paymentAmount'].invalid">
                        Amount is required.
                    </p>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-6 alignRight">
                <label>Status:</label>
            </div>
            <div class="col-md-3 col-3-width">
                <span>{{dailogData?.status}}</span>
            </div>
        </div>
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-6 alignRight">
                <p class="payment-label mode-para">Mode of Payment:</p>
            </div>
            <div class="col-md-3 popupAmountDiv">
                <p-dropdown [options]="paymentOptions"  appendTo="body"  formControlName="paymentMode" required placeholder="Select" optionLabel="name"></p-dropdown>
                <p class="rentofly-validation-msg" *ngIf="showPopupErrorMessages && popupAttachForm.controls['paymentMode'].invalid">
                    Mode of Payment is required.
                </p>
            </div>
        </div>
        <div class="row"  *ngIf="popupAttachForm.controls['paymentMode'].value.code != 'Cash' && popupAttachForm.controls['paymentMode'].value.code !== undefined" style="margin-top: 10px;">
            <div class="col-md-6 alignRight">
                <p class="payment-label ref-para">Payment Ref Id:</p>
            </div>
            <div class="col-md-3 popupAmountDiv">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <input type="text" id="paymentRefId" class="contact-input" pInputText formControlName="paymentRefId"> 
                    </span>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="popupAttachForm.controls['paymentMode'].value.code != 'Cash' && popupAttachForm.controls['paymentMode'].value.code !== undefined">
            <div class="col-md-6 alignRight">
                <input #file type="file" id="attachment" (change)="onFileChange($event)" style="display: none;" (click)="OnFileClick($event)">
                <p>
                    <button type="button" for="attachment" class="rentofly-primary rentofly-modal-button attachment-button" (click)="file.click()">
                        Add Attachment
                    </button>
                </p>
            </div>
            <div class="col-md-3">
                <p *ngIf="this.newAttachment">
                    {{this.newAttachment.name}} <a (click)=clearAttachment() class='clear'>Clear</a>
                </p>
                <p class="rentofly-validation-msg" *ngIf="this.errorMessage">
                    {{this.errorMessage}} <a (click)=clearAttachment() class='clear'>Clear</a>
                </p>
            </div>
        </div>
    </form>

    <div class="row" *ngIf="dailogData?.paymentRefId">
        <div class="col-md-6 alignRight">
            <label>Payment Ref Id:</label>
        </div>
        <div class="col-md-3 col-3-width">
            <span>{{dailogData?.paymentRefId}}</span>
        </div>
    </div>
    <div class="row" *ngIf="dailogData?.paymentRejectReason">
        <div class="col-md-6 alignRight">
            <label>Reject Reason:</label>
        </div>
        <div class="col-md-3 col-3-width">
            <span>{{dailogData?.paymentRejectReason}}</span>
        </div>
    </div>
    <div class="row" *ngIf="dailogData?.receiptAttachmentURL">
        <div class="col-md-12 alignRight">
            <a href="{{dailogData?.receiptAttachmentURL}}" target="_blank">View Attachment</a>
        </div>
    </div>
    <ng-template pTemplate="footer" class="">
        <button type="button" class="rentofly-failure" *ngIf="dailogData?.status == 'Awaiting Approval' && user == 'Owner'" (click)="paymentAction('Rejected')">Reject</button>
        <button type="button" class="rentofly-tertiary" *ngIf="dailogData?.status == 'Awaiting Approval' && user == 'Owner' " (click)="paymentAction('Approved')">Approve</button>
        <button type="button" class="rentofly-tertiary" *ngIf="dailogData?.status === 'Approved'" (click)="getPaymentReceipt(dailogData?.id)">Download Receipt</button>
        <button type="button" class="rentofly-secondary" *ngIf="dailogData?.status === 'Payment Requested'  && user != 'Owner'" (click)="cancelPaymentRequest()">Cancel</button>
        <button type="button" class="rentofly-primary" *ngIf="dailogData?.status === 'Payment Requested'  && user != 'Owner'" (click)="payRequestedPaymentAmount(dailogData, popupAttachForm.value)">Pay</button>
    </ng-template>
</p-dialog>

<p-dialog header="Confirmation" [(visible)]="showConfirmation" [closable]="false" class="paymentDailog paymentDetailDailog">
    <!-- <p>Are you sure you want to {{actionLabel}}?</p> -->

    <form [formGroup]="commentForm" class="comment-info">
        <div>
            <label for="inputgroup" class="input-label">Are you sure you want to {{actionLabel}}?</label><br />
            <div *ngIf="actionLabel === 'Reject'">
                <textarea id="commentText" class="comment-text" type="text" formControlName="commentText" rows="4" required placeholder="Reject Reason"></textarea><br />
                <span class="message-length-info"><small>{{commentText.value.length}} of 500 characters</small></span><br />
                <div *ngIf="commentText!.invalid && showCommentValidationMessage" class="rentofly-validation-msg">
                    <div *ngIf="commentText!.errors.maxlength">
                        Length cannot exceed 500 characters.
                    </div>
                    <div *ngIf="!commentText!.errors.maxlength">
                        Reason is required.
                    </div>
                </div>
            </div>
        </div>
    </form><br />
    <button type="button" class="rentofly-failure confirm-button" (click)="approveRejectPayment('No', actionLabel)">No</button>
    <button type="button" class="rentofly-tertiary confirm-button" (click)="approveRejectPayment('Yes', actionLabel)">Yes</button>

</p-dialog>