import { Injectable } from "@angular/core";
import * as fromAddress from '../selectors/address.selector';
import * as addressActions from "../actions/address.action";
import { Store } from "@ngrx/store";
import { AddressState } from "../reducers";
import { Address } from "src/app/shared/models";
import { Observable } from "rxjs";

export interface IAddressStoreFacade {

    //getCurrentAddress
    currentAddressLoading$: Observable<boolean>;
    currentAddress$: Observable<Address>;

    queryAddressByPincode(pincode: string): void;
}

@Injectable()
export class AddressStoreFacade implements IAddressStoreFacade {

    get currentAddressLoading$(){
        return this.store.select(fromAddress.currentAddressLoading$);
    }

    get currentAddress$(){
        return this.store.select(fromAddress.currentAddress$);
    }

    constructor(
        private readonly store: Store<AddressState>,
    ){}

    queryAddressByPincode(pincode:string){
        this.store.dispatch(addressActions.queryAddressByPincode({pincode}));
    }
}