import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'rentofly-confirm-comment-box',
  templateUrl: './confirm-comment-box.component.html',
  styleUrls: ['./confirm-comment-box.component.scss']
})
export class ConfirmCommentBoxComponent implements OnInit {
  public confirmMessageHeader: string;
  public confirmMessageContent: string;
  public confirmButtonText: string;
  commentForm: FormGroup;
  showCommentValidationMessage: boolean;

  constructor(public dialogRef: MatDialogRef<ConfirmCommentBoxComponent>) { }

  get commentText() {
    return this.commentForm!.get('commentText');
  }

  ngOnInit(): void {
    this.commentForm = new FormGroup({
      commentText: new FormControl('', [
        Validators.required,
        Validators.maxLength(500)
      ])
    });
  }

  onConfirm(commentForm){
    this.showCommentValidationMessage = false;
    if(this.commentForm.invalid){
      this.showCommentValidationMessage = true;
      return;
    }
    this.dialogRef.close(commentForm)
  }

}
