import { Component, Input, OnInit } from '@angular/core';
import { PropertyStoreFacade } from 'src/app/store/facades/property.store-facade';
import { LocalStorageService } from 'src/app/shared/services';
import { FormControl, FormGroup } from '@angular/forms';
import { FeedbackService } from '../shared/services/feedback.service';
import { ConfirmBoxComponent, ConfirmCommentBoxComponent } from 'src/app/shared/components';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common'
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'rentofly-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  public rentPrompt;
  public stabilityIncome;
  public residential;
  public considerateNeighbor;
  public callBackTenant;
  public propertyStatus;
  public maintenance;
  public arrival;
  public poriorNotice;
  public disturbance;
  public callBackOwner;
  public personName;
  public reviewer;
  public ratingStar;
  public ratingPoint;
  public showTenantQuestion = false;
  public showOwnerQuestion = false;
  public showIntroSec = true;
  public feedbackSubmitted = false;
  public currentUser:any;
  public ratingData = [];
  public user;
  public nextRatingDate;
  public displayedColumns = ['ratingDate', 'ratingPoint'];
  public feedbackQuestions = [];
  public tenantRatingForm : FormGroup;
  public ownerRatingForm : FormGroup;
  public showQuestion = false;
  public feedbackDetails ={
    "propId":"",
    "fromId":"",
    "feedbackRates": []
  }
  public showFeedBackPanel = false;
  public feedbackUrl;
  public showLoading = true;
  public showWelcomeSec = false;
  public feedBack;
  public nextFeedbakDate;
  public showRating = false;
  public lastFeedBackDate;
  public currentDate = new Date();
  public rateBtn = true;
  
  constructor(
    private readonly propertyFacade: PropertyStoreFacade,
    private readonly localStorageService: LocalStorageService,
    private feedbackService: FeedbackService,
    private readonly dialog: MatDialog,
    private datePipe: DatePipe,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.currentUser = this.localStorageService.getObjectFromLocalStorage('current-user');
    this.setUserForFeedBack();
    this.initTenantRatingForm();
    this.initOwnerRatingForm();
    this.feedbackService.previousRating.subscribe(data => {
      if(data){
        this.showWelcomeSec = false;
        this.showLoading = false;
        this.feedBack = data.Rate;
        this.showRating = true;
        this.nextFeedbakDate = this.datePipe.transform(data.NextFeedbackDate, 'dd-MM-yyyy');
        this.lastFeedBackDate = this.datePipe.transform(data.LastFeedbackDate, 'dd-MM-yyyy');
        if(this.currentDate.getTime() != new Date(this.nextFeedbakDate).getTime() && this.currentDate.getTime() > new Date(this.nextFeedbakDate).getTime()){
          this.rateBtn = false;
        } else{
          this.rateBtn = true;
        }
      }
      if(data == "No Results Found"){
        this.showWelcomeSec = true;
        this.showLoading = false;
        this.showRating = false;
        this.rateBtn = false;
      }
    },
    (error) => {
      this.showWelcomeSec = true;
      this.showLoading = false;
      this.showRating = false;
      this.rateBtn = false;
    }
    )
  }
startRating(){
this.showQuestion = true;
this.feedbackService.getFeedBackQuestion(this.user).subscribe(data =>{
  if(this.user == 'owner'){
    this.feedbackQuestions = data;
    // this.showTenantQuestion = true;
    this.showIntroSec = false;
    // this.showOwnerQuestion = false;
  } else {
    this.feedbackQuestions = data;
    // this.showTenantQuestion = false;
    this.showIntroSec = false;
    // this.showOwnerQuestion = true;
  }
})  
}
onRatingSubmit(formValue, formStatus){
  if(formStatus) {
    this.confirmationAlert(formValue);
  } else {
      let error = "Please provide feedback for all questions"
      this._snackBar.open(error, "X", {
        duration: 10000,
        panelClass: ['snackbar-failure']
    });
  }
}

onSubmitConfirm(formValue){
  this.showLoading = true;
  this.showWelcomeSec = false
  for (const item in formValue){
    this.feedbackDetails.feedbackRates.push({"feedbackQuestion":{id:`${item}`}, "answer" : parseInt(formValue[item])})
  }

  this.feedbackService.saveFeedback(this.feedbackDetails, this.user).subscribe(data =>{
    if(data){
      this.feedbackService.getPreviousRating(this.feedbackUrl).subscribe(data => {
        this.feedBack = data.Rate;
        this.nextFeedbakDate = data.NextFeedbackDate;
        this.lastFeedBackDate = data.LastFeedbackDate;
        this.showRating = true;
        this.showLoading = false;
        this.rateBtn = true;
      })
    }
  })
  this.feedbackSubmitted = true;
  this.showTenantQuestion = false;
  this.showOwnerQuestion = false;
  this.showIntroSec = true;
  this.showQuestion = false;
}
onClose(){
  this.showQuestion = false;
  this.showTenantQuestion = false;
  this.showOwnerQuestion = false;
  this.showIntroSec = true;
}
private setUserForFeedBack(){
  this.propertyFacade.currentViewProperty$.subscribe((data) => {
    if(data !== null){
      this.feedbackDetails.propId = data.id;
      if(this.currentUser.id == data.ownerUserId){
        this.user = "tenant";
        this.personName = data.tenantName;
        this.reviewer = data.ownerName;
        this.feedbackDetails['tenantId'] = data.tenantUserId;
        this.feedbackDetails.fromId = data.ownerUserId;
        this.feedbackUrl = "tenant/" + data.id + '/' + data.ownerUserId + '/' + data.tenantUserId;
      }
      else if(this.currentUser.id == data.tenantUserId){
        this.user = "owner";
        this.personName = data.ownerName;
        this.reviewer = data.tenantName;
        this.feedbackDetails['ownerId'] = data.ownerUserId;
        this.feedbackDetails.fromId = data.tenantUserId;
        this.feedbackUrl = "owner/" + data.id + '/' + data.tenantUserId + '/' + data.ownerUserId
      }
    }
  });
}
private initTenantRatingForm() {
  this.tenantRatingForm = new FormGroup({
    rentPrompt: new FormControl(),
    stabilityIncome: new FormControl(),
    tidiness: new FormControl(),
    considerateNeighbor: new FormControl(),
    noDamage: new FormControl(),
    callBackTenant: new FormControl(),
  })
}
private initOwnerRatingForm(){
  this.ownerRatingForm = new FormGroup({
    propertyStatus: new FormControl(),
    maintenance: new FormControl(),
    arrival: new FormControl(),
    poriorNotice: new FormControl(),
    disturbance: new FormControl(),
    callBackOwner: new FormControl(),
  })
}
getStars(rating){
  let val = rating/2;
    // Turn value into number/100
    let size = val/5*100;
    return size + '%';
}
confirmationAlert(formData): void{
  const confirmDialog = this.dialog.open(ConfirmBoxComponent, {
    disableClose: false,
  });
  confirmDialog.componentInstance.confirmMessageHeader = `Confirmation`;
  confirmDialog.componentInstance.confirmMessageContent = `Are you sure you want to submit?`;
  confirmDialog.afterClosed().subscribe(result => {
    if(result)
    {
      this.onSubmitConfirm(formData);
    }
  });
}
// ngOnDestroy(){
//   this.feedbackService.previousRating.unsubscribe();
// }
}
