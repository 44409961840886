import { Injectable } from "@angular/core";
import * as fromBroker from '../selectors/broker.selector';
import * as brokerActions from "../actions/broker.action";
import { Store } from "@ngrx/store";
import { BrokerState } from "../reducers";
import { Broker, Brokerage, User } from "src/app/shared/models";
import { Observable } from "rxjs";

export interface IBrokerStoreFacade {

    //getCurrentBroker
    currentBrokerLoading$: Observable<boolean>;
    currentBroker$: Observable<Broker>;
    brokerage$: Observable<Brokerage>;
    currentUserForBrokerLoading$: Observable<boolean>;
    currentUserForBroker$: Observable<User>;
    editingBroker$: Observable<boolean>;

    queryBrokerById(id: string): void;
    getBrokerage(userId: string): void;
    queryUserForBroker(phoneNumber: string): void;
    addBroker(propertyId: string, broker: Broker): void;
    updateBroker(idpropertyId: string, broker: Broker): void;
    initCurrentBroker(): void;
}

@Injectable()
export class BrokerStoreFacade implements IBrokerStoreFacade {

    get currentBrokerLoading$(){
        return this.store.select(fromBroker.currentBrokerLoading$);
    }

    get currentBroker$(){
        return this.store.select(fromBroker.currentBroker$);
    }

    get currentUserForBroker$(){
        return this.store.select(fromBroker.currentUserForBroker$);
    }

    get currentUserForBrokerLoading$(){
        return this.store.select(fromBroker.currentUserForBrokerLoading$);
    }

    get editingBroker$(){
        return this.store.select(fromBroker.editingBroker$);
    }

    get brokerage$(){
        return this.store.select(fromBroker.brokerage$);
    }

    constructor(
        private readonly store: Store<BrokerState>,
    ){}

    queryBrokerById(id:string){
        this.store.dispatch(brokerActions.queryBrokerById({id}));
    }

    getBrokerage(userId:string){
        this.store.dispatch(brokerActions.getBrokerage({userId}));
    }

    queryUserForBroker(phoneNumber: string): void{
        this.store.dispatch(brokerActions.queryUserForBroker({phoneNumber}));
    }

    addBroker(propertyId: string, broker: Broker): void{
        this.store.dispatch(brokerActions.addBroker({propertyId, broker}));
    }
    
    updateBroker(propertyId: string, broker: Broker): void{
        this.store.dispatch(brokerActions.updateBroker({propertyId, broker}));
    }

    initCurrentBroker(): void {
        this.store.dispatch(brokerActions.initCurrentBroker());
    }
}