<head>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="description" content="Manage your rental property with ease using our AI-powered platform. Get personalized recommendations, flexible rent payment options, automated reminders, and centralized document management. Trust and transparency are at the core of our platform, making each rental transaction secure and hassle-free. Simplify your rental journey today.">
    <meta name="keywords" content="Rental finance management, Property management software, Tenant management system, Rent payment processing, Property listing management, Lease agreement management, Online rent payment, Rental property accounting, Tenant screening, Rent collection automation, Maintenance request management, Property owner dashboard, Real estate software, Online property management, Property management platform, Rental management app, Property management tool, Automated rent collection, Tenant communication platform">
    <meta name="robots" content="index, follow">
</head>
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-Y3D2LBP9KV"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-Y3D2LBP9KV');
</script>
<!-- Google tag (gtag.js) -->
<div class="container full-page">
    <div class="row">
        <div class="col-md-12">
            <h1>Terms of Use</h1>
            <p>
                <b>Introduction</b>. This website (the "Site") is operated by Yuvajunction Services Private Limited (YSPL). These Terms of Service apply to the national version of the Site, located at  Rentofly.com, as well as to any versions or pages of the Site that
                Yuvajunction Services Pvt. Ltd. operates, including those national and localized versions or pages that Yuvajunction Services Pvt. Ltd. co-brands, private labels or otherwise distributes with its newspaper and broadcast affiliates and other distribution
                partners (collectively, "Affiliates"). You may have arrived at the Site from an Affiliate’s website. By using the Site, you hereby agree that you are at least eighteen (18) years of age and bound by all of the following provisions of these
                Terms of Service (the "Terms"). Click <a routerLink="/privacy-policy" target="_blank">here</a> to review the Site’s Privacy Policy. For purposes of these Terms, the term "Site" shall include the Advertising Service, as described below.
            </p>
            <p>
                <b>Real Estate Information Disclaimer</b>. The information presented on this Site is provided by the user and is not guaranteed or warranted to be accurate or complete and is subject to change without notice. Please verify all information
                with the user. The calculators and rate information are provided only as a convenience and do not constitute an offer to lend/rent. Consult your lender/owner/broker/renter for actual mortgage rates, rent, fees, payments, and qualification
                requirements.
            </p>
            <p>
                <b>Yuvajunction Services Pvt. Ltd.is a Data collection, Advertising and Research Service</b>. Yuvajunction Services Pvt. Ltd. operates the Site as an online real estate data collection advertising and research service for Broker, Owner & Renter. Yuvajunction Services Pvt. Ltd.
                does not represent such buyers or sellers, does not provide real estate services to such buyers or sellers, and is never a party to or otherwise involved in any transaction between such Broker, Owner & Renter. By way
                of example and not limitation, Yuvajunction Services Pvt. Ltd. does not: (a) buy, hold in escrow, sell, rent, insure, warrant, or guarantee any property advertised on the Site, (b) guarantee or ensure any transaction between any Broker, Owner
                & Renter, or (c) collect or process payments or transfer title on behalf of Broker, Owner & Renter.
            </p>
            <p>
                <b>Site Content</b>. Copyright and other intellectual property laws protect all of the information and content available on this Site (collectively, "Content"). The Content is owned by Yuvajunction Services Pvt. Ltd., Affiliate, and/or their
                licensors and suppliers (which may include owners, agents, brokers, multiple listing services, builders, service providers, content providers, vendors and others) (collectively, "Licensors and Suppliers"). You may interact with or download
                a single copy of any portion of the Content for your personal, non-commercial use, but you may not reproduce, sell, publish, distribute, modify, display, perform, re-post, transmit, or otherwise use any portion of the Content in any other
                way or for any other purpose without the prior written consent of Yuvajunction Services Pvt. Ltd. To the extent your use of our Content includes Google Maps, you are bound by the Google's Terms of Service.
            </p>
            <p>
                <b>Copyrights and Copyright Agents</b>. It is the policy of Yuvajunction Services Pvt. Ltd.to respond to claims of intellectual property infringement. Yuvajunction Services Pvt. Ltd. will promptly process and investigate notices of alleged infringement
                and will take appropriate actions applicable intellectual property laws.
            </p>
            <p>
                To be effective, the notification must be a written communication that includes the following:
            </p>
            <ol>
                <li>
                    A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;
                </li>
                <li>
                    Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site;
                </li>
                <li>
                    Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit the service provider to locate
                    the material;
                </li>
                <li>
                    Information reasonably sufficient to permit the service provider to contact the complaining party, such as an address, telephone number and, if available, an electronic mail address at which the complaining party may be contacted;
                </li>
                <li>
                    A statement that the complaining party has a good-faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent or the law;
                </li>
                <li>
                    A statement that the information in the notification is accurate and, under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
                </li>
            </ol>
            <p>
                <b>Trademarks</b>. You acknowledge that the Content includes certain trademarks and service marks owned by Yuvajunction Services Pvt. Ltd., Affiliate, the Licensors and Suppliers, and others. You agree not to copy, use or otherwise infringe upon
                or dilute these trademarks or service marks. You further agree that you will not alter or remove any trademark, copyright or other notice from any permitted copies of the Content.
            </p>
            <p>
                <b>Software</b>. Please note that all software programming, including without limitation, all HTML and other code contained in this Site (collectively, "Software"), is owned by Yuvajunction Services Pvt. Ltd. and/or its Licensors and Suppliers
                and is protected by copyright laws and other intellectual property laws, as well as international treaty provisions. Any reproduction, redistribution, publication, display, or other unauthorized use of the Software without the express
                written consent of Yuvajunction Services Pvt. Ltd.is prohibited, and may result in severe civil and criminal penalties. Violators will be prosecuted to the maximum extent possible.
            </p>
            <p>
                <b>License</b>. Unless otherwise agreed in a writing signed by Yuvajunction Services Pvt. Ltd. or Affiliate, by submitting content, photos, video, data or other materials directly through the Site, including, without limitation, in the form of
                or in connection with Ads (as defined below) (collectively, "Materials"), you hereby grant to Yuvajunction Services Pvt. Ltd. and Affiliate a perpetual, irrevocable, royalty-free, non-exclusive, fully transferable right and license to access,
                store, copy, modify, display, distribute, perform, create derivative works from, and otherwise use and exploit all such Materials in any form, media, software or technology of any kind now existing or developed in the future and the right
                to sublicense the foregoing rights through multiple tiers. You further grant to Yuvajunction Services Pvt. Ltd. and Affiliate a royalty-free right and license to use your name, image and likeness in advertising and in connection with the licensed
                rights for the Materials.
            </p>
            <p>
                <b>Interactive Areas</b>. The Site may offer forums, blogs, comments areas, bulletin boards and chat rooms (collectively, "Forums") that are intended to provide users 18 years of age and older an interesting and stimulating forum in which
                they can express their opinions and share their ideas. Yuvajunction Services Pvt. Ltd. and its Affiliates do not endorse the accuracy or reliability of any advice, opinion, statement or information posted on these Forums. Please use your best
                judgment, and be respectful of other individuals using these Forums. Do not use vulgar, abusive or hateful language. You may not use the Site’s Forums for advertising or promotional materials or other forms of solicitation. Uploading copyrighted
                or other proprietary material of any kind on the Site without the express permission of the owner of that material is prohibited and may result in civil and/or criminal liability. Any information you disclose when posting a message in
                these Forums may become public. You should not include any information in your posting that you do not want other parties to see or use and you hereby agree that you will not hold Yuvajunction Services Pvt. Ltd. responsible for any third party’s
                use of information contained in such posting.
            </p>
            <p>
                <b>Yuvajunction Services Pvt. Ltd. Rights</b>. Given the nature of the Site and the volume of Materials submitted, Yuvajunction Services Pvt. Ltd. cannot and does not monitor all of the Materials posted or transmitted by you and other third-party information
                providers via the Site, including, without limitation, any Materials posted via the Forums and the Advertising Service. You expressly agree that Yuvajunction Services Pvt. Ltd. and its Affiliates: (a) will not be liable for Materials and (b)
                reserve the right to review, reject, delete, remove, modify, or edit any Materials at any time for any reason, without liability, without notice to you and without providing a refund.
            </p>
            <p>
                <b>Representations and Warranties</b>. You hereby represent and warrant that: (a) you have all necessary authority, rights and permissions to submit the Materials and grant the licenses described in these Terms, (b) the Materials are accurate,
                current and complete, (c) the Materials and your use of the Site shall not infringe or misappropriate any copyright, trademark, trade secret, patent, or other intellectual property right of any third party or violate any other rights of
                third party, including, without limitation, any rights of privacy or publicity or any contractual rights, (d) the Materials and your use of the Site shall not violate any applicable law or regulation, including, without limitation, any
                applicable advertising or fair housing laws or regulations, or cause injury to any person; (e) your use of the Site shall not violate any agreements between you and a third party.
            </p>
            <p>
                <b>Indemnification</b>. You agree to indemnify and hold Yuvajunction Services Pvt. Ltd., Affiliates, and the Licensors and Suppliers, and their respective officers, directors, employees, agents, and contractors harmless from and against any and
                all claims, costs, demands, damages, liabilities, or expenses, including, without limitation, reasonable attorneys’ fees, arising from or related to: (a) the Materials you provide, (b) your use of the Site or your reliance on the Content,
                (c) your breach of these Terms, or (d) any actual, prospective, completed or terminated purchase, sale or other transaction between you and a third party.
            </p>
            <p>
                <b>Prohibited Activities</b>. The following is a partial list of the kinds of activities that are prohibited on or through the Site: (a) submitting Materials that are patently offensive to the online community, such as content that promotes
                racism, bigotry, hatred or physical harm of any kind against any group or individual; (b) engaging in activities or submitting Materials that could be harmful to minors; (c) engaging in activity or submitting Materials that harasses or
                advocates harassment of another person; (d) engaging in activity that involves the transmission of "junk mail" or unsolicited mass mailing or "spam" or harvesting or otherwise collecting personally identifiable information about Site users,
                including names, phone numbers, addresses, email addresses, (collectively, "User Data") without their consent; (e) engaging in activity, or submitting Materials, or promoting information that is false, misleading or promotes illegal activities
                or conduct that is abusive, threatening, obscene, defamatory or libelous; (f) submitting Materials that contain restricted or password only access pages, or hidden pages or images; (g) submitting Materials that displays pornographic or
                sexually explicit material of any kind; (h) submitting Materials that provide instructional information about illegal activities such as making or buying illegal weapons, violating someone’s privacy, or providing or creating computer viruses;
                (i) submitting Materials that contain viruses, Trojan horses, worms, or any other similar forms of malware, (j) engaging in activities or submitting Materials that solicit passwords or personally identifiable information for unlawful purposes
                from other users; (k) engaging in unauthorized commercial activities and/or sales without our prior written consent such as advertising, solicitations, contests, sweepstakes, barter, and pyramid schemes; (l) using any robot, spider, other
                automatic device, or manual process to monitor, copy, or "scrape" web pages or the Content contained in the Site or for any other unauthorized purpose without Yuvajunction Services Pvt. Ltd.’s prior written consent; (m) using any device, software,
                or routine to interfere or attempt to interfere with the proper working of the Site; (n) decompiling, reverse engineering, or disassembling the Software or attempting to do so; or (o) taking any action that imposes an unreasonable or disproportionately
                large load on the Site or Yuvajunction Services Pvt. Ltd.’s hardware and software infrastructure or that of any of its Licensors or Suppliers.
            </p>
            <p>
                <b>Advertising Service</b>. This Site may offer a publicly available self-service advertising product through which private parties, owners, builders, agents, brokers, and/or others (collectively, "Advertisers") may purchase online property
                listings ("Ads") and manage those Ads once purchased (the "Advertising Service"). By purchasing an Ad, you acknowledge and agree that: (a) you have the right to list the advertised property for sale; (b) you or your client is willing to
                sell the listed property at the price and on any other terms specified in the Ad; (c) each Ad shall feature only one unique property (e.g., you cannot buy one Ad and advertise more than one property on that Ad, either simultaneously or
                consecutively); (d) upon selling the listed property or otherwise taking it off the market, you will promptly delete the Ad for that property; (e) you will not use the Advertising Service to advertise or promote anything other than the
                property for sale and your real estate services in connection therewith; (f) you will pay for your Ad even if you or your client fails to sell the listed property or fails to receive any inquiries regarding the property; and (g) you will
                use User Data solely to respond to property inquiries and will not sell, rent, lease, license, sublicense, or otherwise distribute or transfer User Data to third parties or otherwise use User Data in any unauthorized manner, including
                for "spam", "junk mail", or direct marketing campaigns. Further, (i) you shall provide real estate services and otherwise operate in accordance with all applicable laws, rules, regulations, and codes of conduct, and (ii) you shall provide
                all services to Site users in a prompt, professional, honest, and courteous manner. You acknowledge and agree that certain Ads may need to be renewed or extended to remain on the Site. Generally, amounts paid for Ads are non-refundable
                except in limited instances. If you wish to advertise in an Affiliate’s newspaper, you must contact the Affiliate directly. Depending on the Advertising Service offered on the Site, certain Ads may need to be renewed or extended to remain
                on the Site. Due to processing time, there may be a delay in posting Ads to the Site.
            </p>
            <p>
                <b>Warranty Disclaimer</b>. YOU EXPRESSLY AGREE THAT USE OF THE SITE AND RELIANCE ON ITS CONTENT IS AT YOUR OWN RISK. THE SITE, SOFTWARE, AND CONTENT IS PROVIDED ON AN "AS IS, AS AVAILABLE" BASIS AND Yuvajunction Services Pvt. Ltd.AND AFFILIATE
                AND THEIR THIRD PARTY LICENSORS AND SUPPLIERS SPECIFICALLY DISCLAIM ANY AND ALL EXPRESS OR IMPLIED WARRANTIES, INCLUDING WITHOUT LIMITATION, THE WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE, WARRANTIES OF MERCHANTABILITY, AND WARRANTIES
                OF NON-INFRINGEMENT. Yuvajunction Services Pvt. Ltd., AFFILIATE, AND THEIR THIRD PARTY LICENSORS AND SUPPLIERS DO NOT MAKE ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND REGARDING THE SITE, SOFTWARE, AND CONTENT, OR THE RESULTS THAT MAY BE OBTAINED
                FROM USE OF ANY OF THE FOREGOING, INCLUDING, WITHOUT LIMITATION, ANY REPRESENTATION OR WARRANTY THAT ANY CONTENT IS ACCURATE, COMPLETE OR ERROR-FREE, THAT A LISTED PROPERTY WILL SELL, OR THAT YOU WILL RECEIVE INQUIRIES REGARDING A LISTED
                PROPERTY OR AS A RESULT OF PURCHASING AN AD. ALL REAL ESTATE INFORMATION AVAILABLE ON THIS SITE IS SUPPLIED BY THIRD PARTIES. Yuvajunction Services Pvt. Ltd.AND AFFILIATE CANNOT AND DO NOT VERIFY OR WARRANT THE ACCURACY OR COMPLETENESS OF SUCH
                INFORMATION. FOR PURPOSES OF THIS WARRANTY DISCLAIMER, "THIRD PARTY LICENSOR AND SUPPLIER" DOES NOT INCLUDE YOU.
            </p>
            <p>
                <b>Limitation of Liability</b>. Yuvajunction Services Pvt. Ltd.AND AFFILIATE, AND THEIR RESPECTIVE THIRD PARTY LICENSORS AND SUPPLIERS SHALL IN NO EVENT BE LIABLE TO YOU FOR ANY DAMAGES OR LOSSES, INCLUDING, WITHOUT LIMITATION, INDIRECT, CONSEQUENTIAL,
                SPECIAL, INCIDENTAL OR PUNITIVE DAMAGES, RESULTING FROM OR CAUSED BY THE SITE, SOFTWARE, OR CONTENT OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, LOSSES RELATED TO: YOUR USE OR INABILITY TO USE THE SITE; ANY ERRORS, OMISSIONS OR DEFECTS
                IN THE CONTENT; ANY INTERRUPTIONS OR DELAYS IN TRANSMISSION; OR COMPUTER VIRUSES. IN NO EVENT WILL THE AGGREGATE MAXIMUM LIABILITY OF Yuvajunction Services Pvt. Ltd.AND AFFILIATE AND THEIR RESPECTIVE THIRD PARTY LICENSORS AND SUPPLIERS TO YOU
                FOR ANY CLAIM EXCEED THE GREATER OF THE AMOUNT YOU PAID TO USE THE SITE IN THE MONTH PRECEDING THE CLAIM GIVING RISE TO LIABILITY. FOR PURPOSES OF THIS LIMITATION OF LIABILITY, "THIRD PARTY LICENSOR AND SUPPLIER" DOES NOT INCLUDE YOU.
            </p>
            <p>
                <b>Termination of Service</b>. You understand and agree that in Yuvajunction Services Pvt. Ltd.’s or an Affiliate’s sole discretion, and without prior notice, your access to this Site or any portion thereof may be terminated, and Yuvajunction Services Pvt. Ltd.
                and Affiliates may exercise any other remedy available if either Yuvajunction Services Pvt. Ltd. and/or an Affiliate believes that your use of the Site and/or any Materials you provided violate: (i) these Terms, (ii) the rights of Yuvajunction Services Pvt. Ltd.
                , the applicable Affiliate, or any user of the Site, or (iii) any law, rule or regulation, or are otherwise objectionable or inappropriate for any reason. You agree that monetary damages may not provide a sufficient
                remedy to Yuvajunction Services Pvt. Ltd. .and an Affiliate for violations of these Terms, and you consent to injunctive or other equitable relief for such violations without the requirement that Yuvajunction Services Pvt. Ltd. post a bond. Yuvajunction Services Pvt. Ltd.
                , or as applicable, Affiliate, are not required to provide any refund to you if you are terminated as an authorized user of the Site or any portion thereof pursuant to this Section.
            </p>
            <p>
                <b>Payment</b>. Certain features or services on the Site may require payment. By accessing these features or services, you agree to pay all applicable fees and charges. Payments are non-refundable unless expressly stated otherwise. Yuvajunction Services Pvt. Ltd. 
                may use third-party services for processing payments and is not responsible for any errors or issues arising from these services.
            </p>
            <p>
                <b>Refunds</b>. Refunds, if applicable, will be processed in accordance with the terms specified at the time of purchase or as required by law. Yuvajunction Services Pvt. Ltd. 
                reserves the right to determine, at its sole discretion, whether a refund is warranted in exceptional circumstances. Requests for refunds must be made in accordance with the procedures outlined in the specific terms and conditions applicable to the service or feature for which a refund is sought.
            </p>
            <p>
                <b>Refund Disputes</b>. Any dispute regarding refunds shall be addressed promptly and resolved at the discretion of Yuvajunction Services Pvt. Ltd. or as otherwise required by law.
            </p>
            <p>
                <b>Miscellaneous</b>. These Terms may be changed at any time and you will be notified of any such changes by an updated posting of the new Terms on this page on the Site. Your continued use of the Site after the posting of any amended
                Terms shall constitute your agreement to be bound by any such changes. Yuvajunction Services Pvt. Ltd. may modify, suspend, discontinue or restrict the use of any portion of the Site without notice or liability. Yuvajunction Services Pvt. Ltd. retains
                all right, title, and interest in and to User Data. The Site includes links to other Web sites not established or supervised by Yuvajunction Services Pvt. Ltd. or Affiliate. Yuvajunction Services Pvt. Ltd. and Affiliate are not responsible for any linked
                sites. These linked sites are not endorsed or sponsored by Yuvajunction Services Pvt. Ltd. or Affiliate. These Terms will be governed by and construed in accordance with the laws of the State of California, without regard to its conflicts of
                law provisions. You hereby agree that any cause of action you may have with respect to the subject matter of these Terms must be filed in the federal or state courts located in Orange County, California within one (1) year after the cause
                of action arises or the cause is barred. As a condition of using this Site, you agree that all causes of action arising out of or connected with these Terms shall be resolved individually, without resort to any form of class action. If
                for any reason a court of competent jurisdiction finds any provision of these Terms, or portion thereof, to be unenforceable, that provision of these Terms will be enforced to the maximum extent permissible so as to affect the intent of
                the parties, and the remainder of these Terms will continue in full force and effect. Any failure to enforce any provision of these Terms will not be deemed a waiver of future enforcement of that or any other provision of these Terms.
                These Terms constitute the entire agreement between Yuvajunction Services Pvt. Ltd. and you regarding the subject matter hereof. Neither these Terms, nor any terms and conditions contained herein, shall be construed as creating a partnership,
                joint venture or agency relationship or granting a franchise between the parties. These Terms are to be construed in accordance with any Affiliate Site terms and conditions ("Affiliate Terms") that may also be displayed on the Site, which
                shall apply only to Affiliate. In the event of any conflict between these Terms and the Affiliate Terms as they apply to Affiliate, the Affiliate Terms shall control. In the event of any conflict between these Terms and any separate written
                agreement between you and Yuvajunction Services Pvt. Ltd. or between you and Affiliate with respect to the same subject matter, the terms of the written agreement shall control.
            </p>
            <p>
                © 2021 Yuvajunction Services Pvt. Ltd.
            </p>
        </div>
    </div>
</div>