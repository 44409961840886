import { formatDate } from "@angular/common";
import { Injectable } from "@angular/core";

@Injectable()
export class DateService{
    getFormattedDate(input: Date, days = 1){
        let date = new Date(input);
        date.setDate(date.getDate() + days);
        return date;
    }

    getFormattedDateString(createdDate: Date){
        let dateTimeFormatOptions = { month: "long", day: "numeric", year: "numeric" } as const;
        const date =  new Date(formatDate(createdDate, 'medium', 'en-US'));
        date.setDate(date.getDate() + 1);
        return date.toLocaleString('en-US', dateTimeFormatOptions);
      }
}