import { Injectable } from "@angular/core";
import * as fromOwner from '../selectors/owner.selector';
import * as ownerActions from "../actions/owner.action";
import { Store } from "@ngrx/store";
import { OwnerState } from "../reducers";
import { Email, Owner, OwnerFinances, User } from "src/app/shared/models";
import { Observable } from "rxjs";
import { EmailWhatsapp } from "src/app/shared/models/email-whatsapp.model";

export interface IOwnerStoreFacade {

    //getCurrentOwner
    currentOwnerLoading$: Observable<boolean>;
    currentOwner$: Observable<Owner>;

    currentUserForOwnerLoading$: Observable<boolean>;
    currentUserForOwner$: Observable<User>;
    editingOwner$: Observable<boolean>;
    ownerFinances$: Observable<OwnerFinances>;

    queryOwnerById(id: string): void;
    getOwnerFinances(userId: string): void;
    queryUserForOwner(phoneNumber: string): void;
    addOwner(propertyId: string, owner: Owner): void;
    updateOwner(idpropertyId: string, owner: Owner): void;
    initCurrentOwner(): void;
    updateOwnerConsent(id: string, status: string, emails: Email[]): void;
}

@Injectable()
export class OwnerStoreFacade implements IOwnerStoreFacade {

    get currentOwnerLoading$(){
        return this.store.select(fromOwner.currentOwnerLoading$);
    }

    get currentOwner$(){
        return this.store.select(fromOwner.currentOwner$);
    }

    get currentUserForOwner$(){
        return this.store.select(fromOwner.currentUserForOwner$);
    }

    get currentUserForOwnerLoading$(){
        return this.store.select(fromOwner.currentUserForOwnerLoading$);
    }

    get editingOwner$(){
        return this.store.select(fromOwner.editingOwner$);
    }

    get ownerFinances$(){
        return this.store.select(fromOwner.ownerFinances$);
    }

    constructor(
        private readonly store: Store<OwnerState>,
    ){}

    queryOwnerById(id:string){
        this.store.dispatch(ownerActions.queryOwnerById({id}));
    }
    getOwnerFinances(userId:string){
        this.store.dispatch(ownerActions.getOwnerFinances({userId}));
    }
    queryUserForOwner(phoneNumber: string): void{
        this.store.dispatch(ownerActions.queryUserForOwner({phoneNumber}));
    }

    addOwner(propertyId: string, owner: Owner): void{
        this.store.dispatch(ownerActions.addOwner({propertyId, owner}));
    }
    
    updateOwner(propertyId: string, owner: Owner, emails: EmailWhatsapp[] = []): void{
        this.store.dispatch(ownerActions.updateOwner({propertyId, owner, emails}));
    }

    initCurrentOwner(): void {
        this.store.dispatch(ownerActions.initCurrentOwner());
    }

    updateOwnerConsent(id: string, status: string, emails: Email[] = []): void{
        this.store.dispatch(ownerActions.updateOwnerConsent({id, status, emails}));
    }
}