
<head>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="description" content="Manage your rental property with ease using our AI-powered platform. Get personalized recommendations, flexible rent payment options, automated reminders, and centralized document management. Trust and transparency are at the core of our platform, making each rental transaction secure and hassle-free. Simplify your rental journey today.">
    <meta name="keywords" content="Rental finance management, Property management software, Tenant management system, Rent payment processing, Property listing management, Lease agreement management, Online rent payment, Rental property accounting, Tenant screening, Rent collection automation, Maintenance request management, Property owner dashboard, Real estate software, Online property management, Property management platform, Rental management app, Property management tool, Automated rent collection, Tenant communication platform">
    <meta name="robots" content="index, follow">
</head><!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-Y3D2LBP9KV"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-Y3D2LBP9KV');
</script>
<!-- Google tag (gtag.js) -->
<div class="row" *ngIf="(currentViewPropertyLoading$ | async)">
    <div class="col-md-12">
        <rentofly-loading></rentofly-loading>
    </div>
</div>
<div class="row" *ngIf="(currentViewProperty)">
    <div class="col-md-12 header-div">
        <p class="info-header-top">Congratulations <b class="name">{{this.currentViewProperty?.ownerName}}</b> and <b class="name">{{this.currentViewProperty?.tenantName}}</b> has accepted the deal!</p>
        
    </div>
</div>
<div class="row form-row" *ngIf="(currentViewProperty)">
    <div class="col-md-12">
        <form [formGroup]="draftAgreementForm" class="draft-agreement">
            <div class="container draft-text">
                <p class="agreement-completed-text">Please click below <span class="primary-color">Initial Deal Completed</span> button to close the deal in Rentofly.</p>
                            <div class="action">
                                <button class="rentofly-primary" (click)="onInitiateDeal(draftAgreementForm.value)">Initial Deal Completed</button>
                                
                            </div>
                            <hr />
                            <p class="info-header">Please follow the below steps to complete the agreement process.</p>
                <div class="row">
                    <div class="col-md-12">
                        <div class="p-inputgroup">
                            <ol class="list-text">
                                <li>
                                    Go to Government Website <span class="spanColor">(Check important links on homepage. e.g. :- Go to </span><a href="https://efilingigr.maharashtra.gov.in/ereg/" target="_blank">Maharashtra e-filing website</a><span class="spanColor">. Create Profile / select your district.)</span>
                                </li>
                                <li>Add property details.</li>
                                <li>Add party details.</li>
                                <li>Add rent.</li>
                                <li>Pay fees.</li>
                                <li>Schedule an appointment with the sub-registrar.</li>
                                <li>Take a print out of documents. This will ensure that successful registration of the applicant's property is conducted swiftly.</li>
                                <li>Upload your rental agreement to Rentofly for future reference by using below <span class="primary-color">Upload Document</span> button.</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <rentofly-property-attachments [propertyId]="propertyId"></rentofly-property-attachments>
            </div>
            <br />
           
            <br />
            <div class="action">
                
                <a (click)="onBack()">Back</a>|<a (click)="onExit()">Exit</a>
            </div>
        </form>
    </div>
</div>