import { formatDate, isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UUID } from 'angular2-uuid';
import { Observable } from 'rxjs';
import { ConfirmBoxComponent } from 'src/app/shared/components';
import { FileUploadModel, ViewProperty } from 'src/app/shared/models';
import { DateService, LocalStorageService, WindowService } from 'src/app/shared/services';
import { AttachmentsStoreFacade } from 'src/app/store/facades';

@Component({
  selector: 'rentofly-property-attachments',
  templateUrl: './property-attachments.component.html',
  styleUrls: ['./property-attachments.component.scss']
})
export class PropertyAttachmentsComponent implements OnInit {

  public errorMessage: string;
  public currentFileUpload: boolean;
  public progress: number = 20;
  public attachments$: Observable<FileUploadModel[]>;
  public displayedColumns: string[] = ['fileName', 'createdDate', 'action'];
  public attachmentsLoading$: Observable<boolean>;
  public newAttachment: FileUploadModel;
  @Input()
  propertyId: string;

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly attachmentsFacade: AttachmentsStoreFacade,
    private readonly _snackBar: MatSnackBar,
    private readonly dateService: DateService,
    private readonly windowService: WindowService,
    private readonly dialog: MatDialog,
    @Inject(PLATFORM_ID) private readonly platformId: any,
  ) { }

  ngOnInit(): void {
    if(this.windowService.isMobileBrowser()){
      this.displayedColumns = ['fileName', 'action'];
    }
    this.errorMessage = null;
    this.attachments$ = this.attachmentsFacade.getAttachments$;
    //this.attachmentsFacade.getAttachmentsByPropertyId(this.propertyId);
    this.attachmentsLoading$ = this.attachmentsFacade.attachmentsLoading$;
    this.attachmentsFacade.attachmentsProgress$.subscribe(
      percentage => {
        this.progress = Math.round(percentage);
        // console.log(this.progress);
        if(this.progress == 100)
        {
          // this.attachmentsFacade.getAttachmentsByPropertyId(this.currentProperty.id);
          // console.log(this.newAttachment);
          // this.attachmentsFacade.addAttachment(this.newAttachment);
          this.currentFileUpload = false;
          // this.attachmentFacade.queryAttachmentsByQuoteIdentifier(this.quoteIdentifier);
        }
      });

    this.attachmentsFacade.attachmentError$.subscribe((error) => {
      if(error)
      {
        this._snackBar.open(error, "X", {
          duration: 10000,
          panelClass: ['snackbar-failure']
        });
      }
    })
  }

  onFileChange(event) {
    this.errorMessage = null;
    const fileList: FileList = event.target.files;
  
    let loggedInUser = this.localStorageService.getObjectFromLocalStorage('current-user');
    for(var i =0; i < fileList.length; i++)
    {
      let file = fileList[i];
      if(file.size/Math.pow(1024,2) > 10)
      {
        this.errorMessage = 'The file that you are trying to upload exceeds the limit of 10MB';
        return;
      }
      var allowedExtensions = ["xls","xlsx", "pdf", "doc", "docx", "png", "jpg","jpeg"];
      var extn = file.name.split(".").pop();
      if(allowedExtensions.indexOf(extn.toLowerCase()) < 0 ) {
        this.errorMessage = ' The attached file type is not supported. Please try using .xls, .xlsx, .pdf, .doc, .docx, .png, .jpg, .jpeg file types.';
        return;
      }
      this.newAttachment = {
        id: UUID.UUID(),
        name: file.name,
        createdBy: loggedInUser.uid,
        createdDate: new Date(),
        propertyId: this.propertyId
      };
      this.attachmentsFacade.createAttachment(this.newAttachment, file);
      this.currentFileUpload = true;
      this.progress = 0;
    }
  }

  OnFileClick(event){
    event.target.value = '';
  }

  deleteAttachment(element){
    const confirmDialog = this.dialog.open(ConfirmBoxComponent, {
      disableClose: false,
    });
    confirmDialog.componentInstance.confirmMessageHeader = `Delete Attachment - ${element.name}`;
    confirmDialog.componentInstance.confirmMessageContent = `Are you sure you want to continue?`;
    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        if (isPlatformBrowser(this.platformId)) {
          this.attachmentsFacade.deleteAttachmentById(element.id);
        }
      }
    });
  }

  getFormattedDateString(date){
    return this.dateService.getFormattedDateString(date);
  }
}
