import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from "./base.service";
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
  export class ServicesInfoService extends BaseService{

    private servicesInfoUrl = this.baseServicesUrl() + '/services/';

    addRentoflyServices(serviceData): Observable<any>{
        const headers = this.getHeader();
        return this.getHttpClient().post(this.servicesInfoUrl + 'add', serviceData, { headers: headers });
    }

    getAllServices(data):Observable<any>{
        const headers = this.getHeader();
        return this.getHttpClient().post(this.servicesInfoUrl + 'fetch', data, { headers: headers })
    }

    updateRentoflyServices(updatedData):Observable<any>{
      const headers = this.getHeader();
      return this.getHttpClient().post(this.servicesInfoUrl + 'update', updatedData, { headers: headers });
    }

    fetchServiceType(data):Observable<any>{
      const headers = this.getHeader();
      return this.getHttpClient().post(this.servicesInfoUrl + 'serviceType/fetch', data,{ headers: headers })
    }
  }