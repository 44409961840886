import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { EmailService, LocalStorageService } from 'src/app/shared/services';
import { ReferralCodeService } from 'src/app/shared/services/referral-code.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/shared/services/user.service';
import { Email} from '../../../shared/models';
import { environment } from 'src/environments/environment';
import { ReferralCodeTemplate } from 'src/app/shared/emailTemplates/referral-code.template';
import { PartnerStoreFacade } from 'src/app/store/facades';
import { EmailWhatsapp } from 'src/app/shared/models/email-whatsapp.model';

@Component({
  selector: 'rentofly-referral-code',
  templateUrl: './referral-code.component.html',
  styleUrls: ['./referral-code.component.scss']
})
export class ReferralCodeComponent implements OnInit {

  public referralCode = '';
  public currentUser:any;
  public phoneNumber;
  public emailBtn = false;

  constructor(private referralCodeService: ReferralCodeService,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router, 
    private userService: UserService,
    private readonly partnerFacade: PartnerStoreFacade,
    private emailservice: EmailService,
    private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getCurrentUser(this.localStorageService.getObjectFromLocalStorage('dashboard-user').phoneNumber);
    //this.currentUser = this.localStorageService.getObjectFromLocalStorage('current-user');
  }

  getCurrentUser(mobileNumber){
    this.userService.getUserByPhoneNumber(mobileNumber).subscribe(data =>{
      if(data.message){
        this.currentUser = data.message;
      }else{
        this.currentUser = this.localStorageService.getObjectFromLocalStorage('current-user');
      }
    })
  }

  utiliseReferralCode() {
    if(this.referralCode !== ''){
      let referralObj = {
        "code": this.referralCode,
        "user": {
          "id": this.currentUser.id,
          "email": this.currentUser.emailAddress,
          "name": this.currentUser.name
        }
      }
  
      this.referralCodeService.utiliseReferralCode(referralObj).subscribe(data =>{
        if(data.isSuccess){
          this.router.navigate(['/']);          
          this.localStorageService.addToLocalStorage('availability', data.isSuccess);
          this.referralCode = '';
        } else{
          this._snackBar.open(data.message, "X", {
            duration: 10000,
            panelClass: ['snackbar-failure']
        });
        }
      })
    } else{
      this._snackBar.open('Please Enter Refferal Code', "X", {
        duration: 10000,
        panelClass: ['snackbar-failure']
    });
    }
  }

  sendEmail(){
    // let emails: Email[] = [];
    
    //   let partnerEmail: Email = {
    //     to: 'vipin.shukla@rentofly.com',
    //     from: environment.emailUsers.infoEmail,
    //     subject: `'Rentofly - Referral code request'`,
    //     htmlBody: this.getEmailBody(ReferralCodeTemplate, this.currentUser.phoneNumber)
    //   }
    //   emails.push(partnerEmail);
    //   emails.forEach((data) => {
    //     this.emailservice.sendEmail(data).subscribe( resp =>{
    //       this.emailBtn = true;
    //     }, error =>{
    //       console.log(error)
    //     });
    //   });
    //return emails;
    let emailWhatsapp : EmailWhatsapp = {
        senderName: 'Team rentofly',
        receiverName: "Vipin Shukla",
        receiverEmail: "vipin.shukla@rentofly.com",
        type: "",
        template: "referral_code_template",
        messageParameters: {
          subjectTextValue: "Rentofly - Referral code request",
          userName: 'Team',
          requesterNumber: this.currentUser.phoneNumber,
        }
    }
    this.emailservice.sendEmail(emailWhatsapp).subscribe( resp =>{
            this.emailBtn = true;
          }, error =>{
            console.log(error)
          });
  }

  private getEmailBody(template: string, phoneNumber)
  {  
    return template.replace('{phoneNumber}', phoneNumber)
  }
}