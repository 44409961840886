<head>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="description" content="Manage your rental property with ease using our AI-powered platform. Get personalized recommendations, flexible rent payment options, automated reminders, and centralized document management. Trust and transparency are at the core of our platform, making each rental transaction secure and hassle-free. Simplify your rental journey today.">
    <meta name="keywords" content="Rental finance management, Property management software, Tenant management system, Rent payment processing, Property listing management, Lease agreement management, Online rent payment, Rental property accounting, Tenant screening, Rent collection automation, Maintenance request management, Property owner dashboard, Real estate software, Online property management, Property management platform, Rental management app, Property management tool, Automated rent collection, Tenant communication platform">
    <meta name="robots" content="index, follow">
</head>
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-Y3D2LBP9KV"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-Y3D2LBP9KV');
</script>
<!-- Google tag (gtag.js) -->
<rentofly-loading *ngIf="(propertyEditing$ | async)"></rentofly-loading>
<div class="row form-row">
    <div class="col-md-12 topSpace">
        <p-accordion class="view-property-info-accordian addPropertyAcc">
            <p-accordionTab header="Property Details" [(selected)]="activeState[0]">
                <form [formGroup]="propertyInfoForm" class="property-info">
                    <div class="form-group">
                        <span class="p-float-label contact-span span-space">
                            <input id="float-input-name propertyName" class="contact-input" type="text" formControlName="propertyName" pInputText> 
                            <label for="float-input-name" class="input-label">Property Name</label>
                        </span>
                        <div *ngIf="propertyName!.invalid && showPropertyInfoValidationMessage" class="rentofly-validation-msg ">
                            <div>
                                Property Name is required.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <span class="p-float-label contact-span span-space">
                            <input id="float-input-pin inputnumber propertyZipCode" class="contact-input" type="text" pKeyFilter="num"
                                pattern="^[1-9][0-9]*$"
                                oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                                maxlength="6" formControlName="propertyZipCode" pInputText (blur)="queryAddressByZipCode(propertyZipCode.value)"> 
                            <label for="float-input-pin" class="input-label">PIN Code</label>
                        </span>
                        <div *ngIf="propertyZipCode!.invalid && showPropertyInfoValidationMessage" class="rentofly-validation-msg ">
                            <div>
                                PIN Code is required.
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="(currentAddressLoading$ | async)">
                        <rentofly-loading></rentofly-loading>
                    </div>
                    <div class="form-group" *ngIf="!(currentAddressLoading$ | async)">
                        <span class="p-float-label contact-span span-space">
                            <input id="float-input-city propertyCity" class="contact-input" type="text" formControlName="propertyCity" pInputText> 
                            <label for="float-input-city" class="input-label">City</label>
                        </span>
                        <div *ngIf="propertyCity!.invalid && showPropertyInfoValidationMessage" class="rentofly-validation-msg ">
                            <div>
                                City is required.
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="!(currentAddressLoading$ | async)">
                        <span class="p-float-label contact-span span-space">
                            <input id="float-input-state propertyState" class="contact-input" type="text" formControlName="propertyState" pInputText> 
                            <label for="float-input-state" class="input-label">State</label>
                        </span>
                        <div *ngIf="propertyState!.invalid && showPropertyInfoValidationMessage" class="rentofly-validation-msg ">
                            <div>
                                State is required.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <span class="p-float-label contact-span span-space">
                            <input id="float-input-landmark" class="contact-input" type="text" formControlName="propertyLandmark" pInputText> 
                            <label for="float-input-landmark" class="input-label">Landmark</label>
                        </span>
                    </div>
                    <div class="form-group">
                        <span class="p-float-label contact-span span-space">
                            <input id="float-input-area" class="contact-input" type="text" formControlName="propertyArea" pInputText> 
                            <label for="float-input-area" class="input-label">Building /Area /Village name</label>
                        </span>
                    </div>
                    <div class="form-group">
                        <span class="p-float-label contact-span span-space">
                            <input id="float-input-street" class="contact-input" type="text" formControlName="propertyStreet" pInputText> 
                            <label for="float-input-street" class="input-label">Street details</label>
                        </span>
                    </div>
                    <div class="form-group">
                        <span class="p-float-label contact-span span-space">
                            <input id="float-input-flat" class="contact-input" type="text" formControlName="propertyHouseNo" pInputText> 
                            <label for="float-input-flat" class="input-label">Flat/ House Number</label>
                        </span>
                    </div>
                    <span class="notificationSpan">Additional property details are Optional, Please click on continue in case you wish to skip the step</span>
                </form>
            </p-accordionTab>
            <p-accordionTab header="Additional Details">
                <form [formGroup]="additionalPropertyInfoForm" class="property-info">
                    <div class="form-group">
                        <p class="info-header radio-para-div">Property Type : </p>
                        <div class="p-field-radiobutton radio-type-div">
                            <p-radioButton name="propertyType" value="residential" formControlName="propertyType" (click)="selectPropertyType('residential')" inputId="residential"></p-radioButton>
                            <label for="residential" class="radio-label">Residential</label>
                        </div>
                        <div class="p-field-radiobutton radio-type-div">
                            <p-radioButton name="propertyType" value="commercial" formControlName="propertyType" (click)="selectPropertyType('commercial')" inputId="commercial"></p-radioButton>
                            <label for="commercial" class="radio-label">Commercial</label>
                        </div>
                    </div>
                    <div class="form-group">
                        <p class="info-header radio-para-div">Sub Type : </p>
                        <p-dropdown [options]="subType" formControlName="subPropertyType" [disabled]="propertySelected" placeholder="Select a Type" optionLabel="name"></p-dropdown>
                    </div>
                    <div class="form-group">
                        <span class="p-float-label contact-span span-space">
                            <input id="float-input-name propertyName" class="contact-input" type="text" formControlName="area" 
                                pKeyFilter="num" maxlength="10" pInputText> 
                            <label for="float-input-name" class="input-label">Area (sq ft.)</label>
                        </span>
                    </div>
                    <div class="form-group">
                        <span class="p-float-label contact-span span-space">
                            <input id="float-input-name propertyName" class="contact-input" type="text" formControlName="numBedrooms" 
                                pKeyFilter="num" maxlength="3" pInputText> 
                            <label for="float-input-name" class="input-label">Number of Bedroom/s</label>
                        </span>
                    </div>
                    <div class="form-group">
                        <span class="p-float-label contact-span span-space">
                            <input id="float-input-name propertyName" class="contact-input" type="text" formControlName="numBathroom" 
                                pKeyFilter="num" maxlength="3" pInputText> 
                            <label for="float-input-name" class="input-label">Number of Bathroom/s</label>
                        </span>
                    </div>
                    <div class="form-group">
                        <span class="p-float-label contact-span span-space">
                            <input id="float-input-name propertyName" class="contact-input" type="text" formControlName="numBalcony" 
                                pKeyFilter="num" maxlength="3" pInputText> 
                            <label for="float-input-name" class="input-label">Balcony/Patio</label>
                        </span>
                    </div>
                    <div class="form-group">
                        <span class="p-float-label contact-span span-space">
                            <input id="float-input-name propertyName" class="contact-input" type="text" formControlName="accomodates" 
                                pKeyFilter="num" maxlength="3" pInputText> 
                            <label for="float-input-name" class="input-label">Accomodates</label>
                        </span>
                    </div>
                    <div class="form-group">
                        <p class="info-header radio-para-div" style="margin-right: 60px;">Parking Available : </p>
                        <div class="p-field-radiobutton radio-div">
                            <p-radioButton name="parking" value="yes" formControlName="parking" (click)="pAvailable(true)" inputId="parkingYes"></p-radioButton>
                            <label for="parkingYes" class="radio-label">Yes</label>
                        </div>
                        <div class="p-field-radiobutton radio-div">
                            <p-radioButton name="parking" value="no" formControlName="parking" (click)="pAvailable(false)" inputId="parkingNo"></p-radioButton>
                            <label for="parkingNo" class="radio-label">No</label>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="parkingAvailable">
                        <p class="info-header radio-para-div" style="margin-right: 90px;">Parking Type : </p>
                        <div class="p-field-radiobutton radio-div">
                            <p-radioButton name="parkingType" value="Covered" formControlName="parkingType" inputId="parkingTypeCoverd"></p-radioButton>
                            <label for="parkingTypeCoverd" class="radio-label">Covered</label>
                        </div>
                        <div class="p-field-radiobutton radio-div">
                            <p-radioButton name="parkingType" value="Open" formControlName="parkingType" inputId="parkingTypeOpen"></p-radioButton>
                            <label for="parkingTypeOpen" class="radio-label">Open</label>
                        </div>
                    </div>
                    <div class="form-group">
                        <p class="info-header radio-para-div">Visitor Parking Available : </p>
                        <div class="p-field-radiobutton radio-div">
                            <p-radioButton name="visitorParking" value="yes" formControlName="visitorParking" (click)="vpAvailable(true)" inputId="visitorParkingYes"></p-radioButton>
                            <label for="visitorParkingYes" class="radio-label">Yes</label>
                        </div>
                        <div class="p-field-radiobutton radio-div">
                            <p-radioButton name="visitorParking" value="no" formControlName="visitorParking" (click)="vpAvailable(false)" inputId="visitorParkingNo"></p-radioButton>
                            <label for="visitorParkingNo" class="radio-label">No</label>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="visitorParkingAvailable">
                        <p class="info-header radio-para-div" style="margin-right: 42px;">Visitor Parking Type : </p>
                        <div class="p-field-radiobutton radio-div">
                            <p-radioButton name="visitorParkingType" value="Covered" formControlName="visitorParkingType" inputId="visitorParkingTypeCoverd"></p-radioButton>
                            <label for="visitorParkingTypeCoverd" class="radio-label">Covered</label>
                        </div>
                        <div class="p-field-radiobutton radio-div">
                            <p-radioButton name="visitorParkingType" value="Open" formControlName="visitorParkingType" inputId="visitorParkingTypeOpen"></p-radioButton>
                            <label for="visitorParkingTypeOpen" class="radio-label">Open</label>
                        </div>
                    </div>
                    <div class="form-group">
                        <p class="info-header radio-para-div" style="margin-right: 72px;">Furnishing Type : </p>
                        <div class="p-field-radiobutton radio-div">
                            <p-radioButton name="furnishing" value="fully" formControlName="furnishing" (click)="furnishedType(true)" inputId="fully"></p-radioButton>
                            <label for="fully" class="radio-label">Fully furnished</label>
                        </div>
                        <div class="p-field-radiobutton radio-div-semi">
                            <p-radioButton name="furnishing" value="semi" formControlName="furnishing" (click)="furnishedType(true)" inputId="semi"></p-radioButton>
                            <label for="semi" class="radio-label">Semi furnished</label>
                        </div>
                        <div class="p-field-radiobutton radio-div-uni">
                            <p-radioButton name="furnishing" value="unfurnished" formControlName="furnishing" (click)="furnishedType(false)" inputId="unfurnished"></p-radioButton>
                            <label for="unfurnished" class="radio-label">Unfurnished</label>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="furnished">
                        <p class="info-header radio-para-div" style="margin-right: 50px;">Furniture/Electronics</p>
                        <p-multiSelect [options]="furnitureData" formControlName="selectedFurniture" defaultLabel="Select a Furniture" optionLabel="name"></p-multiSelect>
                    </div>
                    <div class="form-group">
                        <p class="info-header radio-para-div" style="margin-right: 82px;">Electricity Board:</p>
                        <p-dropdown [options]="electricBoard" formControlName="electricBoard" placeholder="Select Electric Board" optionLabel="name"></p-dropdown>
                    </div>
                    <div class="form-group">
                        <span class="p-float-label contact-span span-space">
                            <input id="float-input-name propertyName" class="contact-input" type="text" formControlName="consumernumber" 
                                maxlength="20" pInputText> 
                            <label for="float-input-name" class="input-label">Consumer Number</label>
                        </span>
                    </div>
                    <div class="form-group">
                        <p class="info-header radio-para-div" style="margin-right: 92px;">Power Backup: </p>
                        <div class="p-field-radiobutton radio-div">
                            <p-radioButton name="powerBackup" value="yes" formControlName="powerBackup" inputId="powerBackupYes"></p-radioButton>
                            <label for="powerBackupYes" class="radio-label">Yes</label>
                        </div>
                        <div class="p-field-radiobutton radio-div">
                            <p-radioButton name="powerBackup" value="no" formControlName="powerBackup" inputId="powerBackupNo"></p-radioButton>
                            <label for="powerBackupNo" class="radio-label">No</label>
                        </div>
                    </div>
                </form>
            </p-accordionTab> 
        </p-accordion>
        <div class="action">
            <button class="rentofly-tertiary" (click)="onContinue(propertyInfoForm.value, additionalPropertyInfoForm.value)">Continue</button><br />
            <!-- <button class="rentofly-secondary" (click)="onSaveAndExit(propertyInfoForm.value)">Save & Exit</button> -->
            <a (click)="onBack()">Back</a>|<a (click)="onExit()">Exit</a>
        </div>
    </div>
</div>