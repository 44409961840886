import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-view-property',
  templateUrl: './view-property.component.html',
  styleUrls: ['./view-property.component.scss'],
})
export class ViewPropertyComponent implements OnInit {

  availableTabStyle = 'active-style';
  rentedTabStyle = 'inactive-style';
  serviceTabStyle = 'inactive-style';
  terminatedTabStyle = 'inactive-style';
  showPropertyType = true;

  constructor(
    public router: Router,
  ) { 

    if(this.router.url.includes("/available-property"))
    {
      this.changeStyle("available");
    }
    else if(this.router.url.includes("/rented-property")){
      this.changeStyle("rented");
    }
    else if(this.router.url.includes("/service")){
      this.changeStyle("service");
    }
    else if(this.router.url.includes("/terminated-property")){
      this.changeStyle("terminated");
    }
    else{
      this.showPropertyType = false;
    }
    this.router.events.subscribe((evt) => {
      this.showPropertyType = true;
      if ((evt instanceof NavigationEnd)) {
        if(evt.urlAfterRedirects.includes("/available-property"))
        {
          this.changeStyle("available");
        }
        else if(evt.urlAfterRedirects.includes("/rented-property")){
          this.changeStyle("rented");
        }
        else if(evt.urlAfterRedirects.includes("/service")){
          this.changeStyle("service");
        }
        else if(evt.urlAfterRedirects.includes("/terminated-property")){
          this.changeStyle("terminated");
        }
        else{
          this.showPropertyType = false;
        }
      }
    });
  }

  ngOnInit(): void {
  }

  changeStyle(tabName: string): void{
    if(tabName == "available"){
      this.availableTabStyle = 'active-style';
      this.rentedTabStyle = 'inactive-style';
      this.serviceTabStyle = 'inactive-style';
      this.terminatedTabStyle = 'inactive-style';
    }
    if(tabName == "rented"){
      this.availableTabStyle = 'inactive-style';
      this.rentedTabStyle = 'active-style';
      this.serviceTabStyle = 'inactive-style';
      this.terminatedTabStyle = 'inactive-style';
    }
    if(tabName == "service"){
      this.availableTabStyle = 'inactive-style';
      this.rentedTabStyle = 'inactive-style';
      this.serviceTabStyle = 'active-style';
      this.terminatedTabStyle = 'inactive-style';
    }
    if(tabName == "terminated"){
      this.availableTabStyle = 'inactive-style';
      this.rentedTabStyle = 'inactive-style';
      this.serviceTabStyle = 'inactive-style';
      this.terminatedTabStyle = 'active-style';
    }
  }

}
