import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { UUID } from 'angular2-uuid';
import { Observable } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { PropertyInfo, PropertyListRequest } from 'src/app/shared/models';
import { LocalStorageService, WindowService } from 'src/app/shared/services';
import { PropertyStoreFacade } from '../../../store/facades/property.store-facade';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-available-property',
  templateUrl: './available-property.component.html',
  styleUrls: ['./available-property.component.scss'],
})
export class AvailablePropertyComponent implements OnInit {
  totalProperties$: Observable<number>;
  pageSize$: Observable<number>;
  pageIndex$: Observable<number>;
  propertyListRequest: PropertyListRequest;
  allProperties$: Observable<PropertyInfo[]>;
  newPropertyId = UUID.UUID();
  isPropertyLoading$: Observable<boolean>;
  currentUser: any;
  public displayedColumns: string[] = ['propertyName', 'propertyStatus', 'rentPerMonth', 'ownerName', 'tenantName', 'brokerName'];
  
  constructor(
    private readonly router: Router,
    private readonly propertyFacade: PropertyStoreFacade,
    private readonly localStorageService: LocalStorageService,
    private readonly windowService: WindowService,
  ) { 
    
  }

  ngOnInit(): void {
    if(this.windowService.isMobileBrowser()){
      this.displayedColumns = ['propertyName', 'propertyStatus', 'rentPerMonth'];
    }
    this.propertyFacade.setPropertyListRequest({
      pageIndex: 0,
      pageSize: 10,
      orderBy: '',
      orderByColumn: null
    });
    this.currentUser = this.localStorageService.getObjectFromLocalStorage('current-user');
    this.propertyFacade.queryPaginatedAvailableProperty(this.currentUser.id);
    this.totalProperties$ = this.propertyFacade.getTotalProperties$;
    this.pageSize$ = this.propertyFacade.pageSize$;
    this.pageIndex$ = this.propertyFacade.pageIndex$;
    this.propertyFacade.propertyListRequest$.subscribe((data) => {
      if(data)
      {
        this.propertyListRequest = data;
      }
    })
    this.allProperties$ = this.propertyFacade.selectAllProperties$;
    this.isPropertyLoading$ = this.propertyFacade.isPropertyLoading$;
    this.totalProperties$.pipe(
      withLatestFrom(this.isPropertyLoading$)
    ).subscribe(([data, loading]) => {
      if(data == 0 && !this.router.url.includes("/rented-property") && !loading
      && !this.router.url.includes("/terminated-property"))
      {
        this.router.navigate(['dashboard/add-property'], { queryParams: { propertyIdentifier: this.newPropertyId }});
      }
    });
  }

  editProperty(propertyId: string){
    this.router.navigate(['dashboard/add-property'], { queryParams: { propertyIdentifier: propertyId }});
  }

  viewProperty(propertyId: string){
    this.router.navigate(['dashboard/view-property-info'], { queryParams: { propertyIdentifier: propertyId }});
  }

  isViewEnabled(data: PropertyInfo){
    if((data.createdBy == data.ownerUserId || data.createdBy == data.tenantUserId) && 
    data.dealStatus != null){
      return true;
    }
    if(data.ownerConsentStatus == 'Approved' && data.tenantConsentStatus == 'Approved' )
    {
      return true;
    }
    return false;

  }

  isEditEnabled(data: PropertyInfo){
    if(data.createdBy == this.currentUser.id){
      if(data.ownerConsentStatus == 'Approved' && data.tenantConsentStatus == 'Approved' )
      {
        return false;
      }
      return true;
    }
    return false;

  }

  onPageChanged(pageEvent: PageEvent){
    let propertyListRequest = {
      ...this.propertyListRequest,
      pageIndex: pageEvent.pageIndex,
      pageSize: pageEvent.pageSize
    }
    this.propertyFacade.setPropertyListRequest(propertyListRequest);
    this.propertyFacade.queryPaginatedAvailableProperty(this.currentUser.id);
  }

  isMobileBrowser(): boolean {
    return this.windowService.isMobileBrowser();
  }

  refreshList() {
    this.propertyFacade.queryPaginatedAvailableProperty(this.currentUser.id);
  }

  getDealStatus(propertyInfo: PropertyInfo): string{
    if(propertyInfo.dealStatus)
    {
      if(propertyInfo.ownerConsentStatus == 'Approved' && propertyInfo.tenantConsentStatus == 'Approved')
      {
        return 'Draft Agreement';
      }
      if(propertyInfo.ownerConsentStatus == 'Rejected' || propertyInfo.tenantConsentStatus == 'Rejected')
      {
        return 'Sent back for review';
      }
      if(propertyInfo.ownerConsentStatus == 'Pending' && propertyInfo.tenantConsentStatus == 'Pending')
      {
        return 'Consent Pending';
      }
      if(propertyInfo.ownerConsentStatus == 'Pending')
      {
        return 'Owner Consent Pending';
      }
      if(propertyInfo.tenantConsentStatus == 'Pending')
      {
        return 'Tenant Consent Pending';
      }
      
    }
    return 'Available';
  }

  sortChange(sortState: Sort){
    let propertyListRequest = {
      ...this.propertyListRequest,
      orderBy: sortState.direction,
      orderByColumn: sortState.direction ? sortState.active : null
    }
    this.propertyFacade.setPropertyListRequest(propertyListRequest);
    this.propertyFacade.queryPaginatedAvailableProperty(this.currentUser.id);
  }

}
