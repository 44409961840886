import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/shared/services';

@Component({
  selector: 'rentofly-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {

  adminLoginForm: FormGroup;
  showLoginValidationMessage = false;
  ç
  constructor(
    private readonly firebaseAuth: AngularFireAuth,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.initAdminLoginForm();
  }

  get email() {
    return this.adminLoginForm!.get('email');
  }

  get password() {
    return this.adminLoginForm!.get('password');
  }

  initAdminLoginForm(){
    this.adminLoginForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      password: new FormControl('', [
        Validators.required
      ])
    })
  }

  adminLogin(adminLoginForm)
  {
    this.showLoginValidationMessage = false;
    if(this.adminLoginForm.invalid){
      this.showLoginValidationMessage = true;
      return;
    }

    this.firebaseAuth.signInWithEmailAndPassword(adminLoginForm.email, 
      adminLoginForm.password)
      .then((userCredentials) => {
        this.localStorageService.addToLocalStorage('dashboard-user', JSON.stringify(userCredentials.user));
        this.router.navigate(['/admin-dashboard']);
    })
    .catch((error) => {
      console.log(error);
      this._snackBar.open(error, "X", {
          duration: 10000,
          panelClass: ['snackbar-failure']
      });
    })
  }

}
