<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <h1>{{confirmMessageHeader}}</h1>
        </div>
        <div class="col-md-12">
            <form [formGroup]="commentForm" class="comment-info">
                <div class="form-group">
                    <label for="inputgroup" class="input-label">{{confirmMessageContent}}</label><br />
                    <textarea id="commentText" class="comment-text" type="text" formControlName="commentText" rows="4" required></textarea>
                    <span class="message-length-info"><small>{{commentText.value.length}} of 500 characters</small></span><br />
                    <div *ngIf="commentText!.invalid && showCommentValidationMessage" class="rentofly-validation-msg">
                        <div>
                            Comment is required.
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-md-12 action">
            <div>
                <button class="rentofly-primary rentofly-modal-button" (click)="onConfirm(commentForm.value)">{{confirmButtonText}}</button>
                <a class="cancel-link" (click)="dialogRef.close(false)">Cancel</a>
            </div>
        </div>
    </div>
</div>