import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { ConfirmBoxComponent } from '../confirm-box/confirm-box.component';
import { AuthService, LocalStorageService, WindowService } from 'src/app/shared/services';
import { FeatureToggleService } from 'src/app/shared/services/feature-toggle.service';
import { UUID } from 'angular2-uuid';
import { PartnerStoreFacade } from 'src/app/store/facades';
import { Partner } from '../../models';

@Component({
  selector: 'rentofly-header',
  templateUrl: './header-latest.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isAuthenticated: boolean;
  isAdminAuthenticated: boolean;
  isAddPropertyEnabled: boolean = true;
  isAdminProfile: boolean = false;
  currentUser: any;
  partner: Partner;
  constructor(
    public router: Router,
    private readonly authService: AuthService,
    private readonly localStorageService: LocalStorageService,
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private firebaseAuth: AngularFireAuth,
    private readonly dialog: MatDialog,
    private readonly windowService: WindowService,
    private readonly featureToggleService: FeatureToggleService,
    private readonly partnerFacade: PartnerStoreFacade,
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if ((evt instanceof NavigationEnd)) {
        if(evt.url == "/login")
        {
          this.isAuthenticated = false
        }
        else{
          this.isAuthenticated = this.authService.isAuthenticated();
        }
        if(evt.url.includes("/add-property"))
        {
          this.isAddPropertyEnabled = false;
        }
        else{
          this.isAddPropertyEnabled = true;
        }
        if(evt.url.includes("/admin"))
        {
          this.isAdminProfile = true;
        }
        else{
          this.isAdminProfile = false;
        }
        if(evt.url == "/admin"){
          this.isAdminAuthenticated = false;
        } else {
          this.isAdminAuthenticated = this.authService.isAdminAuthenticated();
        }

      }
    });
    
    
  }

  goToHomePage(){
    if(this.isAuthenticated){
      if(this.checkAvailibility()){
        this.router.navigateByUrl('/dashboard/dashboard');
      } else {
        this.router.navigateByUrl('/referral');
      }      
    } else{
      this.router.navigateByUrl('/login')
    }
  }

  logout(): void{
    const confirmDialog = this.dialog.open(ConfirmBoxComponent, {
      disableClose: false,
    });
    confirmDialog.componentInstance.confirmMessageHeader = `Logout`;
    confirmDialog.componentInstance.confirmMessageContent = `Are you sure you want to continue?`;
    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        if (isPlatformBrowser(this.platformId)) {
          this.localStorageService.removeFromLocalStorage('dashboard-user');
          this.localStorageService.removeFromLocalStorage('current-user');
          this.firebaseAuth.signOut();
          if(this.isAdminAuthenticated)
          {
            this.router.navigate(['admin']);
          }
          else{
            this.router.navigate(['login']);
          }
          this.windowService.windowRef.location.reload();
          
          }
      }
    });
    // this.localStorageService.removeFromLocalStorage('dashboard-user');
    // this.router.navigate(['login']);
  }

  addProperty(): void {
    if(this.isAddPropertyEnabled)
    {
      let propertyId = UUID.UUID();
      this.router.navigate(['dashboard/add-property'], { queryParams: { propertyIdentifier: propertyId }});
    }
  }
 isMobileBrowser(){
   return this.windowService.isMobileBrowser();
 }

 scrollToTop()
  {
    this.router.navigate(['login']);
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({top: 0, behavior: 'smooth'})
    }
  }

  isPartnerEnabled(){
    if(this.featureToggleService.isFeatureEnabled("addPartner"))
    {
      return true;
    }
    return true;
  }

  isPartnerApproved(){
    this.currentUser = this.localStorageService.getObjectFromLocalStorage('current-user');
    return this.currentUser?.partnerStatus === 'Approved';
  }
  
  checkAvailibility(){
    return this.localStorageService.getFromLocalStorage('availability') === 'true';
  }

  downloadApp(){
    window.open('https://onelink.to/8pf293','_blank')
  }

  headerNavToggle() {
    let x = document.getElementById("headerLinks");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }
}
