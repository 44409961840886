import { createEntityAdapter, Dictionary, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import { Broker, Brokerage, User } from "src/app/shared/models";
import { addBroker, addBrokerFailure, addBrokerSuccess, getBrokerage, getBrokerageFailure, getBrokerageSuccess, initCurrentBroker, queryBrokerById, queryBrokerByIdFailure, queryBrokerByIdSuccess, queryUserForBroker, queryUserForBrokerFailure, queryUserForBrokerSuccess, updateBroker, updateBrokerFailure, updateBrokerSuccess } from "../actions";

export function getBrokerReducer(state: BrokerState | undefined, action: Action) {
    return brokerReducer(state, action);
}

export const brokerAdapter = createEntityAdapter<Broker>();

export interface BrokerState extends EntityState<Broker> {
    entities: Dictionary<Broker>;
    error: any;
    currentBroker: Broker;
    currentBrokerLoading: boolean;
    currentUserForBrokerLoading: boolean;
    currentUserForBroker: User;
    editingBroker: boolean;
    brokerage: Brokerage;
}

export const initialBrokerState: BrokerState = brokerAdapter.getInitialState({
    entities: [],
    error: null,
    currentBroker: null,
    currentBrokerLoading: false,
    currentUserForBrokerLoading: false,
    currentUserForBroker: null,
    editingBroker: false,
    brokerage: null
});

export const brokerReducer = createReducer(
    initialBrokerState,

    on(queryBrokerById, (state) => {
        return  {
            ...state,
            currentBrokerLoading: true,
            currentBroker: null
        };
    }),

    on(queryBrokerByIdSuccess, (state, {broker}) => {
        return  {
            ...state,
            currentBrokerLoading: false,
            currentBroker: broker
        };
    }),

    on(queryBrokerByIdFailure, (state, {error}) => {
        return  {
            ...state,
            currentBrokerLoading: false,
            error
        };
    }),

    on(addBroker, (state, {propertyId, broker}) => {
        return  {
            ...state,
            currentBroker: broker,
            editingBroker: true
        };
    }),

    on(addBrokerFailure, (state, {error}) => {
        return  {
            ...state,
            error,
            editingBroker: false
        };
    }),

    on(addBrokerSuccess, (state) => {
        return  {
            ...state,
            editingBroker: false
        };
    }),

    on(updateBroker, (state, {propertyId, broker}) => {
        return  {
            ...state,
            currentBroker: broker,
            editingBroker: true
        };
    }),

    on(updateBrokerSuccess, (state) => {
        return  {
            ...state,
            editingBroker: false
        };
    }),

    on(updateBrokerFailure, (state, {error}) => {
        return  {
            ...state,
            error,
            editingBroker: false
        };
    }),

    on(initCurrentBroker, (state) => {
        return  {
            ...initialBrokerState
        };
    }),

    on(queryUserForBroker, (state) => {
        return  {
            ...state,
            currentUserForBrokerLoading: true,
            currentUserForBroker: null
        };
    }),

    on(queryUserForBrokerSuccess, (state, {user}) => {
        return  {
            ...state,
            currentUserForBrokerLoading: false,
            currentUserForBroker: user
        };
    }),

    on(queryUserForBrokerFailure, (state, {error}) => {
        return  {
            ...state,
            currentUserForBrokerLoading: false,
            currentUserForBroker: null,
            error
        };
    }),

    on(getBrokerage, (state) => {
        return  {
            ...state,
            brokerage: null
        };
    }),

    on(getBrokerageSuccess, (state, {brokerage}) => {
        return  {
            ...state,
            brokerage
        };
    }),

    on(getBrokerageFailure, (state, {error}) => {
        return  {
            ...state,
            error
        };
    }),
)