import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeedbackService } from 'src/app/shared/services/feedback.service';
import { UsersStoreFacade } from 'src/app/store/facades/users.store-facade';
import { Observable } from 'rxjs';
import { AvailablePropertyData, Brokerage, OwnerFinances, RentedPropertyData, TenantFinances, TerminatedPropertyData } from 'src/app/shared/models';
import { LocalStorageService } from 'src/app/shared/services';
import { BrokerStoreFacade, OwnerStoreFacade, PropertyStoreFacade, TenantStoreFacade } from 'src/app/store/facades';
import { UUID } from 'angular2-uuid';
import { withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-detail',
  templateUrl: './dashboard-new-detail.component.html',
  styleUrls: ['./dashboard-detail.component.scss']
})
export class DashboardDetailComponent implements OnInit {
  //Available Property
  availablePropertyData: AvailablePropertyData;
  availablePropertyDataLoading$: Observable<boolean>;

  //Rented Property
  rentedPropertyData: RentedPropertyData;
  rentedPropertyDataLoading$: Observable<boolean>;

  //Terminated Property
  terminatedPropertyData: TerminatedPropertyData;
  terminatedPropertyDataLoading$: Observable<boolean>;
  
  //finances
  brokerage: Brokerage;
  tenantFinances: TenantFinances;
  ownerFinances: OwnerFinances;

  currentUser: any;

  public ratingAsOwner;
  public ratingAsTenant;
  public userId;
  public incomPerYear = 45500;
  public userName
  public showWelcomePopup = false;
  public loading = true;
  newPropertyId = UUID.UUID();
  totalProperties$: Observable<number>;
  isPropertyLoading$: Observable<boolean>;
  public ownerData;
  public brokerageData;
  public tenantFinanceData
  public showRating = false;

  constructor(
    public readonly router: Router,
    private readonly propertyFacade: PropertyStoreFacade,
    private readonly localStorageService: LocalStorageService,
    private readonly feedbackService: FeedbackService,
    private readonly userFacade: UsersStoreFacade,
    private readonly brokerFacade: BrokerStoreFacade,
    private readonly ownerFacade: OwnerStoreFacade,
    private readonly tenantFacade: TenantStoreFacade 
  ) {}

  ngOnInit(): void {
    this.currentUser = this.localStorageService.getObjectFromLocalStorage('current-user');
    this.propertyFacade.queryAvailablePropertyData(this.currentUser.id);
    this.propertyFacade.queryPaginatedAvailableProperty(this.currentUser.id);
    this.isPropertyLoading$ = this.propertyFacade.isPropertyLoading$;
    
    this.propertyFacade.availablePropertyData$.subscribe((data) => {
      if(data)
      {
        this.availablePropertyData = data;
        console.log(data);
      }
    })
    this.availablePropertyDataLoading$ = this.propertyFacade.availablePropertyDataLoading$;

    this.totalProperties$ = this.propertyFacade.getTotalProperties$;

    //totalProperty
    this.totalProperties$.pipe(
      withLatestFrom(this.isPropertyLoading$)
    ).subscribe(([data, loading]) => {
      if(data == 0 && !loading)
      {
        this.showWelcomePopup = true;
        this.loading = false;
      } else {
        this.showWelcomePopup = false;
        this.loading = false;
      }
    });
    

    //rented
    this.propertyFacade.queryRentedPropertyData(this.currentUser.id);
    this.propertyFacade.rentedPropertyData$.subscribe((data) => {
      if(data)
      {
        this.rentedPropertyData = data;
      }
    })
    this.rentedPropertyDataLoading$ = this.propertyFacade.rentedPropertyDataLoading$;

    //terminated
    this.propertyFacade.queryTerminatedPropertyData(this.currentUser.id);
    this.propertyFacade.terminatedPropertyData$.subscribe((data) => {
      if(data)
      {
        this.terminatedPropertyData = data;
      }
    })
    this.terminatedPropertyDataLoading$ = this.propertyFacade.terminatedPropertyDataLoading$;

    let user = this.userFacade.getCurrentUser$;
    user.subscribe(data => {
      if(data)
      {
        this.userId = data.id;
        this.profileOwnerRating();
        this.profileOTenantRating();
        this.userName = data.name;
        this.configureFinances()
      }
    })
  }

  configureFinances(){
    //finances
    this.brokerFacade.getBrokerage(this.userId)
    this.ownerFacade.getOwnerFinances(this.userId)
    this.tenantFacade.getTenantFinances(this.userId)
    this.brokerFacade.brokerage$.subscribe(data => {
      this.brokerage = data
      console.log(this.brokerage)
      this.createBrokerageMap(this.brokerage)
    })

    this.ownerFacade.ownerFinances$.subscribe(data => {
      this.ownerFinances = data;
      this.createOwnerMap(this.ownerFinances)
    })

    this.tenantFacade.tenantFinances$.subscribe(data => {
      this.tenantFinances = data
      this.createTenantFinanceMap(this.tenantFinances);
    })
  }

  createOwnerMap(data){
    if(data && (data.totalRentDue > 0 ||  data.totalRentReceivedInRentedProperty > 0)){
      this.ownerData = {
        labels: ['Pending Rent','Recived Rent'],
        datasets: [
            {
                data: [data.totalRentDue, data.totalRentReceivedInRentedProperty],
                backgroundColor: [
                    "#afd0ff",
                    "#4278ff"
                ],
                hoverBackgroundColor: [
                  "#afd0ff",
                  "#4278ff"
                ]
            }]    
        };
    }
  }

  createBrokerageMap(data){
    if(data && (data.potentialBrokerage > 0 || data.receivedBrokerage > 0)){
      console.log(data.potentialBrokerage)
      this.brokerageData = {
        labels: ['Pending Brokerage','Recived Brokerage'],
        datasets: [
            {
                data: [data.potentialBrokerage, data.receivedBrokerage],
                backgroundColor: [
                  "#afd0ff",
                  "#4278ff"
              ],
              hoverBackgroundColor: [
                "#afd0ff",
                "#4278ff"
              ]
            }]    
        };
    }
  }

  createTenantFinanceMap(data){
    if(data && (data.totalRentDue > 0 ||  data.totalRentPaidInRentedProperty > 0)){
      this.tenantFinanceData = {
        labels: ['Pending Brokerage','Recived Brokerage'],
        datasets: [
            {
                data: [data.totalRentDue, data.totalRentPaidInRentedProperty],
                backgroundColor: [
                  "#afd0ff",
                  "#4278ff"
              ],
              hoverBackgroundColor: [
                "#afd0ff",
                "#4278ff"
              ]
            }]    
        };
    }
  }

  addProperty(){
    this.showWelcomePopup = false;
    this.router.navigate(['dashboard/add-property'], { queryParams: { propertyIdentifier: this.newPropertyId }});
  }

  viewProperty(url){
    this.router.navigateByUrl('/dashboard/view-property/' + url);
  }
  profileOwnerRating(){
    let data = 'owner/'+ this.userId;
    this.feedbackService.getProfileRating(data).subscribe(data =>{
      this.ratingAsOwner = data;
    },
    (error) => {
      this.ratingAsOwner = "";
    })    
  }
  profileOTenantRating(){
    let data = 'tenant/'+ this.userId;
    this.feedbackService.getProfileRating(data).subscribe(data =>{
      this.ratingAsTenant = data;
    },
    (error) => {
      this.ratingAsTenant = "";
    })
  }

  getStars(rating){
    let val = rating/2;
      let size = val/5*100;
      return size + '%';
  }
}
