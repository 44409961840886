import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";
import { PaginatedPartner, Partner } from "src/app/shared/models";
import { EmailService, PartnerService } from "src/app/shared/services";
import * as partnerActions from "../actions/partner.action";
import { PartnerState } from "../reducers";
import { partnerRequest$ } from "../selectors/partner.selector";

@Injectable()
export class PartnerEffects{
    constructor(
        private readonly actions$: Actions,
        private readonly partnerService: PartnerService,
        private _snackBar: MatSnackBar,
        private readonly store: Store<PartnerState>,
        private readonly emailService: EmailService,
    ){}

    getPartnerById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partnerActions.queryPartnerById),
            switchMap((action) => 
                this.partnerService.getPartnerById(action.id).pipe(
                    map((data) => partnerActions.queryPartnerByIdSuccess({partner: data.message})),
                    catchError((error: any) => {
                        if(error.status != 404)
                        {
                            this._snackBar.open('The action performed was not successful. Please contact admin.', "X", {
                                duration: 10000,
                                panelClass: ['snackbar-failure']
                            });
                        }
                        return of(partnerActions.queryPartnerByIdFailure(error))
                    }))
                )
            )
    );

    getPartnerByPhoneNumber$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partnerActions.queryPartnerByPhoneNumber),
            switchMap((action) => 
                this.partnerService.getPartnerByPhoneNumber(action.phoneNumber).pipe(
                    map((data) => partnerActions.queryPartnerByPhoneNumberSuccess({partner: data.message})),
                    catchError((error: any) => {
                        // if(error.status != 404)
                        // {
                        //     this._snackBar.open('The action performed was not successful. Please contact admin.', "X", {
                        //         duration: 10000,
                        //         panelClass: ['snackbar-failure']
                        //     });
                        // }
                        return of(partnerActions.queryPartnerByPhoneNumberFailure(error))
                    }))
                )
            )
    );

    getPartnerByPinCode$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partnerActions.queryPartnersByPinCode),
            switchMap((action) => 
                this.partnerService.getPartnersByPinCode(action.pinCode).pipe(
                    map((data) => partnerActions.queryPartnersByPinCodeSuccess({partners: data.message})),
                    catchError((error: any) => {
                        if(error.status != 404)
                        {
                            this._snackBar.open('The action performed was not successful. Please contact admin.', "X", {
                                duration: 10000,
                                panelClass: ['snackbar-failure']
                            });
                        }
                        return of(partnerActions.queryPartnersByPinCodeFailure(error))
                    }))
                )
            )
    );

    getPartners$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partnerActions.queryPartners),
            withLatestFrom(this.store.select(partnerRequest$)),
            switchMap(([action, partnerRequest]) => 
                this.partnerService.getPaginatedAndSortedPartners(partnerRequest).pipe(
                    map((data) => partnerActions.queryPartnersSuccess({paginatedPartner: data.message})),
                    catchError((error: any) => {
                        if(error)
                        {
                            this._snackBar.open('The action performed was not successful. Please contact admin.', "X", {
                                duration: 10000,
                                panelClass: ['snackbar-failure']
                            });
                        }
                        return of(partnerActions.queryPartnersFailure(error))
                    }))
                )
            )
    );

    getApprovedPartners$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partnerActions.queryApprovedPartners),
            withLatestFrom(this.store.select(partnerRequest$)),
            switchMap(([action, partnerRequest]) => {
                let approvedPartnerRequest = {
                    ...partnerRequest,
                    status: 'Approved'
                };
                return this.partnerService.getPaginatedAndSortedPartners(approvedPartnerRequest).pipe(
                    map((data: PaginatedPartner) => partnerActions.queryApprovedPartnersSuccess({paginatedPartner: data})),
                    catchError((error: any) => {
                        if(error)
                        {
                            this._snackBar.open('The action performed was not successful. Please contact admin.', "X", {
                                duration: 10000,
                                panelClass: ['snackbar-failure']
                            });
                        }
                        return of(partnerActions.queryApprovedPartnersFailure(error))
                    }))}
                )
            )
    );

    getPendingPartners$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partnerActions.queryPendingPartners),
            withLatestFrom(this.store.select(partnerRequest$)),
            switchMap(([action, partnerRequest]) => {
                let pendingPartnerRequest = {
                    ...partnerRequest,
                    status: 'Pending'
                };
                return this.partnerService.getPaginatedAndSortedPartners(pendingPartnerRequest).pipe(
                    map((data: PaginatedPartner) => partnerActions.queryPendingPartnersSuccess({paginatedPartner: data})),
                    catchError((error: any) => {
                        if(error)
                        {
                            this._snackBar.open('The action performed was not successful. Please contact admin.', "X", {
                                duration: 10000,
                                panelClass: ['snackbar-failure']
                            });
                        }
                        return of(partnerActions.queryPendingPartnersFailure(error))
                    }))}
                )
            )
    );

    addPartner$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partnerActions.addPartner),
            switchMap((action) => 
                this.partnerService.addPartner(action.partner).pipe(
                    map((data) => {
                        action.emails.forEach((data) => {
                            this.emailService.sendEmail(data).subscribe();
                        });
                        return partnerActions.addPartnerSuccess();
                    }),
                    catchError((error: any) => {
                        if(error.status)
                        {
                            this._snackBar.open('The action performed was not successful. Please contact admin.', "X", {
                                duration: 10000,
                                panelClass: ['snackbar-failure']
                            });
                        }
                        return of(partnerActions.addPartnerFailure(error))
                    }))
                )
            )
    );

    updatePartner$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partnerActions.updatePartnerById),
            switchMap((action) => 
                this.partnerService.updatePartner(action.partner).pipe(
                    map((data) => partnerActions.updatePartnerByIdSuccess()),
                    catchError((error: any) => {
                        if(error.status != 404)
                        {
                            this._snackBar.open('Update Partner was not successful. Please contact admin.', "X", {
                                duration: 10000,
                                panelClass: ['snackbar-failure']
                            });
                        }
                        return of(partnerActions.updatePartnerByIdFailure(error))
                    }))
                )
            )
    );

    deletePartner$ = createEffect(() =>
        this.actions$.pipe(
            ofType(partnerActions.deletePartnerById),
            switchMap((action) => 
                this.partnerService.deletePartner(action.id).pipe(
                    map((data) => partnerActions.deletePartnerByIdSuccess()),
                    catchError((error: any) => {
                        if(error.status != 404)
                        {
                            this._snackBar.open('The action performed was not successful. Please contact admin.', "X", {
                                duration: 10000,
                                panelClass: ['snackbar-failure']
                            });
                        }
                        return of(partnerActions.deletePartnerByIdFailure(error))
                    }))
                )
            )
    );
}