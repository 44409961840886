import { createAction, props } from "@ngrx/store";
import { Email, PaginatedPartner, Partner, PartnerRequest } from "src/app/shared/models";
import { EmailWhatsapp } from "src/app/shared/models/email-whatsapp.model";

export const queryPartnerById = createAction(
    '[Partner] query Partner by id',
    props<{id: string}>()
);

export const queryPartnerByIdSuccess = createAction(
    '[Partner] query Partner by id Success',
    props<{partner: Partner}>()
);

export const queryPartnerByIdFailure = createAction(
    '[Partner] query Partner by id Failure',
    props<{error: any}>()
);

export const queryPartnerByPhoneNumber = createAction(
    '[Partner] query Partner by PhoneNumber',
    props<{phoneNumber: string}>()
);

export const queryPartnerByPhoneNumberSuccess = createAction(
    '[Partner] query Partner by PhoneNumber Success',
    props<{partner: Partner}>()
);

export const queryPartnerByPhoneNumberFailure = createAction(
    '[Partner] query Partner by PhoneNumber Failure',
    props<{error: any}>()
);

export const queryPartnersByPinCode = createAction(
    '[Partner] query Partners by PinCode',
    props<{pinCode: number}>()
);

export const queryPartnersByPinCodeSuccess = createAction(
    '[Partner] query Partners by PinCode Success',
    props<{partners: Partner[]}>()
);

export const queryPartnersByPinCodeFailure = createAction(
    '[Partner] query Partners by PinCode Failure',
    props<{error: any}>()
);

export const queryPartners = createAction(
    '[Partner] query Partners'
);

export const queryPartnersSuccess = createAction(
    '[Partner] query Partners Success',
    props<{paginatedPartner: PaginatedPartner}>()
);

export const queryPartnersFailure = createAction(
    '[Partner] query Partners Failure',
    props<{error: any}>()
);

export const queryApprovedPartners = createAction(
    '[Partner] query Approved Partners'
);

export const queryApprovedPartnersSuccess = createAction(
    '[Partner] query Approved Partners Success',
    props<{paginatedPartner: PaginatedPartner}>()
);

export const queryApprovedPartnersFailure = createAction(
    '[Partner] query Approved Partners Failure',
    props<{error: any}>()
);

export const queryPendingPartners = createAction(
    '[Partner] query Pending Partners'
);

export const queryPendingPartnersSuccess = createAction(
    '[Partner] query Pending Partners Success',
    props<{paginatedPartner: PaginatedPartner}>()
);

export const queryPendingPartnersFailure = createAction(
    '[Partner] query Pending Partners Failure',
    props<{error: any}>()
);

export const addPartner = createAction(
    '[Partner] add Partner',
    props<{partner: Partner, emails: EmailWhatsapp[]}>()
);

export const addPartnerSuccess = createAction(
    '[Partner] add Partner Success'
);

export const addPartnerFailure = createAction(
    '[Partner] add Partner Failure',
    props<{error: any}>()
);

export const updatePartnerById = createAction(
    '[Partner] update Partner',
    props<{partner: Partner}>()
);

export const updatePartnerByIdSuccess = createAction(
    '[Partner] update Partner Success'
);

export const updatePartnerByIdFailure = createAction(
    '[Partner] update Partner Failure',
    props<{error: any}>()
);

export const deletePartnerById = createAction(
    '[Partner] delete Partner',
    props<{id: string}>()
);

export const deletePartnerByIdSuccess = createAction(
    '[Partner] delete Partner Success'
);

export const deletePartnerByIdFailure = createAction(
    '[Partner] delete Partner Failure',
    props<{error: any}>()
);

export const setpartnerPageDetails = createAction(
    '[Partner] set Partner Page Details',
    props<{pageIndex: number, pageSize: number}>()
);

export const setPartnerRequest = createAction(
    '[Partner] set PartnerRequest',
    props<{partnerRequest: PartnerRequest}>()
);