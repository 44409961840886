export * from './owner.template';
export * from './tenant.template';
export * from './contact-us.template';
export * from './broker.template';
export * from './approve-consent.template';
export * from './reject-consent.template';
export * from './lease-termination.template';
export * from './termination-approval.template';
export * from './termination-cancelled.template';
export * from './add-partner.template';
export * from './approve-partner.template';