import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CreateDashboardState } from "..";
import { propertyAdapter, PropertyState } from "../reducers";

export const getCreateDashboardState = createFeatureSelector<CreateDashboardState>('dashboard');
export const getPropertyState = createSelector(
    getCreateDashboardState,
    (state: CreateDashboardState) => state.property
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = propertyAdapter.getSelectors(getPropertyState);

export const isPropertyLoading$ = createSelector(getPropertyState, (state: PropertyState) => state.propertyLoading);

export const noPropertyResult$ = createSelector(getPropertyState, (state: PropertyState) => state.noPropertyResult);
export const totalProperties$ = createSelector(getPropertyState, (state: PropertyState) => state.totalProperties);
export const pageSize$ = createSelector(getPropertyState, (state: PropertyState) => state.pageSize);
export const pageIndex$ = createSelector(getPropertyState, (state: PropertyState) => state.pageIndex);
export const stage$ = createSelector(getPropertyState, (state: PropertyState) => state.stage);
export const propertyListRequest$ = createSelector(getPropertyState, (state: PropertyState) => state.propertyListRequest);

//getpropertyById
export const currentProperty$ = createSelector(getPropertyState, (state: PropertyState) => state.currentProperty);
export const currentViewProperty$ = createSelector(getPropertyState, (state: PropertyState) => state.currentViewProperty);
export const currentViewPropertyLoading$ = createSelector(getPropertyState, (state: PropertyState) => state.currentViewPropertyLoading);
export const currentPropertyLoading$ = createSelector(getPropertyState, (state: PropertyState) => state.currentPropertyLoading);
export const propertyEditing$ = createSelector(getPropertyState, (state: PropertyState) => state.propertyEditing);
export const additionalDetails$ = createSelector(getPropertyState, (state: PropertyState) => state.additionalDetails);
export const additionalDetailsLoading$ = createSelector(getPropertyState, (state: PropertyState) => state.additionalDetailsLoading);
export const availablePropertyData$ = createSelector(getPropertyState, (state: PropertyState) => state.availablePropertyData);
export const availablePropertyDataLoading$ = createSelector(getPropertyState, (state: PropertyState) => state.availablePropertyDataLoading);
export const rentedPropertyData$ = createSelector(getPropertyState, (state: PropertyState) => state.rentedPropertyData);
export const rentedPropertyDataLoading$ = createSelector(getPropertyState, (state: PropertyState) =>state.rentedPropertyDataLoading);
export const terminatedPropertyData$ = createSelector(getPropertyState, (state: PropertyState) => state.terminatedPropertyData);
export const terminatedPropertyDataLoading$ = createSelector(getPropertyState, (state: PropertyState) =>state.terminatedPropertyDataLoading);
