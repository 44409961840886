import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, Form } from '@angular/forms';
import { Observable } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { DateService, LocalStorageService, WindowService } from 'src/app/shared/services';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { AttachmentsStoreFacade, PropertyStoreFacade } from 'src/app/store/facades';
import { FileUploadModel, Payment, PaymentUpdate, ViewProperty } from 'src/app/shared/models';
import { UUID } from 'angular2-uuid';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';

@Component({
  selector: 'rentofly-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  public paymentTypesOptions = [];
  public frequencyOptions = [];
  public paymentOptions = [];
  public paymentInfoForm: FormGroup;
  public popupAttachForm: FormGroup;
  public displayedColumns: string[];
  public allPaymentDetails = [];
  public paymentUrl;
  public currentPropertyDetail;
  public blob;
  public fieldDisabled = true;
  public fromMin;
  public fromMax;
  public toMin;
  public toMax;
  public showPaymentDailog = false;
  public dailogData;
  public currentUser;
  public user;
  isPropertyLoading$: Observable<boolean>;
  public loading = true;
  public showText = false;
  public totalPayMoney;
  public totalPay;
  public showCalculatedText = false;
  public redColor = false;
  public greenColor = false;
  public loader = true;
  public showConfirmation = false;
  public action;
  public actionLabel;
  public newAttachment: FileUploadModel;
  public newFile: File;
  public attachments$: Observable<FileUploadModel[]>
  errorMessage: any;
  public showErrorMessages: boolean = false;
  public showPopupErrorMessages: boolean = false;

  //RejectReaon Form
  commentForm: FormGroup;
  showCommentValidationMessage: boolean;

  get commentText() {
    return this.commentForm!.get('commentText');
  }

  constructor(
    private paymentService: PaymentService,
    private readonly propertyFacade: PropertyStoreFacade,
    private readonly datePipe: DatePipe,
    private readonly dateService: DateService,
    private readonly localStorageService: LocalStorageService,
    private readonly windowService: WindowService,
    private readonly attachmentsFacade: AttachmentsStoreFacade,
    private readonly _snackBar: MatSnackBar,
    private readonly _dateService: DateService
    ) {  
    this.paymentTypesOptions =  [
      {name:"Rent", code:"Rent"},
      {name:"Deposit", code:"Deposit"},
      {name:"Brokerage", code:"Brokerage"}
    ];
    this.frequencyOptions = [
      {name:"Monthly", code:"Monthly"},
      {name:"Quarterly", code:"Quarterly"},
      {name:"Biannually", code:"Biannually"},
      {name:"Annually", code:"Annually"},
      {name:"Other", code:"Other"}
    ];
    this.paymentOptions = [
      {name:"Cash", code:"Cash"},
      {name:"Cheque", code:"Cheque"},
      {name:"Money Order", code:"Money Order"},
      {name:"Online", code:"Online"},
      {name:"Other", code:"Other"}
    ];
    this.displayedColumns = ['paymentType', 'amount', 'frequency', 'period', 'status', 'receipt'];
   }

   get paymentType() {
    return this.paymentInfoForm!.get('paymentType');
  }

  get frequencyType() {
    return this.paymentInfoForm!.get('frequencyType');
  }

  get paymentPeriodFrom() {
    return this.paymentInfoForm!.get('paymentPeriodFrom');
  }

  get paymentPeriodTo() {
    return this.paymentInfoForm!.get('paymentPeriodTo');
  }

  get paymentAmount() {
    return this.paymentInfoForm!.get('paymentAmount');
  }

  get paymentMode() {
    return this.paymentInfoForm!.get('paymentMode');
  }

  get paymentRefId() {
    return this.paymentInfoForm!.get('paymentRefId');
  }

  ngOnInit(): void {
    if(this.windowService.isMobileBrowser()){
      this.displayedColumns = ['paymentType', 'period', 'status', 'receipt'];
    }
    this.currentUser = this.localStorageService.getObjectFromLocalStorage('current-user');
    this.isPropertyLoading$ = this.propertyFacade.isPropertyLoading$;
    this.initPaymentForm();
    this.getCurrentPropertyDetail();
    this.getPaymentHistoryDetails();
    this.commentForm = new FormGroup({
      commentText: new FormControl('', [
        Validators.required,
        Validators.maxLength(500)
      ])
    });
  }

  private initPaymentForm(){
    this.paymentInfoForm = new FormGroup({
      paymentType: new FormControl('',[]),
      frequencyType: new FormControl('',[]),
      paymentPeriodFrom: new FormControl({value:'', disabled: this.fieldDisabled},[]),
      paymentPeriodTo: new FormControl({value:'', disabled: this.fieldDisabled},[]),
      paymentAmount: new FormControl({value:'', disabled: this.fieldDisabled}, []),
      paymentMode: new FormControl({value:'', disabled: this.fieldDisabled}, []),
      paymentRefId: new FormControl('', []),
    })
    this.popupAttachForm = new FormGroup({
      paymentMode: new FormControl('', []),
      paymentRefId: new FormControl('', []),
      paymentAmount: new FormControl('', [])
    })
  }

  public getCurrentPropertyDetail(){
    this.propertyFacade.currentViewProperty$.subscribe((data) => {
      this.currentPropertyDetail = data;
      if(this.currentUser.id == data?.ownerUserId){
        this.user = 'Owner';
      }else {
        this.user = 'Tenant';
      }
    })
  }

  resetForm(){
    this.paymentInfoForm.reset();
    this.initPaymentForm();
    this.showErrorMessages = false;
    this.clearAttachment();
  }

  showAttachment(){
    let showAttachment = this.paymentMode?.value?.code && this.paymentMode.value.code !== 'Cash' && this.paymentMode.value.code !== 'Select';
    if(!showAttachment)
    {
      this.paymentRefId.reset();
    }
    return showAttachment;
  }

  payRequestedPaymentAmount(dailogData, formValue){
    this.showPopupErrorMessages = false;
    if(this.errorMessage || this.popupAttachForm.invalid)
    {
      this.showPopupErrorMessages = true;
      return;
    }
    let popUpPaymentData = {
      paymentAmount:dailogData.amount,
      paymentPeriodFrom:this.getFormattedDate(dailogData.fromDate),
      paymentMode:formValue.paymentMode,
      frequencyType:{name:dailogData.paymentFrequency, code:dailogData.paymentFrequency},
      paymentType:{name:dailogData.paymentType, code:dailogData.paymentType},
      paymentPeriodTo:this.getFormattedDate(dailogData.toDate),
      paymentRefId:formValue.paymentRefId,
      initiator: dailogData.initiator
    }
    this.loader = true;
    if(this.newAttachment && this.newFile)
    {
      this.attachmentsFacade.createAttachment(this.newAttachment, this.newFile);
      this.attachmentsFacade.newAttachmentUrl$.subscribe((data)=>{
        if(data)
        {
          this.processPayment(data, popUpPaymentData);
        }
      })
    }
    else{
      this.processPayment(null,popUpPaymentData);
    }
    this.showPaymentDailog = false;
  }

  cancelPaymentRequest(){
    this.showPaymentDailog = false;
  }

  paymentTypeSelect(){
    switch(this.paymentType.value.name){
      case 'Rent':
        this.paymentAmount.patchValue(this.currentPropertyDetail.rentPerMonth);
        break;
      case 'Deposit':
        this.paymentAmount.patchValue(this.currentPropertyDetail.deposit);
        break;
      case 'Brokerage':
        this.paymentAmount.patchValue(this.currentPropertyDetail.brokerage);
        break;
    }
    this.showCalculatedText = false;
  }

  calculatePayment(){
    if(this.paymentAmount.value > this.totalPay){
      let amount = this.paymentAmount.value - this.totalPay;
      this.totalPayMoney = amount + ' ' + 'Rupees More';
      this.showText = false;
      this.showCalculatedText = true;
      this.greenColor = true;
      this.redColor = false;

    } else if(this.paymentAmount.value < this.totalPay){
      let amount = this.totalPay - this.paymentAmount.value;
      this.totalPayMoney = amount + ' ' + 'Rupees Less';
      this.showText = false;
      this.showCalculatedText = true;
      this.greenColor = false;
      this.redColor = true;
    } else{
      this.showText = true;
      this.showCalculatedText = false;
      this.greenColor = false;
      this.redColor = false;
    }
  }

  paymentFrequencySelect(){
    if(this.frequencyType.value.name !== 'Other'){
      this.paymentPeriodFrom.enable();
      this.paymentPeriodTo.disable();
      this.paymentPeriodFrom.patchValue(new Date());
      this.paymentPeriodTo.patchValue(this.toDateCalculation(new Date(this.paymentPeriodFrom.value), this.daysCalculation(this.frequencyType.value.name)));
    } else{
      this.paymentPeriodFrom.enable();
      this.paymentPeriodTo.enable();
      this.paymentPeriodFrom.patchValue(new Date());
      this.paymentPeriodTo.patchValue(new Date());
      this.toMin = this.calculateMaxDate(new Date(this.paymentPeriodFrom.value));
    }
    // this.fromMax = this.calculateMaxDate(new Date());
    this.paymentAmount.enable();
    this.paymentMode.enable();
    this.showCalculatedText = false;
  }

  fromDateChange(){
    if(this.frequencyType.value.name !== 'Other'){
    this.paymentPeriodTo.patchValue(this.toDateCalculation(new Date(this.paymentPeriodFrom.value), this.daysCalculation(this.frequencyType.value.name)));
    } else{
      this.toMin = new Date(this.paymentPeriodFrom.value);
    }
  }

  toDateCalculation(date: Date, days){
    let toDate = moment(date);
    if(days === 30)
    {
      toDate.add(1, 'months');
      
    }
    else if(days === 90){
      toDate.add(3, 'months');
    }
    else if(days === 180){
      toDate.add(6, 'months');
    }
    else if(days === 365){
      toDate.add(12, 'months');
    }
    else{
      toDate.add(1, 'months');
    }
    toDate.add(-1, 'days');
    return toDate.format("yyyy-MM-DD");


      // let toDate2 = date;
      // toDate2.setDate(toDate2.getDate() + days);
      // console.log(toDate2.toISOString().split('T')[0])
      // return toDate2.toISOString().split('T')[0];
  }

  calculateMaxDate(date: Date){
    let newDate = date;
    let year = newDate.getFullYear();
    let month = newDate.getMonth();
    let day = newDate.getDate();
    return new Date(year,month, day)
  }

  viewPaymentDetail(data){
    this.dailogData = data;
    this.popupAttachForm.get('paymentAmount').patchValue(data.amount);
    this.showPaymentDailog = true;
  }

  paymentAction(status){
    this.showConfirmation = true;
    this.showPaymentDailog = false;
    if(status !== 'Rejected'){
      this.actionLabel = 'Approve';
      this.action = 'Approved';
    } else{
      this.actionLabel = 'Reject';
      this.action = 'Rejected';
    }
    
  }

  approveRejectPayment(status, actionLabel){
    this.showCommentValidationMessage = false;
    if(this.commentForm.invalid && actionLabel === 'Reject' && status === 'Yes'){
      this.showCommentValidationMessage = true;
      return;
    }
    if(status !== 'No'){
      this.loader = true;
      let objData: PaymentUpdate = {
        id: this.dailogData.id,
        status: this.action,
        paymentRejectReason: this.commentText.value,
        amount: this.dailogData.amount,
        fromDate: this.dailogData.fromDate,
        fromId: this.dailogData.fromId,
        modeOfPayment: this.dailogData.modeOfPayment,
        paymentFrequency: this.dailogData.paymentFrequency,
        paymentType: this.dailogData.paymentType,
        propId: this.dailogData.propId,
        toDate: this.dailogData.toDate,
        toId: this.dailogData.toId,
        receiptAttachmentURL: this.dailogData.receiptAttachmentURL,
        paymentRefId: this.dailogData.paymentRefId,
      }
      this.paymentService.updateStatus(objData).subscribe(data => {
          this.getPaymentHistoryDetails();
          this.loader = false
      },
      (error) => {
        this.loader = false;
        this._snackBar.open("There was some error. Please contact Admin", "X", {
          duration: 10000,
          panelClass: ['snackbar-failure']
        });
      })
      if(actionLabel === 'Reject' && this.commentForm.valid)
      {
        this.commentText.setValue("");
      }
      this.showPaymentDailog = false;
      this.showConfirmation = false;
    } else{
      if(actionLabel === 'Reject' && this.commentForm.valid)
      {
        this.commentText.setValue("");
      }
      this.showConfirmation = false;
      this.showPaymentDailog = true;
    }
    this.commentText.setValue("");
  }

  daysCalculation(frequency){
    let days;
    switch(frequency){
      case 'Monthly':
        if(this.paymentType.value.name == 'Rent'){
          this.paymentAmount.patchValue(this.currentPropertyDetail.rentPerMonth);
        }
        days = 30;
        break;
        case 'Quarterly':
          if(this.paymentType.value.name == 'Rent'){
            this.paymentAmount.patchValue(this.currentPropertyDetail.rentPerMonth * 3);
          };
          days = 90;
          break;
        case 'Biannually':
          if(this.paymentType.value.name == 'Rent'){
            this.paymentAmount.patchValue(this.currentPropertyDetail.rentPerMonth * 6);
          };
          days = 180;
          break;
        case 'Annually':
          if(this.paymentType.value.name == 'Rent'){
            this.paymentAmount.patchValue(this.currentPropertyDetail.rentPerMonth * 12);
          };
          days = 365;
          break;
        default:
          days = 30;
    }
    this.showText = true;
    this.totalPay = this.paymentAmount.value;
    return days;
  }

  getPaymentHistoryDetails(){
    this.paymentUrl = this.currentPropertyDetail.id + "/" + this.currentPropertyDetail.tenantId + "/" + this.currentPropertyDetail.ownerId;
    this.paymentService.getPaymentHistory(this.paymentUrl).subscribe((data) => {
        if(data){
          this.allPaymentDetails = data;
          this.loader = false;
        }
    },
    (error) => {
      this.loader = false;
      // this._snackBar.open("There was some error. Please contact Admin", "X", {
      //   duration: 10000,
      //   panelClass: ['snackbar-failure']
      // });
    })
  }

  addPayment(formValue){
    this.showErrorMessages = false;
    if(this.errorMessage || this.paymentInfoForm.invalid)
    {
      this.showErrorMessages = true;
      return;
    }
    this.loader = true;
    if(this.newAttachment && this.newFile)
    {
      this.attachmentsFacade.createAttachment(this.newAttachment, this.newFile);
      this.attachmentsFacade.newAttachmentUrl$.subscribe((data)=>{
        if(data)
        {
          this.processPayment(data, formValue);
        }
      })
    }
    else{
      this.processPayment(null,formValue);
    }
    
  }

  processPayment(attachmentUrl: string = null, formValue){
    if(formValue.paymentMode.code !== 'Other'){
      formValue.paymentPeriodTo = formValue.paymentPeriodTo !== undefined ? formValue.paymentPeriodTo : this.paymentInfoForm.get('paymentPeriodTo').value;
    }
    let paymentObj: Payment = {
      amount: formValue.paymentAmount,
      fromDate: formValue.paymentPeriodFrom,
      fromId: this.currentPropertyDetail.tenantId,
      modeOfPayment: this.user!=='Owner'? formValue.paymentMode.code : '',
      paymentFrequency: formValue.frequencyType.code,
      paymentType: formValue.paymentType.code,
      propId: this.currentPropertyDetail.id,
      status: this.user!=='Owner'?"Awaiting Approval": 'Payment Requested',
      toDate: formValue.paymentPeriodTo,
      toId: this.currentPropertyDetail.ownerId,
      receiptAttachmentURL: attachmentUrl,
      paymentRefId: formValue.paymentRefId,
      initiator: formValue.initiator !== undefined? formValue.initiator : this.getInitiator()
    }
    this.paymentService.addPayment(paymentObj).subscribe(data => {
      this.getPaymentHistoryDetails();
      this.resetForm();
    },
    (error) =>{
      var message = error.error.message;
      if(message == 'Email Id not present for given owner/tenant. Request is saved however can not send email conformation.'){
        this._snackBar.open(message, "X", {
          duration: 10000,
          panelClass: ['snackbar-warning']
        });
        this.resetForm();
      }
      else{
        this._snackBar.open(message, "X", {
          duration: 10000,
          panelClass: ['snackbar-failure']
        });
      }
      
      this.getPaymentHistoryDetails();
    })
    this.showCalculatedText = false;
  }

  getInitiator(){
    let initiatorId;
      if(this.currentUser.id == this.currentPropertyDetail?.ownerUserId){
        initiatorId = this.currentPropertyDetail.ownerId;
      }else {
        initiatorId = this.currentPropertyDetail.tenantId;
      }
    return initiatorId;
  }

  getPaymentReceipt(id){
    this.paymentService.getReceipt(id)
  }

  OnFileClick(event){
    event.target.value = '';
  }

  onFileChange(event) {
    this.errorMessage = null;
    const fileList: FileList = event.target.files;
  
    let loggedInUser = this.localStorageService.getObjectFromLocalStorage('current-user');
    for(var i =0; i < fileList.length; i++)
    {
      let file = fileList[i];
      if(file.size/Math.pow(1024,2) > 10)
      {
        this.errorMessage = 'The file that you are trying to upload exceeds the limit of 10MB';
        return;
      }
      var allowedExtensions = ["png", "jpg","jpeg"];
      var extn = file.name.split(".").pop();
      if(allowedExtensions.indexOf(extn.toLowerCase()) < 0 ) {
        this.errorMessage = ' The attached file type is not supported. Please try using .png, .jpg, .jpeg file types.';
        return;
      }
      this.newFile = file;
      this.newAttachment = {
        id: UUID.UUID(),
        name: file.name,
        createdBy: loggedInUser.id,
        createdDate: new Date(),
        propertyId: this.currentPropertyDetail.id
      };
      // this.attachmentsFacade.createAttachment(this.newAttachment, file);
      // this.currentFileUpload = true;
      // this.progress = 0;
    }
  }

  payRequestedPayment(formValue){

  }

  clearAttachment(){
    this.newAttachment = null;
    this.newFile = null;
    this.errorMessage = null;
  }

  isOwner(){
    return this.user
  }

  isMobileBrowserEnabled(){
    return this.windowService.isMobileBrowser()
  }

  getFormattedDate(date: Date){
    return this._dateService.getFormattedDate(date);
  }

}
