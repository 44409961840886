import { Injectable } from "@angular/core";
import { LocalStorageService } from 'src/app/shared/services';

@Injectable()
export class FeatureToggleService{
    constructor(
        private readonly localStorageService: LocalStorageService,
    ) {}

    isFeatureEnabled(feature): boolean{
        let featureToggle = this.localStorageService.getFromLocalStorage('feature-toggle');
        if(feature === featureToggle){
            return true;
        }
        return false;
    }
}