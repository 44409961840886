<header class="container-fluid rentofly-header">
    <div class="row">
        <div class="col-md-2 mobileViewLogo">
            <img src="/assets/images/new-img/Horizontal logo.svg" height="50" width="155" style="cursor: pointer;" (click)="goToHomePage()">
        </div>
        <div  *ngIf="!isMobileBrowser()" class="nav-link-div col-md-10">
            <button pButton type="button" icon="pi pi-plus" iconPos="left" class="rentofly-primary rentofly-header-nav-button header-Btn" *ngIf="isAuthenticated && isMobileBrowser()" (click)="addProperty()"></button>
            <button pButton type="button" icon="pi pi-home" iconPos="left" class="rentofly-primary-home rentofly-header-nav-button home-Btn" *ngIf="isAuthenticated && isMobileBrowser()" routerLink="/dashboard/view-property"></button>
            <a *ngIf="isAuthenticated && !isMobileBrowser() && checkAvailibility()" class="nav-item nav-link" routerLink="/dashboard/dashboard" alt="Home" data-toggle="collapse" data-target=".navbar-collapse.show">Dashboard</a>
            <a *ngIf="isAuthenticated && checkAvailibility()" class="nav-item nav-link" routerLink="/dashboard/view-property" alt="Property" data-toggle="collapse" data-target=".navbar-collapse.show">Property</a>
            <a *ngIf="isAuthenticated && checkAvailibility()" class="nav-item nav-link" routerLink="/dashboard/services-info" alt="services" data-toggle="collapse" data-target=".navbar-collapse.show">Services</a>
            <a *ngIf="!isAuthenticated" class="nav-item nav-link" (click)="scrollToTop()" alt="LogIn" data-toggle="collapse" data-target=".navbar-collapse.show">Home</a>
            <a *ngIf="isAuthenticated && checkAvailibility()" class="nav-item nav-link" routerLink="profile" alt="Profile" data-toggle="collapse" data-target=".navbar-collapse.show">Your Account</a>
            <a *ngIf="!isAuthenticated" class="nav-item nav-link" routerLink="about-us" alt='About' data-toggle="collapse" data-target=".navbar-collapse.show">About</a>
            <a *ngIf="!isAuthenticated" class="nav-item nav-link" routerLink="contact-us" alt='ContactUs' data-toggle="collapse" data-target=".navbar-collapse.show">Contact Us</a>
            <a *ngIf="!isAuthenticated  && isPartnerEnabled()" class="partner-reg-btn" routerLink="add-partner" alt='AddPartner' data-toggle="collapse" data-target=".navbar-collapse.show">Partner Registration</a>
            <a *ngIf="isAuthenticated  && checkAvailibility()" class="partner-reg-btn" (click)="addProperty()" alt='AddPartner' data-toggle="collapse" data-target=".navbar-collapse.show">Add Property</a>
            

            <!-- <a *ngIf="isAuthenticated && isPartnerApproved()" class="nav-item nav-link" routerLink="partner" alt="Profile" data-toggle="collapse" data-target=".navbar-collapse.show">PARTNER</a> -->
            <a *ngIf="isAuthenticated" class="nav-item nav-link" routerLinkActive="active" alt='Logout' data-toggle="collapse" data-target=".navbar-collapse.show" (click)="logout()">Logout</a>
           
            <!-- <button type="button" class="rentofly-primary rentofly-header-nav-button header-Btn" *ngIf="isAuthenticated  && checkAvailibility()" (click)="addProperty()">ADD PROPERTY</button> -->
        </div>
        <div *ngIf="isMobileBrowser()" class="nav-link-div mobileView col-md-10">
            <a href="javascript:void(0);" class="icon" (click)="headerNavToggle()">
                <i class="fa fa-bars"></i>
            </a>
        </div>
        <div *ngIf="isMobileBrowser()" class="topnav">
            <div id="headerLinks">
                <a *ngIf="!isAuthenticated" class="nav-item nav-link" (click)="scrollToTop()" href="">Home</a>
                <a *ngIf="!isAuthenticated" class="nav-item nav-link" routerLink="about-us" (click)="headerNavToggle()" href="">About</a>
                <a *ngIf="!isAuthenticated" class="nav-item nav-link" routerLink="contact-us" (click)="headerNavToggle()" href="">Contact Us</a>
                <a *ngIf="isAuthenticated && !isMobileBrowser() && checkAvailibility()" (click)="headerNavToggle()"  class="nav-item nav-link" routerLink="/dashboard/dashboard" alt="Home" data-toggle="collapse" data-target=".navbar-collapse.show">Dashboard</a>
                <a *ngIf="isAuthenticated && checkAvailibility()" class="nav-item nav-link" (click)="headerNavToggle()" routerLink="/dashboard/view-property" alt="Property" data-toggle="collapse" data-target=".navbar-collapse.show">Property</a>
                <a *ngIf="isAuthenticated && checkAvailibility()" class="nav-item nav-link" (click)="headerNavToggle()" routerLink="/dashboard/services-info" alt="services" data-toggle="collapse" data-target=".navbar-collapse.show">Services</a>
                <a *ngIf="isAuthenticated && checkAvailibility()" class="nav-item nav-link" (click)="headerNavToggle()" routerLink="profile" alt="Profile" data-toggle="collapse" data-target=".navbar-collapse.show">Your Account</a>
                <!-- <a (click)="downloadApp()" alt='AddPartner' data-toggle="collapse" data-target=".navbar-collapse.show">Download Our App</a> -->
                <a *ngIf="!isAuthenticated  && isPartnerEnabled()" class="" (click)="headerNavToggle()" routerLink="add-partner" alt='AddPartner' data-toggle="collapse" data-target=".navbar-collapse.show">Partner Registration</a>
                <a *ngIf="isAuthenticated" class="nav-item nav-link" routerLinkActive="active" alt='Logout' data-toggle="collapse" data-target=".navbar-collapse.show" (click)="logout()">Logout</a>
            </div>
        </div>
    </div>
</header>