import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PropertyService } from 'src/app/shared/services';
import * as propertyActions from '../actions/property.action';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  AdditionalPropertyDetail,
  AvailablePropertyData,
  PaginatedProperty,
  Property,
  RentedPropertyData,
  TerminatedPropertyData,
  ViewProperty,
} from '../../shared/models';
import { of } from 'rxjs';
import { PaginatedPropertyInfo } from 'src/app/shared/models/property-info.model';
import { Store } from '@ngrx/store';
import { PropertyState } from '..';
import { propertyListRequest$ } from '../selectors/property.selector';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class PropertyEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly propertyService: PropertyService,
    private readonly store: Store<PropertyState>,
    private _snackBar: MatSnackBar
  ) {}

  queryPaginatedProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(propertyActions.queryPaginatedProperty),
      withLatestFrom(this.store.select(propertyListRequest$)),
      switchMap(([action, propertyListRequest]) =>
        this.propertyService
          .getPaginatedProperty(
            action.userId,
            propertyListRequest.pageIndex,
            propertyListRequest.pageSize
          )
          .pipe(
            map((data: PaginatedPropertyInfo) =>
              propertyActions.queryPaginatedPropertySuccess({
                paginatedProperties: data,
              })
            ),
            catchError((error: any) => {
              if (error.status != 404) {
                this._snackBar.open(
                  'The action performed was not successful. Please contact admin.',
                  'X',
                  {
                    duration: 10000,
                    panelClass: ['snackbar-failure'],
                  }
                );
              }
              return of(propertyActions.queryPaginatedPropertyFailure(error));
            })
          )
      )
    )
  );

  queryPaginatedAvailableProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(propertyActions.queryPaginatedAvailableProperty),
      withLatestFrom(this.store.select(propertyListRequest$)),
      switchMap(([action, propertyListRequest]) =>
        this.propertyService
          .getPaginatedAndSortedAvailableProperty(
            action.userId,
            propertyListRequest
          )
          .pipe(
            map((data) =>
              propertyActions.queryPaginatedAvailablePropertySuccess({
                paginatedProperties: data?.message,
              })
            ),
            catchError((error: any) => {
              if (error.status != 404) {
                this._snackBar.open(
                  'The action performed was not successful. Please contact admin.',
                  'X',
                  {
                    duration: 10000,
                    panelClass: ['snackbar-failure'],
                  }
                );
              }
              return of(
                propertyActions.queryPaginatedAvailablePropertyFailure(error)
              );
            })
          )
      )
    )
  );

  queryPaginatedRentedProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(propertyActions.queryPaginatedRentedProperty),
      withLatestFrom(this.store.select(propertyListRequest$)),
      switchMap(([action, propertyListRequest]) =>
        this.propertyService
          .getPaginatedAndSortedRentedProperty(
            action.userId,
            propertyListRequest
          )
          .pipe(
            map((data) =>
              propertyActions.queryPaginatedRentedPropertySuccess({
                paginatedProperties: data?.message,
              })
            ),
            catchError((error: any) => {
              if (error.status != 404) {
                this._snackBar.open(
                  'The action performed was not successful. Please contact admin.',
                  'X',
                  {
                    duration: 10000,
                    panelClass: ['snackbar-failure'],
                  }
                );
              }
              return of(
                propertyActions.queryPaginatedRentedPropertyFailure(error)
              );
            })
          )
      )
    )
  );

  queryPaginatedTerminatedProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(propertyActions.queryPaginatedTerminatedProperty),
      withLatestFrom(this.store.select(propertyListRequest$)),
      switchMap(([action, propertyListRequest]) =>
        this.propertyService
          .getPaginatedAndSortedTerminatedProperty(
            action.userId,
            propertyListRequest
          )
          .pipe(
            map((data) =>
              propertyActions.queryPaginatedTerminatedPropertySuccess({
                paginatedProperties: data?.message,
              })
            ),
            catchError((error: any) => {
              if (error.status != 404) {
                this._snackBar.open(
                  'The action performed was not successful. Please contact admin.',
                  'X',
                  {
                    duration: 10000,
                    panelClass: ['snackbar-failure'],
                  }
                );
              }
              return of(
                propertyActions.queryPaginatedTerminatedPropertyFailure(error)
              );
            })
          )
      )
    )
  );

  queryPaginatedPropertyUsingCreatedBy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(propertyActions.queryPaginatedPropertyUsingCreatedBy),
      switchMap((action) =>
        this.propertyService
          .getPaginatedPropertyUsingCreatedBy(action.createdBy, 0, 10)
          .pipe(
            map((data: PaginatedProperty) =>
              propertyActions.queryPaginatedPropertyUsingCreatedBySuccess({
                paginatedProperties: data,
              })
            ),
            catchError((error: any) =>
              of(
                propertyActions.queryPaginatedPropertyUsingCreatedByFailure(
                  error
                )
              )
            )
          )
      )
    )
  );

  addProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(propertyActions.addProperty),
      switchMap((action) =>
        this.propertyService.addProperty(action.property).pipe(
          map((data: string) =>
            propertyActions.addPropertySuccess({ id: data })
          ),
          catchError((error: any) => {
            if (error?.status != 404) {
              this._snackBar.open(
                'The property was not added correctly. Please verify and contact admin.',
                'X',
                {
                  duration: 10000,
                  panelClass: ['snackbar-failure'],
                }
              );
            }
            return of(propertyActions.addPropertyFailure(error));
          })
        )
      )
    )
  );

  getPropertyById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(propertyActions.queryPropertyById),
      switchMap((action) =>
        this.propertyService.getPropertyById(action.id).pipe(
          map((data) =>
            propertyActions.queryPropertyByIdSuccess({
              property: data?.message,
            })
          ),
          catchError((error: any) => {
            console.log(error);
            if (error?.status != 404) {
              this._snackBar.open(
                'The action you performed was not successful. Please contact admin.',
                'X',
                {
                  duration: 10000,
                  panelClass: ['snackbar-failure'],
                }
              );
            }
            return of(propertyActions.queryPropertyByIdFailure(error));
          })
        )
      )
    )
  );

  //viewpeopertybyid
  getViewPropertyById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(propertyActions.queryViewPropertyById),
      switchMap((action) =>
        this.propertyService.getViewPropertyById(action.id).pipe(
          map((data) =>
            propertyActions.queryViewPropertyByIdSuccess({
              property: data?.message,
            })
          ),
          catchError((error: any) => {
            this._snackBar.open(
              'The action you performed was not successful. Please contact admin.',
              'X',
              {
                duration: 10000,
                panelClass: ['snackbar-failure'],
              }
            );
            return of(propertyActions.queryViewPropertyByIdFailure(error));
          })
        )
      )
    )
  );

  //updateProperty
  updateProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(propertyActions.updateProperty),
      switchMap((action) =>
        this.propertyService.updateProperty(action.property).pipe(
          map((data: string) =>
            propertyActions.updatePropertySuccess({ id: data })
          ),
          catchError((error: any) => {
            this._snackBar.open(
              'Property update was not successful. Please try again or contact admin.',
              'X',
              {
                duration: 10000,
                panelClass: ['snackbar-failure'],
              }
            );
            return of(propertyActions.updatePropertyFailure(error));
          })
        )
      )
    )
  );

  //addadditionaldetails
  addAdditionalDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(propertyActions.addAdditionalDetails),
      switchMap((action) =>
        this.propertyService
          .addAdditionalDetails(action.additionalDetails)
          .pipe(
            map((data: string) =>
              propertyActions.addAdditionalDetailsSuccess({ id: data })
            ),
            catchError((error: any) => {
              if (error?.status != 404) {
                this._snackBar.open(
                  'The property additional details was not added correctly. Please verify and contact admin.',
                  'X',
                  {
                    duration: 10000,
                    panelClass: ['snackbar-failure'],
                  }
                );
              }
              return of(propertyActions.addAdditionalDetailsFailure(error));
            })
          )
      )
    )
  );

  //update additionaldetails
  updateAdditionalDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(propertyActions.updateAdditionalDetails),
      switchMap((action) =>
        this.propertyService
          .updateAdditionalDetails(action.additionalDetails)
          .pipe(
            map((data: string) =>
              propertyActions.updateAdditionalDetailsSuccess({ id: data })
            ),
            catchError((error: any) => {
              if (error?.status != 404) {
                this._snackBar.open(
                  'The property additional details was not added correctly. Please verify and contact admin.',
                  'X',
                  {
                    duration: 10000,
                    panelClass: ['snackbar-failure'],
                  }
                );
              }
              return of(propertyActions.updateAdditionalDetailsFailure(error));
            })
          )
      )
    )
  );

  //viewadditionalproperty
  getAdditionalDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(propertyActions.queryAdditionalDetails),
      switchMap((action) =>
        this.propertyService
          .getAdditionalDetailsProperty(action.propertyId)
          .pipe(
            map((data) =>
              propertyActions.queryAdditionalDetailsSuccess({
                additionalDetails: data?.message,
              })
            ),
            catchError((error: any) => {
              this._snackBar.open(
                'The action you performed was not successful. Please contact admin.',
                'X',
                {
                  duration: 10000,
                  panelClass: ['snackbar-failure'],
                }
              );
              return of(propertyActions.queryAdditionalDetailsFailure(error));
            })
          )
      )
    )
  );

  //queryAvailablePropertyData
  queryAvailablePropertyData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(propertyActions.queryAvailablePropertyData),
      switchMap((action) =>
        this.propertyService.getAvailablePropertyData(action.userId).pipe(
          map((data) =>
            propertyActions.queryAvailablePropertyDataSuccess({
              availablePropertyData: data.message,
            })
          ),
          catchError((error: any) => {
            this._snackBar.open(
              'The action you performed was not successful. Please contact admin.',
              'X',
              {
                duration: 10000,
                panelClass: ['snackbar-failure'],
              }
            );
            return of(propertyActions.queryAvailablePropertyDataFailure(error));
          })
        )
      )
    )
  );

  //queryRentedPropertyData
  queryRentedPropertyData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(propertyActions.queryRentedPropertyData),
      switchMap((action) =>
        this.propertyService.getRentedPropertyData(action.userId).pipe(
          map((data) =>
            propertyActions.queryRentedPropertyDataSuccess({
              rentedPropertyData: data?.message,
            })
          ),
          catchError((error: any) => {
            this._snackBar.open(
              'The action you performed was not successful. Please contact admin.',
              'X',
              {
                duration: 10000,
                panelClass: ['snackbar-failure'],
              }
            );
            return of(propertyActions.queryRentedPropertyDataFailure(error));
          })
        )
      )
    )
  );

  //queryTerminatedPropertyData
  queryTerminatedPropertyData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(propertyActions.queryTerminatedPropertyData),
      switchMap((action) =>
        this.propertyService.getTerminatedPropertyData(action.userId).pipe(
          map((data) =>
            propertyActions.queryTerminatedPropertyDataSuccess({
              terminatedPropertyData: data?.message,
            })
          ),
          catchError((error: any) => {
            this._snackBar.open(
              'The action you performed was not successful. Please contact admin.',
              'X',
              {
                duration: 10000,
                panelClass: ['snackbar-failure'],
              }
            );
            return of(
              propertyActions.queryTerminatedPropertyDataFailure(error)
            );
          })
        )
      )
    )
  );

  //terminateProperty
  terminateProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(propertyActions.terminateProperty),
      switchMap((action) =>
        this.propertyService.terminateProperty(action.propertyId).pipe(
          map((data: string) =>
            propertyActions.terminatePropertySuccess({ propertyId: data })
          ),
          catchError((error: any) => {
            this._snackBar.open(
              'The action you performed was not successful. Please contact admin.',
              'X',
              {
                duration: 10000,
                panelClass: ['snackbar-failure'],
              }
            );
            return of(
              propertyActions.queryTerminatedPropertyDataFailure(error)
            );
          })
        )
      )
    )
  );
}
