import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CreateDashboardState } from "../reducers";
import { userAdapter, UserState } from "../reducers/users.reducer";

export const getCreateDashboardState = createFeatureSelector<CreateDashboardState>('dashboard');
export const getUsersState = createSelector(
    getCreateDashboardState,
    (state: CreateDashboardState) => state.users
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = userAdapter.getSelectors(getUsersState);

export const isUserLoading$ = createSelector(getUsersState, (state: UserState) => state.userLoading);

export const getCurrentUser$ = createSelector(getUsersState, (state: UserState) => state.currentUser);

export const noUserResult$ = createSelector(getUsersState, (state: UserState) => state.noUserResult);

export const editingUser$ = createSelector(getUsersState, (state: UserState) => state.editingUser);