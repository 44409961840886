import { createAction, props } from "@ngrx/store";
import { FileUploadModel, PartnerFileUploadModel } from "src/app/shared/models";

export const createAttachment = createAction(
    '[Attachment] create Attachment',
    props<{attachment: FileUploadModel, file: File}>()
);

export const createAttachmentSuccess = createAction(
    '[Attachment] create Attachment Success',
    props<{progress: number}>()
);

export const createAttachmentFailure = createAction(
    '[Attachment] create Attachment Failure',
    props<{error: any}>()
);

export const addAttachment = createAction(
    '[Attachment] add Attachment',
    props<{attachment: FileUploadModel}>()
);

export const addAttachmentFailure = createAction(
    '[Attachment] add Attachment Failure',
    props<{error: any}>()
);

export const addPartnerAttachment = createAction(
    '[Attachment] add Partner Attachment',
    props<{attachment: PartnerFileUploadModel}>()
);

export const addPartnerAttachmentFailure = createAction(
    '[Attachment] add Partner Attachment Failure',
    props<{error: any}>()
);

export const getAttachmentsByPropertyId = createAction(
    '[Attachment] get Attachments by PropertyId',
    props<{propertyId: string}>()
);

export const getAttachmentsByPropertyIdSuccess = createAction(
    '[Attachment] get Attachments by PropertyId Success',
    props<{attachments: FileUploadModel[]}>()
);

export const getAttachmentsByPropertyIdFailure = createAction(
    '[Attachment] get Attachments by PropertyId Failure',
    props<{error: any}>()
);

export const getAttachmentsByPartnerId = createAction(
    '[Attachment] get Attachments by PartnerId',
    props<{partnerId: string}>()
);

export const getAttachmentsByPartnerIdSuccess = createAction(
    '[Attachment] get Attachments by PartnerId Success',
    props<{attachments: PartnerFileUploadModel[]}>()
);

export const getAttachmentsByPartnerIdFailure = createAction(
    '[Attachment] get Attachments by PartnerId Failure',
    props<{error: any}>()
);

export const deleteAttachmentsById = createAction(
    '[Attachment] delete Attachments by Id',
    props<{id: string}>()
);

export const deleteAttachmentsByIdSuccess = createAction(
    '[Attachment] delete Attachments by Id Success',
    props<{id: string}>()
);

export const deleteAttachmentsByIdFailure = createAction(
    '[Attachment] delete Attachments by Id Failure',
    props<{error: any}>()
);

export const createTempAttachment = createAction(
    '[Attachment] create Temp Attachment',
    props<{attachment: FileUploadModel, file: File}>()
);

export const addTempAttachmentLink = createAction(
    '[Attachment] create Temp Attachment',
    props<{link: string}>()
);

export const createTempAttachmentSuccess = createAction(
    '[Attachment] create Temp Attachment Success',
    props<{progress: number}>()
);

export const createTempAttachmentFailure = createAction(
    '[Attachment] create Temp Attachment Failure',
    props<{error: any}>()
);

export const createPartnerAttachment = createAction(
    '[Attachment] create Partner Attachment',
    props<{attachment: PartnerFileUploadModel, file: File}>()
);

export const createPartnerAttachmentSuccess = createAction(
    '[Attachment] create Partner Attachment Success',
    props<{progress: number}>()
);

export const createPartnerAttachmentFailure = createAction(
    '[Attachment] create Partner Attachment Failure',
    props<{error: any}>()
);