<head>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="description" content="Manage your rental property with ease using our AI-powered platform. Get personalized recommendations, flexible rent payment options, automated reminders, and centralized document management. Trust and transparency are at the core of our platform, making each rental transaction secure and hassle-free. Simplify your rental journey today.">
    <meta name="keywords" content="Rental finance management, Property management software, Tenant management system, Rent payment processing, Property listing management, Lease agreement management, Online rent payment, Rental property accounting, Tenant screening, Rent collection automation, Maintenance request management, Property owner dashboard, Real estate software, Online property management, Property management platform, Rental management app, Property management tool, Automated rent collection, Tenant communication platform">
    <meta name="robots" content="index, follow">
</head>
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-Y3D2LBP9KV"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-Y3D2LBP9KV');
</script>
<!-- Google tag (gtag.js) -->
<div class="row form-row">
    <div class="col-md-12" *ngIf="(currentDealLoading$ | async) || (dealEditing$ | async)">
        <rentofly-loading></rentofly-loading>
    </div>
    <div class="col-md-12" *ngIf="!(currentDealLoading$ | async)">
        <form [formGroup]="dealInfoForm" class="deal-info">
            <div class="form-group">
                <p class="info-header">Agreement Details</p>
            </div>
            <div class="form-group date-field-group">
                <label for="inputgroup" class="input-label">Start Date</label>
                <input [matDatepicker]="startDatepicker" (focus)="startDatepicker.open()" value="" id="startDate" formControlName="startDate" class="form-control date-picker contact-input" placeholder="DD/MM/YYYY" required>
                <mat-datepicker-toggle matSuffix [for]="startDatepicker" class="date-picker-icon"></mat-datepicker-toggle>
                <mat-datepicker #startDatepicker></mat-datepicker>
                <div *ngIf="startDate!.invalid && showDealInfoValidationMessage" class="rentofly-validation-msg">
                    <div>
                        Start Date is required.
                    </div>
                </div>
            </div>
            <div class="form-group date-field-group">
                <label for="inputgroup" class="input-label">End Date</label>
                <input [matDatepicker]="endDatepicker" (focus)="endDatepicker.open()" value="" id="endDate" formControlName="endDate" class="form-control date-picker contact-input" placeholder="DD/MM/YYYY" required>
                <mat-datepicker-toggle matSuffix [for]="endDatepicker" class="date-picker-icon"></mat-datepicker-toggle>
                <mat-datepicker #endDatepicker></mat-datepicker>
                <div *ngIf="endDate!.invalid && showDealInfoValidationMessage" class="rentofly-validation-msg">
                    <div>
                        End Date is required.
                    </div>
                </div>
            </div>
            <div class="form-group date-field-group">
                <label for="inputgroup" class="input-label">Monthly Rent Payment Date</label>
                <select class="form-control" name="rentPaymentDay" id="rentPaymentDay" formControlName="rentPaymentDay">
                    <option value="" selected>Select Day</option>
                    <option *ngFor="let day of daysOfMonth" [value]="day">{{day}}</option>
                </select>
            </div>
            <div class="form-group date-field-group">
                <label for="inputgroup" class="input-label">Notice Period</label>
                <select class="form-control" name="noticePeriod" id="noticePeriod" formControlName="noticePeriod">
                    <option value="15">15 days</option>
                    <option value="30" selected="selected">30 days</option>
                    <option value="45">45 days</option>
                    <option value="60">60 days</option>
                    <option value="75">75 days</option>
                    <option value="90">90 days</option>
                </select>
            </div>
            <div class="form-group">
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <span>&#8377;</span>
                    </span>
                    <span class="p-float-label">
                        <input type="number" id="rentPerMonth" class="contact-input" pInputText formControlName="rentPerMonth" 
                            OnlyNumber="true"> 
                        <label for="inputgroup" class="input-label">Rent Per Month</label>
                    </span>
                </div>
                <div *ngIf="rentPerMonth!.invalid && showDealInfoValidationMessage" class="rentofly-validation-msg">
                    <div>
                        Rent Per Month is required.
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <span>&#8377;</span>
                    </span>
                    <span class="p-float-label">
                        <input type="number" id="deposit" class="contact-input" pInputText formControlName="deposit" 
                            OnlyNumber="true">  
                        <label for="inputgroup" class="input-label">Deposit</label>
                    </span>
                </div>
                <div *ngIf="deposit!.invalid && showDealInfoValidationMessage" class="rentofly-validation-msg">
                    <div>
                        Deposit is required.
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <span>&#8377;</span>
                    </span>
                    <span class="p-float-label">
                        <input type="number" id="brokerage" class="contact-input" pInputText formControlName="brokerage" 
                            OnlyNumber="true">  
                        <label for="inputgroup" class="input-label">Brokerage</label>
                    </span>
                </div>
                <div *ngIf="brokerage!.invalid && showDealInfoValidationMessage" class="rentofly-validation-msg">
                    <div>
                        Brokerage is required.
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="isfeatureEnabled()">
                <p-fileUpload name="dealAgreement[]" url="./upload.php" [showUploadButton]="false" [showCancelButton]="false" chooseLabel="Uploade Agreement" accept=".doc, .pdf,.docx" class="uploadBtn"></p-fileUpload>
            </div>
            <div class="form-group" *ngIf="isfeatureEnabled()">
                <p-fileUpload name="policeNoc[]" url="./upload.php" [showUploadButton]="false" [showCancelButton]="false" chooseLabel="Uploade Police NOC" accept=".doc, .pdf,.docx" class="uploadBtn"></p-fileUpload>
            </div>
            <div class="form-group" *ngIf="isfeatureEnabled()">
                <p-fileUpload name="societyNoc[]" url="./upload.php" [showUploadButton]="false" [showCancelButton]="false" chooseLabel="Uploade Society NOC" accept=".doc, .pdf,.docx" class="uploadBtn"></p-fileUpload>
            </div>
            <div class="action">
                <button class="rentofly-primary" (click)="onInitiateDeal(dealInfoForm.value)">{{submitButtonText}}</button>
                <button class="rentofly-secondary" (click)="onSaveAndExit(dealInfoForm.value)">Save & Exit</button><br />
                <a (click)="onBack()">Back</a>|<a (click)="onExit()">Exit</a>
            </div>
        </form>
    </div>
</div>