<head>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="description" content="Manage your rental property with ease using our AI-powered platform. Get personalized recommendations, flexible rent payment options, automated reminders, and centralized document management. Trust and transparency are at the core of our platform, making each rental transaction secure and hassle-free. Simplify your rental journey today.">
    <meta name="keywords" content="Rental finance management, Property management software, Tenant management system, Rent payment processing, Property listing management, Lease agreement management, Online rent payment, Rental property accounting, Tenant screening, Rent collection automation, Maintenance request management, Property owner dashboard, Real estate software, Online property management, Property management platform, Rental management app, Property management tool, Automated rent collection, Tenant communication platform">
    <meta name="robots" content="index, follow">
</head>
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-Y3D2LBP9KV"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-Y3D2LBP9KV');
</script>
<!-- Google tag (gtag.js) -->
<div class="container">
    <div class="row">
        <div class="col-md-2">&nbsp;</div>
        <div class="col-md-8">
            <rentofly-loading *ngIf="(currentPartnerLoading$ | async) || (updatePartnerLoading$ | async)"></rentofly-loading>
            <form [formGroup]="editPartnerForm" class="partner-info" *ngIf="!(currentPartnerLoading$ | async) && !(updatePartnerLoading$ | async)">
                <div class="form-group partner-div">
                    <span class="p-float-label partner-span partner-us-page">
                        <input id="partnerName" class="partner-input" type="text" formControlName="partnerName" pInputText [readonly]="isPartnerApproved()"> 
                        <label for="partnerName" class="input-label">Partner Name</label>
                        
                    </span>
                    <div *ngIf="partnerName!.invalid && showPartnerValidationMessage" class="rentofly-login-validation-msg ">
                        <div>
                            Partner Name is invalid.
                        </div>
                    </div>
                </div>

                <div class="form-group partner-div">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <input type="text" id="phoneNumber" class="partner-input" pInputText formControlName="phoneNumber" 
                                readonly>  
                            <label for="phoneNumber" class="input-label">Mobile #</label>
                        </span>
                    </div>
                </div>

                <div class="form-group partner-div">
                    <span class="p-float-label partner-span partner-us-page">
                        <input id="emailAddress" class="partner-input" type="text" formControlName="emailAddress" pInputText [readonly]="isPartnerApproved()"> 
                        <label for="emailAddress" class="input-label">Email Address</label>
                        
                    </span>
                    <div *ngIf="emailAddress!.invalid && showPartnerValidationMessage" class="rentofly-login-validation-msg ">
                        <div>
                            Email Address is invalid.
                        </div>
                    </div>
                </div>

                <div class="form-group partner-div">
                    <span class="p-float-label contact-span span-space">
                        <input id="float-input-pin inputnumber partnerPinCode" class="partner-input" type="text" pKeyFilter="num"
                            pattern="^[1-9][0-9]*$"
                            oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                            maxlength="6" formControlName="partnerPinCode" pInputText [readonly]="isPartnerApproved()"> 
                        <label for="float-input-pin" class="input-label">Pin Code</label>
                    </span>
                    <div *ngIf="partnerPinCode!.invalid && showPartnerValidationMessage" class="rentofly-validation-msg ">
                        <div>
                            PIN Code is required.
                        </div>
                    </div>
                </div>
                <div class="form-group partner-div">
                    <div class="id-text">
                        Please fill one of the fields below for ID proof to proceed.
                    </div>
                </div>
                <div class="form-group partner-div">
                    <span class="p-float-label partner-span partner-us-page">
                        <input id="panNumber" class="partner-input" maxlength="10" type="text" formControlName="panNumber" pInputText [readonly]="isPartnerApproved()"> 
                        <label for="panNumber" class="input-label">Pan Number</label>
                    
                    </span>
                    <div *ngIf="panNumber!.invalid && showPartnerPanValidationMessage" class="rentofly-login-validation-msg ">
                        <div>
                            Pan Number is invalid.
                        </div>
                    </div>
                </div>

                <div class="form-group partner-div">
                    <span class="p-float-label partner-span partner-us-page">
                    <input id="aadharNumber" 
                    pattern="^[1-9][0-9]*$"
                    oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                    maxlength="12"
                    class="partner-input" type="text" formControlName="aadharNumber" pInputText [readonly]="isPartnerApproved()"> 
                    <label for="aadharNumber" class="input-label">Aadhar Number</label>
                    
                </span>
                    <div *ngIf="aadharNumber!.invalid && showPartnerAadharValidationMessage" class="rentofly-login-validation-msg ">
                        <div>
                            Aadhar Number is invalid.
                        </div>
                    </div>
                </div>

                <div class="form-group partner-div">
                    <span class="p-float-label partner-span partner-us-page">
                    <input id="brokerRegNumber" class="partner-input" type="text" formControlName="brokerRegNumber" pInputText [readonly]="isPartnerApproved()"> 
                    <label for="brokerRegNumber" class="input-label">Broker Reg Number</label>
                    
                </span>
                    <div *ngIf="brokerRegNumber!.invalid && showPartnerBrokerRegValidationMessage" class="rentofly-login-validation-msg ">
                        <div>
                            Broker Reg Number is invalid.
                        </div>
                    </div>
                </div>

                <div class="form-group partner-div">
                    <span class="p-float-label partner-span partner-us-page">
                    <select class="form-control" name="approvalStatus" id="approvalStatus" formControlName="approvalStatus" [attr.disabled]="isPartnerApproved() ? true: null">
                        <option value="Pending">Pending</option>
                        <option value="Approved">Approved</option>
                    </select>
                </span>
                    <div *ngIf="approvalStatus!.invalid && showPartnerValidationMessage" class="rentofly-login-validation-msg ">
                        <div>
                            Select Approval Status.
                        </div>
                    </div>
                </div>
                <!--- --->
                <div class="action">
                    <button class="rentofly-tertiary partner-confirm-btn" (click)="savePartner(editPartnerForm.value)" *ngIf="!isPartnerApproved()">Save</button>
                    <button class="rentofly-secondary" (click)="exit()">Exit</button>
                </div>
            </form>
        </div>
        <div class="col-md-2">&nbsp;</div>
    </div>
</div>