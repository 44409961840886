import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from "./base.service";
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService extends BaseService{
  rating;
  previousRating : BehaviorSubject<any> = new BehaviorSubject(null);

  
  private feedbackUrl = this.baseFeedBackUrl() + "/feedback/";
  getFeedBackQuestion(user): Observable<any>{
    const headers = this.getHeader();
    return this.getHttpClient().get(this.feedbackUrl + 'question/'+ user, { headers: headers });
  }
  saveFeedback(feedbackData, user): Observable<any>{
    const headers = this.getHeader();
    return this.getHttpClient().post(this.feedbackUrl + user, feedbackData, { headers: headers });
  }
  getPreviousRating(data): Observable<any>{
    const headers = this.getHeader();
    return this.getHttpClient().get(this.feedbackUrl + 'rate/'+ data, { headers: headers });
  }
  getProfileRating(data): Observable<any>{
    const headers = this.getHeader();
    return this.getHttpClient().get(this.feedbackUrl + 'rate/'+ data, { headers: headers });
  }
  previousData(data){
    this.rating = data;
    this.previousRating.next(this.rating);
  }
}
