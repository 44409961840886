import { createEntityAdapter, Dictionary, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import { FileUploadModel } from "src/app/shared/models";
import { addAttachment, addAttachmentFailure, addPartnerAttachment, addPartnerAttachmentFailure, addTempAttachmentLink, createAttachment, createAttachmentFailure, createAttachmentSuccess, createPartnerAttachment, createPartnerAttachmentFailure, createPartnerAttachmentSuccess, createTempAttachment, createTempAttachmentFailure, createTempAttachmentSuccess, deleteAttachmentsById, deleteAttachmentsByIdFailure, deleteAttachmentsByIdSuccess, getAttachmentsByPartnerId, getAttachmentsByPartnerIdFailure, getAttachmentsByPartnerIdSuccess, getAttachmentsByPropertyId, getAttachmentsByPropertyIdFailure, getAttachmentsByPropertyIdSuccess } from "../actions";

export function getAttachmentsReducer(state: AttachmentsState | undefined, action: Action) {
    return attachmentsReducer(state, action);
}

export const attachmentsAdapter = createEntityAdapter<FileUploadModel>();

export interface AttachmentsState extends EntityState<FileUploadModel> {
    entities: Dictionary<FileUploadModel>;
    error: any;
    loading: boolean;
    progress: number;
    link: string;
    linkLoading: boolean;
    newAttachmentUrl: string;
}

export const initialAttachmentsState: AttachmentsState = attachmentsAdapter.getInitialState({
    entities: [],
    error: null,
    loading: false,
    progress: 0,
    link: null,
    linkLoading: false,
    newAttachmentUrl: null
})

export const attachmentsReducer = createReducer(
    initialAttachmentsState,

    on(addAttachment, (state, attachment) => {
        return  attachmentsAdapter.removeAll({
            ...state,
            progress: 0,
            loading: true,
            newAttachmentUrl: attachment.attachment.url
        });
    }),

    on(addAttachmentFailure, (state, {error}) => {
        return {
            ...state,
            error: "The Upload operation failed. Please try again later.",
            loading: false
        }
    }),

    on(addPartnerAttachment, (state, attachment) => {
        return  attachmentsAdapter.removeAll({
            ...state,
            progress: 0,
            loading: true,
            newAttachmentUrl: attachment.attachment.url
        });
    }),

    on(addPartnerAttachmentFailure, (state, {error}) => {
        return {
            ...state,
            error: "The Upload operation failed. Please try again later.",
            loading: false
        }
    }),

    on(createAttachment, (state) => {
        return {
            ...state,
            progress: 0
        }
    }),

    on(createAttachmentSuccess, (state, {progress}) => {
        return {
            ...state,
            progress
        }
    }),

    on(createAttachmentFailure, (state, {error}) => {
        return {
            ...state,
            progress: 0,
            error: "The Upload operation failed. Please try again later.",
        }
    }),

    on(createPartnerAttachment, (state) => {
        return {
            ...state,
            progress: 0
        }
    }),

    on(createPartnerAttachmentSuccess, (state, {progress}) => {
        return {
            ...state,
            progress
        }
    }),

    on(createPartnerAttachmentFailure, (state, {error}) => {
        return {
            ...state,
            progress: 0,
            error: "The Upload operation failed. Please try again later.",
        }
    }),

    on(getAttachmentsByPropertyId, (state) => {
        return  attachmentsAdapter.removeAll({
            ...state,
            loading: true
        });
    }),

    on(getAttachmentsByPropertyIdSuccess, (state, {attachments}) => {
        return attachmentsAdapter.addMany(attachments, {
            ...state,
            loading: false,
            error: null
        })
    }),

    on(getAttachmentsByPropertyIdFailure, (state, {error}) => {
        return {
            ...state,
            error: "Cannot get attachments. Please try again later.",
            loading: false
        }
    }),

    on(getAttachmentsByPartnerId, (state) => {
        return  attachmentsAdapter.removeAll({
            ...state,
            loading: true
        });
    }),

    on(getAttachmentsByPartnerIdSuccess, (state, {attachments}) => {
        return attachmentsAdapter.addMany(attachments, {
            ...state,
            loading: false,
            error: null
        })
    }),

    on(getAttachmentsByPartnerIdFailure, (state, {error}) => {
        return {
            ...state,
            error: "Cannot get attachments. Please try again later.",
            loading: false
        }
    }),

    on(deleteAttachmentsById, (state) => {
        return {
            ...state,
            loading: true
        }
    }),

    on(deleteAttachmentsByIdSuccess, (state, {id}) => {
        return attachmentsAdapter.removeOne(id, {
            ...state,
            loading: false
        })
    }),

    on(deleteAttachmentsByIdFailure, (state, {error}) => {
        return {
            ...state,
            error: "The Delete operatopn failed. Please try again!",
            loading: false
        }
    }),

    on(createTempAttachment, (state, {attachment, file}) => {
        return {
            ...state,
            linkLoading: true,
            progress: 0
        }
    }),

    on(createTempAttachmentSuccess, (state, {progress}) => {
        return {
            ...state,
            progress
        }
    }),

    on(createTempAttachmentFailure, (state, {error}) => {
        return {
            ...state,
            linkLoading: false,
            error: error
        }
    }),

    on(addTempAttachmentLink, (state, {link}) => {
        return {
            ...state,
            linkLoading: false,
            link: link
        }
    }),
)