<head>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="description" content="Manage your rental property with ease using our AI-powered platform. Get personalized recommendations, flexible rent payment options, automated reminders, and centralized document management. Trust and transparency are at the core of our platform, making each rental transaction secure and hassle-free. Simplify your rental journey today.">
    <meta name="keywords" content="Rental finance management, Property management software, Tenant management system, Rent payment processing, Property listing management, Lease agreement management, Online rent payment, Rental property accounting, Tenant screening, Rent collection automation, Maintenance request management, Property owner dashboard, Real estate software, Online property management, Property management platform, Rental management app, Property management tool, Automated rent collection, Tenant communication platform">
    <meta name="robots" content="index, follow">
</head>
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-Y3D2LBP9KV"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-Y3D2LBP9KV');
</script>
<!-- Google tag (gtag.js) -->
<div class="row" *ngIf="showLoading">
    <rentofly-loading></rentofly-loading>
</div>

<div>
    <div class="row" *ngIf="showIntroSec">
        <div class="col-md-12">
            <div *ngIf="showWelcomeSec">
                <p class="feedbackPara">
                    Welcome to the feedback feature of Rentofly!<br><br>
                    Have you ever read the customer reviews before buying any product that costs around 50 rupees? Then why not have reviews for a product that will make you drop your approx.40% of the total yearly budget. <br><br>
                    Yes, you read it right! We, at Rentofly are passionate that a more transparent property management process exists and we are confident that collecting and presenting existing reviews is just a start of it.<br><br>
                    Here are the 4 solid reasons to rate –
                </p>
                <ol class="feedbackPara">
                    <li>Its free! Rating will cost you absolutely nothing.</li>
                    <li>It will only take less than a min of your time.</li>
                    <li>You will contribute in making the rental process transparent.</li>
                    <li>You will be given Rento Points for every successful feedback.</li>
                </ol>
                <p class="feedbackPara">So, what are you waiting for? Have your say and voice your opinion by clicking on the button below.</p>
            </div>
            <div class="row form-group" *ngIf="showRating">
                <div>
                    <p class="ratingPara">You have already provided feedback successfully on <b>{{lastFeedBackDate}}</b></p>
                </div>
                <div>
                    <p class="ratingPara">Based on your feedback, below is the rating for <b>{{personName}}</b></p>
                </div>
                <div class="divWidth">
                    <p-card class="ratingCard" *ngFor="let data of feedBack">
                        <p-avatar icon="pi pi-user" styleClass="p-mr-2" size="large" [style]="{'background-color':'#2196F3', 'color': '#ffffff'}" shape="circle"></p-avatar>
                        <h4 class="reviewerHeader">{{personName}}</h4>
                        <span class="fedbackTime">{{data.createdAt}}</span>
                        <span class="stars alignright">
                            <span [ngStyle] ="{ 'width': getStars(data.rating) }"></span>
                        </span>
                        <span class="feedbackRating">{{data.rating}} out of 10</span>
                    </p-card>
                </div>
                <p style="margin-top: 20px;">You can provide next feedback starting from <b>{{nextFeedbakDate}}</b></p>
                
            </div>
            <div class="rateBtnDiv" *ngIf="!showLoading">
                <button class="rentofly-tertiary" [disabled]="rateBtn" 
                    [ngClass]="{'disabledBtn':rateBtn}" (click)="startRating()">Rate</button>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="showQuestion">
        <form #feedbackForm="ngForm" class="form-group">
            <div class="questionDiv" *ngFor="let question of feedbackQuestions; let i = index">
                <p class="questionHeader">{{question.questionHeader}}</p>
                <ul>
                    <li class="questionList">
                        <p>{{question.questionName}}</p>
                        <div class="p-field-radiobutton radio-div">
                            <p-radioButton name="{{question.id}}" value="1" [(ngModel)]="question[i]" inputId="disturbanceYes" required></p-radioButton>
                            <label for="disturbanceYes" class="radio-label">Yes</label>
                        </div>
                        <div class="p-field-radiobutton radio-div">
                            <p-radioButton name="{{question.id}}" value="0" [(ngModel)]="question[i]" inputId="disturbanceNo"></p-radioButton>
                            <label for="disturbanceNo" class="radio-label">No</label>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="rateBtnDiv" *ngIf="!showIntroSec">
                <button class="rentofly-tertiary" (click)="onRatingSubmit(feedbackForm.value, feedbackForm.form.valid)" 
                 style="margin-right: 10px;">Submit</button>
                <button class="rentofly-secondary" (click)="onClose()">Exit</button>
            </div>
        </form>
    </div>
    
</div>
