import { Component, Input, OnInit } from '@angular/core';
import { ViewProperty } from 'src/app/shared/models';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'rentofly-share-property',
  templateUrl: './share-property.component.html',
  styleUrls: ['./share-property.component.scss']
})
export class SharePropertyComponent implements OnInit {
  @Input()
  currentViewProperty: ViewProperty;
  
  constructor() { }

  ngOnInit(): void {
  }

  generatePdf(){
    let DATA = document.getElementById('htmlData');
      
    html2canvas(DATA).then(canvas => {
        
        let fileWidth = 208;
        let fileHeight = canvas.height * fileWidth / canvas.width;
        
        const FILEURI = canvas.toDataURL('image/png')
        let PDF = new jsPDF('p', 'mm', 'a4');
        let position = 0;
        PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight)
        let res = PDF.save(`${this.currentViewProperty.propertyName}.pdf`);
    });   
  }

}
