import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UUID } from 'angular2-uuid';
import { environment } from 'src/environments/environment';
import { AddPartnerTemplate } from '../shared/emailTemplates';
import { Email, FileUploadModel, Partner, PartnerFileUploadModel } from '../shared/models';
import { LocalStorageService } from '../shared/services';
import { AttachmentsStoreFacade, PartnerStoreFacade } from '../store/facades';
import { EmailWhatsapp } from '../shared/models/email-whatsapp.model';

@Component({
  selector: 'rentofly-add-partner',
  templateUrl: './add-partner.component.html',
  styleUrls: ['./add-partner.component.scss']
})
export class AddPartnerComponent implements OnInit {
  partnerForm: FormGroup;
  showPartnerValidationMessage;
  showPartnerAadharValidationMessage;
  showPartnerPanValidationMessage;
  showPartnerBrokerRegValidationMessage;
  sendingEmail = false;
  registrationComplete = false;
  validatePhoneError;
  loading = false;
  currentFile: File;
  public currentFileUpload: boolean;
  public progress: number = 20;
  public newAttachment: PartnerFileUploadModel;
  public errorMessage: string;
  constructor(
    private readonly partnerFacade: PartnerStoreFacade,
    private readonly router: Router,
    private readonly localStorageService: LocalStorageService,
    private readonly attachmentsFacade: AttachmentsStoreFacade,
  ) {}

  ngOnInit(): void {
    this.initPartnerForm();
  }

  initPartnerForm(){
    this.partnerForm = new FormGroup({
      name: new FormControl('', [
        Validators.required
      ]),
      phoneNumber: new FormControl('', [
        Validators.required,
        Validators.minLength(10)
      ]),
      emailAddress: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      pinCode: new FormControl('', [
        Validators.required,
        Validators.minLength(6)
      ]),
      panNumber: new FormControl('', [
        // Validators.required,
        Validators.minLength(10)
      ]),
      aadharNumber: new FormControl('', [
        // Validators.required,
        Validators.minLength(12)
      ]),
      brokerRegNumber: new FormControl('', [
        Validators.required
      ]),
      idType: new FormControl('', [
        // Validators.required,
      ]),
    })
    this.currentFile = null;
  }

  get name() {
    return this.partnerForm!.get('name');
  }

  get phoneNumber() {
    return this.partnerForm!.get('phoneNumber');
  }

  get emailAddress() {
    return this.partnerForm!.get('emailAddress');
  }

  get pinCode() {
    return this.partnerForm!.get('pinCode');
  }

  get panNumber() {
    return this.partnerForm!.get('panNumber');
  }

  get aadharNumber() {
    return this.partnerForm!.get('aadharNumber');
  }

  get brokerRegNumber() {
    return this.partnerForm!.get('brokerRegNumber');
  }

  get idType() {
    return this.partnerForm!.get('idType');
  }

   sendEmail(partnerForm)
   {
    this.showPartnerValidationMessage = false;
    this.showPartnerAadharValidationMessage = false;
    this.showPartnerBrokerRegValidationMessage = false;
    this.showPartnerPanValidationMessage = false;
    if(this.partnerForm.invalid || this.validatePhoneError)
    {
      if(this.name.invalid || 
        this.phoneNumber.invalid || 
        this.emailAddress.invalid || 
        this.pinCode.invalid){
          this.showPartnerValidationMessage = true;
          return;
        }
      if((!this.aadharNumber.value && !this.brokerRegNumber.value && !this.panNumber.value)){
        this.showPartnerValidationMessage = true;
        return;
      }
      if(this.aadharNumber.value && this.aadharNumber.invalid){
        this.showPartnerAadharValidationMessage = true;
        return;
      }
      if(this.brokerRegNumber.value && this.brokerRegNumber.invalid){
        this.showPartnerBrokerRegValidationMessage = true;
        return;
      }
      if(this.panNumber.value && this.panNumber.invalid){
        this.showPartnerPanValidationMessage = true;
        return;
      }
    }

    let partner: Partner = {
      id: UUID.UUID(),
      name: partnerForm.name,
      phoneNumber: "+91"+partnerForm.phoneNumber,
      emailAddress: partnerForm.emailAddress,
      pinCode: +(partnerForm.pinCode),
      panNumber: partnerForm.panNumber,
      aadharNumber: partnerForm.aadharNumber,
      brokerRegNumber: partnerForm.brokerRegNumber,
      idType: partnerForm.idType,
      status: "Pending"
    }
    this.partnerFacade.addPartner(partner, this.getEmails(partner));
    this.newAttachment = {
      id: UUID.UUID(),
      name: this.currentFile?.name,
      createdDate: new Date(),
      partnerId: partner.id
    };
    this.attachmentsFacade.createAttachmentForPartner(this.newAttachment, this.currentFile);
    this.registrationComplete = true;
   }

   registerReset(){
     this.initPartnerForm();
     this.router.navigate(['/login']);
   }

   validatePhone(){
     this.partnerFacade.currentPartnerLoading$.subscribe((data) => {
       this.loading = data;
     });
     this.partnerFacade.queryPartnerByPhoneNumber("+91"+this.phoneNumber.value);
     this.partnerFacade.partnerError$.subscribe((data) => {
      if(data == "phoneNumber"){
        this.validatePhoneError = null;
      }
      else{
        this.validatePhoneError = "PhoneNumber is already used."
      }
     });
   }

   private getEmails(partner: Partner): EmailWhatsapp[]{
    let emails: EmailWhatsapp[] = [];
    
      // let partnerEmail: Email = {
      //   to: partner.emailAddress,
      //   from: environment.emailUsers.infoEmail,
      //   fromName: environment.emailUsers.infoEmailName,
      //   subject: `'Rentofly - Partner Registration'`,
      //   htmlBody: this.getEmailBody(AddPartnerTemplate, partner)
      // }
     let partnerEmail: EmailWhatsapp = {
       senderName: 'Team rentofly',
       receiverName: partner.name,
       receiverEmail: partner.emailAddress,
       phoneNumber: partner.phoneNumber,
       type: "",
       template: "add_partner_template",
       messageParameters: {
         subjectTextValue: "Rentofly - Partner Registration",
         userName: partner.name,
       }
     }
      emails.push(partnerEmail);
    return emails;
  }

  private getEmailBody(template: string, partner: Partner)
  {  
    return template.replace('{UserName}', partner.name)
  }

  onFileChange(event) {
    this.errorMessage = null;
    const fileList: FileList = event.target.files;
  
    let loggedInUser = this.localStorageService.getObjectFromLocalStorage('current-user');
    for(var i =0; i < fileList.length; i++)
    {
      let file = fileList[i];
      if(file.size/Math.pow(1024,2) > 10)
      {
        this.errorMessage = 'The file that you are trying to upload exceeds the limit of 10MB';
        return;
      }
      var allowedExtensions = ["xls","xlsx", "pdf", "doc", "docx", "png", "jpg","jpeg"];
      var extn = file.name.split(".").pop();
      if(allowedExtensions.indexOf(extn.toLowerCase()) < 0 ) {
        this.errorMessage = ' The attached file type is not supported. Please try using .xls, .xlsx, .pdf, .doc, .docx, .png, .jpg, .jpeg file types.';
        return;
      }
      this.currentFile = file;
    }
  }

  OnFileClick(event){
    event.target.value = '';
  }

}
