import { state } from "@angular/animations";
import { createEntityAdapter, Dictionary, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import { PaginatedPartner, Partner, PartnerRequest } from "src/app/shared/models";
import * as partnerActions from "../actions/partner.action";

export function getPartnerReducer(state: PartnerState | undefined, action: Action) {
    return partnerReducer(state, action);
}

export const partnerAdapter = createEntityAdapter<Partner>();

export interface PartnerState extends EntityState<Partner> {
    entities: Dictionary<Partner>;
    error: any;
    currentPartner: Partner;
    currentPartnerLoading: boolean;
    partnerRequest: PartnerRequest;
    partnerLoading: boolean;
    noPartnerResult: boolean;
    totalPartners: number;
    updatePartnerLoading: boolean;
    approvedPartners: PaginatedPartner;
    approvedPartnersLoading: boolean;
    pendingPartners: PaginatedPartner;
    pendingPartnersLoading: boolean;
}

export const initialPartnerState: PartnerState = partnerAdapter.getInitialState({
    entities: [],
    error: null,
    currentPartner: null,
    currentPartnerLoading: false,
    partnerRequest: {
        pageSize: 10,
        pageIndex: 0
    },
    partnerLoading: false,
    noPartnerResult: false,
    totalPartners: 0,
    updatePartnerLoading: false,
    approvedPartnersLoading: false,
    approvedPartners: {
        partners: [],
        totalPartners: 0
    },
    pendingPartners: {
        partners: [],
        totalPartners: 0
    },
    pendingPartnersLoading: false
});

export const partnerReducer = createReducer(
    initialPartnerState,

    on(partnerActions.queryPartnerById, (state) => {
        return  {
            ...state,
            currentPartner: null,
            currentPartnerLoading: true,
            error: null
        };
    }),

    on(partnerActions.queryPartnerByIdSuccess, (state, {partner}) => {
        return  {
            ...state,
            currentPartnerLoading: false,
            currentPartner: partner
        };
    }),

    on(partnerActions.queryPartnerByIdFailure, (state, {error}) => {
        return  {
            ...state,
            currentPartnerLoading: false,
            error
        };
    }),

    on(partnerActions.queryPartnerByPhoneNumber, (state) => {
        return  {
            ...state,
            currentPartner: null,
            currentPartnerLoading: true,
            error: null
        };
    }),

    on(partnerActions.queryPartnerByPhoneNumberSuccess, (state, {partner}) => {
        return  {
            ...state,
            currentPartnerLoading: false,
            currentPartner: partner
        };
    }),

    on(partnerActions.queryPartnerByPhoneNumberFailure, (state, {error}) => {
        return  {
            ...state,
            currentPartnerLoading: false,
            error: "phoneNumber"
        };
    }),

    on(partnerActions.queryPartnersByPinCode, (state) => {
        return  partnerAdapter.removeAll({
            ...state,
            currentPartnerLoading: true,
            currentPartner: null,
            error: null
        });
    }),

    on(partnerActions.queryPartnersByPinCodeSuccess, (state, {partners}) => {
        return  partnerAdapter.addMany(partners,{
            ...state,
            currentPartnerLoading: false,
            currentPartner: partners && partners.length > 0 ? partners[0] : null,
            error: null
        });
    }),

    on(partnerActions.queryPartnersByPinCodeFailure, (state, {error}) => {
        return  {
            ...state,
            currentPartnerLoading: false,
            error
        };
    }),

    //pageDetails
    on(partnerActions.setpartnerPageDetails, (state, {pageIndex, pageSize}) => {
        return {
            ...state,
            partnerRequest: {
                ...state.partnerRequest,
                pageIndex,
                pageSize
            }
        }
    }),

    on(partnerActions.setPartnerRequest, (state, {partnerRequest}) => {
        return {
            ...state,
            partnerRequest
        }
    }),

    on(partnerActions.queryPartners, (state) => {
        return  partnerAdapter.removeAll({
            ...state,
            partnerLoading: true
        });
    }),

    on(partnerActions.queryPartnersSuccess, (state, {paginatedPartner}) => {
        return  partnerAdapter.addMany(paginatedPartner.partners,{
                ...state,
                partnerLoading: false,
                totalPartners: paginatedPartner.totalPartners,
                noPartnerResult: paginatedPartner.totalPartners == 0
            }
        );
    }),

    on(partnerActions.queryPartnersFailure, (state) => {
        return  {
            ...state,
            partnerLoading: false,
            noPartnerResult: true
        };
    }),

    //Approvedpartner
    on(partnerActions.queryApprovedPartners, (state) => {
        return  {
            ...state,
            approvedPartnersLoading: true,
            approvedPartners: {
                partners: [],
                totalPartners: 0
            }
        };
    }),

    on(partnerActions.queryApprovedPartnersSuccess, (state, {paginatedPartner}) => {
        return  {
            ...state,
            approvedPartnersLoading: false,
            approvedPartners: paginatedPartner
        };
    }),

    on(partnerActions.queryApprovedPartnersFailure, (state) => {
        return  {
            ...state,
            approvedPartnersLoading: false
        };
    }),

    //Pendingpartner
    on(partnerActions.queryPendingPartners, (state) => {
        return  {
            ...state,
            pendingPartnersLoading: true,
            pendingPartners: {
                partners: [],
                totalPartners: 0
            }
        };
    }),

    on(partnerActions.queryPendingPartnersSuccess, (state, {paginatedPartner}) => {
        return  {
            ...state,
            pendingPartnersLoading: false,
            pendingPartners: paginatedPartner
        };
    }),

    on(partnerActions.queryPendingPartnersFailure, (state) => {
        return  {
            ...state,
            pendingPartnersLoading: false
        };
    }),

    on(partnerActions.updatePartnerById, (state, {partner}) => {
        return {
            ...state,
            updatePartnerLoading: true
        }
    }),

    on(partnerActions.updatePartnerByIdSuccess, (state) => {
        return {
            ...state,
            updatePartnerLoading: false
        }
    }),

    on(partnerActions.updatePartnerByIdFailure, (state) => {
        return {
            ...state,
            updatePartnerLoading: false
        }
    })
)