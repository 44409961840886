<head>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="description" content="Manage your rental property with ease using our AI-powered platform. Get personalized recommendations, flexible rent payment options, automated reminders, and centralized document management. Trust and transparency are at the core of our platform, making each rental transaction secure and hassle-free. Simplify your rental journey today.">
    <meta name="keywords" content="Rental finance management, Property management software, Tenant management system, Rent payment processing, Property listing management, Lease agreement management, Online rent payment, Rental property accounting, Tenant screening, Rent collection automation, Maintenance request management, Property owner dashboard, Real estate software, Online property management, Property management platform, Rental management app, Property management tool, Automated rent collection, Tenant communication platform">
    <meta name="robots" content="index, follow">
</head>
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-Y3D2LBP9KV"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-Y3D2LBP9KV');
</script>
<!-- Google tag (gtag.js) -->
<div class="row" *ngIf="(currentViewPropertyLoading$ | async) 
            || (editingTenant$ | async) 
            || (editingOwner$ | async)
            || (editingDeal$ | async)">
    <rentofly-loading></rentofly-loading>
</div>
<p-card class="viewPropertyCard add-property-card">
    <div *ngIf="!(currentViewPropertyLoading$ | async)">
        <!-- <div class="row">
            <div class="col-md-12" style="text-align: center;">
                <h4 class="about-header">Details</h4>
                <div class="heading-line"></div>
            </div>
        </div> -->

        <div class="row">
            <div class="col-md-6 name-text">
                Creator: <b>{{currentViewProperty.creatorName ? currentViewProperty.creatorName :
                    currentViewProperty.creatorPhone}}</b>
            </div>
            <div class="col-md-5 status-text">
                Status:
                <span [ngClass]="{'header-class-approve': currentViewProperty.dealStatus === 'Completed', 'header-class-pendiing' : currentViewProperty.dealStatus === 'Pending' }">
                    {{getPropertyStatus(currentViewProperty) ? getPropertyStatus(currentViewProperty) : ''}}
                </span>
            </div>
            <div class="col-md-1 mobileView">
                <i class="fa fa-long-arrow-left viewIcon" style="margin-right: 20px;" (click)="onExit()"></i>
                <i class="fa fa-edit viewIcon" *ngIf="isEditVisible() && !enableEditProperty()" (click)="editProperty()"></i>
            </div>
            <!-- <div class="col-md-12">
                Share: <a href="{{whatsappUrl}}" target="_blank">Share1</a>
            </div> -->
        </div>
        <p-tabView class="viewtab" (onChange)="onPropertyTabChange($event)">
            <p-tabPanel header="Lease Details" rightIcon="pi pi-file-o">
                <div class="row">
                    <div class="col-md-12">
                        <p-accordion class="view-property-info-accordian">
                            <div class="row property-details" *ngIf="currentViewProperty?.propertyCode">
                                <div class="col-md-12">
                                    <p-accordionTab header="Property Details" [(selected)]="activeState[0]">
                                        <div class="row property-details" *ngIf="currentViewProperty?.propertyCode">
                                            <div class="col-md-12">
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-pt" type="text"
                                                            value="{{additionalDetails?.propertyType}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-pt">Property Type</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-pc" type="text"
                                                            value="{{currentViewProperty.propertyCode}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-pc">Property Code</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-pn" type="text"
                                                            value="{{currentViewProperty.propertyName}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-pn">Property Name</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-pCode" type="text"
                                                            value="{{currentViewProperty.zipCode}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-pCode">PIN Code</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-pCity" type="text"
                                                            value="{{currentViewProperty.city}}" class="details-input"
                                                            pInputText disabled>
                                                        <label for="float-input-pCity">City</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-pState" type="text"
                                                            value="{{currentViewProperty.state}}" class="details-input"
                                                            pInputText disabled>
                                                        <label for="float-input-pState">State</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row" *ngIf="currentViewProperty.landmark">
                                                    <span class="p-float-label">
                                                        <input id="float-input-pLandmark" type="text"
                                                            value="{{currentViewProperty.landmark}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-pLandmark">Landmark</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row" *ngIf="currentViewProperty.buildingName">
                                                    <span class="p-float-label">
                                                        <input id="float-input-pBuilding" type="text"
                                                            value="{{currentViewProperty.buildingName}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-pBuilding">Building Name</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row" *ngIf="currentViewProperty.streetDetails">
                                                    <span class="p-float-label">
                                                        <input id="float-input-pStreet" type="text"
                                                            value="{{currentViewProperty.streetDetails}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-pStreet">Street Details</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row" *ngIf="currentViewProperty.flatNumber">
                                                    <span class="p-float-label">
                                                        <input id="float-input-pFlat" type="text"
                                                            value="{{currentViewProperty.flatNumber}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-pFlat">Flat Number</label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row details form-row" *ngIf="!currentViewProperty?.propertyCode">
                                            <div class="col-md-12">
                                                No Property Details Added
                                            </div>
                                        </div>
                                    </p-accordionTab>
                                    <p-accordionTab header="Deal Details" [(selected)]="activeState[1]">
                                        <div class="row property-details" *ngIf="currentViewProperty?.dealId">
                                            <div class="col-md-12">
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-dStart" type="text"
                                                            value="{{getFormattedDate(currentViewProperty.startDate)}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-dStart">Start Date</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-dEnd" type="text"
                                                            value="{{getFormattedDate(currentViewProperty.endDate)}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-dEnd">End Date</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-dRentDate" type="text"
                                                            value="{{currentViewProperty?.rentPaymentDay ? currentViewProperty?.rentPaymentDay : 'No Day Selected'}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-dRentDate">Monthly Rent Payment
                                                            Date</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-dNoticePeriod" type="text" value="{{getNoticePeriod(currentViewProperty?.noticePeriod)}}" 
                                                        class="details-input" pInputText disabled> 
                                                        <label for="float-input-dRentDate">Notice Period</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row" *ngIf="viewNoticeEndDate(currentViewProperty?.dealStatus)">
                                                    <span class="p-float-label">
                                                        <input id="float-input-dEnd" type="text" value="{{getFormattedDate(currentViewProperty.noticeEndDate)}}" 
                                                        class="details-input" pInputText disabled> 
                                                        <label for="float-input-dEnd">Notice End Date</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-dRent" type="text"
                                                            value="{{currentViewProperty.rentPerMonth | currency:'INR'}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-dRent">Rent Per Month</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-dDeposit" type="text"
                                                            value="{{currentViewProperty.deposit | currency:'INR'}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-dDeposit">Deposit</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-dBrokerage" type="text"
                                                            value="{{currentViewProperty.brokerage | currency:'INR'}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-dBrokerage">Brokerage</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-dStatus" type="text"
                                                            value="{{getDealStatus(currentViewProperty)}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-dStatus">Deal Status</label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row details form-row" *ngIf="!currentViewProperty?.dealId">
                                            <div class="col-md-12">
                                                No Deal details added.
                                            </div><br /><br />
                                        </div>
                                    </p-accordionTab>
                                    <p-accordionTab header="Owner Details" [(selected)]="activeState[2]">
                                        <div class="row property-details" *ngIf="currentViewProperty?.ownerId">
                                            <div class="col-md-12">
                                                <form [formGroup]="ownerInfoForm">
                                                    <div class="form-group details-row">
                                                        <span class="p-float-label">
                                                            <input id="float-input-oName" type="text"
                                                                formControlName="ownerName" class="details-input"
                                                                pInputText>
                                                            <label for="float-input-oName">Owner Name</label>
                                                        </span>
                                                    </div>
                                                    <div class="form-group details-row">
                                                        <span class="p-float-label">
                                                            <input id="float-input-oPhone" type="text"
                                                                formControlName="ownerPhone" class="details-input"
                                                                pInputText>
                                                            <label for="float-input-oPhone">Owner Phone Number</label>
                                                        </span>
                                                    </div>
                                                    <div class="form-group details-row">
                                                        <span class="p-float-label">
                                                            <input id="float-input-oEmail" type="text"
                                                                formControlName="ownerEmail" class="details-input"
                                                                pInputText>
                                                            <label for="float-input-oEmail">Owner Email</label>
                                                        </span>
                                                    </div>
                                                    <div class="form-group details-row">
                                                        <span class="p-float-label">
                                                            <input id="float-input-oPan" type="text"
                                                                formControlName="ownerPan" class="details-input"
                                                                maxlength="10" pInputText>
                                                            <label for="float-input-oPan">Owner Pan Card</label>
                                                        </span>
                                                        <div *ngIf="!(ownerPan!.invalid)" class="maskedInformation"><span>PAN # will be masked and not visible to others</span></div>
                                                        <div *ngIf="ownerPan!.invalid" class="rentofly-validation-msg">
                                                            <div>
                                                                Owner PAN in invalid.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group details-row">
                                                        <span class="p-float-label">
                                                            <input id="float-input-oStatus" type="text"
                                                                formControlName="ownerConstent" class="details-input"
                                                                pInputText>
                                                            <label for="float-input-oStatus">Owner Consent
                                                                Status</label>
                                                        </span>
                                                    </div>
                                                    <div class="form-group details-row" *ngIf="currentViewProperty?.ownerRejectReason">
                                                        <span class="p-float-label">
                                                            <textarea id="float-input-oReject" type="text"
                                                                formControlName="ownerRejectReason"
                                                                class="details-input" pInputText rows="8"> </textarea>
                                                            <label for="float-input-oReject">Owner Reject Reason</label>
                                                        </span>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div class="row details form-row" *ngIf="!currentViewProperty?.ownerId">
                                            <div class="col-md-12">
                                                No owner details added.
                                            </div><br /><br />
                                        </div>
                                    </p-accordionTab>
                                    <p-accordionTab header="Tenant Details" [(selected)]="activeState[3]">
                                        <div class="row property-details" *ngIf="currentViewProperty?.tenantId">
                                            <div class="col-md-12">
                                                <form [formGroup]="tenantInfoForm">
                                                    <div class="form-group details-row">
                                                        <span class="p-float-label">
                                                            <input id="float-input-tName" type="text"
                                                                formControlName="tenantName" class="details-input"
                                                                pInputText>
                                                            <label for="float-input-tName">Tenant Name</label>
                                                        </span>
                                                    </div>
                                                    <div class="form-group details-row">
                                                        <span class="p-float-label">
                                                            <input id="float-input-tPhone" type="text"
                                                                formControlName="tenantPhone" class="details-input"
                                                                pInputText>
                                                            <label for="float-input-tPhone">Tenant Phone Number</label>
                                                        </span>
                                                    </div>
                                                    <div class="form-group details-row">
                                                        <span class="p-float-label">
                                                            <input id="float-input-tEmail" type="text"
                                                                formControlName="tenantEmail" class="details-input"
                                                                pInputText>
                                                            <label for="float-input-tEmail">Tenant Email</label>
                                                        </span>
                                                    </div>
                                                    <div class="form-group details-row">
                                                        <span class="p-float-label">
                                                            <input id="float-input-tPan" type="text"
                                                                formControlName="tenantPan" class="details-input"
                                                                maxlength="10" pInputText>
                                                            <label for="float-input-tPan">Tenant Pan Card</label>
                                                        </span>
                                                        <div *ngIf="!(tenantPan!.invalid)" class="maskedInformation"><span>PAN # will be masked and not visible to others</span></div>
                                                        <div *ngIf="tenantPan!.invalid" class="rentofly-validation-msg">
                                                            <div>
                                                                Tenant PAN in invalid.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group details-row">
                                                        <span class="p-float-label">
                                                            <input id="float-input-tAadhar" type="text"
                                                                formControlName="tenantAadhar" class="details-input"
                                                                maxlength="12" pInputText>
                                                            <label for="float-input-tAadhar">Tenant Aadhar</label>
                                                        </span>
                                                        <div *ngIf="!(tenantAadhar!.invalid)"class="maskedInformation"><span>Aadhar # will be masked and not visible to others</span></div>
                                                        <div *ngIf="tenantAadhar!.invalid" class="rentofly-validation-msg">
                                                            <div>
                                                                Tenant Aadhar in invalid.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group details-row" *ngIf="currentViewProperty.tenantDetails">
                                                        <span class="p-float-label">
                                                            <input id="float-input-tDetail" type="text"
                                                                formControlName="tenantDetails" class="details-input"
                                                                pInputText>
                                                            <label for="float-input-tDetail">Tenant Details</label>
                                                        </span>
                                                    </div>
                                                    <div class="form-group details-row">
                                                        <span class="p-float-label">
                                                            <input id="float-input-tStatus" type="text"
                                                                formControlName="tenantConsentStatus"
                                                                class="details-input" pInputText>
                                                            <label for="float-input-tStatus">Tenant Consent
                                                                Status</label>
                                                        </span>
                                                    </div>
                                                    <div class="form-group details-row" *ngIf="currentViewProperty?.tenantRejectReason">
                                                        <span class="p-float-label">
                                                            <textarea id="float-input-oTenantReject" type="text"
                                                                formControlName="tenantRejectReason"
                                                                class="details-input" pInputText rows="8"> </textarea>
                                                            <label for="float-input-oTenantReject">Tenant Reject
                                                                Reason</label>
                                                        </span>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        <div class="row details form-row" *ngIf="!currentViewProperty?.tenantId">
                                            <div class="col-md-12">
                                                No Tenant details added.
                                            </div><br /><br />
                                        </div>
                                    </p-accordionTab>
                                    <p-accordionTab header="Broker Details" [(selected)]="activeState[4]" *ngIf="currentViewProperty?.stakeholderType != 0">
                                        <div class="row property-details" *ngIf="currentViewProperty?.brokerId">
                                            <div class="col-md-12">
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-bName" type="text"
                                                            value="{{currentViewProperty.brokerName}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-bName">Broker Name</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-bPhone" type="text"
                                                            value="{{currentViewProperty.brokerPhone}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-bPhone">Broker Phone Number</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-bEmail" type="text"
                                                            value="{{currentViewProperty.brokerEmail}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-bEmail">Broker Email</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-bCode" type="text"
                                                            value="{{currentViewProperty.brokerCode}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-bCode">Broker Code</label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row details form-row" *ngIf="!currentViewProperty?.brokerId">
                                            <div class="col-md-12">
                                                No Broker details added.
                                            </div><br /><br />
                                        </div>
                                    </p-accordionTab>
                                    <p-accordionTab header="Additional Property Details" *ngIf="additionalDetails">
                                        <div class="row property-details">
                                            <div class="col-md-12">
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-pt" type="text"
                                                            value="{{additionalDetails?.subPropertyType}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-pt">Sub Type</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-pt" type="text"
                                                            value="{{additionalDetails?.area}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-pt">Area (sq ft.)</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-pt" type="text"
                                                            value="{{additionalDetails?.numBedrooms}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-pt">Number of Bedroom/s</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-pt" type="text"
                                                            value="{{additionalDetails?.numBathroom}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-pt">Number of Bathroom/s</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-pt" type="text"
                                                            value="{{additionalDetails?.numBalcony}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-pt">Balcony/Patio</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-pt" type="text"
                                                            value="{{additionalDetails?.accomodates}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-pt">Accomodates</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-pt" type="text"
                                                            value="{{additionalDetails?.parking}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-pt">Parking available</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row" *ngIf="additionalDetails?.parking == 'yes'">
                                                    <span class="p-float-label">
                                                        <input id="float-input-pt" type="text"
                                                            value="{{additionalDetails?.parkingType}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-pt">Parking Type</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-pt" type="text"
                                                            value="{{additionalDetails?.visitorParking}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-pt">Visitor Parking available</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row" *ngIf="additionalDetails?.visitorParking == 'yes'">
                                                    <span class="p-float-label">
                                                        <input id="float-input-pt" type="text"
                                                            value="{{additionalDetails?.visitorParkingType}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-pt">Visitor Parking Type</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-pt" type="text"
                                                            value="{{additionalDetails?.furnishing}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-pt">Furnishing</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <!-- <input id="float-input-pt" type="text"
                                                            value="{{additionalDetails?.selectedFurniture}}"
                                                            class="details-input" pInputText disabled> -->
                                                            <textarea id="float-input" pInputTextarea
                                                                value="{{additionalDetails?.selectedFurniture}}" class="details-input" 
                                                                disabled></textarea>
                                                        <label for="float-input-pt">Furniture/Electronics included</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-pt" type="text"
                                                            value="{{additionalDetails?.consumernumber}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-pt">Consumer Number</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-pt" type="text"
                                                            value="{{additionalDetails?.electricBoard}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-pt">Electricity Board</label>
                                                    </span>
                                                </div>
                                                <div class="form-group details-row">
                                                    <span class="p-float-label">
                                                        <input id="float-input-pt" type="text"
                                                            value="{{additionalDetails?.powerBackup}}"
                                                            class="details-input" pInputText disabled>
                                                        <label for="float-input-pt">Power Backup</label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </p-accordionTab>
                                </div>
                            </div>
                        </p-accordion>
                        <div class="row" style="margin-top: 30px;">
                            <br /><br />
                            <div class="action col-md-12">
                                <button *ngIf="viewActionButton()" class="rentofly-tertiary" (click)="giveConsent(userType)">Approve</button>
                                <button *ngIf="viewActionButton()" class="rentofly-failure" (click)="reject(userType)">Reject</button><br>
                                <button *ngIf="isEditVisible()" class="rentofly-primary" (click)="editProperty()">{{enableEditProperty() ? 'Draft Agreement' : 'Edit
                                    Property'}}</button>
                                <button *ngIf="viewTerminateProperty(userType)" class="rentofly-failure" (click)="terminate(userType)">Terminate</button>
                                <button *ngIf="viewCancelTerminate(userType)" class="rentofly-tertiary" (click)="cancelTermination(userType)">Cancel Termination</button>
                                <button class="rentofly-secondary" (click)="onExit()">Exit</button><br />
                            </div>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Payment" *ngIf="displayPayment()" rightIcon=" pi pi-money-bill ">
                <rentofly-payment></rentofly-payment>
            </p-tabPanel>
            <p-tabPanel header="Feedback " rightIcon="pi pi-star-o " *ngIf="currentViewProperty.dealStatus==='Completed' && this.userType !='Broker' && this.userType !='' ">
                <rentofly-feedback></rentofly-feedback>
            </p-tabPanel>
            <p-tabPanel header="Documents " rightIcon="pi pi-upload ">
                <rentofly-property-attachments [propertyId]="propertyId "></rentofly-property-attachments>
            </p-tabPanel>
            <!-- <p-tabPanel header="Share " rightIcon="pi pi-share-alt " *ngIf="currentViewProperty.dealStatus !=='Completed' ">
                <rentofly-share-property [currentViewProperty]="currentViewProperty "></rentofly-share-property>
            </p-tabPanel> -->
        </p-tabView>
    </div>
</p-card>