import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { DealService, EmailService, PropertyService } from "src/app/shared/services";
import * as dealActions from "../actions/deal.action";
import { catchError, map, switchMap } from "rxjs/operators";
import { Deal } from "../../shared/models";
import { of } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class DealEffects{
    constructor(
        private readonly actions$: Actions,
        private readonly propertyService: PropertyService,
        private readonly dealService: DealService,
        private readonly emailService: EmailService,
        private _snackBar: MatSnackBar,
    ){}

    getDealById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dealActions.queryDealById),
            switchMap((action) => 
                this.dealService.getDealById(action.id).pipe(
                    map((data: Deal) => dealActions.queryDealByIdSuccess({deal: data})),
                    catchError((error: any) => {
                        if(error.status != 404)
                        {
                            this._snackBar.open('The action performed was not successful. Please contact admin.', "X", {
                                duration: 10000,
                                panelClass: ['snackbar-failure']
                            });
                        }
                        return of(dealActions.queryDealByIdFailure(error))
                    }))
                )
            )
    );

    addDeal$ = createEffect(() =>
    this.actions$.pipe(
        ofType(dealActions.addDeal),
        switchMap((action) => 
            this.propertyService.addDeal(action.propertyId, action.deal).pipe(
                map((data: Deal) => {
                    if(action.initiateDeal)
                    {
                        action.emails.forEach((data) => {
                            this.emailService.sendEmail(data).subscribe();
                        });
                    }
                    
                    return dealActions.addDealSuccess()
                }),
                catchError((error: any) => {
                    this._snackBar.open('Adding Deal was not successful. Please contact admin.', "X", {
                        duration: 10000,
                        panelClass: ['snackbar-failure']
                    });
                    return of(dealActions.addDealFailure(error))
                }))
            )
        )
    );

    updateDeal$ = createEffect(() =>
    this.actions$.pipe(
        ofType(dealActions.updateDeal),
        switchMap((action) => 
            this.propertyService.updateDeal(action.propertyId, action.deal).pipe(
                map((data: Deal) => {
                    if(action.initiateDeal)
                    {
                        action.emails.forEach((data) => {
                            this.emailService.sendEmail(data).subscribe();
                        });
                    }
                    return dealActions.updateDealSuccess()
                }),
                catchError((error: any) => {
                    this._snackBar.open('Updating Deal was not successful. Please contact admin.', "X", {
                        duration: 10000,
                        panelClass: ['snackbar-failure']
                    });
                    return of(dealActions.updateDealFailure(error))
                }))
            )
        )
    );

    updateDealStatus$ = createEffect(() =>
    this.actions$.pipe(
        ofType(dealActions.updateDealStatus),
        switchMap((action) => 
            this.propertyService.updateDealStatus(action.id, action.status).pipe(
                map(() => {
                    action.emails.forEach((data) => {
                        this.emailService.sendEmail(data).subscribe();
                    });
                    return dealActions.updateDealStatusSuccess()
                }),
                catchError((error: any) => {
                    this._snackBar.open('Updating Deal was not successful. Please contact admin.', "X", {
                        duration: 10000,
                        panelClass: ['snackbar-failure']
                    });
                    return of(dealActions.updateDealStatusFailure(error))
                }))
            )
        )
    );

    updateNoticeDateAndStatus$ = createEffect(() =>
    this.actions$.pipe(
        ofType(dealActions.updateNoticeDateAndStatus),
        switchMap((action) => 
            this.propertyService.updateNoticeDateAndStatus(action.id, action.updateDeal).pipe(
                map(() => {
                    action.emails.forEach((data) => {
                        this.emailService.sendEmail(data).subscribe();
                    });
                    return dealActions.updateNoticeDateAndStatusSuccess()
                }),
                catchError((error: any) => {
                    this._snackBar.open('Updating Deal was not successful. Please contact admin.', "X", {
                        duration: 10000,
                        panelClass: ['snackbar-failure']
                    });
                    return of(dealActions.updateNoticeDateAndStatusFailure(error))
                }))
            )
        )
    );

    destructor(){
        
    }
}