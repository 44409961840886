import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UUID } from 'angular2-uuid';
import { Observable } from 'rxjs';
import { Broker, Deal, Email, Owner, Property, Tenant, User } from 'src/app/shared/models';
import { environment } from 'src/environments/environment';
import { BrokerTemplate, OwnerTemplate, TenantTemplate } from 'src/app/shared/emailTemplates';
import { BrokerStoreFacade, TenantStoreFacade, OwnerStoreFacade, DealStoreFacade } from 'src/app/store/facades';
import { DateService, LocalStorageService } from 'src/app/shared/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FeatureToggleService } from 'src/app/shared/services/feature-toggle.service';
import { EmailWhatsapp } from 'src/app/shared/models/email-whatsapp.model';

@Component({
  selector: 'rentofly-deal-info',
  templateUrl: './deal-info.component.html',
  styleUrls: ['./deal-info.component.scss']
})
export class DealInfoComponent implements OnInit {

  @Input()
  currentProperty: Property;
  
  @Output()
  initiateDeal: EventEmitter<any> = new EventEmitter();

  @Output()
  continue: EventEmitter<any> = new EventEmitter();

  @Output()
  back: EventEmitter<any> = new EventEmitter();

  @Output()
  exit: EventEmitter<any> = new EventEmitter();

  @Output()
  saveAndExit: EventEmitter<any> = new EventEmitter();
  currentUser: User;
  dealInfoForm: FormGroup;
  showDealInfoValidationMessage = false;
  currentDeal: Deal;
  currentDealLoading$: Observable<boolean>;
  dealEditing$: Observable<boolean>;
  submitButtonText = "Initiate Deal";
  currentDate: Date = new Date();
  initiateDealFlag = false;
  currentOwner: Owner;
  currentTenant: Tenant;
  currentBroker: Broker;

  noticePeriodsinDays = [
    15,30,45,60,75,90
  ]

  daysOfMonth = [
    "1st of the month",
    "2nd of the month",
    "3rd of the month",
    "4th of the month",
    "5th of the month",
    "6th of the month",
    "7th of the month",
    "8th of the month",
    "9th of the month",
    "10th of the month",
    "11th of the month",
    "12th of the month",
    "13th of the month",
    "14th of the month",
    "15th of the month",
    "16th of the month",
    "17th of the month",
    "18th of the month",
    "19th of the month",
    "20th of the month",
    "21st of the month",
    "22nd of the month",
    "23rd of the month",
    "24th of the month",
    "25th of the month",
    "26th of the month",
    "27th of the month",
    "28th of the month",
    "29th of the month",
    "30th of the month",
    "31st of the month"
  ]
  
  constructor(
    private readonly dealFacade: DealStoreFacade,
    private readonly ownerFacade: OwnerStoreFacade,
    private readonly tenantFacade: TenantStoreFacade,
    private readonly brokerFacade: BrokerStoreFacade,
    private readonly _snackBar: MatSnackBar,
    private readonly localStorageService: LocalStorageService,
    private readonly dateService: DateService,
    private readonly featureToggleService: FeatureToggleService
  ) { 
    
  }

  get startDate() {
    return this.dealInfoForm!.get('startDate');
  }

  get endDate() {
    return this.dealInfoForm!.get('endDate');
  }
  get rentPaymentDay() {
    return this.dealInfoForm!.get('rentPaymentDay');
  }
  get rentPerMonth() {
    return this.dealInfoForm!.get('rentPerMonth');
  }

  get deposit() {
    return this.dealInfoForm!.get('deposit');
  }

  get brokerage() {
    return this.dealInfoForm!.get('brokerage');
  }

  get noticePeriod() {
    return this.dealInfoForm!.get('noticePeriod');
  }

  ngOnInit(): void {
    this.currentUser = this.localStorageService.getObjectFromLocalStorage('current-user');
    this.ownerFacade.currentOwner$.subscribe((data) =>{
      if(data)
      {
        this.currentOwner = data;
      }
    })
    this.tenantFacade.currentTenant$.subscribe((data) =>{
      if(data)
      {
        this.currentTenant = data;
      }
    })
    this.brokerFacade.currentBroker$.subscribe((data) => {
      if(data){
        this.currentBroker = data;
      }
    })
    this.currentDealLoading$ = this.dealFacade.currentDealLoading$;
    if(this.currentProperty.dealId)
    {
      this.dealFacade.queryDealById(this.currentProperty.dealId);
    }
    this.dealFacade.currentDeal$.subscribe((dealData) => { 
      this.currentDeal = dealData;
      if(this.currentDeal?.dealStatus == "Consent")
      {
        this.submitButtonText = "Draft Agreement";
      }
      this.initForm(this.currentDeal);
    });
    this.dealEditing$ = this.dealFacade.dealEditing$;
    
  }

  onInitiateDeal(dealFormValue): void{
    this.initiateDealFlag = true;
    this.showDealInfoValidationMessage = false;
    if(this.dealInfoForm.invalid)
    {
      this.showDealInfoValidationMessage = true;
      return;
    }
    let start = new Date(dealFormValue.startDate)
    let end = new Date(dealFormValue.endDate)
    if(start > end)
    {
      this._snackBar.open('Start Date cannot be later than End Date.', "X", {
        duration: 10000,
        panelClass: ['snackbar-failure']
      });
      return;
    }
    this.onSave(dealFormValue, this.initiateDealFlag);

    this.dealEditing$.subscribe((data) => {
      if(data && this.initiateDealFlag)
      {
        this.initiateDealFlag = false;
        this.initiateDeal.emit(1);
        this._snackBar.open('Email is sent to respective Owner/Tenant to provide the consent. Once they provide the consent you can draft the agreement.', "X", {
          duration: 10000,
          panelClass: ['snackbar-success']
        });
      }
    });
  }

  onBack(): void{
    this.back.emit(1);
  }

  onExit(): void {
    this.exit.emit(1);
  }

  onSaveAndExit(dealFormValue): void{
    this.initiateDealFlag = false;
    this.showDealInfoValidationMessage = false;
    if(this.dealInfoForm.invalid)
    {
      this.showDealInfoValidationMessage = true;
      return;
    }
    let start = new Date(dealFormValue.startDate)
    let end = new Date(dealFormValue.endDate)
    if(start > end)
    {
      this._snackBar.open('Start Date cannot be after End Date.', "X", {
        duration: 10000,
        panelClass: ['snackbar-failure']
      });
      return;
    }
    
    this.onSave(dealFormValue, this.initiateDealFlag)
    
    
    this.dealEditing$.subscribe((data) => {
      if(!data && !this.initiateDealFlag)
      {
        this.saveAndExit.emit(1);
      }
    });
  }

  clearDate(field: string) {
    if (field == 'startDate') {
      this.startDate.setValue(null);
    }

    if (field == 'endDate') {
      this.endDate.setValue(null);
    }
  }

  private initForm(dealData: Deal){
    this.dealInfoForm = new FormGroup({
      startDate: new FormControl(dealData?.startDate ? 
        this.dateService.getFormattedDate(dealData?.startDate) :
        '', [
        Validators.required
      ]),
      endDate: new FormControl(dealData?.endDate ? 
        this.dateService.getFormattedDate(dealData?.endDate) :
        '', [
        Validators.required
      ]),
      rentPaymentDay: new FormControl(dealData?.rentPaymentDay ??
        '', [
      ]),
      rentPerMonth: new FormControl(dealData?.rentPerMonth ?? '', [
        Validators.required
      ]),
      deposit: new FormControl(dealData?.deposit ?? '', [
        Validators.required
      ]),
      brokerage: new FormControl(dealData?.brokerage ?? '', [
        Validators.required
      ]),
      noticePeriod: new FormControl(dealData?.noticePeriod ?? '30', [
        Validators.required
      ])
    });
  }

  private onSave(dealFormValue, initiateDeal)
  {
    if(this.currentDeal)
    {
      var newDeal: Deal = {
        id: this.currentDeal.id,
        startDate: dealFormValue.startDate,
        endDate: dealFormValue.endDate,
        rentPerMonth: +(dealFormValue.rentPerMonth),
        dealStatus: initiateDeal ? "Pending" : this.currentDeal.dealStatus,
        deposit: +(dealFormValue.deposit),
        brokerage: +(dealFormValue.brokerage),
        specialCondition: this.currentDeal.specialCondition,
        rentPaymentDay: dealFormValue.rentPaymentDay,
        noticePeriod: +(dealFormValue.noticePeriod)
      }
      if(initiateDeal)
      {
        this.dealFacade.updateDeal(this.currentProperty.id, newDeal,initiateDeal, this.getEmails());  
      }
      else{
        this.dealFacade.updateDeal(this.currentProperty.id, newDeal,initiateDeal, []);
      }
      this.updateOwner(initiateDeal);
      this.updateTenant(initiateDeal);
    }
    else{
      var newDeal: Deal = {
        id: UUID.UUID(),
        startDate: dealFormValue.startDate,
        endDate: dealFormValue.endDate,
        rentPerMonth: +(dealFormValue.rentPerMonth),
        dealStatus: initiateDeal ? "Pending" : null,
        deposit: +(dealFormValue.deposit),
        brokerage: +(dealFormValue.brokerage),
        rentPaymentDay: dealFormValue.rentPaymentDay,
        propertyId: this.currentProperty.id,
        noticePeriod: +(dealFormValue.noticePeriod)
      }
      if(initiateDeal)
      {
        this.dealFacade.addDeal(this.currentProperty.id, newDeal,initiateDeal, this.getEmails());  
      }
      else{
        this.dealFacade.addDeal(this.currentProperty.id, newDeal,initiateDeal, []);
      }

      this.updateOwner(initiateDeal);
      this.updateTenant(initiateDeal);
    }
    
  }

  private updateOwner(initiateDeal = false){
    var udpateOwner: Owner = {
      id: this.currentOwner.id,
      userId: this.currentOwner.userId,
      phoneNumber: this.currentOwner.phoneNumber,
      pan: this.currentOwner.pan,
      aadhar: this.currentOwner.aadhar,
      name: this.currentOwner.name,
      ownerConsentStatus: this.currentOwner.phoneNumber == this.currentUser.phoneNumber && initiateDeal ? 'Approved' : 'Pending',
      emailAddress: this.currentOwner.emailAddress,
      rejectReason: initiateDeal ? '' : this.currentOwner.rejectReason
    }
    this.ownerFacade.updateOwner(this.currentProperty.id, udpateOwner);
  }

  private updateTenant(initiateDeal = false){
    var updateTenant: Tenant = {
      id: this.currentTenant.id,
      userId: this.currentTenant.userId,
      phoneNumber: this.currentTenant.phoneNumber,
      tenantPanCard: this.currentTenant.tenantPanCard,
      tenantAadhar: this.currentTenant.tenantAadhar,
      name: this.currentTenant.name,
      tenantConsentStatus: this.currentTenant.phoneNumber == this.currentUser.phoneNumber && initiateDeal ? 'Approved' :  'Pending',
      emailAddress: this.currentTenant.emailAddress,
      tenantDetails: this.currentTenant.tenantDetails,
      rejectReason: initiateDeal ? '' : this.currentTenant.rejectReason
    }
    this.tenantFacade.updateTenant(this.currentProperty.id, updateTenant);
  }

  private getEmails(): EmailWhatsapp[]{
    let emails: EmailWhatsapp[] = [];
    if((this.currentOwner.phoneNumber != this.currentUser.phoneNumber) || (this.currentOwner.phoneNumber == this.currentUser.phoneNumber))
    {
      // let ownerEmail: Email = {
      //   to: this.currentOwner.emailAddress,
      //   from: environment.emailUsers.infoEmail,
      //   fromName: environment.emailUsers.infoEmailName,
      //   subject: `'Rentofly - Added you as a Owner on a Property'`,
      //   htmlBody: this.getEmailBody(OwnerTemplate, 'Owner'),
      //   cc: this.getCurrentUserEmail(this.currentOwner.emailAddress)
      // }
      let ownerEmail: EmailWhatsapp = {
        senderName: 'Team rentofly',
        receiverName: this.currentOwner.name,
        receiverEmail: this.currentOwner.emailAddress,
        phoneNumber: this.currentOwner.phoneNumber,
        type: "",
        template: "owner_template",
        messageParameters: {
          subjectTextValue: "Rentofly - Added you as a Owner on a Property",
          userName: this.currentOwner.name,
          propertyCode: this.currentProperty.id,
          propertyName: this.currentProperty.propertyName,
          City: this.currentProperty.city,
          State: this.currentProperty.state,
          zipCode: this.currentProperty.zipCode.toString(),
          userMobile: this.currentOwner.phoneNumber
        }
      }
      emails.push(ownerEmail);
    }
    if((this.currentTenant.phoneNumber != this.currentUser.phoneNumber) || (this.currentTenant.phoneNumber == this.currentUser.phoneNumber))
    {
      // let tenantEmail: Email = {
      //   to: this.currentTenant.emailAddress,
      //   from: environment.emailUsers.infoEmail,
      //   fromName: environment.emailUsers.infoEmailName,
      //   subject: `'Rentofly - Added you as a Tenant on a Property'`,
      //   htmlBody: this.getEmailBody(TenantTemplate, 'Tenant'),
      //   cc: this.getCurrentUserEmail(this.currentTenant.emailAddress)
      // }
      let tenantEmail: EmailWhatsapp = {
        senderName: 'Team rentofly',
        receiverName: this.currentTenant.name,
        receiverEmail: this.currentTenant.emailAddress,
        phoneNumber: this.currentTenant.phoneNumber,
        type: "",
        template: "tenant_template",
        messageParameters: {
          subjectTextValue: "Rentofly - Added you as a Tenant on a Property",
          userName: this.currentTenant.name,
          propertyCode: this.currentProperty.id,
          propertyName: this.currentProperty.propertyName,
          City: this.currentProperty.city,
          State: this.currentProperty.state,
          zipCode: this.currentProperty.zipCode.toString(),
          userMobile: this.currentTenant.phoneNumber
        }
      }
      emails.push(tenantEmail);
    }
    if(this.currentBroker?.emailAddress && this.currentBroker.userId != this.currentUser.id){
      // let brokerEmail: Email = {
      //   to: this.currentBroker.emailAddress,
      //   from: environment.emailUsers.infoEmail,
      //   fromName: environment.emailUsers.infoEmailName,
      //   subject: `'Rentofly - Added you as a Broker on a Property'`,
      //   htmlBody: this.getEmailBody(BrokerTemplate, 'Broker'),
      //   cc: this.getCurrentUserEmail(this.currentBroker.emailAddress)
      // }
      let brokerEmail: EmailWhatsapp = {
        senderName: 'Team rentofly',
        receiverName: this.currentBroker.name,
        receiverEmail: this.currentBroker.emailAddress,
        phoneNumber: this.currentBroker.phoneNumber,
        type: "",
        template: "broker_template",
        messageParameters: {
          subjectTextValue: "Rentofly - Added you as a Broker on a Property",
          userName: this.currentBroker.name,
          propertyCode: this.currentProperty.id,
          propertyName: this.currentProperty.propertyName,
          City: this.currentProperty.city,
          State: this.currentProperty.state,
          zipCode: this.currentProperty.zipCode.toString(),
          userMobile: this.currentBroker.phoneNumber
        }
      }
      emails.push(brokerEmail);
    }
    return emails;
  }

  private getEmailBody(template: string, userRole: string)
  {
    
    if(!this.currentProperty.id){
      template = template.replace('<b>Property Code:</b> {Property Code}<br /><br /> ','');
    }
    if(userRole == 'Owner')
    {
      return template.replace('{UserName}', this.currentOwner.name)
      .replace('{Property Code}', this.currentProperty.id)
      .replace('{Property Name}', this.currentProperty.propertyName)
      .replace('{City}', this.currentProperty.city)
      .replace('{State}', this.currentProperty.state)
      .replace('{Zip Code}', this.currentProperty.zipCode.toString())
      .replace('{Website URl}', environment.url)
      .replace('{User Mobile}', this.currentOwner.phoneNumber)
    }
    if(userRole == 'Broker'){
      return template.replace('{UserName}', this.currentBroker.name)
      .replace('{Property Code}', this.currentProperty.id)
      .replace('{Property Name}', this.currentProperty.propertyName)
      .replace('{City}', this.currentProperty.city)
      .replace('{State}', this.currentProperty.state)
      .replace('{Zip Code}', this.currentProperty.zipCode.toString())
      .replace('{Website URl}', environment.url)
      .replace('{User Mobile}', this.currentBroker.phoneNumber)
    }
    if(userRole == 'Tenant'){
      return template.replace('{UserName}', this.currentTenant.name)
    .replace('{Property Code}', this.currentProperty.id)
    .replace('{Property Name}', this.currentProperty.propertyName)
    .replace('{City}', this.currentProperty.city)
    .replace('{State}', this.currentProperty.state)
    .replace('{Zip Code}', this.currentProperty.zipCode.toString())
    .replace('{Website URl}', environment.url)
    .replace('{User Mobile}', this.currentTenant.phoneNumber)
    }
    

  }

  private getCurrentUserEmail(emailAddress)
  {
    if(this.currentUser?.emailAddress !== emailAddress)
    {
      return [this.currentUser?.emailAddress]
    }
    return [];
  }
  public isfeatureEnabled() {
    return this.featureToggleService.isFeatureEnabled('uploadFeature');
  }
}
