import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";

@Injectable()
export class WindowService{

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
    ){}
        
    get windowRef(){
        if (isPlatformBrowser(this.platformId)) {
        return window;
        }
    }

    isMobileBrowser(): boolean {
        if (isPlatformBrowser(this.platformId)) {
        return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 1000 ) );
        }
    }

    isTabletBrowser(): boolean {
        if (isPlatformBrowser(this.platformId)) {
        return ( ( window.innerWidth <= 1125 ) && ( window.innerHeight <= 1125 ) );
        }
    }
}