import { createAction, props } from "@ngrx/store";
import { User } from "../../shared/models/user.model";

// export const queryUserByPhoneNumber = createAction(
//     '[Users] query user by PhoneNumber',
//     props<{phoneNumber: string}>()
// );

// export const addedUsers = createAction(
//     '[Users] added',
//     props<{user: User}>()
// );

// export const createUser = createAction(
//     '[Users] create User',
//     props<{user: User}>()
// );

// export const createUserSuccess = createAction(
//     '[Users] create User Success'
// );

// export const modifiedUsers = createAction(
//     '[Users] modified',
//     props<{user: User}>()
// );

// export const updateUsers = createAction(
//     '[Users] update',
//     props<{id:string, changes: Partial<User>}>()
// );

// export const updateUsersSuccess = createAction(
//     '[Users] update success'
// );

// export const removedUser = createAction(
//     '[Users] removed',
//     props<{user: User}>()
// );

// export const userError = createAction(
//     '[Users] user Error',
//     props<{error: any}>()
// );

// export const noUserResult = createAction(
//     '[Users] no User Result'
// );


///new
export const queryUserByPhoneNumber = createAction(
    '[Users] query user by PhoneNumber',
    props<{phoneNumber: string}>()
);

export const queryUserByPhoneNumberSuccess = createAction(
    '[Users] query user by Phone Number Success',
    props<{user: User}>()
);

export const queryUserByPhoneNumberFailure = createAction(
    '[Users] query user by Phone Number Failure',
    props<{error: any}>()
);

export const addUser = createAction(
    '[Users] add user',
    props<{user: User}>()
);

export const addUserSuccess = createAction(
    '[Users] query user by PhoneNumber Success',
    props<{phoneNumber: string}>()
);

export const addUserFailure = createAction(
    '[Users] query user by PhoneNumber Failure',
    props<{error: any}>()
);

export const updateUser = createAction(
    '[Users] update user',
    props<{id:string, changes: Partial<User>}>()
);

export const updateUserSuccess = createAction(
    '[Users] update user success'
);

export const updateUserFailure = createAction(
    '[Users] update user failure',
    props<{error:any}>()
);