import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from "./base.service";
import { BehaviorSubject } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
  })
  export class ReferralCodeService extends BaseService{
    private referralCodeUrl = this.baseReferralCodeUrl() + '/platform/benefits/';
    // public headerData = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Authorization': `Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjY3YmFiYWFiYTEwNWFkZDZiM2ZiYjlmZjNmZjVmZTNkY2E0Y2VkYTEiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vcmVudG9mbHktcHJvZCIsImF1ZCI6InJlbnRvZmx5LXByb2QiLCJhdXRoX3RpbWUiOjE2ODcwOTU1MzUsInVzZXJfaWQiOiJpVnFYZXZOQUI4Z000NE5hbjF5T2Y0c1lmaEYzIiwic3ViIjoiaVZxWGV2TkFCOGdNNDROYW4xeU9mNHNZZmhGMyIsImlhdCI6MTY4NzA5NTUzNSwiZXhwIjoxNjg3MDk5MTM1LCJwaG9uZV9udW1iZXIiOiIrOTEyMjIyMjIyMjIyIiwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJwaG9uZSI6WyIrOTEyMjIyMjIyMjIyIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGhvbmUifX0.kflUGfnNSk6IQLig5hvcJmLmYmhVpELg-5mGAXzC16LuhA4RYTUslvYagdbuQKaovDPWFaEhyrKt1GCpigD_Fq3KD2BtvwrBue5tuOroDyJaSpgVPSBx3lFV80mbkxAH-WPhu7yQKM_fKlYqV6zg_79LFfNohzkvmwtzakjiaLHlbjL5RZRpxMMWUscXi2jyqJX1qhmbodPptRvIk87GAl9RY-oG7FkaH0r18Il0KxJfa2tZVhgUhlzvOYyVVuXWYJYKjHyd6Qms_jqb1H1lDi-HLQi6Ssv7xk61qYtlvC5SP4T6P2aCKqlxODKnR8bH6lcoPkNv8t6dQcJmqC2xWw`
    // })

    checkAvailibility(userId): Observable<any>{
        const headers = this.getHeader();
        //const headers = this.headerData
        return this.getHttpClient().get(this.referralCodeUrl + `checkAvailibility/${userId}`, { headers: headers });
    }

    utiliseReferralCode(referralData): Observable<any>{
        const headers = this.getHeader();
        //const headers = this.headerData
        return this.getHttpClient().post(this.referralCodeUrl + `utilise`, referralData, { headers: headers })
    }
  }