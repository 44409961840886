import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CreateDashboardState } from "..";
import { addressAdapter, AddressState } from "../reducers";

export const getCreateDashboardState = createFeatureSelector<CreateDashboardState>('dashboard');
export const getAddressState = createSelector(
    getCreateDashboardState,
    (state: CreateDashboardState) => state.address
);

export const currentAddress$ = createSelector(getAddressState, (state: AddressState) => state.currentAddress);
export const currentAddressLoading$ = createSelector(getAddressState, (state: AddressState) => state.currentAddressLoading);