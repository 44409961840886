import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";

@Injectable()
export class LocalStorageService{

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
    ){

    }

    getFromLocalStorage(id: string): string{
        if (isPlatformBrowser(this.platformId)) {
            return localStorage.getItem(id);
        }
    }

    getObjectFromLocalStorage(id: string): any{
        if (isPlatformBrowser(this.platformId)) {
            return JSON.parse(localStorage.getItem(id));
        }
    }

    addToLocalStorage(id: string, data: string){
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem(id, data);
        }
    }

    addObjectToLocalStorage(id: string, data: any){
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem(id, JSON.stringify(data));
        }
    }

    removeFromLocalStorage(id: string){
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem(id);
        }
    }
}