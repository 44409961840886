import { 
  AfterViewInit, 
  Component, 
  ElementRef, 
  Inject, 
  OnInit, 
  PLATFORM_ID, 
  ViewChild 
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import 'firebase/auth';
import firebase from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { LocalStorageService, WindowService } from '../shared/services';
import { PhoneNumber, User } from '../shared/models';
import { environment } from 'src/environments/environment';
import { countries } from '../shared/data-store';
import { CountdownComponent } from 'ngx-countdown';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from "@angular/router";
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

  windowRef: any;
  phoneNumber = new PhoneNumber();
  verificationCode: string;
  user: any;

  public countries:any = countries;
  public loginForm: FormGroup;

  public showPhoneValidationMessage = false;
  public showOtpValidationMessage = false;
  public products = [];
  public features = [];
  public itemPerpage = 3;
  public itemScroll = 3;
  disableOtp = false;
  enableLogin = false;
  requestOtp = false;
  verifyLogin = false;
  linkClass = "send-otp";
  loadingLogin = false;
  public responsiveOptions = [];
  
  @ViewChild('loginCaptcha') public loginCaptcha: ElementRef;
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  @ViewChild('howItWorks') public howItWorks:ElementRef;

  get loginMobileNo() {
    return this.loginForm!.get('loginMobileNo');
  }

  get password() {
    return this.loginForm!.get('password');
  }
  
  constructor(
    private readonly firebaseAuth: AngularFireAuth,
    private readonly windowService: WindowService,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router,
    private _snackBar: MatSnackBar,
    @Inject(PLATFORM_ID) private platformId: any,
    private readonly route: ActivatedRoute,
  ) {

    


    this.loginForm = new FormGroup({
      loginMobileNo: new FormControl('',[
        Validators.required,
        Validators.min(1000000000)
      ]),
      password: new FormControl('',[
        Validators.required,
        Validators.max(999999)
      ])
    });
    this.products = [
      {"name": "Maharashtra", "link": "https://efilingigr.maharashtra.gov.in/ereg/"},
      {"name": "Delhi", "link": "https://doris.delhigovt.nic.in/"},
      {"name": "Karnataka", "link": "https://kaverionline.karnataka.gov.in/"},
      {"name": "Tamilnadu", "link": "https://www.tenancy.tn.gov.in/"},
      {"name": "Gujarat", "link": "https://garvi.gujarat.gov.in/"},
      {"name": "Telangana", "link": "https://registration.telangana.gov.in/"}
    ],
    this.features = [
      {src:"/assets/images/property_details.svg", heading:'Payment'},
      {src:"/assets/images/property_details.svg", heading:'Rent Receipt'},
      {src:"/assets/images/reminder.svg", heading:'Reminder'},
      {src:"/assets/images/dashboard2.svg", heading:'Document Management '},
      {src:"/assets/images/feedback_new.svg", heading:'Feedback'},
      {src:"/assets/images/termination.svg", heading:'Agreement Renewal and/or termination'}
    ]
    this.responsiveOptions = [
      {
          breakpoint: '1024px',
          numVisible: 3,
          numScroll: 3
      },
      {
          breakpoint: '768px',
          numVisible: 1,
          numScroll: 1
      },
      {
          breakpoint: '560px',
          numVisible: 1,
          numScroll: 1
      }
  ];
  }

  ngOnInit(): void {
    this.windowRef = this.windowService.windowRef;
  }

  ngAfterViewInit() {
    if (firebase.apps.length < 2) {
      firebase.initializeApp(environment.firebaseConfig);
    }
    try{
      this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(this.loginCaptcha.nativeElement, {
        'size':'invisible',
        'callback': (response) => {
          // this.linkClass = "send-otp-disabled";
        }
      })
    }
   catch(error){
    this.disableOtp = false;
   }
    
  }

  formRequest(event){
    if(event.keyCode === 13 && this.disableOtp){
      this.verifyOtp();
    } else if(event.keyCode === 13 && !this.disableOtp){
      this.sendOtpRequest();
    }
  }

  sendOtpRequest(resend = false){
    if(resend)
    {
      this.linkClass = "send-otp-disabled";
    }
    this.showPhoneValidationMessage = false;
    if(this.loginForm.controls.loginMobileNo.status == "INVALID")
    {
      this.showPhoneValidationMessage = true;
      return;
    }
    this.requestOtp = true;
    this.firebaseAuth.signInWithPhoneNumber('+91'+this.loginMobileNo.value.toString(), 
      this.windowRef.recaptchaVerifier)
      .then((confirmationResult) => {
        this.disableOtp = true;
        this.enableLogin = true;
        this.loginForm.controls.password.reset();
        this.loginForm.controls.password.setErrors(null)
      this.windowRef.confirmationResult = confirmationResult
    })
    .catch((error) => {
      console.log(error);
      this.disableOtp = false;
      this.requestOtp = false;
      this.loginForm.controls.password.reset();
      this.loginForm.controls.password.setErrors(null);
      this._snackBar.open(error, "X", {
          duration: 10000,
          panelClass: ['snackbar-failure']
      });
    })
  }

  verifyOtp(){
    if(!this.enableLogin)
    {
      this.loginForm.controls.password.setErrors({'incorrect': true});
    }
    if(this.loginForm.controls.password.status  == "INVALID")
    {
      this.showOtpValidationMessage = true;
      return;
    }
    this.verifyLogin = true;
    this.showOtpValidationMessage = false;
    this.loadingLogin = true;
    this.windowRef.confirmationResult.confirm(this.password.value.toString())
      .then((userCredentials) => {
        this.loadingLogin = false;
        this.localStorageService.addToLocalStorage('dashboard-user', JSON.stringify(userCredentials.user));
        this.router.navigate(['/']);
        
      })
      .catch((error) => {
        console.log(error);
        this.loadingLogin = false;
        this.linkClass = "send-otp";
        this.disableOtp = false;
        this.enableLogin = false;
        this.requestOtp = false;
        this.verifyLogin = false;
        this._snackBar.open(error, "X", {
            duration: 10000,
            panelClass: ['snackbar-failure']
        });
      });
  }
  public howItWorksStruc():void {
    this.howItWorks.nativeElement.scrollIntoView({ behavior: 'smooth', inline: 'start' });
  }

  public isCarouselEnabled(){
    if (isPlatformBrowser(this.platformId)) {
      return true;
    }
    return false;
  }
}
