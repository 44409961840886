import { Injectable } from "@angular/core";
import * as fromProperty from '../selectors/property.selector';
import * as propertyActions from "../actions/property.action";
import { Store } from "@ngrx/store";
import { PropertyState } from "../reducers";
import { AdditionalPropertyDetail, AvailablePropertyData, Property, PropertyListRequest, RentedPropertyData, TerminatedPropertyData, ViewProperty } from "src/app/shared/models";
import { Observable, of } from "rxjs";

export interface IPropertyStoreFacade {
    selectAllProperties$: Observable<Property[]>;
    isPropertyLoading$: Observable<boolean>;
    noPropertyResult$: Observable<boolean>;
    propertyEditing$: Observable<boolean>;
    
    getTotalProperties$: Observable<number>;
    pageSize$: Observable<number>;
    pageIndex$: Observable<number>;
    stage$: Observable<number>;
    propertyListRequest$: Observable<PropertyListRequest>;

    availablePropertyDataLoading$: Observable<boolean>;
    availablePropertyData$: Observable<AvailablePropertyData>;    

    rentedPropertyDataLoading$: Observable<boolean>;
    rentedPropertyData$: Observable<RentedPropertyData>;    

    terminatedPropertyDataLoading$: Observable<boolean>;
    terminatedPropertyData$: Observable<TerminatedPropertyData>;    

    //getCurrentProperty
    currentPropertyLoading$: Observable<boolean>;
    currentViewPropertyLoading$: Observable<boolean>;
    currentProperty$: Observable<Property>;
    currentViewProperty$: Observable<ViewProperty>;
    currentAdditionalPropertyDetails$: Observable<AdditionalPropertyDetail>;

    queryPaginatedProperty(userId: string): void;
    queryPaginatedAvailableProperty(userId: string): void;
    queryPaginatedRentedProperty(userId: string): void;
    queryPaginatedTerminatedProperty(userId: string): void;
    queryPaginatedPropertyUsingCreatedBy(createdBy: string): void;
    queryPropertyById(id: string): void;
    queryViewPropertyById(id: string): void;
    addProperty(property: Property): void;
    updateProperty(property: Property): void;
    setPageDetails(pageIndex: number, pageSize: number): void;
    setStage(stage: number): void;
    addPropertyDetails(propertyDetails: AdditionalPropertyDetail): void;
    updatePropertyDetails(propertyDetails: AdditionalPropertyDetail): void;
    queryPropertyDetailsByPropertyId(propertyId:string): void;
    initAdditionalDetails(): void;
    setPropertyListRequest(propertyListRequest: PropertyListRequest): void;
    queryAvailablePropertyData(userId:string): void;
    queryTerminatedPropertyData(userId:string): void;
    queryRentedPropertyData(userId:string): void;
    terminateProperty(propertyId: string): void;
}

@Injectable()
export class PropertyStoreFacade implements IPropertyStoreFacade {

    get selectAllProperties$() {
        return this.store.select(fromProperty.selectAll);
    }

    get isPropertyLoading$() {
        return this.store.select(fromProperty.isPropertyLoading$)
    }

    get noPropertyResult$() {
        return this.store.select(fromProperty.noPropertyResult$)
    }

    get propertyEditing$() {
        return this.store.select(fromProperty.propertyEditing$)
    }

    get getTotalProperties$() {
        return this.store.select(fromProperty.totalProperties$)
    }

    get pageSize$() {
        return this.store.select(fromProperty.pageSize$)
    }

    get pageIndex$() {
        return this.store.select(fromProperty.pageIndex$)
    }

    get propertyListRequest$() {
        return this.store.select(fromProperty.propertyListRequest$)
    }

    get stage$() {
        return this.store.select(fromProperty.stage$)
    }

    //getCurrentProperty
    get currentPropertyLoading$(){
        return this.store.select(fromProperty.currentPropertyLoading$);
    }

    get currentProperty$(){
        return this.store.select(fromProperty.currentProperty$);
    }

    get currentAdditionalPropertyDetails$(){
        return this.store.select(fromProperty.additionalDetails$);
    }

    get currentViewProperty$(){
        return this.store.select(fromProperty.currentViewProperty$);
    }

    get currentViewPropertyLoading$(){
        return this.store.select(fromProperty.currentViewPropertyLoading$);
    }

    get availablePropertyData$(){
        return this.store.select(fromProperty.availablePropertyData$);
    }

    get availablePropertyDataLoading$(){
        return this.store.select(fromProperty.availablePropertyDataLoading$);
    }

    get rentedPropertyData$(){
        return this.store.select(fromProperty.rentedPropertyData$);
    }

    get rentedPropertyDataLoading$(){
        return this.store.select(fromProperty.rentedPropertyDataLoading$);
    }

    get terminatedPropertyData$(){
        return this.store.select(fromProperty.terminatedPropertyData$);
    }

    get terminatedPropertyDataLoading$(){
        return this.store.select(fromProperty.terminatedPropertyDataLoading$);
    }

    constructor(
        private readonly store: Store<PropertyState>,
    ){}

    queryPaginatedProperty(userId: string) {
        this.store.dispatch(propertyActions.queryPaginatedProperty({userId}));
    }
    queryPaginatedAvailableProperty(userId: string) {
        this.store.dispatch(propertyActions.queryPaginatedAvailableProperty({userId}));
    }
    queryPaginatedRentedProperty(userId: string) {
        this.store.dispatch(propertyActions.queryPaginatedRentedProperty({userId}));
    }
    queryPaginatedTerminatedProperty(userId: string) {
        this.store.dispatch(propertyActions.queryPaginatedTerminatedProperty({userId}));
    }
    queryPaginatedPropertyUsingCreatedBy(createdBy: string) {
        this.store.dispatch(propertyActions.queryPaginatedPropertyUsingCreatedBy({createdBy}));
    }
    addProperty(property: Property) {
        this.store.dispatch(propertyActions.addProperty({property}));
    }
    updateProperty(property: Property) {
        this.store.dispatch(propertyActions.updateProperty({property}));
    }
    queryPropertyById(id:string){
        this.store.dispatch(propertyActions.queryPropertyById({id}));
    }
    queryViewPropertyById(id:string){
        this.store.dispatch(propertyActions.queryViewPropertyById({id}));
    }
    setPageDetails(pageIndex: number, pageSize: number): void{
        this.store.dispatch(propertyActions.setPageDetails({pageIndex, pageSize}));
    }
    setPropertyListRequest(propertyListRequest: PropertyListRequest): void{
        this.store.dispatch(propertyActions.setPropertyListRequest({propertyListRequest}));
    }
    setStage(stage: number): void{
        this.store.dispatch(propertyActions.setStage({stage}));
    }

    addPropertyDetails(additionalDetails: AdditionalPropertyDetail) {
        this.store.dispatch(propertyActions.addAdditionalDetails({additionalDetails}));
    }
    updatePropertyDetails(additionalDetails: AdditionalPropertyDetail) {
        this.store.dispatch(propertyActions.updateAdditionalDetails({additionalDetails}));
    }
    queryPropertyDetailsByPropertyId(propertyId:string){
        this.store.dispatch(propertyActions.queryAdditionalDetails({propertyId}));
    }
    initAdditionalDetails(): void {
        this.store.dispatch(propertyActions.initAdditionalDetails());
    }
    queryAvailablePropertyData(userId:string): void{
        this.store.dispatch(propertyActions.queryAvailablePropertyData({userId}));
    }
    queryRentedPropertyData(userId:string): void{
        this.store.dispatch(propertyActions.queryRentedPropertyData({userId}));
    }
    queryTerminatedPropertyData(userId:string): void{
        this.store.dispatch(propertyActions.queryTerminatedPropertyData({userId}));
    }
    terminateProperty(propertyId: string): void{
        this.store.dispatch(propertyActions.terminateProperty({propertyId}));
    }
}