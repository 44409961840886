import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { FileUploadModel, PartnerFileUploadModel } from "../models";
import { BaseService } from "./base.service";

@Injectable()
export class AttachmentsService extends BaseService{
    //private attachmentsQueryUrl = this.baseQueryUrl() + "/Attachments/";
    private attachmentsQueryUrl = this.baseApiUrl() + "/property/";
    private attachmentsCommandUrl = this.baseCommandUrl() + "/Attachments/";
    
    getAttachmentsByPropertyId(propertyId: string): Observable<any>{
        const url = `${this.attachmentsQueryUrl}${propertyId}`;
        const headers = this.getHeader();
        return this.getHttpClient().get<any[]>(url, { headers: headers })
    }

    getAttachmentsByPartnerId(partnerId: string): Observable<PartnerFileUploadModel[]>{
        const url = `${this.attachmentsQueryUrl}/partner/${partnerId}`;
        const headers = this.getHeader();
        return this.getHttpClient().get<FileUploadModel[]>(url, { headers: headers }).pipe(
            map((response: FileUploadModel[]) => response)
        );
    }

    createAttachment(attachment: FileUploadModel): Observable<any | string>{
        const url = this.attachmentsCommandUrl + "add";
        const headers = this.getHeader();
        return this.getHttpClient().post(url, attachment, {headers: headers}).pipe(
            map((response) => attachment.propertyId),
            catchError((error) => {
                console.log(error);
                return error;
            })
        );
    }

    createPartnerAttachment(attachment: PartnerFileUploadModel): Observable<any | string>{
        const url = this.attachmentsCommandUrl + "/Partner/add";
        const headers = this.getHeader();
        return this.getHttpClient().post(url, attachment, {headers: headers}).pipe(
            map((response) => attachment.partnerId),
            catchError((error) => {
                console.log(error);
                return error;
            })
        );
    }

    deleteAttachmentById(attachmentId: string): Observable<any | string>{
        const url = `${this.attachmentsCommandUrl}${attachmentId}`;
        const headers = this.getHeader();
        return this.getHttpClient().delete(url, {headers: headers}).pipe(
            map((response) => attachmentId),
            catchError((error) => {
                console.log(error);
                return error;
            })
        );
    }
}