export * from './profile/profile.component';
export * from './dashboard-home/dashboard-home.component';
export * from './rented-property/rented-property.component';
export * from './available-property/available-property.component';
export * from './service-property/service-property.component';
export * from './add-property/add-property.component';
export * from './view-property/view-property.component';
export * from './property-info/property-info.component';
export * from './stakeholder-info/stakeholder-info.component';
export * from './deal-info/deal-info.component';
export * from './draft-agreement/draft-agreement.component';
export * from './view-property-info/view-property-info.component';
export * from './property-attachments/property-attachments.component';
export * from './terminated-property/terminated-property.component';
export * from './payment/payment.component';
export * from './share-property/share-property.component';