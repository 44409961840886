import { createEntityAdapter, Dictionary, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import { AdditionalPropertyDetail, AvailablePropertyData, Property, PropertyListRequest, RentedPropertyData, TerminatedPropertyData, ViewProperty } from "src/app/shared/models";
import { addProperty, addPropertyFailure, addPropertySuccess, 
    queryPaginatedAvailableProperty, queryPaginatedAvailablePropertyFailure, 
    queryPaginatedAvailablePropertySuccess, queryPaginatedProperty, 
    queryPaginatedPropertyFailure, queryPaginatedPropertySuccess, 
    queryPaginatedPropertyUsingCreatedBy, queryPaginatedPropertyUsingCreatedByFailure, 
    queryPaginatedPropertyUsingCreatedBySuccess, queryPaginatedRentedProperty, 
    queryPaginatedRentedPropertyFailure, queryPaginatedRentedPropertySuccess, 
    queryPropertyById, queryPropertyByIdFailure, queryPropertyByIdSuccess, 
    queryViewPropertyById, queryViewPropertyByIdFailure, queryViewPropertyByIdSuccess, 
    setPageDetails, setStage, updateProperty, updatePropertyFailure, updatePropertySuccess, 
    queryPaginatedTerminatedProperty, queryPaginatedTerminatedPropertySuccess, 
    queryPaginatedTerminatedPropertyFailure, 
    addAdditionalDetails,
    addAdditionalDetailsFailure,
    addAdditionalDetailsSuccess,
    updateAdditionalDetails,
    updateAdditionalDetailsFailure,
    updateAdditionalDetailsSuccess,
    queryAdditionalDetails,
    queryAdditionalDetailsFailure,
    queryAdditionalDetailsSuccess,
    initAdditionalDetails,
    setPropertyListRequest,
    queryAvailablePropertyData,
    queryAvailablePropertyDataSuccess,
    queryAvailablePropertyDataFailure, 
    queryRentedPropertyData,
    queryRentedPropertyDataFailure,
    queryRentedPropertyDataSuccess,
    queryTerminatedPropertyData,
    queryTerminatedPropertyDataFailure,
    queryTerminatedPropertyDataSuccess,
    terminateProperty,
    terminatePropertySuccess,
    terminatePropertyFailure} from "../actions";

export function getPropertyReducer(state: PropertyState | undefined, action: Action) {
    return propertyReducer(state, action);
}

export const propertyAdapter = createEntityAdapter<Property>();

export interface PropertyState extends EntityState<Property> {
    entities: Dictionary<Property>;
    error: any;
    noPropertyResult: boolean;
    propertyLoading: boolean;
    currentProperty: Property;
    currentViewProperty: ViewProperty,
    currentPropertyLoading: boolean;
    currentViewPropertyLoading: boolean
    totalProperties: number;
    pageSize: number;
    pageIndex: number;
    stage: number;
    propertyEditing: boolean;
    additionalDetails: AdditionalPropertyDetail;
    additionalDetailsLoading: boolean;
    propertyListRequest: PropertyListRequest;
    availablePropertyData: AvailablePropertyData;
    availablePropertyDataLoading: boolean;
    rentedPropertyData: RentedPropertyData;
    rentedPropertyDataLoading: boolean;
    terminatedPropertyData: TerminatedPropertyData;
    terminatedPropertyDataLoading: boolean;
}

export const initialPropertyState: PropertyState = propertyAdapter.getInitialState({
    entities: [],
    error: null,
    noPropertyResult: false,
    propertyLoading: false,
    currentPropertyLoading: false,
    currentViewPropertyLoading: false,
    currentProperty: null,
    currentViewProperty: null,
    totalProperties: -1,
    pageSize: 10,
    pageIndex: 0,
    stage: 1,
    propertyEditing: false,
    additionalDetails: null,
    additionalDetailsLoading: false,
    propertyListRequest: {
        pageIndex: 0,
        pageSize: 10,
        orderBy: "0",
        orderByColumn: null
    },
    availablePropertyDataLoading: false,
    availablePropertyData: null,
    rentedPropertyDataLoading: false,
    rentedPropertyData: null,
    terminatedPropertyDataLoading: false,
    terminatedPropertyData: null
});

export const propertyReducer = createReducer(
    initialPropertyState,

    on(queryPaginatedProperty, (state) => {
        return  propertyAdapter.removeAll({
            ...state,
            propertyLoading: true
        });
    }),

    on(queryPaginatedPropertySuccess, (state, {paginatedProperties}) => {
        return  propertyAdapter.addMany(paginatedProperties.properties,{
                ...state,
                propertyLoading: false,
                totalProperties: paginatedProperties.totalProperties,
                noPropertyResult: paginatedProperties.totalProperties == 0
            }
        );
    }),

    on(queryPaginatedPropertyFailure, (state) => {
        return  {
            ...state,
            propertyLoading: false,
            noPropertyResult: true
        };
    }),

    //available
    on(queryPaginatedAvailableProperty, (state) => {
        return  propertyAdapter.removeAll({
            ...state,
            propertyLoading: true
        });
    }),

    on(queryPaginatedAvailablePropertySuccess, (state, {paginatedProperties}) => {
        return  propertyAdapter.addMany(paginatedProperties.properties,{
                ...state,
                propertyLoading: false,
                totalProperties: paginatedProperties.totalProperties,
                noPropertyResult: paginatedProperties.totalProperties == 0
            }
        );
    }),

    on(queryPaginatedAvailablePropertyFailure, (state) => {
        return  {
            ...state,
            propertyLoading: false,
            noPropertyResult: true
        };
    }),

    //rented
    on(queryPaginatedRentedProperty, (state) => {
        return  propertyAdapter.removeAll({
            ...state,
            propertyLoading: true
        });
    }),

    on(queryPaginatedRentedPropertySuccess, (state, {paginatedProperties}) => {
        return  propertyAdapter.addMany(paginatedProperties.properties,{
                ...state,
                propertyLoading: false,
                totalProperties: paginatedProperties.totalProperties,
                noPropertyResult: paginatedProperties.totalProperties == 0
            }
        );
    }),

    on(queryPaginatedRentedPropertyFailure, (state) => {
        return  {
            ...state,
            propertyLoading: false,
            noPropertyResult: true
        };
    }),

     //terminated
     on(queryPaginatedTerminatedProperty, (state) => {
        return  propertyAdapter.removeAll({
            ...state,
            propertyLoading: true
        });
    }),

    on(queryPaginatedTerminatedPropertySuccess, (state, {paginatedProperties}) => {
        return  propertyAdapter.addMany(paginatedProperties.properties,{
                ...state,
                propertyLoading: false,
                totalProperties: paginatedProperties.totalProperties,
                noPropertyResult: paginatedProperties.totalProperties == 0
            }
        );
    }),

    on(queryPaginatedTerminatedPropertyFailure, (state) => {
        return  {
            ...state,
            propertyLoading: false,
            noPropertyResult: true
        };
    }),

    //queryPaginatedPropertyUsingCreatedBy
    on(queryPaginatedPropertyUsingCreatedBy, (state,{createdBy}) => {
        return  propertyAdapter.removeAll({
            ...state,
            propertyLoading: true
        });
    }),

    on(queryPaginatedPropertyUsingCreatedBySuccess, (state, {paginatedProperties}) => {
        return  propertyAdapter.addMany(paginatedProperties.properties,{
                ...state,
                propertyLoading: false,
                totalProperties: paginatedProperties.totalProperties,
                noPropertyResult: paginatedProperties.totalProperties == 0
            }
        );
    }),

    on(queryPaginatedPropertyUsingCreatedByFailure, (state) => {
        return  {
            ...state,
            propertyLoading: false,
            noPropertyResult: true
        };
    }),

    //getcurrentProperty
    on(queryPropertyById, (state, {id}) => {
        return  {
            ...state,
            currentPropertyLoading: true,
            currentProperty: null
        };
    }),

    on(queryPropertyByIdSuccess, (state, {property}) => {
        return  {
            ...state,
            currentPropertyLoading: false,
            currentProperty: property
        };
    }),

    on(queryPropertyByIdFailure, (state, {error}) => {
        return  {
            ...state,
            currentPropertyLoading: false,
            currentProperty: null,
            error
        };
    }),

    //getcurrentViewProperty
    on(queryViewPropertyById, (state, {id}) => {
        return  {
            ...state,
            currentViewPropertyLoading: true,
            currentViewProperty: null
        };
    }),

    on(queryViewPropertyByIdSuccess, (state, {property}) => {
        return  {
            ...state,
            currentViewPropertyLoading: false,
            currentViewProperty: property
        };
    }),

    on(queryViewPropertyByIdFailure, (state, {error}) => {
        return  {
            ...state,
            currentViewPropertyLoading: false,
            currentViewProperty: null,
            error
        };
    }),

    //addProperty
    on(addPropertyFailure, (state, {error}) => {
        return {
            ...state,
            error,
            propertyEditing: false
        }
    }),

    on(addProperty, (state, {property}) => {
        return {
            ...state,
            currentProperty: {
                ...property
            },
            propertyEditing: true
        }
    }),

    on(addPropertySuccess, (state, {id}) => {
        return {
            ...state,
            propertyEditing: false
        }
    }),

    //updateProperty
    on(updateProperty, (state, {property}) => {
        return {
            ...state,
            currentProperty: {
                ...property,
                stakeholderType: property.stakeholderType ?? state.currentProperty.stakeholderType,
                createdBy: state.currentProperty.createdBy
            },
            propertyEditing: true
        }
    }),

    on(updatePropertySuccess, (state, {id}) => {
        return {
            ...state,
            propertyEditing: false
        }
    }),

    on(updatePropertyFailure, (state, {error}) => {
        return {
            ...state,
            error,
            propertyEditing: false
        }
    }),

    //terminateProperty
    on(terminateProperty, (state, {propertyId}) => {
        return {
            ...state,
            propertyEditing: true
        }
    }),

    on(terminatePropertySuccess, (state, {propertyId}) => {
        return {
            ...state,
            propertyEditing: false
        }
    }),

    on(terminatePropertyFailure, (state, {error}) => {
        return {
            ...state,
            error,
            propertyEditing: false
        }
    }),

    //pageDetails
    on(setPageDetails, (state, {pageIndex, pageSize}) => {
        return {
            ...state,
            pageIndex,
            pageSize
        }
    }),

    //propertyListRequest
    on(setPropertyListRequest, (state, {propertyListRequest}) => {
        return {
            ...state,
            propertyListRequest
        }
    }),

    //stage
    on(setStage, (state, {stage}) => {
        return {
            ...state,
            stage
        }
    }),

    //addAdditionalDetails
    on(addAdditionalDetailsFailure, (state, {error}) => {
        return {
            ...state,
            error,
            additionalDetailsLoading: false
        }
    }),

    on(addAdditionalDetails, (state, {additionalDetails}) => {
        return {
            ...state,
            additionalDetails: {
                ...additionalDetails
            },
            additionalDetailsLoading: true
        }
    }),

    on(addAdditionalDetailsSuccess, (state, {id}) => {
        return {
            ...state,
            additionalDetailsLoading: false
        }
    }),

    //updateAdditionalDetails
    on(updateAdditionalDetails, (state, {additionalDetails}) => {
        return {
            ...state,
            additionalDetails: {
                ...additionalDetails
            },
            additionalDetailsLoading: true
        }
    }),

    on(updateAdditionalDetailsSuccess, (state, {id}) => {
        return {
            ...state,
            additionalDetailsLoading: false
        }
    }),

    on(updateAdditionalDetailsFailure, (state, {error}) => {
        return {
            ...state,
            error,
            additionalDetailsLoading: false
        }
    }),

    //getAdditionalDetails
    on(queryAdditionalDetails, (state, {propertyId}) => {
        return {
            ...state,
            additionalDetailsLoading: true
        }
    }),

    on(queryAdditionalDetailsSuccess, (state, {additionalDetails}) => {
        return {
            ...state,
            additionalDetails: additionalDetails,
            additionalDetailsLoading: false
        }
    }),

    on(queryAdditionalDetailsFailure, (state, {error}) => {
        return {
            ...state,
            error,
            additionalDetailsLoading: false
        }
    }),

    on(initAdditionalDetails, (state) => {
        return  {
            ...state,
            additionalDetails: null,
            additionalDetailsLoading: false
        };
    }),

    //queryAvailablePropertyData
    on(queryAvailablePropertyData, (state, {userId}) => {
        return {
            ...state,
            additionalDetailsLoading: true
        }
    }),

    on(queryAvailablePropertyDataSuccess, (state, {availablePropertyData}) => {
        return {
            ...state,
            availablePropertyData: availablePropertyData,
            availablePropertyDataLoading: false
        }
    }),

    on(queryAvailablePropertyDataFailure, (state, {error}) => {
        return {
            ...state,
            error,
            availablePropertyDataLoading: false
        }
    }),

    //queryRentedPropertyData
    on(queryRentedPropertyData, (state, {userId}) => {
        return {
            ...state,
            rentedPropertyDataLoading: true
        }
    }),

    on(queryRentedPropertyDataSuccess, (state, {rentedPropertyData}) => {
        return {
            ...state,
            rentedPropertyData: rentedPropertyData,
            rentedPropertyDataLoading: false
        }
    }),

    on(queryRentedPropertyDataFailure, (state, {error}) => {
        return {
            ...state,
            error,
            rentedPropertyDataLoading: false
        }
    }),

    //queryRentedPropertyData
    on(queryTerminatedPropertyData, (state, {userId}) => {
        return {
            ...state,
            terminatedPropertyDataLoading: true
        }
    }),

    on(queryTerminatedPropertyDataSuccess, (state, {terminatedPropertyData}) => {
        return {
            ...state,
            terminatedPropertyData: terminatedPropertyData,
            terminatedPropertyDataLoading: false
        }
    }),

    on(queryTerminatedPropertyDataFailure, (state, {error}) => {
        return {
            ...state,
            error,
            terminatedPropertyDataLoading: false
        }
    }),
)