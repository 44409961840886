import { createEntityAdapter, Dictionary, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import { User } from "../../shared/models";
import { 
    addUser,
    queryUserByPhoneNumber, 
    queryUserByPhoneNumberFailure, 
    queryUserByPhoneNumberSuccess,
    updateUser,
    updateUserFailure,
    updateUserSuccess
} from "../actions";

export function getUsersReducer(state: UserState | undefined, action: Action) {
    return usersReducer(state, action);
}

export const userAdapter = createEntityAdapter<User>();

export interface UserState extends EntityState<User> {
    entities: Dictionary<User>;
    error: any;
    noUserResult: boolean;
    userLoading: boolean;
    currentUser: User;
    editingUser: boolean;
}

export const initialUserState: UserState = userAdapter.getInitialState({
    entities: [],
    error: null,
    noUserResult: false,
    userLoading: false,
    currentUser: null,
    editingUser: false
});

export const usersReducer = createReducer(
    initialUserState,

    // on(queryUserByPhoneNumber, (state, {phoneNumber}) => {
    //     return  {
    //         ...state,
    //         userLoading: true
    //     };
    // }),

    // on(queryUserByPhoneNumberSuccess, (state, {user}) => {
    //     return  {
    //         ...state,
    //         userLoading: false,
    //         currentUser: user
    //     };
    // }),

    // on(addedUsers, (state, { user }) => {
    //     return userAdapter.addOne(user, {
    //         ...state,
    //         userLoading: false
    //     });
    // }),

    // on(modifiedUsers, (state, { user }) => {
    //     return userAdapter.updateOne({
    //         id: user.id,
    //         changes: user
    //     }, {
    //         ...state,
    //         userLoading: false
    //     })
    // }),

    // on(removedUser, (state, { user }) => {
    //     return userAdapter.removeOne(user.id, state);
    // }),

    // on(userError, (state, { error }) => {
    //     return  {
    //         ...state,
    //         error: 'There was something wrong. Please refresh the page and try again.'
    //     };
    // }),

    // on(noUserResult, (state) => {
    //     return {
    //         ...state,
    //         noUserResult: true
    //     }
    // }),

    // on(updateUserLambda, (state, { id, changes }) => {
    //     return {
    //         ...state,
    //         user: {
    //             ...changes
    //         }
    //     }
    // }),
    
    // on(updateUsers, (state, { id, changes }) => {
    //     return {
    //         ...state,
    //         error: null,
    //         noResult: false
    //     }
    // }),

    on(queryUserByPhoneNumber, (state, {phoneNumber}) => {
        return {
            ...state,
            userLoading: true
        }
    }),

    on(queryUserByPhoneNumberSuccess, (state, {user}) => {
        return {
            ...state,
            currentUser: user,
            noUserResult: false,
            userLoading: false
        }
    }),

    on(queryUserByPhoneNumberFailure, (state, {error}) => {
        return {
            ...state,
            error,
            userLoading: false,
            noUserResult: true
        }
    }),

    on(updateUser, (state) => {
        return {
            ...state,
            editingUser: true
        }
    }),

    on(updateUserSuccess, (state) => {
        return {
            ...state,
            editingUser: false
        }
    }),

    on(updateUserFailure, (state) => {
        return {
            ...state,
            editingUser: false
        }
    }),
);