import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CreateDashboardState } from "..";
import { ownerAdapter, OwnerState } from "../reducers";

export const getCreateDashboardState = createFeatureSelector<CreateDashboardState>('dashboard');
export const getOwnerState = createSelector(
    getCreateDashboardState,
    (state: CreateDashboardState) => state.owner
);

export const currentOwner$ = createSelector(getOwnerState, (state: OwnerState) => state.currentOwner);
export const currentOwnerLoading$ = createSelector(getOwnerState, (state: OwnerState) => state.currentOwnerLoading);
export const currentUserForOwner$ = createSelector(getOwnerState, (state: OwnerState) => state.currentUserForOwner);
export const currentUserForOwnerLoading$ = createSelector(getOwnerState, (state: OwnerState) => state.currentUserForOwnerLoading);
export const editingOwner$ = createSelector(getOwnerState, (state: OwnerState) => state.editingOwner);
export const ownerFinances$ = createSelector(getOwnerState, (state: OwnerState) => state.ownerFinances);

