import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AvailablePropertyComponent, RentedPropertyComponent, ProfileComponent, DashboardHomeComponent, ServicePropertyComponent, AddPropertyComponent, ViewPropertyComponent, ViewPropertyInfoComponent, 
  TerminatedPropertyComponent } from './dashboard/components';
import { LoginComponent } from './login/login.component';
import { AdminAuthGuard, AdminLoginAuthGuard, AuthGuard, LoginAuthGuard } from './shared/guards';
import { FaqComponent } from './faq/faq.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { DashboardDetailComponent } from './dashboard/components/dashboard-detail/dashboard-detail.component';
import { HomeLoginComponent } from './home-login/home-login.component';
import { AddPartnerComponent } from './add-partner/add-partner.component';
import { AdminDashboardComponent, AdminLoginComponent, AdminPartnerViewComponent, ApprovedPartnerDashboardComponent, PendingPartnerDashboardComponent } from './admin';
import { PartnerComponent } from './partner-dashboard';
import { NewHomePageComponent } from './new-home-page/new-home-page.component';
import { ServicesInfoComponent } from './dashboard/components/services-info/services-info.component';
import { ReferralCodeComponent } from './dashboard/components/referral-code/referral-code.component';

const routes: Routes = [
  
  {
    path: '', pathMatch: 'full', redirectTo: 'dashboard'
  },
  { 
    path: 'dashboard', component: DashboardHomeComponent, 
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
        canActivate: [AuthGuard]
      },
      {
        path: 'dashboard',
        pathMatch: 'full',
        component: DashboardDetailComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'add-property',
        component: AddPropertyComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'services-info',
        component: ServicesInfoComponent,
        canActivate: [AuthGuard]
      },
      // {
      //   path: 'referral',
      //   component: ReferralCodeComponent,
      //   canActivate: [AuthGuard]
      // },
      {
        path:'view-property-info',
        component: ViewPropertyInfoComponent,
        canActivate: [AuthGuard],
        pathMatch: 'full'
      },
      {
        path: 'view-property',
        component: ViewPropertyComponent,
        children: [
          {
            path: '',
            redirectTo: 'available-property',
            pathMatch: 'full',
            canActivate: [AuthGuard]
          },
          {
            path: 'available-property',
            component: AvailablePropertyComponent,
            pathMatch: 'full',
            canActivate: [AuthGuard]
          },
          {
            path: 'rented-property',
            component: RentedPropertyComponent,
            pathMatch: 'full',
            canActivate: [AuthGuard]
          },
          {
            path: 'terminated-property',
            component: TerminatedPropertyComponent,
            pathMatch: 'full',
            canActivate: [AuthGuard]
          },
          {
            path: 'service',
            component: ServicePropertyComponent,
            pathMatch: 'full',
            canActivate: [AuthGuard]
          },
          {
            path: ':id',
            component: ViewPropertyInfoComponent,
            pathMatch: 'full',
            canActivate: [AuthGuard]
          },
        ],
        canActivate: [AuthGuard]
      },
      {
        path: 'service-property',
        component: ServicePropertyComponent
      }
    ],
    canActivate: [AuthGuard]
  },
  {path: 'referral', component: ReferralCodeComponent, pathMatch: 'full', canActivate: [AuthGuard]},
  { 
    path: 'profile', component: ProfileComponent, pathMatch: 'full', canActivate: [AuthGuard]
  },
  { path: 'login', component: NewHomePageComponent, pathMatch: 'full', canActivate: [LoginAuthGuard]},
  { path: 'about-us', component: AboutUsComponent, pathMatch: 'full' },
  { path: 'contact-us', component: ContactUsComponent, pathMatch: 'full' },
  { path: 'add-partner', component: AddPartnerComponent, pathMatch: 'full'},
  { path: 'faq', component: FaqComponent, pathMatch: 'full' },
  { path: 'privacy-policy', component: PrivacyPolicyComponent, pathMatch: 'full' },
  { path: 'terms-of-service', component: TermsOfServiceComponent, pathMatch: 'full' },
  { path: 'partner', component: PartnerComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  {
    path: 'admin-partner-view', component: AdminPartnerViewComponent, pathMatch: 'full', canActivate: [AdminAuthGuard]
  },
  {
    path: 'admin-approved-partner-dashboard', component: ApprovedPartnerDashboardComponent, pathMatch: 'full', canActivate: [AdminAuthGuard]
  },
  {
    path: 'admin-pending-partner-dashboard', component: PendingPartnerDashboardComponent, pathMatch: 'full', canActivate: [AdminAuthGuard]
  },
  {
    path: 'admin', component: AdminLoginComponent, pathMatch: 'full', canActivate: [AdminLoginAuthGuard]
  },
  {
    path: 'admin-dashboard', component: AdminDashboardComponent, pathMatch: 'full', canActivate: [AdminAuthGuard]
  },
  { path: '**', redirectTo: 'login' , pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
