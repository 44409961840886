<head>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="description" content="Manage your rental property with ease using our AI-powered platform. Get personalized recommendations, flexible rent payment options, automated reminders, and centralized document management. Trust and transparency are at the core of our platform, making each rental transaction secure and hassle-free. Simplify your rental journey today.">
    <meta name="keywords" content="Rental finance management, Property management software, Tenant management system, Rent payment processing, Property listing management, Lease agreement management, Online rent payment, Rental property accounting, Tenant screening, Rent collection automation, Maintenance request management, Property owner dashboard, Real estate software, Online property management, Property management platform, Rental management app, Property management tool, Automated rent collection, Tenant communication platform">
    <meta name="robots" content="index, follow">
</head>
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-Y3D2LBP9KV"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-Y3D2LBP9KV');
</script>
<!-- Google tag (gtag.js) -->
<div class="row align-items-center" *ngIf="showPropertyType">
    <div class="col-md-12">
       <div class="page-header-title">
           <h5 class="headeText">Property</h5>
           <div class="heading-line"></div>
       </div>
   </div>
</div>
<p-card class="add-property-card">
<div class="row dashboard-nav" *ngIf="showPropertyType">
    <div class="col-sm-2" routerLink="available-property" [ngClass]="[availableTabStyle]" (click)='changeStyle("available")'>
        Available
    </div>
    <div class="col-xs-1">&nbsp;</div>
    <div class="col-sm-2" routerLink="rented-property" [ngClass]="[rentedTabStyle]" (click)='changeStyle("rented")'>
        Rented
    </div>
    <div class="col-xs-1">&nbsp;</div>
    <div class="col-sm-2" routerLink="terminated-property" [ngClass]="[terminatedTabStyle]" (click)='changeStyle("terminated")'>
        Terminated
    </div>
</div>

<router-outlet></router-outlet>
</p-card>