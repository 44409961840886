import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Deal, Property, ViewProperty } from 'src/app/shared/models';
import { DealStoreFacade } from 'src/app/store/facades/deal.store-facade';
import { PropertyStoreFacade } from 'src/app/store/facades/property.store-facade';

@Component({
  selector: 'rentofly-draft-agreement',
  templateUrl: './draft-agreement.component.html',
  styleUrls: ['./draft-agreement.component.scss']
})
export class DraftAgreementComponent implements OnInit {

  @Input()
  currentProperty: Property;
  
  @Output()
  initiateDeal: EventEmitter<any> = new EventEmitter();

  @Output()
  back: EventEmitter<any> = new EventEmitter();

  @Output()
  exit: EventEmitter<any> = new EventEmitter();

  @Output()
  saveAndExit: EventEmitter<any> = new EventEmitter();
  
  draftAgreementForm: FormGroup;
  showDraftAgreementValidationMessage = false;
  propertyId: string;
  currentViewPropertyLoading$: Observable<boolean>;
  currentViewProperty: ViewProperty;
  currentDeal: Deal;
  currentDealLoading$: Observable<boolean>;
  
  constructor(
    private readonly propertyFacade: PropertyStoreFacade,
    private readonly route: ActivatedRoute,
    private readonly dealFacade: DealStoreFacade,
  ) { 
    this.draftAgreementForm = new FormGroup({
      specialCondition: new FormControl( this.currentDeal?.specialCondition ?? '', [])
    });
  }

  get specialCondition() {
    return this.draftAgreementForm!.get('specialCondition');
  }

  ngOnInit(): void {
    this.propertyId = this.currentProperty.id;
    this.currentViewPropertyLoading$ = this.propertyFacade.currentViewPropertyLoading$;
    this.propertyFacade.queryViewPropertyById(this.propertyId);
    this.propertyFacade.currentViewProperty$.subscribe((data) => {
      this.currentViewProperty = data;
    });
    this.currentDealLoading$ = this.dealFacade.currentDealLoading$;
    if(this.currentProperty.dealId)
    {
      this.dealFacade.queryDealById(this.currentProperty.dealId);
    }
    this.dealFacade.currentDeal$.subscribe((dealData) => { 
      this.currentDeal = dealData;
    })
  }

  onInitiateDeal(draftAgreementFormValue): void{
    this.save(draftAgreementFormValue, 'close');
    this.initiateDeal.emit(1);
  }

  onBack(): void{
    this.back.emit(1);
  }

  onExit(): void {
    this.exit.emit(1);
  }

  onSaveAndExit(draftAgreementFormValue): void{
    this.save(draftAgreementFormValue, 'save');
    this.saveAndExit.emit(1);
  }

  save(draftAgreementFormValue, dealStatus){
    var updateDeal: Deal = {
      id: this.currentDeal.id,
      startDate: this.currentDeal.startDate,
      endDate: this.currentDeal.endDate,
      rentPerMonth: this.currentDeal.rentPerMonth,
      dealStatus: dealStatus == 'close' ? 'Completed' : this.currentDeal.dealStatus,
      deposit: this.currentDeal.deposit,
      brokerage: this.currentDeal.brokerage,
      noticePeriod: this.currentDeal.noticePeriod,
      rentPaymentDay: this.currentDeal.rentPaymentDay
      // specialCondition: draftAgreementFormValue.specialCondition
    }
    this.dealFacade.updateDeal(this.currentProperty.id, updateDeal,false, []);
  }

}
