<head>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="description" content="Manage your rental property with ease using our AI-powered platform. Get personalized recommendations, flexible rent payment options, automated reminders, and centralized document management. Trust and transparency are at the core of our platform, making each rental transaction secure and hassle-free. Simplify your rental journey today.">
    <meta name="keywords" content="Rental finance management, Property management software, Tenant management system, Rent payment processing, Property listing management, Lease agreement management, Online rent payment, Rental property accounting, Tenant screening, Rent collection automation, Maintenance request management, Property owner dashboard, Real estate software, Online property management, Property management platform, Rental management app, Property management tool, Automated rent collection, Tenant communication platform">
    <meta name="robots" content="index, follow">
</head>
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-Y3D2LBP9KV"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-Y3D2LBP9KV');
</script>
<!-- Google tag (gtag.js) -->
<!-- <div class="container-fluid main-div-class">
    
    <section class="howItWorkSection">
        <div class="loginMainDiv">
            <div class="loginSec-1">
                <div class="logoSection">
                    <img src="/assets/images/RF_FL.png" class="home-Img">
                </div>
                <h4 class="title-desc-2">One stop solution for all your property renting need.</h4>
            </div>
            <div class="loginSec-2">
                <div class="user-container">
                        <div class="login-container">
                            <div class="login-header">
                                Be a member of <span class="span-underline">elite</span> & <span class="span-underline">trustworthy</span> property rental ecosystem.
                            </div>
                            <div class="login-subTitle">
                                Please Enter Mobile Number to Login
                            </div>
                            <form [formGroup]="loginForm" (keyup)="formRequest($event)" class="login-form">
                                <div class="form-group mobile-number-group">
                                    <span class="prefix">+91</span>
                                    <input type="number" value="" id="loginMobileNo" formControlName="loginMobileNo" class="form-control mobile-number-input" placeholder="Mobile #" onKeyPress="if(this.value.length==10) return false;" OnlyNumber="true" required>
                                    <div *ngIf="loginMobileNo!.invalid && showPhoneValidationMessage" class="rentofly-login-validation-msg ">
                                        <div>
                                            Mobile Number is invalid.
                                        </div>
                                    </div>
                                    <div class="login-button-container" *ngIf="!disableOtp">
                                        <br />
                                        <button class="rentofly-primary login-button" (click)="sendOtpRequest()"><mat-spinner *ngIf="requestOtp" [diameter]="30"></mat-spinner>{{ requestOtp ? '' : 'Proceed to login' }}</button>
                                    </div>
                                    <a [ngClass]="linkClass" (click)="sendOtpRequest(true)" *ngIf="disableOtp">Resend OTP</a>
                                </div>
                                <div #loginCaptcha style="transform: scale(0.77); -webkit-transform: scale(0.77); transform-origin: 0 0; -webkit-transform-origin: 0 0;" data-theme="light" class="repcaptcha-container" ng-hide="true"></div>
                                <div class="form-group" *ngIf="disableOtp">
                                    <input type="text" value="" id="password" formControlName="password" class="form-control" placeholder="OTP" onKeyPress="if(this.value.length==6) return false;" OnlyNumber="true" required>
                                    <div *ngIf="password!.invalid && showOtpValidationMessage" class="rentofly-login-validation-msg ">
                                        <div>
                                            OTP is invalid. Please request again.
                                        </div>
                                    </div>
                                </div>
                                <div class="login-button-container" *ngIf="disableOtp">
                                    <button class="rentofly-primary login-button" (click)="verifyOtp()">Log In</button>
                                </div>
                            </form>
                        </div>
                </div>
            </div>
        </div>
    </section>
    <section class="howItWorkSectionOdd">
        <div class="howItWorksSubSection">
            <div class="sectionContent">
                <img src="/assets/images/property_detail.svg" class="sectionImage">
                <div class="sectionText">
                    Enter Property Details
                </div>
            </div>
        </div>
    </section>
    <section class="howItWorkSectionOdd">
        <div class="howItWorksSubSection">
            <div class="sectionContent">
                <div class="sectionText">
                    Manage Rental Properties
                </div>
                <img src="/assets/images/rental_property.svg" class="sectionImage">
            </div>
        </div>
    </section>
    <section class="howItWorkSectionOdd">
        <div class="howItWorksSubSection">
            <div class="sectionContent">
                <img src="/assets/images/rental_deal.svg" class="sectionImage">
                <div class="sectionText">
                    Approve Rental Deal
                </div>
            </div>
        </div>
    </section>
    <section class="howItWorkSectionOdd">
        <div class="howItWorksSubSection">
            <div class="sectionContent">
                <div class="sectionText">
                    Sign Agreements – Close Deal
                </div>
                <img src="/assets/images/rental_deal.svg" class="sectionImage">
            </div>
        </div>
    </section>
    <section class="howItWorkSectionOdd">
        <div class="howItWorksSubSection">
            <div class="sectionContent">
                <img src="/assets/images/upload.svg" class="sectionImage">
                <div class="sectionText">
                    Upload
                </div>
            </div>
        </div>
    </section>
    <section class="howItWorkSectionOdd">
        <div class="howItWorksSubSection">
            <div class="sectionContent">
                <div class="sectionText">
                    Feedback
                </div>
                <img src="/assets/images/feedback.svg" class="sectionImage">
            </div>
        </div>
    </section>
    <section class="howItWorkSectionOdd">
        <div class="comingSoonHeadSection">
            <h2 class="comingSoonHeader">Coming Soon</h2>
            <div class="heading-line"></div>
        </div>
        <div class="comingSoonSection">
            <div class="comingSoonSubDiv">
                <div>
                    <h1>Dashboard</h1>
                </div>
                <img src="/assets/images/dashboard.svg" class="comingSoonImg">
            </div>
            <div class="comingSoonSubDiv">
                <div>
                    <h1>Payment</h1>
                </div>
                <img src="/assets/images/payment.svg" class="comingSoonImg">
            </div>
        </div>
    </section>
    
    <div class="row section-three">
        <div class="container">
            <div class="row imp-links-div">
                <div class="col-md-12">
                    <h2 class="important-links">Important links</h2>
                    <div class="heading-line"></div>
                </div>
                <div class="col-md-12" *ngIf="isCarouselEnabled()">
                    <p-carousel [value]="products" [numVisible]="3" [numScroll]="1" [circular]="false" [responsiveOptions]="responsiveOptions" [autoplayInterval]="3000">
                        <ng-template let-product pTemplate="item">
                            <div class="product-item">
                                <a href="{{product.link}}" class="imp-link-anchor" target="_blank">
                                    <div class="product-item-content">
                                        <p-card class="carusol-card">
                                            <h4 class="headerText">{{product.name}}</h4>
                                        </p-card>
                                    </div>
                                </a>
                            </div>
                        </ng-template>
                    </p-carousel>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <rentofly-footer-links></rentofly-footer-links> -->
<div class="container">
    <!-- <div class="row section-one">
        <div class="container">
            <div class="row">
                <div class="col-md-6 page-bg-image">
                </div>
                <div class="col-md-6">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 title-div">
                                <h1 class="rentofly-title">Rento<span class="secondary-color">fly</span></h1>
                                <br />
                                <h4 class="title-sub-text">Tenant > Payment > Ranking</h4>
                                <h4 class="title-desc">One stop solution for all your renting need.</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">&nbsp;</div>
                            <div class="col-md-10">
                                <div class="user-container">
                                    <div class="login-container">
                                        <div class="login-title">Secure Account Log In
                                            <mat-icon class="lock-icon" aria-hidden="false" aria-label="lock">lock</mat-icon>
                                        </div>
                                        <form [formGroup]="loginForm" (keyup)="formRequest($event)" class="login-form">
                                            <div class="form-group mobile-number-group">
                                                <span class="prefix">+91</span>
                                                <input type="number" value="" id="loginMobileNo" formControlName="loginMobileNo" class="form-control mobile-number-input" placeholder="Mobile #" onKeyPress="if(this.value.length==10) return false;" OnlyNumber="true" required>
                                                <div *ngIf="loginMobileNo!.invalid && showPhoneValidationMessage" class="rentofly-login-validation-msg ">
                                                    <div>
                                                        Mobile Number is invalid.
                                                    </div>
                                                </div>
                                                <div class="login-button-container" *ngIf="!disableOtp">
                                                    <br />
                                                    <button class="rentofly-primary login-button" (click)="sendOtpRequest()"><mat-spinner *ngIf="requestOtp" [diameter]="30"></mat-spinner>{{ requestOtp ? '' : 'Request OTP' }}</button>
                                                </div>
                                                <a [ngClass]="linkClass" (click)="sendOtpRequest(true)" *ngIf="disableOtp">Resend OTP</a>
                                            </div>
                                            <div #loginCaptcha style="transform: scale(0.77); -webkit-transform: scale(0.77); transform-origin: 0 0; -webkit-transform-origin: 0 0;" data-theme="light" class="repcaptcha-container" ng-hide="true"></div>
                                            <div class="form-group" *ngIf="disableOtp">
                                                <input type="text" value="" id="password" formControlName="password" class="form-control" placeholder="OTP" onKeyPress="if(this.value.length==6) return false;" OnlyNumber="true" required>
                                                <div *ngIf="password!.invalid && showOtpValidationMessage" class="rentofly-login-validation-msg ">
                                                    <div>
                                                        OTP is invalid. Please request again.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="login-button-container" *ngIf="disableOtp">
                                                <button class="rentofly-primary login-button" (click)="verifyOtp()">Log In</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 desc-column">
                    <h4 class="title-desc">Rentofly is a unified platform built to streamline and transform property rental market.</h4>
                </div>
                <div class="col-md-12 icon-column" (click)="howItWorksStruc()">
                    <span class="material-icons arrow-circle">
                        arrow_circle_down
                    </span>
                </div>
            </div>
        </div>
    </div> -->

    <section class="howItWorkSection">
        <div class="row">
            <div class="col-md-7 grid-sec-1">
                <div class="logoSection">
                    <img src="/assets/images/rentofly-new-main-logo.png" class="home-Img">
                </div>
                <h4 class="title-desc-2">One stop solution for all your property renting need.</h4>
            </div>
            <div class="col-md-1 grid-sec-2"></div>
            <div class="col-md-4">
                <div class="user-container">
                    <div class="login-container">
                        <div class="login-header">
                            Be a member of <span class="span-underline">elite</span> & <span class="span-underline">trustworthy</span> property rental ecosystem.
                        </div>
                        <div class="login-subTitle">
                            Please Enter Mobile Number to Login
                        </div>
                        <form [formGroup]="loginForm" (keyup)="formRequest($event)" class="login-form">
                            <div class="form-group mobile-number-group">
                                <span class="prefix">+91</span>
                                <input type="number" value="" id="loginMobileNo" formControlName="loginMobileNo" class="form-control mobile-number-input" placeholder="Mobile #" onKeyPress="if(this.value.length==10) return false;" OnlyNumber="true" required>
                                <div *ngIf="loginMobileNo!.invalid && showPhoneValidationMessage" class="rentofly-login-validation-msg ">
                                    <div>
                                        Mobile Number is invalid.
                                    </div>
                                </div>
                                <div class="login-button-container" *ngIf="!disableOtp">
                                    <br />
                                    <button class="rentofly-primary login-button" (click)="sendOtpRequest()"><mat-spinner *ngIf="requestOtp" [diameter]="30"></mat-spinner>{{ requestOtp ? '' : 'Proceed to login' }}</button>
                                </div>
                                <a [ngClass]="linkClass" (click)="sendOtpRequest(true)" *ngIf="disableOtp">Resend OTP</a>
                            </div>
                            <div #loginCaptcha style="transform: scale(0.77); -webkit-transform: scale(0.77); transform-origin: 0 0; -webkit-transform-origin: 0 0;" data-theme="light" class="repcaptcha-container" ng-hide="true"></div>
                            <div class="form-group" *ngIf="disableOtp">
                                <input type="text" value="" id="password" formControlName="password" class="form-control" placeholder="OTP" onKeyPress="if(this.value.length==6) return false;" OnlyNumber="true" required>
                                <div *ngIf="password!.invalid && showOtpValidationMessage" class="rentofly-login-validation-msg ">
                                    <div>
                                        OTP is invalid. Please request again.
                                    </div>
                                </div>
                            </div>
                            <div class="login-button-container" *ngIf="disableOtp">
                                <button class="rentofly-primary login-button" (click)="verifyOtp()"><mat-spinner *ngIf="verifyLogin" [diameter]="30"></mat-spinner>{{verifyLogin ? '' : 'Log In'}}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
        <!-- <div class="loginMainDiv">
            <div class="loginSec-1">
                <div class="logoSection">
                    <img src="/assets/images/rentofly-new-main-logo.png" class="home-Img">
                </div>
                <h4 class="title-desc-2">One stop solution for all your property renting need.</h4>
            </div>
            <div class="loginSec-2">
                <div class="user-container">
                        <div class="login-container">
                            <div class="login-header">
                                Be a member of <span class="span-underline">elite</span> & <span class="span-underline">trustworthy</span> property rental ecosystem.
                            </div>
                            <div class="login-subTitle">
                                Please Enter Mobile Number to Login
                            </div>
                            <form [formGroup]="loginForm" (keyup)="formRequest($event)" class="login-form">
                                <div class="form-group mobile-number-group">
                                    <span class="prefix">+91</span>
                                    <input type="number" value="" id="loginMobileNo" formControlName="loginMobileNo" class="form-control mobile-number-input" placeholder="Mobile #" onKeyPress="if(this.value.length==10) return false;" OnlyNumber="true" required>
                                    <div *ngIf="loginMobileNo!.invalid && showPhoneValidationMessage" class="rentofly-login-validation-msg ">
                                        <div>
                                            Mobile Number is invalid.
                                        </div>
                                    </div>
                                    <div class="login-button-container" *ngIf="!disableOtp">
                                        <br />
                                        <button class="rentofly-primary login-button" (click)="sendOtpRequest()"><mat-spinner *ngIf="requestOtp" [diameter]="30"></mat-spinner>{{ requestOtp ? '' : 'Proceed to login' }}</button>
                                    </div>
                                    <a [ngClass]="linkClass" (click)="sendOtpRequest(true)" *ngIf="disableOtp">Resend OTP</a>
                                </div>
                                <div #loginCaptcha style="transform: scale(0.77); -webkit-transform: scale(0.77); transform-origin: 0 0; -webkit-transform-origin: 0 0;" data-theme="light" class="repcaptcha-container" ng-hide="true"></div>
                                <div class="form-group" *ngIf="disableOtp">
                                    <input type="text" value="" id="password" formControlName="password" class="form-control" placeholder="OTP" onKeyPress="if(this.value.length==6) return false;" OnlyNumber="true" required>
                                    <div *ngIf="password!.invalid && showOtpValidationMessage" class="rentofly-login-validation-msg ">
                                        <div>
                                            OTP is invalid. Please request again.
                                        </div>
                                    </div>
                                </div>
                                <div class="login-button-container" *ngIf="disableOtp">
                                    <button class="rentofly-primary login-button" (click)="verifyOtp()"><mat-spinner *ngIf="verifyLogin" [diameter]="30"></mat-spinner>{{verifyLogin ? '' : 'Log In'}}</button>
                                </div>
                            </form>
                        </div>
                </div>
            </div>
        </div> -->
    </section>
    <div #howItWorks class="row section-two bg-color-div">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h2 class="how-it-works-header">How it works</h2>
                    <div class="heading-line"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <img src="/assets/images/property_details.svg" width="200" height="200">
                    <span class="notify-badge">1</span>
                    <h4 class="img-header">Enter Property Details</h4>
                </div>
                <div class="col-md-3">
                    <img src="/assets/images/stakeholders.svg" width="180" height="200">
                    <span class="notify-badge">2</span>
                    <h4 class="img-header">Add Stakeholders</h4>
                </div>
                <div class="col-md-3">
                    <img src="/assets/images/rental_deal.svg" width="200" height="200">
                    <span class="notify-badge">3</span>
                    <h4 class="img-header">Approve Rental Deal</h4>
                </div>
                <div class="col-md-3">
                    <img src="/assets/images/agreement.svg" width="200" height="200">
                    <span class="notify-badge">4</span>
                    <h4 class="img-header">Sign Agreements – Close Deal</h4>
                </div>
            </div>
        </div>
    </div>
    <div class="row section-four bg-color-div">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h2 class="how-it-works-header">Features</h2>
                    <div class="heading-line"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <p-carousel [value]="features" [numVisible]="3" [numScroll]="1" [circular]="false" [responsiveOptions]="responsiveOptions" [autoplayInterval]="3000" *ngIf="isCarouselEnabled()">
                        <ng-template let-features pTemplate="item">
                            <div class="product-img-item">
                                <img src="{{features.src}}" class="carusol-Img">

                                <!-- <a href="{{product.link}}" class="imp-link-anchor" target="_blank"> -->
                                <!-- <div class="product-item-content">
                                        <p-card class="carusol-card">
                                            <h4 class="headerText">{{product.name}}</h4>
                                        </p-card>
                                    </div> -->
                                <!-- </a> -->
                            </div>
                            <div class="carusol-img-text">
                                <h4 class="img-header">{{features.heading}}</h4>
                            </div>
                        </ng-template>
                    </p-carousel>
                </div>
                <!-- <div class="col-md-3 comingsoon-img">
                    <img src="/assets/images/upload.svg" width="200" height="200">
                    <h4 class="img-header">Upload</h4>
                </div>
                <div class="col-md-3 comingsoon-img">
                    <img src="/assets/images/feedback_new.svg" width="200" height="200">
                    <h4 class="img-header">Feedback</h4>
                </div>
                <div class="col-md-3 comingsoon-img">
                    <img src="/assets/images/reminder.svg" width="200" height="200">
                    <h4 class="img-header">Reminder</h4>
                </div>
                <div class="col-md-3 comingsoon-img">
                    <img src="/assets/images/termination.svg" width="200" height="200">
                    <h4 class="img-header">Agreement Termination</h4>
                </div> -->
            </div>
        </div>
    </div>
    <div class="row section-four bg-color-div">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h2 class="how-it-works-header">Coming soon</h2>
                    <div class="heading-line"></div>
                </div>
            </div>
            <div class="row">
                <!-- <div class="col-md-3 comingsoon-img">
                    <img src="/assets/images/dashboard2.svg" width="200" height="200">
                    <h4 class="img-header">Dashboard</h4>
                </div> -->
                <div class="col-md-2">

                </div>
                <div class="col-md-3 comingsoon-img">
                    <img src="/assets/images/payment.svg" width="200" height="200">
                    <h4 class="img-header">Payment</h4>
                </div>
                <div class="col-md-3 comingsoon-img">
                    <img src="/assets/images/checklist.svg" width="200" height="200">
                    <h4 class="img-header">Property Inspection checklist</h4>
                </div>
                <div class="col-md-3 comingsoon-img">
                    <img src="/assets/images/rent_receipt.svg" width="200" height="200">
                    <h4 class="img-header">Rent Receipt</h4>
                </div>
            </div>
        </div>
    </div>
    <div class="row section-three">
        <div class="container">
            <div class="row imp-links-div">
                <div class="col-md-12">
                    <h2 class="important-links">Important links</h2>
                    <div class="heading-line"></div>
                </div>
                <div class="col-md-12" *ngIf="isCarouselEnabled()">
                    <p-carousel [value]="products" [numVisible]="3" [numScroll]="1" [circular]="false" [responsiveOptions]="responsiveOptions" [autoplayInterval]="3000">
                        <ng-template let-product pTemplate="item">
                            <div class="product-item">
                                <a href="{{product.link}}" class="imp-link-anchor" target="_blank">
                                    <div class="product-item-content">
                                        <p-card class="carusol-card">
                                            <h4 class="headerText">{{product.name}}</h4>
                                        </p-card>
                                    </div>
                                </a>
                            </div>
                        </ng-template>
                    </p-carousel>
                </div>
            </div>
        </div>
    </div>
</div>

<rentofly-footer-links></rentofly-footer-links>