import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { PaginatedPartner, Partner, PartnerRequest } from "../models";
import { BaseService } from "./base.service";

@Injectable()
export class PartnerService extends BaseService{
    //private partnerQueryUrl = this.baseQueryUrl() + "/Partner/";
    private partnerQueryUrl = this.baseApiUrl() + "/partner/";
    private partnerCommandUrl = this.baseCommandUrl() + "/Partner/";

    getPartnerById(id: string): Observable<any>{
        const url = `${this.partnerQueryUrl}${id}`;
        const headers = this.getHeader();
        return this.getHttpClient().get<Partner>(url, { headers: headers })
    }

    getPartnerByPhoneNumber(phoneNumber: string): Observable<any>{
        const url = `${this.partnerQueryUrl}${phoneNumber}`;
        const headers = this.getHeader();
        return this.getHttpClient().get<Partner>(url, { headers: headers })
    }

    getPartnersByPinCode(pinCode: number): Observable<any>{
        const url = `${this.partnerQueryUrl}pinCode/${pinCode}`;
        const headers = this.getHeader();
        return this.getHttpClient().get<Partner[]>(url, { headers: headers })
    }

    getPaginatedAndSortedPartners(partnerRequest: PartnerRequest): Observable<any>{
        const url = `${this.partnerQueryUrl}paginatedAndSortedPartner`;
        const headers = this.getHeader();
        return this.getHttpClient().post<PaginatedPartner>(url, partnerRequest, { headers: headers })
    }

    addPartner(partner: Partner): Observable<any | string>{
        const url = `${this.partnerCommandUrl}add`;
        const headers = this.getHeader();
        return this.getHttpClient().post(url, partner, {headers: headers}).pipe(
            map((response) => response),
            catchError((error) => {
                console.log(error);
                return error;
            })
        );
    }

    updatePartner(partner: Partner): Observable<any | string>{
        const url = `${this.partnerCommandUrl}update`;
        const headers = this.getHeader();
        return this.getHttpClient().put(url, partner, {headers: headers}).pipe(
            map((response) => response),
            catchError((error) => {
                console.log(error);
                return error;
            })
        );
    }

    deletePartner(id: string): Observable<any | string>{
        const url = `${this.partnerCommandUrl}${id}`;
        const headers = this.getHeader();
        return this.getHttpClient().delete(url, {headers: headers}).pipe(
            map((response) => response),
            catchError((error) => {
                console.log(error);
                return error;
            })
        );
    }
}