import { createEntityAdapter, Dictionary, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import { Tenant, TenantFinances, User } from "src/app/shared/models";
import { addTenant, addTenantFailure, addTenantSuccess, getTenantFinances, getTenantFinancesFailure, getTenantFinancesSuccess, initCurrentTenant, queryTenantById, queryTenantByIdFailure, queryTenantByIdSuccess, queryUserForTenant, queryUserForTenantFailure, queryUserForTenantSuccess, updateTenant, updateTenantFailure, updateTenantSuccess } from "../actions";

export function getTenantReducer(state: TenantState | undefined, action: Action) {
    return tenantReducer(state, action);
}

export const tenantAdapter = createEntityAdapter<Tenant>();

export interface TenantState extends EntityState<Tenant> {
    entities: Dictionary<Tenant>;
    error: any;
    currentTenant: Tenant;
    currentTenantLoading: boolean;
    currentUserForTenantLoading: boolean;
    currentUserForTenant: User;
    editingTenant: boolean;
    tenantFinances: TenantFinances;
}

export const initialTenantState: TenantState = tenantAdapter.getInitialState({
    entities: [],
    error: null,
    currentTenant: null,
    currentTenantLoading: false,
    currentUserForTenantLoading: false,
    currentUserForTenant: null,
    editingTenant: false,
    tenantFinances: null
});

export const tenantReducer = createReducer(
    initialTenantState,

    on(queryTenantById, (state) => {
        return  {
            ...state,
            currentTenantLoading: true,
            currentTenant: null
        };
    }),

    on(queryTenantByIdSuccess, (state, {tenant}) => {
        return  {
            ...state,
            currentTenantLoading: false,
            currentTenant: tenant
        };
    }),

    on(queryTenantByIdFailure, (state, {error}) => {
        return  {
            ...state,
            currentTenantLoading: false,
            error
        };
    }),

    on(addTenant, (state, {propertyId, tenant}) => {
        return  {
            ...state,
            currentTenant: tenant,
            editingTenant: true
        };
    }),

    on(addTenantSuccess, (state) => {
        return  {
            ...state,
            editingTenant: false
        };
    }),

    on(addTenantFailure, (state, {error}) => {
        return  {
            ...state,
            error,
            editingTenant: false
        };
    }),

    on(updateTenant, (state, {propertyId, tenant}) => {
        return  {
            ...state,
            currentTenant: tenant,
            editingTenant: true
        };
    }),

    on(updateTenantSuccess, (state) => {
        return  {
            ...state,
            editingTenant: false
        };
    }),

    on(updateTenantFailure, (state, {error}) => {
        return  {
            ...state,
            error,
            editingTenant: false
        };
    }),

    on(initCurrentTenant, (state) => {
        return  {
            ...initialTenantState
        };
    }),

    on(queryUserForTenant, (state) => {
        return  {
            ...state,
            currentUserForTenantLoading: true,
            currentUserForTenant: null
        };
    }),

    on(queryUserForTenantSuccess, (state, {user}) => {
        return  {
            ...state,
            currentUserForTenantLoading: false,
            currentUserForTenant: user
        };
    }),

    on(queryUserForTenantFailure, (state, {error}) => {
        return  {
            ...state,
            currentUserForTenantLoading: false,
            currentUserForTenant: null,
            error
        };
    }),

    on(getTenantFinances, (state) => {
        return  {
            ...state,
            tenantFinances: null
        };
    }),

    on(getTenantFinancesSuccess, (state, {tenantFinances}) => {
        return  {
            ...state,
            tenantFinances
        };
    }),

    on(getTenantFinancesFailure, (state, {error}) => {
        return  {
            ...state,
            error
        };
    }),
)