<head>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="description" content="Manage your rental property with ease using our AI-powered platform. Get personalized recommendations, flexible rent payment options, automated reminders, and centralized document management. Trust and transparency are at the core of our platform, making each rental transaction secure and hassle-free. Simplify your rental journey today.">
    <meta name="keywords" content="Rental finance management, Property management software, Tenant management system, Rent payment processing, Property listing management, Lease agreement management, Online rent payment, Rental property accounting, Tenant screening, Rent collection automation, Maintenance request management, Property owner dashboard, Real estate software, Online property management, Property management platform, Rental management app, Property management tool, Automated rent collection, Tenant communication platform">
    <meta name="robots" content="index, follow">
</head>
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-Y3D2LBP9KV"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-Y3D2LBP9KV');
</script>
<!-- Google tag (gtag.js) -->
<div class="row">
    <div class="col-md-12">
        <rentofly-loading *ngIf="(isPropertyLoading$ | async)"></rentofly-loading>
        <mat-table [dataSource]="allProperties$" class="property-table" matSort (matSortChange)="sortChange($event)">
            <ng-container matColumnDef="propertyName">
                <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="PropertyName"> Property Name </mat-header-cell>
                <mat-cell *matCellDef="let element"><a (click)="viewProperty(element.id)" class="property-link">{{element.propertyName}}</a> </mat-cell>
            </ng-container>

            <ng-container matColumnDef="propertyStatus">
                <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="PropertyStatus"> Property Status </mat-header-cell>
                <mat-cell *matCellDef="let element">{{getPropertyStatus(element)}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="rentPerMonth">
                <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="RentPerMonth"> Rent Per Month </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{(element.rentPerMonth > 0 ? element.rentPerMonth : '') | currency:'INR'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ownerName">
                <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="OwnerName"> Owner Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.ownerName}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="tenantName">
                <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="TenantName"> Tenant Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.tenantName}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="brokerName">
                <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="BrokerName"> Broker Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.brokerName}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let myRowData; columns: displayedColumns"></mat-row>
        </mat-table>
    </div>
    <div class="col-md-12" *ngIf="(totalProperties$ | async) == 0 && !(isPropertyLoading$ | async)">
        <br /> No Properties to view.
    </div>
    <div class="col-md-12">
        <div class="col-md-12 paginator-section">

            <mat-paginator [length]="totalProperties$ | async" [hidePageSize]="isMobileBrowser()" [pageIndex]="(pageIndex$ | async)" [pageSize]="(pageSize$ | async)" [pageSizeOptions]="[10, 25, 50]" (page)="onPageChanged($event)">
            </mat-paginator>
            <a (click)="refreshList()" class="refresh-list-link">
                <span class="material-icons">
                                        refresh
                                    </span>
            </a>
        </div>
    </div>
</div>