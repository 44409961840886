// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  rentoFlyQuery: {
    baseUrl: "https://query.dev.rentofly.com",
    v1:"v1"
  },
  rentoFlyCommand: {
    baseUrl: "https://command.dev.rentofly.com",
    v1:"v1"
  },
  rentoFlyFeedBack: {
    baseUrl: "https://feedbackv2.dev.rentofly.com",
    v1:"v1"
  },
  rentoFlyServices: {
    baseUrl: "https://rentofly-api.dev.rentofly.com",
    v1: "v1"
  },
  firebaseConfig: {
    apiKey: "AIzaSyBMQuMuAx0gps8fzz-AFKMJwGRz_1CfWbo",
    authDomain: "rpayr-dev.firebaseapp.com",
    projectId: "rpayr-dev",
    storageBucket: "rpayr-dev.appspot.com",
    messagingSenderId: "265945213960",
    appId: "1:265945213960:web:975699a8cb9e75326075bd"
  },
  emailUsers:{
    infoEmail:"info@rentofly.com",
    infoEmailName: "Rentofly Support"
  },
  url:'dev.rentofly.com',
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
