<head>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="description" content="Manage your rental property with ease using our AI-powered platform. Get personalized recommendations, flexible rent payment options, automated reminders, and centralized document management. Trust and transparency are at the core of our platform, making each rental transaction secure and hassle-free. Simplify your rental journey today.">
    <meta name="keywords" content="Rental finance management, Property management software, Tenant management system, Rent payment processing, Property listing management, Lease agreement management, Online rent payment, Rental property accounting, Tenant screening, Rent collection automation, Maintenance request management, Property owner dashboard, Real estate software, Online property management, Property management platform, Rental management app, Property management tool, Automated rent collection, Tenant communication platform">
    <meta name="robots" content="index, follow">
</head>
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-Y3D2LBP9KV"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-Y3D2LBP9KV');
</script>
<!-- Google tag (gtag.js) -->
<div class="container">
    <section class="howItWorkSection">
        <div class="row">
            <div class="col-md-7 grid-sec-1">
                <div class="logoSection">
                    <img src="/assets/images/rentofly-new-main-logo.png" class="home-Img">
                </div>
                <h4 class="title-desc-2">One stop solution for all your property renting need.</h4>
            </div>
            <div class="col-md-1 grid-sec-2"></div>
            <div class="col-md-4">
                <div class="user-container">
                    <div class="login-container">
                        <div class="login-header">
                            Be a member of <span class="span-underline">elite</span> & <span class="span-underline">trustworthy</span> property rental ecosystem.
                        </div>
                        <div class="login-subTitle">
                            Please Enter Mobile Number to Login
                        </div>
                        <form [formGroup]="loginForm" (keyup)="formRequest($event)" class="login-form">
                            <div class="form-group mobile-number-group">
                                <select class="form-control prefix" name="phonePrefix" id="phonePrefix" formControlName="phonePrefix">
                                    <option value="+91" selected>+91 - India</option>
                                    <option value="+1">+1 - USA</option>
                                    <option value="+1">+44 - UK</option>
                                </select>
                                <input type="number" value="" id="loginMobileNo" formControlName="loginMobileNo" class="form-control mobile-number-input" placeholder="Mobile #" onKeyPress="if(this.value.length==10) return false;" OnlyNumber="true" required>
                                <div *ngIf="loginMobileNo!.invalid && showPhoneValidationMessage" class="rentofly-login-validation-msg ">
                                    <div>
                                        Mobile Number is invalid.
                                    </div>
                                </div>
                                <div class="login-button-container" *ngIf="!disableOtp">
                                    <br />
                                    <button class="rentofly-primary login-button" (click)="sendOtpRequest()">
                                        <mat-spinner *ngIf="requestOtp" [diameter]="30"></mat-spinner>{{ requestOtp ? ''
                                        : 'Proceed to login' }}
                                    </button>
                                </div>
                                <a [ngClass]="linkClass" (click)="sendOtpRequest(true)" *ngIf="disableOtp">Resend
                                    OTP</a>
                            </div>
                            <div #loginCaptcha style="transform: scale(0.77); -webkit-transform: scale(0.77); transform-origin: 0 0; -webkit-transform-origin: 0 0;" data-theme="light" class="repcaptcha-container" ng-hide="true"></div>
                            <div class="form-group" *ngIf="disableOtp">
                                <input type="text" value="" id="password" formControlName="password" class="form-control" placeholder="OTP" onKeyPress="if(this.value.length==6) return false;" OnlyNumber="true" required>
                                <div *ngIf="password!.invalid && showOtpValidationMessage" class="rentofly-login-validation-msg ">
                                    <div>
                                        OTP is invalid. Please request again.
                                    </div>
                                </div>
                            </div>
                            <div class="login-button-container" *ngIf="disableOtp">
                                <button class="rentofly-primary login-button" (click)="verifyOtp()">
                                    <mat-spinner *ngIf="verifyLogin" [diameter]="30"></mat-spinner>{{verifyLogin ? '' :
                                    'Log In'}}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="row section-four bg-color-div">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h2 class="how-it-works-header">Features</h2>
                    <div class="heading-line"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <p-carousel [value]="features" [numVisible]="3" [numScroll]="1" [circular]="false" [responsiveOptions]="responsiveOptions" [autoplayInterval]="3000" *ngIf="isCarouselEnabled()">
                        <ng-template let-features pTemplate="item">
                            <div class="product-img-item">
                                <img src="{{features.src}}" class="carusol-Img">
                            </div>
                            <div class="carusol-img-text">
                                <h4 class="img-header">{{features.heading}}</h4>
                            </div>
                        </ng-template>
                    </p-carousel>
                </div>
            </div>
        </div>
    </div>
    <div #howItWorks class="row section-two bg-color-div">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h2 class="how-it-works-header">How it works</h2>
                    <div class="heading-line"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <img src="/assets/images/property_details.svg" width="200" height="200">
                    <span class="notify-badge">1</span>
                    <h4 class="img-header">Enter Property Details</h4>
                </div>
                <div class="col-md-3">
                    <img src="/assets/images/stakeholders.svg" width="180" height="200">
                    <span class="notify-badge">2</span>
                    <h4 class="img-header">Add Stakeholders</h4>
                </div>
                <div class="col-md-3">
                    <img src="/assets/images/rental_deal.svg" width="200" height="200">
                    <span class="notify-badge">3</span>
                    <h4 class="img-header">Approve Rental Deal</h4>
                </div>
                <div class="col-md-3">
                    <img src="/assets/images/agreement.svg" width="200" height="200">
                    <span class="notify-badge">4</span>
                    <h4 class="img-header">Sign Agreements – Close Deal</h4>
                </div>
            </div>
        </div>
    </div>
    
    <!-- <div class="row section-four bg-color-div">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h2 class="how-it-works-header">Coming soon</h2>
                    <div class="heading-line"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">

                </div>
                <div class="col-md-3 comingsoon-img">
                    <img src="/assets/images/payment.svg" width="200" height="200">
                    <h4 class="img-header">Payment</h4>
                </div>
                <div class="col-md-3 comingsoon-img">
                    <img src="/assets/images/checklist.svg" width="200" height="200">
                    <h4 class="img-header">Property Inspection checklist</h4>
                </div>
                <div class="col-md-3 comingsoon-img">
                    <img src="/assets/images/rent_receipt.svg" width="200" height="200">
                    <h4 class="img-header">Rent Receipt</h4>
                </div>
            </div>
        </div>
    </div> -->
    <div class="row section-three">
        <div class="container">
            <div class="row imp-links-div">
                <div class="col-md-12">
                    <h2 class="important-links">Important links</h2>
                    <div class="heading-line"></div>
                </div>
                <div class="col-md-12">
                    <p-carousel [value]="products" [numVisible]="3" [numScroll]="1" [circular]="false" [responsiveOptions]="responsiveOptions" [autoplayInterval]="3000" *ngIf="isCarouselEnabled()">
                        <ng-template let-product pTemplate="item">
                            <div class="product-item">
                                <a href="{{product.link}}" class="imp-link-anchor" target="_blank">
                                    <div class="product-item-content">
                                        <p-card class="carusol-card">
                                            <h4 class="headerText">{{product.name}}</h4>
                                        </p-card>
                                    </div>
                                </a>
                            </div>
                        </ng-template>
                    </p-carousel>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <rentofly-footer-links></rentofly-footer-links> -->