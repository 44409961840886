import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { TenantService, PropertyService, EmailService } from "src/app/shared/services";
import * as tenantActions from "../actions/tenant.action";
import { catchError, map, switchMap } from "rxjs/operators";
import { Tenant, TenantFinances, User } from "../../shared/models";
import { of } from "rxjs";
import { UserService } from "src/app/shared/services/user.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class TenantEffects{
    constructor(
        private readonly actions$: Actions,
        private readonly propertyService: PropertyService,
        private readonly tenantService: TenantService,
        private readonly userService: UserService,
        private readonly emailService: EmailService,
        private _snackBar: MatSnackBar,
    ){}

    getTenantById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tenantActions.queryTenantById),
            switchMap((action) => 
                this.tenantService.getTenantById(action.id).pipe(
                    map((data) => tenantActions.queryTenantByIdSuccess({tenant: data.message})),
                    catchError((error: any) => {
                        if(error.status != 404)
                        {
                            this._snackBar.open('The action performed was not successful. Please contact admin.', "X", {
                                duration: 10000,
                                panelClass: ['snackbar-failure']
                            });
                        }
                        return of(tenantActions.queryTenantByIdFailure(error))
                    }))
                )
            )
    );

    addTenant$ = createEffect(() =>
    this.actions$.pipe(
        ofType(tenantActions.addTenant),
        switchMap((action)=>
            this.userService.getUserByPhoneNumber(action.tenant.phoneNumber).pipe(
                map((data) => {
                    //if else condition added to fix delete user issue 
                    if(data.message)
                    {
                        var updateUser: User = {
                            ...data.message,
                            name: data.message?.name ?? action.tenant.name,
                            emailAddress: data.message?.emailAddress ?? action.tenant.emailAddress
                        }
                        this.userService.updateUser(data.message.id, updateUser).subscribe();
                        let propertyId = action.propertyId;
                        let tenant: Tenant = {
                            ...action.tenant,
                            userId: data.message.id
                        }
                        return tenantActions.addTenantWithUser({propertyId, tenant})
                    } else{
                        let propertyId = action.propertyId;
                        let tenant: Tenant = {
                            ...action.tenant,
                            userId: null
                        }
                        return tenantActions.addTenantWithUser({propertyId, tenant})
                    }
                }),
                catchError((error: any) => {
                    if(error?.status == "404")
                    {
                        let propertyId = action.propertyId;
                        let tenant = {
                            ...action.tenant,
                            userId: null
                        }
                        return of(tenantActions.addTenantWithUser({propertyId, tenant}))
                    }else{
                        this._snackBar.open('Adding Tenant was not successful. Please contact admin.', "X", {
                            duration: 10000,
                            panelClass: ['snackbar-failure']
                        });
                    }
                    
                })   
            )
        ),
        switchMap((action) => 
            this.propertyService.addTenant(action.propertyId, action.tenant).pipe(
                map((data: Tenant) => {
                    return tenantActions.addTenantSuccess()
                }),
                catchError((error: any) => {
                    this._snackBar.open('Adding Tenant was not successful. Please contact admin.', "X", {
                        duration: 10000,
                        panelClass: ['snackbar-failure']
                    });
                    return of(tenantActions.addTenantFailure(error))
                }))
            )
        )
    );

    updateTenant$ = createEffect(() =>
    this.actions$.pipe(
        ofType(tenantActions.updateTenant),
        switchMap((action)=>
            this.userService.getUserByPhoneNumber(action.tenant.phoneNumber).pipe(
                map((data) => {
                    //if else condition added to fix delete user issue 
                    if(data.message)
                    {
                        var updateUser: User = {
                            ...data.message,
                            name: data.message?.name ?? action.tenant.name,
                            emailAddress: data.message?.emailAddress ?? action.tenant.emailAddress
                        }
                        this.userService.updateUser(data.message.id, updateUser).subscribe();
                        let propertyId = action.propertyId;
                        let emails = action.emails;
                        let tenant: Tenant = {
                            ...action.tenant,
                            userId: data.message.id
                        }
                        return tenantActions.updateTenantWithUser({propertyId, tenant, emails})
                    } else{
                        let propertyId = action.propertyId;
                        let emails = action.emails;
                        let tenant: Tenant = {
                            ...action.tenant,
                            userId: null
                        }
                        return tenantActions.updateTenantWithUser({propertyId, tenant, emails})
                    }
                    
                }),
                catchError((error: any) => {
                    if(error?.status == "404")
                    {
                        let propertyId = action.propertyId;
                        let emails = action.emails;
                        let tenant = {
                            ...action.tenant,
                            userId: null
                        }
                        return of(tenantActions.updateTenantWithUser({propertyId, tenant, emails}))
                    }else{
                        this._snackBar.open('Updating Tenant was not successful. Please contact admin.', "X", {
                            duration: 10000,
                            panelClass: ['snackbar-failure']
                        });
                    }
                })   
            )
        ),
        switchMap((action) => 
            this.propertyService.updateTenant(action.propertyId, action.tenant).pipe(
                map((data: Tenant) => {
                    if(action.emails?.length > 0)
                    {
                        action.emails.forEach((data) => {
                            this.emailService.sendEmail(data).subscribe();
                        });
                    }
                    return tenantActions.updateTenantSuccess()
                }),
                catchError((error: any) => {
                    this._snackBar.open('Updating Tenant was not successful. Please contact admin.', "X", {
                        duration: 10000,
                        panelClass: ['snackbar-failure']
                    });
                    return of(tenantActions.updateTenantFailure(error))
                }))
            )
        )
    );

    getUserForTenant$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tenantActions.queryUserForTenant),
            switchMap((action) => 
                this.userService.getUserByPhoneNumber(action.phoneNumber).pipe(
                    map((data) => tenantActions.queryUserForTenantSuccess({user: data.message})),
                    catchError((error: any) => {
                        return of(tenantActions.queryUserForTenantFailure(error))
                    }))
                )
            )
    );

    updateTenantConsent$ = createEffect(() =>
    this.actions$.pipe(
        ofType(tenantActions.updateTenantConsent),
        switchMap((action) => 
            this.propertyService.updateTenantConsent(action.id, action.status).pipe(
                map(() => {
                    action.emails.forEach((data) => {
                        this.emailService.sendEmail(data).subscribe();
                    });
                    return tenantActions.updateTenantConsentSuccess()
                }),
                catchError((error: any) => {
                    this._snackBar.open('Updating Tenant Consent was not successful. Please contact admin.', "X", {
                        duration: 10000,
                        panelClass: ['snackbar-failure']
                    });
                    return of(tenantActions.updateTenantConsentFailure(error))
                }))
            )
        )
    );

    getTenantFinances$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tenantActions.getTenantFinances),
            switchMap((action) => 
                this.tenantService.getTenantFinances(action.userId).pipe(
                    map((data) => tenantActions.getTenantFinancesSuccess({tenantFinances: data.message})),
                    catchError((error: any) => {
                        return of(tenantActions.getTenantFinancesFailure(error))
                    }))
                )
            )
    );
}