import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Broker, Brokerage } from "../models";
import { BaseService } from "./base.service";

@Injectable()
export class BrokerService extends BaseService{
    //private brokerQueryUrl = this.baseQueryUrl() + "/Broker/";
    private brokerQueryUrl = this.baseApiUrl() + "/broker/";

    getBrokerById(id: string): Observable<Broker>{
        const url = `${this.brokerQueryUrl}${id}`;
        const headers = this.getHeader();
        return this.getHttpClient().get<Broker>(url, { headers: headers }).pipe(
            map((response: Broker) => response)
        );
    }

    getBrokerage(userId: string): Observable<Brokerage>{
        const url = `${this.brokerQueryUrl}brokerage/${userId}`;
        const headers = this.getHeader();
        return this.getHttpClient().get<Brokerage>(url, { headers: headers }).pipe(
            map((response: Brokerage) => response)
        );
    }
}