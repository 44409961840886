import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PaginatedPartner, Partner, PartnerRequest } from 'src/app/shared/models';
import { WindowService } from 'src/app/shared/services';
import { PartnerStoreFacade } from 'src/app/store/facades';

@Component({
  selector: 'rentofly-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {
  
  approvedPartnerLoading$: Observable<boolean>;
  pendingPartnerLoading$: Observable<boolean>;
  approvedPartners: PaginatedPartner;
  pendingPartners: PaginatedPartner;

  constructor(
    private readonly partnerFacade: PartnerStoreFacade,
    private readonly windowService: WindowService,
    private readonly router: Router,
  ) { }

  ngOnInit(): void {
    this.partnerFacade.queryApprovedPartners();
    this.partnerFacade.queryPendingPartners();
    this.partnerFacade.approvedPartners$.subscribe((approvedPartners) => {
      if(approvedPartners){
        this.approvedPartners = approvedPartners;
      }
    });
    this.partnerFacade.pendingPartners$.subscribe((pendingPartners) => {
      if(pendingPartners){
        this.pendingPartners = pendingPartners;
      }
    });
    this.approvedPartnerLoading$ = this.partnerFacade.approvedPartnersLoading$;
    this.pendingPartnerLoading$ = this.partnerFacade.pendingPartnersLoading$;

  }

  isMobileBrowser(): boolean {
    return this.windowService.isMobileBrowser();
  }

  getPartners(status){
    if(status === 'Approved'){
      this.router.navigate(['/admin-approved-partner-dashboard']);
    } else{
      this.router.navigate(['/admin-pending-partner-dashboard']);
    }
  }

}
