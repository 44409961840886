import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Property, AdditionalPropertyDetail} from 'src/app/shared/models';
import { LocalStorageService } from 'src/app/shared/services';
import { DealStoreFacade } from 'src/app/store/facades/deal.store-facade';
import { OwnerStoreFacade } from 'src/app/store/facades/owner.store-facade';
import { PropertyStoreFacade } from 'src/app/store/facades/property.store-facade';
import { TenantStoreFacade } from 'src/app/store/facades/tenant.store-facade';
import {MenuItem} from 'primeng/api';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { filter } from 'rxjs/operators';
import { BrokerStoreFacade } from 'src/app/store/facades/broker.store-facade';


@Component({
  selector: 'app-add-property',
  templateUrl: './add-property.component.html',
  styleUrls: ['./add-property.component.scss'],
})
export class AddPropertyComponent implements OnInit, OnDestroy {
  stage: number;
  propertyType: string;
  active = "active";
  inactive = "inactive";
  propertyId: string;
  items: MenuItem[];

  currentPropertyLoading$: Observable<boolean>;
  currentProperty$: Observable<Property>;
  currentAdditionalPropertyDetails$: Observable<AdditionalPropertyDetail>
  currentUser:any;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  isEditable = false;
  activeIndex: number;
  activeNumber: number;

  constructor(
    public readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly propertyFacade: PropertyStoreFacade,
    private readonly ownerFacade: OwnerStoreFacade,
    private readonly tenantFacade: TenantStoreFacade,
    private readonly dealFacade: DealStoreFacade,
    private readonly brokerFacade: BrokerStoreFacade,
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private readonly localStorageService: LocalStorageService,
    private _formBuilder: FormBuilder
  ) { 
    this.router.events.subscribe((evt) => {
      if ((evt instanceof NavigationEnd)) {
        if(evt.url.includes("/rented-property"))
        {
          this.propertyType = "rented";
        }
        else if(evt.url.includes("/available-property")){
          this.propertyType = "available";
        }
      }
    });
  }
  ngOnDestroy(): void {
    this.propertyFacade.setStage(1);
    this.ownerFacade.initCurrentOwner();
    this.tenantFacade.initCurrentTenant();
    this.dealFacade.initCurrentDeal();
    this.brokerFacade.initCurrentBroker();
    this.propertyFacade.initAdditionalDetails();
  }

  ngOnInit(): void {
    this.currentUser = this.localStorageService.getObjectFromLocalStorage('current-user');
    this.route.queryParams.pipe(
      filter(params => params.propertyIdentifier)
    ).subscribe((params) => {
      this.propertyId = params.propertyIdentifier
      this.currentPropertyLoading$ = this.propertyFacade.currentPropertyLoading$;
      this.currentProperty$ = this.propertyFacade.currentProperty$;
      this.currentAdditionalPropertyDetails$ = this.propertyFacade.currentAdditionalPropertyDetails$;
      this.propertyFacade.queryPropertyById(this.propertyId);
      this.propertyFacade.queryPropertyDetailsByPropertyId(this.propertyId);
    })
    this.propertyFacade.stage$.subscribe((stage) => {
      this.stage = stage;
      this.activeNumber = stage - 1;
    })
    
    this.currentProperty$.subscribe((data: Property) => {
      if(data == null && this.propertyId == null)
      {
        this.router.navigate(['dashboard/view-property'])
      }
      if(data)
      {
        if(data.ownerConsentStatus == 'Approved' && data.tenantConsentStatus == 'Approved')
        {
          this.propertyFacade.setStage(4);
        }
      }
    })
    this.items = [
      {
        label: 'Property',
        command: (event:any) => {
          this.activeIndex = 0;
        }
      },
      {
        label: 'Stakeholder',
        command: (event:any) => {
          this.activeIndex = 1;
        }
      },
      {
        label: 'Deal',
        command: (event:any) => {
          this.activeIndex = 2;
        }
      },
      {
        label: 'Agreement',
        command: (event:any) => {
          this.activeIndex = 3;
        }
      }
  ];
  this.firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required]
  });
  this.secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required]
  });
  }

  onContinue(event): void {
    this.propertyFacade.setStage(event);
    this.scrollToTop();
  }

  onBack(event): void {
    if(this.stage == 1)
    {
      this.onExit(null);
      return;
    }
    this.propertyFacade.setStage(this.stage - 1);
    this.scrollToTop();
  }

  onExit(event): void {
    this.propertyFacade.setStage(1);
    this.router.navigate(['../../view-property'], { relativeTo: this.route });
  }

  scrollToTop()
  {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({top: 0, behavior: 'smooth'})
    }
  }

  onSaveAndExit(event): void {
    this.propertyFacade.setStage(1);
    this.onExit(event);
  }

  onInitiateDeal(event): void {
    this.propertyFacade.setStage(1);
    this.onExit(event);
  }

}
