import { Injectable } from "@angular/core";
import * as fromTenant from '../selectors/tenant.selector';
import * as tenantActions from "../actions/tenant.action";
import { Store } from "@ngrx/store";
import { TenantState } from "../reducers";
import { Email, Tenant, TenantFinances, User } from "src/app/shared/models";
import { Observable } from "rxjs";
import { EmailWhatsapp } from "src/app/shared/models/email-whatsapp.model";

export interface ITenantStoreFacade {

    //getCurrentTenant
    currentTenantLoading$: Observable<boolean>;
    currentTenant$: Observable<Tenant>;
    editingTenant$: Observable<boolean>;

    currentUserForTenantLoading$: Observable<boolean>;
    currentUserForTenant$: Observable<User>;
    tenantFinances$: Observable<TenantFinances>;

    queryTenantById(id: string): void;
    getTenantFinances(id: string): void;
    queryUserForTenant(phoneNumber: string): void;
    addTenant(propertyId: string, tenant: Tenant): void;
    updateTenant(idpropertyId: string, tenant: Tenant): void;
    initCurrentTenant(): void;
    updateTenantConsent(id: string, status: string, emails: EmailWhatsapp[]): void;
}

@Injectable()
export class TenantStoreFacade implements ITenantStoreFacade {

    get currentTenantLoading$(){
        return this.store.select(fromTenant.currentTenantLoading$);
    }

    get currentTenant$(){
        return this.store.select(fromTenant.currentTenant$);
    }

    get editingTenant$(){
        return this.store.select(fromTenant.editingTenant$);
    }

    get currentUserForTenant$(){
        return this.store.select(fromTenant.currentUserForTenant$);
    }

    get currentUserForTenantLoading$(){
        return this.store.select(fromTenant.currentUserForTenantLoading$);
    }

    get tenantFinances$(){
        return this.store.select(fromTenant.tenantFinances$);
    }

    constructor(
        private readonly store: Store<TenantState>,
    ){}

    queryTenantById(id:string){
        this.store.dispatch(tenantActions.queryTenantById({id}));
    }

    getTenantFinances(userId:string){
        this.store.dispatch(tenantActions.getTenantFinances({userId}));
    }

    queryUserForTenant(phoneNumber: string): void{
        this.store.dispatch(tenantActions.queryUserForTenant({phoneNumber}));
    }

    addTenant(propertyId: string, tenant: Tenant): void{
        this.store.dispatch(tenantActions.addTenant({propertyId, tenant}));
    }
    
    updateTenant(propertyId: string, tenant: Tenant, emails: EmailWhatsapp[] = []): void{
        this.store.dispatch(tenantActions.updateTenant({propertyId, tenant, emails}));
    }

    initCurrentTenant(): void {
        this.store.dispatch(tenantActions.initCurrentTenant());
    }

    updateTenantConsent(id: string, status: string, emails: EmailWhatsapp[] = []): void{
        this.store.dispatch(tenantActions.updateTenantConsent({id, status, emails}));
    }
}