import { createAction, props } from "@ngrx/store";
import { AdditionalPropertyDetail, AvailablePropertyData, Deal, Owner, PropertyListRequest, RentedPropertyData, Tenant, TerminatedPropertyData, ViewProperty } from "src/app/shared/models";
import { PaginatedPropertyInfo } from "src/app/shared/models/property-info.model";
import { PaginatedProperty, Property } from "src/app/shared/models/property.model";


export const queryPaginatedProperty = createAction(
    '[Property] query paginated property',
    props<{userId: string}>()
);

export const queryPaginatedPropertySuccess = createAction(
    '[Property] query paginated property Success',
    props<{paginatedProperties: PaginatedPropertyInfo}>()
);

export const queryPaginatedPropertyFailure = createAction(
    '[Property] query paginated property Failure',
    props<{error: any}>()
);

export const queryPaginatedAvailableProperty = createAction(
    '[Property] query paginated Available property',
    props<{userId: string}>()
);

export const queryPaginatedAvailablePropertySuccess = createAction(
    '[Property] query paginated Available property Success',
    props<{paginatedProperties: PaginatedPropertyInfo}>()
);

export const queryPaginatedAvailablePropertyFailure = createAction(
    '[Property] query paginated Available property Failure',
    props<{error: any}>()
);

export const queryPaginatedRentedProperty = createAction(
    '[Property] query paginated Rented property',
    props<{userId: string}>()
);

export const queryPaginatedRentedPropertySuccess = createAction(
    '[Property] query paginated Rented property Success',
    props<{paginatedProperties: PaginatedPropertyInfo}>()
);

export const queryPaginatedRentedPropertyFailure = createAction(
    '[Property] query paginated Rented property Failure',
    props<{error: any}>()
);

export const queryPaginatedTerminatedProperty = createAction(
    '[Property] query paginated Terminated property',
    props<{userId: string}>()
);

export const queryPaginatedTerminatedPropertySuccess = createAction(
    '[Property] query paginated Terminated property Success',
    props<{paginatedProperties: PaginatedPropertyInfo}>()
);

export const queryPaginatedTerminatedPropertyFailure = createAction(
    '[Property] query paginated Terminated property Failure',
    props<{error: any}>()
);

//queryPaginatedPropertyUsingCreatedBy

export const queryPaginatedPropertyUsingCreatedBy = createAction(
    '[Property] query paginated property using created by',
    props<{createdBy: string}>()
);

export const queryPaginatedPropertyUsingCreatedBySuccess = createAction(
    '[Property] query paginated property using created by Success',
    props<{paginatedProperties: PaginatedProperty}>()
);

export const queryPaginatedPropertyUsingCreatedByFailure = createAction(
    '[Property] query paginated property using created by Failure',
    props<{error: any}>()
);

export const queryPropertyById = createAction(
    '[Property] query property by id',
    props<{id: string}>()
);

export const queryPropertyByIdSuccess = createAction(
    '[Property] query property by id Success',
    props<{property: Property}>()
);

export const queryPropertyByIdFailure = createAction(
    '[Property] query property by id Failure',
    props<{error: any}>()
);

//view property by id

export const queryViewPropertyById = createAction(
    '[Property] query view property by id',
    props<{id: string}>()
);

export const queryViewPropertyByIdSuccess = createAction(
    '[Property] query view property by id Success',
    props<{property: ViewProperty}>()
);

export const queryViewPropertyByIdFailure = createAction(
    '[Property] query view property by id Failure',
    props<{error: any}>()
);

export const addProperty = createAction(
    '[Property] add Property',
    props<{property: Property}>()
);

export const addPropertySuccess = createAction(
    '[Property] add PropertySuccess',
    props<{id: string}>()
);

export const addPropertyFailure = createAction(
    '[Property] add PropertyFailure',
    props<{error: any}>()
);

//updateProperty
export const updateProperty = createAction(
    '[Property] update Property',
    props<{property: Property}>()
);

export const updatePropertySuccess = createAction(
    '[Property] update PropertySuccess',
    props<{id: string}>()
);

export const updatePropertyFailure = createAction(
    '[Property] update PropertyFailure',
    props<{error: any}>()
);

//pagedetails
export const setPageDetails = createAction(
    '[Property] set Page Details',
    props<{pageIndex: number, pageSize: number}>()
);

export const setPropertyListRequest = createAction(
    '[Property] set setPropertyListRequest',
    props<{propertyListRequest: PropertyListRequest}>()
);

export const setStage = createAction(
    '[Property] set Stage',
    props<{stage: number}>()
);

//add additional details
export const addAdditionalDetails = createAction(
    '[Property] add AdditionalDetails',
    props<{additionalDetails: AdditionalPropertyDetail}>()
);

export const addAdditionalDetailsSuccess = createAction(
    '[Property] add AdditionalDetails Success',
    props<{id: string}>()
);

export const addAdditionalDetailsFailure = createAction(
    '[Property] add AdditionalDetails Failure',
    props<{error: any}>()
);

//updateProperty
export const updateAdditionalDetails = createAction(
    '[Property] update AdditionalDetails',
    props<{additionalDetails: AdditionalPropertyDetail}>()
);

export const updateAdditionalDetailsSuccess = createAction(
    '[Property] update AdditionalDetailsSuccess',
    props<{id: string}>()
);

export const updateAdditionalDetailsFailure = createAction(
    '[Property] update AdditionalDetails Failure',
    props<{error: any}>()
);

//getProperty
export const queryAdditionalDetails = createAction(
    '[Property] query AdditionalDetails',
    props<{propertyId: string}>()
);

export const queryAdditionalDetailsSuccess = createAction(
    '[Property] query AdditionalDetailsSuccess',
    props<{additionalDetails: AdditionalPropertyDetail}>()
);

export const queryAdditionalDetailsFailure = createAction(
    '[Property] query AdditionalDetails Failure',
    props<{error: any}>()
);

export const initAdditionalDetails = createAction(
    '[Property] init Additional Details'
);

//getAvailableProperty
export const queryAvailablePropertyData = createAction(
    '[Property] query AvailablePropertyData',
    props<{userId: string}>()
);

export const queryAvailablePropertyDataSuccess = createAction(
    '[Property] query AvailablePropertyDataSuccess',
    props<{availablePropertyData: any}>()
);

export const queryAvailablePropertyDataFailure = createAction(
    '[Property] query AvailablePropertyData Failure',
    props<{error: any}>()
);

//getRentedProperty
export const queryRentedPropertyData = createAction(
    '[Property] query RentedPropertyData',
    props<{userId: string}>()
);

export const queryRentedPropertyDataSuccess = createAction(
    '[Property] query RentedPropertyDataSuccess',
    props<{rentedPropertyData: RentedPropertyData}>()
);

export const queryRentedPropertyDataFailure = createAction(
    '[Property] query RentedPropertyData Failure',
    props<{error: any}>()
);

//getTerminatedProperty
export const queryTerminatedPropertyData = createAction(
    '[Property] query TerminatedPropertyData',
    props<{userId: string}>()
);

export const queryTerminatedPropertyDataSuccess = createAction(
    '[Property] query TerminatedPropertyDataSuccess',
    props<{terminatedPropertyData: TerminatedPropertyData}>()
);

export const queryTerminatedPropertyDataFailure = createAction(
    '[Property] query TerminatedPropertyData Failure',
    props<{error: any}>()
);

//terminateProperty
export const terminateProperty = createAction(
    '[Property] terminateProperty',
    props<{propertyId: string}>()
);

export const terminatePropertySuccess = createAction(
    '[Property] terminateProperty Success',
    props<{propertyId: string}>()
);

export const terminatePropertyFailure = createAction(
    '[Property] terminateProperty Failure',
    props<{error: any}>()
);
