
export interface Property{
    id: string; 
    propertyCode?: string;
    propertyName?: string;
    zipCode?: number;
    city?: string;
    state?: string;
    landmark?: string;
    buildingName?: string;
    streetDetails?: string;
    flatNumber?: string;
    ownerId?: string;
    tenantId?: string;
    dealId?: string;
    brokerId?: string;
    ownerConsentStatus?: string;
    tenantConsentStatus?: string;
    dealStatus?: string;
    createdBy?: string;
    createdDate?: Date;
    stakeholderType?: number;
    propertyType?: string;
}

export interface PaginatedProperty{
    properties: Property[];
    totalProperties: number;
}

export enum StakeholderType{
    Owner = 0,
    Broker = 1,
    OwnerWithBroker = 2
}