import { UsersEffects } from './users.effects';
import { PropertyEffects } from './property.effects';
import { OwnerEffects } from './owner.effects';
import { TenantEffects } from './tenant.effects';
import { DealEffects } from './deal.effects';
import { BrokerEffects } from './broker.effects';
import { AddressEffects } from './address.effects';
import { AttachmentsEffects } from './attachments.effects';
import { PartnerEffects } from './partner.effects';

export * from './users.effects';
export * from './property.effects';
export * from './owner.effects';
export * from './tenant.effects';
export * from './deal.effects';
export * from './broker.effects';
export * from './address.effects';
export * from './attachments.effects';
export * from './partner.effects';

export const effects: any[] = [
    UsersEffects,
    PropertyEffects,
    OwnerEffects,
    TenantEffects,
    DealEffects,
    BrokerEffects,
    AddressEffects,
    AttachmentsEffects,
    PartnerEffects
];