<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <h1>{{confirmMessageHeader}}</h1>
            <div class="confirm-body">{{confirmMessageContent}}</div>
            <div>
                <button class="rentofly-primary rentofly-modal-button" (click)="dialogRef.close(true)">{{confirmButtonText}}</button>
                <a class="cancel-link" (click)="dialogRef.close(false)">Cancel</a>
            </div>
        </div>
    </div>
</div>