import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeLoginComponent } from './home-login/home-login.component';
import { ConfirmBoxComponent, ConfirmCommentBoxComponent, ConfirmDateBoxComponent, HeaderComponent, LoadingComponent } from './shared/components';
import { AddressService, AttachmentsService, AuthService, BrokerService, DateService, DealService, EmailService, LocalStorageService, OwnerService, PartnerService, PropertyService, 
  TenantService, 
  WindowService } from './shared/services';
import { FeatureToggleService } from './shared/services/feature-toggle.service';
import { FooterComponent } from './shared/components/footer/footer.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { OnlyNumberDirective } from './shared/directives';
import { CountdownModule } from 'ngx-countdown';
import { AboutUsComponent } from './about-us/about-us.component';
import { AdminAuthGuard, AdminLoginAuthGuard, AuthGuard, LoginAuthGuard } from './shared/guards';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AddPartnerComponent } from './add-partner/add-partner.component';
import { 
  AddPropertyComponent, 
  AvailablePropertyComponent, 
  DashboardHomeComponent, 
  DealInfoComponent, 
  DraftAgreementComponent, 
  ProfileComponent, 
  PropertyAttachmentsComponent, 
  PropertyInfoComponent, 
  RentedPropertyComponent, 
  ServicePropertyComponent,
  StakeholderInfoComponent,
  TerminatedPropertyComponent,
  ViewPropertyComponent, 
  ViewPropertyInfoComponent,
  PaymentComponent,
  SharePropertyComponent
} from './dashboard/components';
import { MatDialogModule } from '@angular/material/dialog';
import { UserService } from './shared/services/user.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromStore from './store';
import { JwtHelperService, JWT_OPTIONS  } from '@auth0/angular-jwt';
import { HttpClientModule } from '@angular/common/http';
import { MatPaginatorModule } from '@angular/material/paginator';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {ButtonModule} from 'primeng/button';
import {StepsModule} from 'primeng/steps';
import {CardModule} from 'primeng/card';
import {CheckboxModule} from 'primeng/checkbox';
import {TabViewModule} from 'primeng/tabview';
import {GMapModule} from 'primeng/gmap';
import {AccordionModule} from 'primeng/accordion';
import {TooltipModule} from 'primeng/tooltip';
import {RadioButtonModule} from 'primeng/radiobutton';
import { FaqComponent } from './faq/faq.component'
import { BadgeModule } from "primeng/badge";
import {CarouselModule} from 'primeng/carousel';
import {InputMaskModule} from 'primeng/inputmask';
import {ToastModule} from 'primeng/toast';
import {KeyFilterModule} from 'primeng/keyfilter';
import {FileUploadModule} from 'primeng/fileupload';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {DropdownModule} from 'primeng/dropdown';
import {DialogModule} from 'primeng/dialog';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ChartModule} from 'primeng/chart';
import {TimelineModule} from 'primeng/timeline';
import { 
  AddressStoreFacade, 
  BrokerStoreFacade,
  OwnerStoreFacade,
  TenantStoreFacade,
  DealStoreFacade,
  PropertyStoreFacade,
  UsersStoreFacade,
  AttachmentsStoreFacade,
  PartnerStoreFacade
} from './store/facades';
import { FooterLinksComponent } from './footer-links/footer-links.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { DatePipe } from '@angular/common';
import {MultiSelectModule} from 'primeng/multiselect';
import {DataViewModule} from 'primeng/dataview';
import {CalendarModule} from 'primeng/calendar';
import {ToggleButtonModule} from 'primeng/togglebutton';
import { DashboardDetailComponent } from './dashboard/components/dashboard-detail/dashboard-detail.component';
import { MatSortModule } from '@angular/material/sort';
import { AdminDashboardComponent, AdminLoginComponent, AdminPartnerViewComponent, ApprovedPartnerDashboardComponent, PendingPartnerDashboardComponent } from './admin';
import { PartnerComponent } from './partner-dashboard';
import { NewHomePageComponent } from './new-home-page/new-home-page.component';
import { ServicesInfoComponent } from './dashboard/components/services-info/services-info.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker'
import { ReferralCodeComponent } from './dashboard/components/referral-code/referral-code.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeLoginComponent,
    NewHomePageComponent,
    HeaderComponent,
    FooterComponent,
    ConfirmBoxComponent,
    ConfirmCommentBoxComponent,
    ConfirmDateBoxComponent,
    AboutUsComponent,
    ContactUsComponent,
    AddPartnerComponent,
    DashboardHomeComponent,
    AvailablePropertyComponent,
    ProfileComponent,
    RentedPropertyComponent,
    ServicePropertyComponent,
    AddPropertyComponent,
    ServicesInfoComponent,
    ReferralCodeComponent,
    ViewPropertyComponent,
    PropertyInfoComponent,
    StakeholderInfoComponent,
    SharePropertyComponent,
    DealInfoComponent,
    DraftAgreementComponent,
    ViewPropertyInfoComponent,
    PropertyAttachmentsComponent,
    LoadingComponent,
    OnlyNumberDirective,
    FaqComponent,
    FooterLinksComponent,
    PrivacyPolicyComponent,
    TermsOfServiceComponent,
    FeedbackComponent,
    TerminatedPropertyComponent,
    DashboardDetailComponent,
    PaymentComponent,
    AdminLoginComponent,
    AdminDashboardComponent,
    PartnerComponent,
    AdminPartnerViewComponent,
    ApprovedPartnerDashboardComponent,
    PendingPartnerDashboardComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatTableModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatSortModule,
    MatProgressSpinnerModule,
    CountdownModule,
    HttpClientModule,
    BrowserAnimationsModule,
    InputTextModule,
    InputTextareaModule,
    StepsModule,
    ButtonModule,
    CardModule,
    CheckboxModule,
    TabViewModule,
    GMapModule,
    AccordionModule,
    TooltipModule,
    RadioButtonModule,
    BadgeModule,
    CarouselModule,
    InputMaskModule,
    ToastModule,
    KeyFilterModule,
    FileUploadModule,
    AvatarModule,
    AvatarGroupModule,
    DropdownModule,
    DialogModule,
    ConfirmDialogModule,
    InputSwitchModule,
    MultiSelectModule,
    DataViewModule,
    CalendarModule,
    ToggleButtonModule,
    ChartModule,
    TimelineModule,
    NgxMaterialTimepickerModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    StoreModule.forRoot({}),
    StoreModule.forFeature('dashboard', fromStore.createDashboardReducers),
    EffectsModule.forRoot(),
    EffectsModule.forFeature(fromStore.effects),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig, environment.firebaseConfig.projectId),
    AngularFirestoreModule, // Only required for database features
    AngularFireAuthModule, // Only required for auth features,
    AngularFireStorageModule, // Only required for storage features
  ],
  providers: [
    WindowService,
    LocalStorageService,
    AuthService,
    LoginAuthGuard,
    AuthGuard,
    AdminLoginAuthGuard,
    AdminAuthGuard,
    PropertyService,
    UserService,
    OwnerService,
    TenantService,
    DealService,
    BrokerService,
    EmailService,
    AddressService,
    DateService,
    FeatureToggleService,
    AttachmentsService,
    PartnerService,
    ConfirmationService,
    UsersStoreFacade,
    PropertyStoreFacade,
    OwnerStoreFacade,
    TenantStoreFacade,
    DealStoreFacade,
    BrokerStoreFacade,
    AddressStoreFacade,
    AttachmentsStoreFacade,
    PartnerStoreFacade,
    DatePipe,
    {provide : LocationStrategy , useClass: PathLocationStrategy  },
    {provide: MAT_DATE_LOCALE, useValue: 'en-IN'},
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
