import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UUID } from 'angular2-uuid';
import { Observable } from 'rxjs';
import { User } from 'src/app/shared/models';
import { LocalStorageService } from 'src/app/shared/services';
import { FeedbackService } from 'src/app/shared/services/feedback.service';
import { UsersStoreFacade } from 'src/app/store/facades/users.store-facade';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  profileForm: FormGroup;
  showProfileValidationMessage = false;
  currentUser: User;
  userCredsFromLocal: any;
  isUserLoading$: Observable<boolean>;
  editingUser$: Observable<boolean>;
  userId;
  ratingAsOwner;
  ratingAsTenant;

  constructor(
    public readonly router: Router,
    private readonly localStorageService: LocalStorageService,
    private readonly userFacade: UsersStoreFacade,
    private _snackBar: MatSnackBar,
    private feedbackService: FeedbackService
  ) { 
  }

  get name() {
    return this.profileForm!.get('name');
  }

  get mobileNo() {
    return this.profileForm!.get('mobileNo');
  }

  get emailAddress() {
    return this.profileForm!.get('emailAddress');
  }

  get profileType() {
    return this.profileForm!.get('profileType');
  }

  get brokerId() {
    return this.profileForm!.get('brokerId');
  }

  get officeName() {
    return this.profileForm!.get('officeName');
  }

  get zipCode() {
    return this.profileForm!.get('zipCode');
  }
  get panNo() {
    return this.profileForm!.get('panNo');
  }
  get aadharNo() {
    return this.profileForm!.get('aadharNo');
  }

  get city() {
    return this.profileForm!.get('city');
  }

  get state() {
    return this.profileForm!.get('state');
  }

  get landmark() {
    return this.profileForm!.get('landmark');
  }

  get buildingName() {
    return this.profileForm!.get('buildingName');
  }

  get streetDetails() {
    return this.profileForm!.get('streetDetails');
  }

  get flatNumber() {
    return this.profileForm!.get('flatNumber');
  }

  ngOnInit(): void {
    this.userCredsFromLocal = JSON.parse(this.localStorageService.getFromLocalStorage('dashboard-user'));
    this.userFacade.queryUserByPhoneNumber(this.userCredsFromLocal.phoneNumber);

    let userNoResult = this.userFacade.noUserResult$;
    userNoResult.subscribe(data => {
      if(data)
      {
        let user: User = {
          id: UUID.UUID(),
          firebaseUserId: this.userCredsFromLocal.uid,
          phoneNumber: this.userCredsFromLocal.phoneNumber,
          createdDate: new Date()
        }
        this.userFacade.addUser(user);
      }
    })
    
    let currentUser = this.userFacade.getCurrentUser$;
    currentUser.subscribe(data => {
      if(data)
      {
        this.currentUser = data;
        this.initForm();
        this.userId = data.id;
        this.profileOwnerRating();
        this.profileOTenantRating();
      }
    })

    this.isUserLoading$ = this.userFacade.isUserLoading$;
    this.editingUser$ = this.userFacade.editingUser$;

  }

  profileOwnerRating(){
    let data = 'owner/'+ this.userId;
    this.feedbackService.getProfileRating(data).subscribe(data =>{
      this.ratingAsOwner = data;
    },
    (error) => {
      this.ratingAsOwner = "";
    })    
  }
  profileOTenantRating(){
    let data = 'tenant/'+ this.userId;
    this.feedbackService.getProfileRating(data).subscribe(data =>{
      this.ratingAsTenant = data;
    },
    (error) => {
      this.ratingAsTenant = "";
    })
  }
  onExit(): void {
    this.router.navigate(['view-property']);
  }

  onSave(formValue): void {
    this.showProfileValidationMessage = false;
    if(this.profileForm.invalid)
    {
      this.showProfileValidationMessage = true;
      return;
    }
    this.userFacade.updateUser(this.currentUser.id, {
      phoneNumber: this.currentUser.phoneNumber,
      name: formValue.name,
      zipCode: formValue.zipCode ? +(formValue.zipCode) : null,
      emailAddress: formValue.emailAddress,
      pan: formValue.panNo,
      aadhar: formValue.aadharNo.toString()
    })
    this.editingUser$.subscribe((data) => {
      if(!data)
      {
        this._snackBar.open('Profile Updated Successfully.', "X", {
          duration: 10000,
          panelClass: ['snackbar-success']
        });
      }
    })
  }

  private initForm(){
    this.profileForm = new FormGroup({
      name: new FormControl(this.currentUser?.name ?? '', [
        Validators.required
      ]),
      mobileNo: new FormControl({value:this.currentUser?.phoneNumber ?? '', disabled: true}, [
        Validators.required
      ]),
      emailAddress: new FormControl(this.currentUser?.emailAddress ?? '', [
        Validators.required
      ]),
      panNo: new FormControl(this.currentUser?.pan ?? '',[
        Validators.minLength(10),
        Validators.maxLength(10)
      ]),
      aadharNo: new FormControl(this.currentUser?.aadhar ?? '',[
        Validators.min(100000000000),
        Validators.max(999999999999)
      ]),
      zipCode: new FormControl(this.currentUser?.zipCode == 0 ? '' : this.currentUser?.zipCode, []),
    });
  }

}
