<head>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="description" content="Manage your rental property with ease using our AI-powered platform. Get personalized recommendations, flexible rent payment options, automated reminders, and centralized document management. Trust and transparency are at the core of our platform, making each rental transaction secure and hassle-free. Simplify your rental journey today.">
    <meta name="keywords" content="Rental finance management, Property management software, Tenant management system, Rent payment processing, Property listing management, Lease agreement management, Online rent payment, Rental property accounting, Tenant screening, Rent collection automation, Maintenance request management, Property owner dashboard, Real estate software, Online property management, Property management platform, Rental management app, Property management tool, Automated rent collection, Tenant communication platform">
    <meta name="robots" content="index, follow">
</head>
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-Y3D2LBP9KV"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-Y3D2LBP9KV');
</script>
<!-- Google tag (gtag.js) -->
<div class="container">
    <rentofly-loading *ngIf="sendingEmail"></rentofly-loading>
    <div style="text-align: center;">
        <h4 class="contact-header">Get in touch with us !</h4>
    </div>
    <div class="heading-line"></div>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6 contact-header-img">
        </div>
        <div class="col-md-3 heade-social-div">
            <h5 class="header-email">Email</h5>
            <a href="mailto:contact@rentofly.com" class="email-link">info@rentofly.com</a>
            <h5 class="header-email" style="margin-top: 10px;">Call us</h5>
            <a href="tel:7700990949" class="email-link">+91-7700990949</a>
            <div class="header-social-icons-div">
                <a href="https://www.youtube.com/channel/UCeAKf0Or8Tx_UlKegdEXpag" class="social-link" target="_blank">
                    <!-- <i class="fa fa-youtube footer-icon"></i> -->
                    <img src="../../../../assets/images/new-img/Youtube.svg" width="40" height="33">
                </a>
                <a href="https://mobile.twitter.com/rentofly" class="social-link" target="_blank">
                    <!-- <i class="fa fa-twitter footer-icon"></i> -->
                    <img src="../../../../assets/images/new-img/carbon_logo-x.svg" width="35" height="35" style="margin-right: 5px;">
                </a>
                <a href="https://www.facebook.com/rentofly/" class="social-link" target="_blank">
                    <!-- <i class="fa fa-facebook footer-icon"></i> -->
                    <img src="../../../../assets/images/new-img/Fb.svg" width="30" height="30">
                </a>
                <a href="https://www.linkedin.com/company/rentofly" class="social-link" target="_blank">
                    <!-- <i class="fa fa-linkedin footer-icon"></i> -->
                    <img src="../../../../assets/images/new-img/In.svg" width="30" height="40">
                </a>
                <a href="https://www.instagram.com/rentofly/" class="social-link" target="_blank">
                    <!-- <i class="fa fa-instagram footer-icon"></i> -->
                    <img src="../../../../assets/images/new-img/Insta.svg" width="30" height="37">
                </a>
            </div>
        </div>
    </div>
    <p-card class="contact-card add-property-card">
        <div class="row form-row ">
            <div class="col-md-12 contact-parent-div">
                <form [formGroup]="contactUsForm" class="contact-us-info">
                    <div class="form-group contact-main-div">
                        <span class="p-float-label contact-span contact-us-page">
                        <input id="float-input" class="contact-input" type="text" formControlName="fullName" pInputText> 
                        <label for="float-input" class="input-label">Name</label>
                        
                    </span>
                        <div *ngIf="fullName!.invalid && showContactValidationMessage" class="rentofly-login-validation-msg ">
                            <div>
                                Full Name is invalid.
                            </div>
                        </div>
                    </div>
                    <div class="form-group contact-div">
                        <span class="p-float-label contact-span contact-us-page">
                        <input id="float-input" class="contact-input" type="text" formControlName="email" pInputText> 
                        <label for="float-input" class="input-label">Email</label>
                        
                    </span>
                        <div *ngIf="email!.invalid && showContactValidationMessage" class="rentofly-login-validation-msg ">
                            <div>
                                Email is invalid.
                            </div>
                        </div>
                    </div>
                    <div class="form-group contact-div">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <span>+91</span>
                            </span>
                            <span class="p-float-label">
                                <input type="text" id="inputgroup contactNumber" class="contact-input" pInputText formControlName="contactNumber" 
                                    onKeyPress="if(this.value.length==10) return false;" OnlyNumber="true">  
                                <label for="inputgroup" class="input-label">Mobile #</label>
                            
                            </span>
                        </div>
                        <div *ngIf="contactNumber!.invalid && showContactValidationMessage" class="rentofly-login-validation-msg ">
                            <div>
                                Contact # is invalid.
                            </div>
                        </div>
                    </div>
                    <div class="form-group contact-div">
                        <span class="p-float-label contact-us-page" style="display: inline;">
                        <textarea id="float-input" rows="5" cols="30" pinputtextarea="" formControlName="additionalDetail"
                        [ngClass]="{'textArea-filled':contactUsForm.value.additionalDetail!==''}" class="p-inputtextarea p-inputtext p-component contact-input">
                        </textarea>
                        <label for="float-input" class="input-label">Enquiry/Feedback</label>
                        
                    </span>
                        <div *ngIf="additionalDetail!.invalid && showContactValidationMessage" class="rentofly-login-validation-msg ">
                            <div>
                                Feedback is required.
                            </div>
                        </div>
                    </div>
                    <div class="action">
                        <button class="rentofly-tertiary contact-confirm-btn" (click)="sendEmail(contactUsForm.value)">Submit</button>
                        <!-- <button class="rentofly-secondary">Cancel</button> -->
                    </div>
                </form>
            </div>
        </div>
    </p-card>
</div>