import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Email, Owner, Tenant, UpdateDeal, ViewProperty, AdditionalPropertyDetail } from 'src/app/shared/models';
import { DateService, LocalStorageService } from 'src/app/shared/services';
import { FeatureToggleService } from 'src/app/shared/services/feature-toggle.service';
import { OwnerStoreFacade } from 'src/app/store/facades/owner.store-facade';
import { PropertyStoreFacade } from 'src/app/store/facades/property.store-facade';
import { TenantStoreFacade } from 'src/app/store/facades/tenant.store-facade';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmBoxComponent, ConfirmCommentBoxComponent } from 'src/app/shared/components';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { RejectTemplate, ApproveTemplate, LeaseTerminationTemplate, TerminationApprovalTemplate, TerminationCancelledTemplate } from 'src/app/shared/emailTemplates';
import { FeedbackService } from 'src/app/shared/services/feedback.service';
import { DealStoreFacade } from 'src/app/store/facades';
import { ConfirmDateBoxComponent } from 'src/app/shared/components/confirm-date-box/confirm-date-box.component';
import { DomSanitizer } from '@angular/platform-browser';
import { EmailWhatsapp } from 'src/app/shared/models/email-whatsapp.model';

@Component({
  selector: 'app-view-property-info',
  templateUrl: './view-property-info.component.html',
  styleUrls: ['./view-property-info.component.scss']
})
export class ViewPropertyInfoComponent implements OnInit, OnDestroy {
  propertyId: string;
  currentViewPropertyLoading$: Observable<boolean>;
  currentViewProperty: ViewProperty;
  additionalDetails: AdditionalPropertyDetail;
  currentUser:any;
  userType: string;
  public dateTimeFormatOptions = { month: "long", day: "numeric", year: "numeric" } as const;
  isConsentGiven: boolean;
  ownerPanData;
  tenantPanData;
  tenantAadharData;
  editingOwner$: Observable<boolean>;
  editingDeal$: Observable<boolean>;
  editingProperty$: Observable<boolean>;
  editingTenant$: Observable<boolean>;
  activeState: boolean[] = [true, false, false, false, false];
  tenantDetailStatus = true;
  ownerDetailStatus = true;
  ownerInfoForm: FormGroup;
  tenantInfoForm: FormGroup;
  feedbackUrl;
  feedbackData;
  whatsappUrl: string;
  constructor(
    public readonly router: Router,
    private readonly propertyFacade: PropertyStoreFacade,
    private readonly ownerFacade: OwnerStoreFacade,
    private readonly tenantFacade: TenantStoreFacade,
    private readonly dealFacade: DealStoreFacade,
    private readonly route: ActivatedRoute,
    private readonly localStorageService: LocalStorageService,
    private readonly dialog: MatDialog,
    private readonly dateService: DateService,
    private readonly featureToggleService: FeatureToggleService,
    private readonly feedbackService: FeedbackService,
    private sanitizer: DomSanitizer
  ) { }
  ngOnDestroy(): void {
    this.feedbackService.previousData(null);
  }

  get ownerName() {
    return this.ownerInfoForm!.get('ownerName');
  }
  get ownerPhone() {
    return this.ownerInfoForm!.get('ownerPhone');
  }
  get ownerEmail() {
    return this.ownerInfoForm!.get('ownerEmail');
  }
  get ownerPan() {
    return this.ownerInfoForm!.get('ownerPan');
  }
  get ownerConstent() {
    return this.ownerInfoForm!.get('ownerConstent');
  }

  get tenantName() {
    return this.tenantInfoForm!.get('tenantName');
  }
  get tenantPhone() {
    return this.tenantInfoForm!.get('tenantPhone');
  }
  get tenantEmail() {
    return this.tenantInfoForm!.get('tenantEmail');
  }
  get tenantAadhar() {
    return this.tenantInfoForm!.get('tenantAadhar');
  }
  get tenantDetails() {
    return this.tenantInfoForm!.get('tenantDetails');
  }
  get tenantConsentStatus() {
    return this.tenantInfoForm!.get('tenantConsentStatus');
  }
  get tenantPan() {
    return this.tenantInfoForm!.get('tenantPan');
  }

  ngOnInit(): void {
    this.currentUser = this.localStorageService.getObjectFromLocalStorage('current-user');
    this.checkRouteForCurrentProperty();
    this.getAdditionalPropertyDetail();
    this.editingOwner$ = this.ownerFacade.editingOwner$;
    this.editingTenant$ = this.tenantFacade.editingTenant$;
    this.editingDeal$ = this.dealFacade.dealEditing$;
    this.editingProperty$ = this.propertyFacade.propertyEditing$;
    this.initOwnerForm(); 
    this.initTenantForm();  
    this.setUserForCurrentViewProperty();
    this.whatsappUrl = `https://api.whatsapp.com/send?text=https://dev.rentofly.com/share?propertyId=${this.propertyId}`
  }

  private initOwnerForm(ownerFormVal = null) {
    if(ownerFormVal !== null){
      if(this.currentUser.id == this.currentViewProperty.ownerUserId){
        this.ownerPanData = this.currentViewProperty.ownerPanCard;
      } else {
        this.ownerPanData = this.currentViewProperty.ownerPanCard?.replace(/\w(?=\w{4})/g, "*")
      }
    }

    this.ownerInfoForm = new FormGroup({
      ownerName: new FormControl({value:ownerFormVal?.ownerName ?? '', disabled: true}, [
        Validators.required
      ]),
      ownerPhone: new FormControl({value:ownerFormVal?.ownerPhone ?? '', disabled: true}, [
        Validators.required
      ]),
      ownerEmail: new FormControl({value:ownerFormVal?.ownerEmail ?? '', disabled: true}, [
        Validators.required
      ]),
      ownerPan: new FormControl(this.ownerPanData ?? '', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]),
      ownerConstent: new FormControl({value:ownerFormVal?.ownerConsentStatus ?? '', disabled: true}, [
        Validators.required
      ]),
      ownerRejectReason: new FormControl({value:ownerFormVal?.ownerRejectReason ?? '', disabled: true}, [
      ]),
    });
  }

  private initTenantForm(tenantFormVal = null) {
    if(tenantFormVal !== null){
      if(this.currentUser.id == this.currentViewProperty.tenantUserId){
        this.tenantPanData = this.currentViewProperty.tenantPanCard
        this.tenantAadharData = this.currentViewProperty.tenantAadhar
      } else {
        this.tenantPanData = this.currentViewProperty.tenantPanCard?.replace(/\w(?=\w{4})/g, "*");
        this.tenantAadharData = this.currentViewProperty.tenantAadhar?.replace(/\d(?=\d{4})/g, "*");
      }
    }
    
    this.tenantInfoForm = new FormGroup({
      tenantName: new FormControl({value:tenantFormVal?.tenantName ?? '', disabled: true}, [
        Validators.required,
      ]),
      tenantPhone: new FormControl({value:tenantFormVal?.tenantPhone ?? '' , disabled: true}, [
        Validators.required
      ]),
      tenantEmail: new FormControl({value:tenantFormVal?.tenantEmail ?? '', disabled: true}, [
        Validators.required
      ]),
      tenantPan: new FormControl(this.tenantPanData ?? '', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]),
      tenantAadhar: new FormControl(this.tenantAadharData ?? '', [
        Validators.required,
        Validators.minLength(12),
        Validators.maxLength(12)
      ]),
      tenantDetails: new FormControl({value:tenantFormVal?.tenantDetails ?? '', disabled: true}, [
        Validators.required
      ]),
      tenantConsentStatus: new FormControl({value:tenantFormVal?.tenantConsentStatus ?? '', disabled: true}, [
        Validators.required
      ]),
      tenantRejectReason: new FormControl({value:tenantFormVal?.tenantRejectReason ?? '', disabled: true}, [
      ]),
    });
  }

  enableEditProperty(){
    return this.currentViewProperty.ownerConsentStatus == 'Approved' &&
      this.currentViewProperty.tenantConsentStatus == 'Approved';
  }

  isEditVisible(){
    let visible = false;
    if(this.currentViewProperty?.dealStatus == 'Completed' ||
    this.currentViewProperty?.dealStatus == 'Termination in Progress' ||
    this.currentViewProperty?.dealStatus == 'Notice Period' ||
    this.currentViewProperty?.dealStatus == 'Terminated'){
      return visible;
    }
    // console.log(this.currentViewProperty.createdBy)
    // console.log(this.currentViewProperty.ownerUserId)
    // console.log(this.currentViewProperty.brokerUserId)
    // console.log("---------")
    if(this.currentViewProperty.createdBy != this.currentViewProperty.ownerUserId
      && this.currentViewProperty.createdBy != this.currentViewProperty.brokerUserId)
    {
      visible = this.currentViewProperty.brokerUserId == this.currentUser.id 
      || this.currentViewProperty.ownerUserId == this.currentUser.id 
    }
    visible = visible || this.currentViewProperty.createdBy == this.currentUser.id
    return visible && this.currentViewProperty?.dealStatus != 'Completed';
  }

  editProperty(){
    this.router.navigate(['dashboard/add-property'], { queryParams: { propertyIdentifier: this.currentViewProperty.id }});
  }

  onExit(){
    if(this.currentViewProperty.dealStatus == 'Completed' ||
    this.currentViewProperty.dealStatus == 'Termination in Progress' ||
    this.currentViewProperty.dealStatus == 'Notice Period')
    {
      this.router.navigate(['dashboard/view-property/rented-property']);
      return;
    }
    
    if(this.currentViewProperty.dealStatus == 'Terminated')
    {
      this.router.navigate(['dashboard/view-property/terminated-property']);
      return;
    }
    this.router.navigate(['dashboard/view-property/available-property']);
  }

  giveConsent(userType: string): void{
    if(userType === 'Owner'){
        if (this.ownerInfoForm.invalid){
          this.ownerDetailStatus = false;
          this.activeState = [false, false, true, false, false];
          return;
        } else{
          this.consentAlert(userType);
        }
    } else {
      if (this.tenantInfoForm.invalid){
        this.activeState = [false, false, false, true, false];
        return;
      }else{
        this.consentAlert(userType);
      }
    }
  }

  reject(userType: string): void{
    this.rejectAlert(userType);
  }

  consentAlert(userType: string): void{
    const confirmDialog = this.dialog.open(ConfirmBoxComponent, {
      disableClose: false,
    });
    confirmDialog.componentInstance.confirmMessageHeader = `Approve`;
    confirmDialog.componentInstance.confirmMessageContent = `All the information is correct. I would like to give my approval.`;
    confirmDialog.afterClosed().subscribe(result => {
      if(result)
      {
        this.updateConsent(userType);
      }
    });
  }

  rejectAlert(userType: string): void{
    const confirmDialog = this.dialog.open(ConfirmCommentBoxComponent, {
      disableClose: false,
    });
    confirmDialog.componentInstance.confirmMessageHeader = `Reject`;
    confirmDialog.componentInstance.confirmMessageContent = `Please enter a reason for rejection:`;
    confirmDialog.componentInstance.confirmButtonText = `Reject`;
    confirmDialog.afterClosed().subscribe(result => {
      if(result)
      {
        this.updateConsent(userType, result.commentText);
      }
    });
  }

  updateConsent(userType: string, rejectReason = null){
    let emails = this.getEmails(userType, rejectReason);
    if(userType == "Owner")
    {
      let updateOwner: Owner = {
        id: this.currentViewProperty.ownerId,
        userId: this.currentViewProperty.ownerUserId,
        phoneNumber: this.currentViewProperty.ownerPhone.toString(),
        pan: this.ownerInfoForm.value.ownerPan,
        aadhar: this.ownerInfoForm.value?.ownerAadhar,
        name: this.currentViewProperty.ownerName,
        ownerConsentStatus: rejectReason ? 'Rejected' : 'Approved',
        emailAddress: this.currentViewProperty.ownerEmail,
        rejectReason: rejectReason
      }
      this.ownerFacade.updateOwner(this.currentViewProperty.id, updateOwner, emails);
    }
    if(userType == "Tenant")
    {
      let updateTenant: Tenant = {
        id: this.currentViewProperty.tenantId,
        userId: this.currentViewProperty.tenantUserId,
        phoneNumber: this.currentViewProperty.tenantPhone.toString(),
        tenantPanCard: this.tenantInfoForm.value.tenantPan,
        tenantAadhar: this.tenantInfoForm.value.tenantAadhar,
        name: this.currentViewProperty.tenantName,
        tenantConsentStatus: rejectReason ? 'Rejected' : 'Approved',
        emailAddress: this.currentViewProperty.tenantEmail,
        tenantDetails: this.currentViewProperty.tenantDetails,
        rejectReason: rejectReason
      }
      this.tenantFacade.updateTenant(this.currentViewProperty.id, updateTenant, emails);
    }
    combineLatest([this.editingTenant$, this.editingOwner$])
    .subscribe(([ownerEdit, tenantEdit]) => {
      if(!ownerEdit && !tenantEdit)
      {
        this.onExit();
      }
    })
  }

  getEmails(userType: string, rejectReason: string): EmailWhatsapp[]{
    let emails: EmailWhatsapp[] = [];
    if(this.currentViewProperty.brokerEmail){
      // let brokerEmail: Email = {
      //   to: this.currentViewProperty.brokerEmail,
      //   from: environment.emailUsers.infoEmail,
      //   subject: rejectReason ? `'Rentofly - ${userType} Reject on Property'` : `'Rentofly - ${userType} Approve on Property'`,
      //   htmlBody: this.getEmailBody(userType, rejectReason, this.currentViewProperty.brokerPhone, this.currentViewProperty.brokerName)
      // }
      let brokerEmail: EmailWhatsapp = {
        senderName: 'Team rentofly',
        receiverName: this.currentViewProperty.brokerName,
        receiverEmail: this.currentViewProperty.brokerEmail,
        phoneNumber: this.currentViewProperty.brokerPhone,
        type: "",
        template: rejectReason ? "reject_template" : "approve_template",
        messageParameters: {
          subjectTextValue: rejectReason ? `Rentofly - ${userType} Reject on Property` : `Rentofly - ${userType} Approve on Property`,
          userName: this.currentViewProperty.brokerName,
          userType: userType,
          propertyCode: this.currentViewProperty.propertyCode,
          propertyName: this.currentViewProperty.propertyName,
          City: this.currentViewProperty.city,
          State: this.currentViewProperty.state,
          zipCode: this.currentViewProperty.zipCode.toString(),
          userMobile: this.currentViewProperty.brokerPhone
        }
      }
      emails.push(brokerEmail);
    }

    if(userType != 'Owner')
    {
      // let ownerEmail: Email = {
      //   to: this.currentViewProperty.ownerEmail,
      //   from: environment.emailUsers.infoEmail,
      //   subject: rejectReason ? `'Rentofly - ${userType} Reject on Property'` : `'Rentofly - ${userType} Approve on Property'`,
      //   htmlBody: this.getEmailBody(userType, rejectReason, this.currentViewProperty.ownerPhone, this.currentViewProperty.ownerName)
      // }
      let ownerEmail: EmailWhatsapp = {
        senderName: 'Team rentofly',
        receiverName: this.currentViewProperty.ownerName,
        receiverEmail: this.currentViewProperty.ownerEmail,
        phoneNumber: this.currentViewProperty.ownerPhone,
        type: "",
        template: rejectReason ? "reject_template" : "approve_template",
        messageParameters: {
          subjectTextValue: rejectReason ? `Rentofly - ${userType} Reject on Property` : `Rentofly - ${userType} Approve on Property`,
          userName: this.currentViewProperty.ownerName,
          userType: userType,
          propertyCode: this.currentViewProperty.propertyCode,
          propertyName: this.currentViewProperty.propertyName,
          City: this.currentViewProperty.city,
          State: this.currentViewProperty.state,
          zipCode: this.currentViewProperty.zipCode.toString(),
          userMobile: this.currentViewProperty.ownerPhone
        }
      }
      emails.push(ownerEmail);
    }
    if(userType != 'Tenant')
    {
      // let tenantEmail: Email = {
      //   to: this.currentViewProperty.tenantEmail,
      //   from: environment.emailUsers.infoEmail,
      //   subject: rejectReason ? `'Rentofly - ${userType} Reject on Property'` : `'Rentofly - ${userType} Approve on Property'`,
      //   htmlBody: this.getEmailBody(userType, rejectReason, this.currentViewProperty.tenantPhone, this.currentViewProperty.tenantName)
      // }
      let tenantEmail: EmailWhatsapp = {
        senderName: 'Team rentofly',
        receiverName: this.currentViewProperty.tenantName,
        receiverEmail: this.currentViewProperty.tenantEmail,
        phoneNumber: this.currentViewProperty.tenantPhone,
        type: "",
        template: rejectReason ? "reject_template" : "approve_template",
        messageParameters: {
          subjectTextValue: rejectReason ? `Rentofly - ${userType} Reject on Property` : `Rentofly - ${userType} Approve on Property`,
          userName: this.currentViewProperty.tenantName,
          userType: userType,
          propertyCode: this.currentViewProperty.propertyCode,
          propertyName: this.currentViewProperty.propertyName,
          City: this.currentViewProperty.city,
          State: this.currentViewProperty.state,
          zipCode: this.currentViewProperty.zipCode.toString(),
          userMobile: this.currentViewProperty.tenantPhone
        }
      }
      emails.push(tenantEmail);
    }
    if(this.currentViewProperty.tenantPhone != this.currentViewProperty.creatorPhone
      && this.currentViewProperty.ownerPhone != this.currentViewProperty.creatorPhone
      && this.currentViewProperty.brokerPhone != this.currentViewProperty.creatorPhone)
      {
        // let creatorEmail: Email = {
        //   to: this.currentViewProperty.creatorEmail,
        //   from: environment.emailUsers.infoEmail,
        //   subject: rejectReason ? `'Rentofly - ${userType} Reject on Property'` : `'Rentofly - ${userType} Approve on Property'`,
        //   htmlBody: this.getEmailBody(userType, rejectReason, this.currentViewProperty.creatorPhone, this.currentViewProperty.creatorName)
        // }
      let creatorEmail: EmailWhatsapp = {
        senderName: 'Team rentofly',
        receiverName: this.currentViewProperty.creatorName,
        receiverEmail: this.currentViewProperty.creatorEmail,
        phoneNumber: this.currentViewProperty.creatorPhone,
        type: "",
        template: rejectReason ? "reject_template" : "approve_template",
        messageParameters: {
          subjectTextValue: rejectReason ? `Rentofly - ${userType} Reject on Property` : `Rentofly - ${userType} Approve on Property`,
          userName: this.currentViewProperty.creatorName,
          userType: userType,
          propertyCode: this.currentViewProperty.propertyCode,
          propertyName: this.currentViewProperty.propertyName,
          City: this.currentViewProperty.city,
          State: this.currentViewProperty.state,
          zipCode: this.currentViewProperty.zipCode.toString(),
          userMobile: this.currentViewProperty.creatorPhone
        }
      }
        emails.push(creatorEmail);
      }
    return emails;
  }

  getEmailBody(userType: string, rejectReason = null, phone: string, name: string){
    if(rejectReason)
    {
      return RejectTemplate.replace('{Property Code}', this.currentViewProperty.propertyCode)
      .replace('{Property Name}', this.currentViewProperty.propertyName)
      .replace('{City}', this.currentViewProperty.city)
      .replace('{State}', this.currentViewProperty.state)
      .replace('{Zip Code}', this.currentViewProperty.zipCode.toString())
      .replace('{Website URl}', environment.url)
      .replace('{Reject Reason}', rejectReason)
      .replace('{User Type}', userType)
      .replace('{User Mobile}', phone)
      .replace('{UserName}', name)
      .replace('{User}', userType)
    } else{
      return ApproveTemplate.replace('{Property Code}', this.currentViewProperty.propertyCode)
      .replace('{Property Name}', this.currentViewProperty.propertyName)
      .replace('{City}', this.currentViewProperty.city)
      .replace('{State}', this.currentViewProperty.state)
      .replace('{Zip Code}', this.currentViewProperty.zipCode.toString())
      .replace('{Website URl}', environment.url)
      .replace('{User Type}', userType)
      .replace('{User Mobile}', phone)
      .replace('{UserName}', name)
      .replace('{User}', userType)
    }

  }

  getFormattedDate(date: Date){
    if(date !== undefined) {
      const formattedDate = this.dateService.getFormattedDate(date);
      const newdate =  new Date(formatDate(formattedDate, 'medium', 'en-US'));
      return newdate.toLocaleString('en-US', this.dateTimeFormatOptions);
    }
  }

  getPropertyStatus(propertyInfo: ViewProperty): string{
    if(propertyInfo.dealStatus == 'Completed')
    {
      return 'Rented';
    }
    if(propertyInfo.dealStatus == 'Termination in Progress')
    {
      return 'Deal Termination in Progress';
    }
    if(propertyInfo.dealStatus == 'Notice Period')
    {
      return 'On notice period';
    }
    if(propertyInfo.dealStatus == 'Terminated')
    {
      return 'Terminated';
    }
    if(propertyInfo.dealStatus)
    {
      if(propertyInfo.ownerConsentStatus == 'Approved' && propertyInfo.tenantConsentStatus == 'Approved')
      {
        return 'Draft Agreement';
      }
      if(propertyInfo.ownerConsentStatus == 'Pending' && propertyInfo.tenantConsentStatus == 'Pending')
      {
        return 'Consent Pending';
      }
      if(propertyInfo.ownerConsentStatus == 'Rejected' || propertyInfo.tenantConsentStatus == 'Rejected')
      {
        return 'Sent back for review';
      }
      if(propertyInfo.ownerConsentStatus == 'Pending')
      {
        return 'Owner Consent Pending';
      }
      if(propertyInfo.tenantConsentStatus == 'Pending')
      {
        return 'Tenant Consent Pending';
      }
      
    }
    
    return 'Available';
  }

  public viewActionButton(){
    if(this.currentViewProperty.dealStatus == 'Termination in Progress'
    || this.currentViewProperty.dealStatus == 'Notice Period'
    || this.currentViewProperty.dealStatus == 'Terminated'
    || this.currentViewProperty?.dealStatus == null){
      return false;
    }
    if(this.userType == 'Owner'){
      return !this.isConsentGiven &&
        this.currentViewProperty.ownerConsentStatus != 'Rejected'
    }
    if(this.userType == 'Tenant'){
      return !this.isConsentGiven &&
        this.currentViewProperty.tenantConsentStatus != 'Rejected' 
    }
    return false;
  }

  getDealStatus(propertyInfo: ViewProperty): string{
    if(propertyInfo.dealStatus == 'Termination in Progress')
    {
      return 'Termination in Progress';
    }
    if(propertyInfo.dealStatus == 'Notice Period')
    {
      return 'Notice Period';
    }
    if(propertyInfo.dealStatus == 'Completed')
    {
      return 'On Rent';
    }
    if(propertyInfo.dealStatus == 'Terminated')
    {
      return 'Terminated';
    }
    if(propertyInfo.dealStatus)
    {
      if(propertyInfo.ownerConsentStatus == 'Approved' && propertyInfo.tenantConsentStatus == 'Approved')
      {
        return 'Draft Agreement';
      }
      if(propertyInfo.ownerConsentStatus == 'Pending' && propertyInfo.tenantConsentStatus == 'Pending')
      {
        return 'Consent Pending';
      }
      if(propertyInfo.ownerConsentStatus == 'Pending')
      {
        return 'Owner Consent Pending';
      }
      if(propertyInfo.tenantConsentStatus == 'Pending')
      {
        return 'Tenant Consent Pending';
      }
      
    }
    return 'Pending';
  }

  private setUserForCurrentViewProperty(){
    this.propertyFacade.currentViewProperty$.subscribe((data) => {
      this.currentViewProperty = data;
      if(data != null){
        this.initOwnerForm(this.currentViewProperty);
        this.initTenantForm(this.currentViewProperty);
        if(this.currentViewProperty.dealStatus !== 'Pending')
        {
          this.tenantInfoForm.disable();
          this.ownerInfoForm.disable();
          this.tenantInfoForm.get('tenantPan').setValue(this.currentViewProperty.tenantPanCard?.replace(/\w(?=\w{4})/g, "*"));
          this.tenantInfoForm.get('tenantAadhar').setValue(this.currentViewProperty.tenantAadhar?.replace(/\d(?=\d{4})/g, "*"));
         
        }
        
        if(this.currentUser.id == this.currentViewProperty.ownerUserId)
        {
          this.userType = "Owner";
          this.tenantInfoForm.disable();
          this.isConsentGiven = this.currentViewProperty.ownerConsentStatus == 'Approved';
          this.feedbackUrl = "tenant/" + this.currentViewProperty.id + '/' + this.currentViewProperty.ownerUserId + '/' + this.currentViewProperty.tenantUserId;
        }
        else if(this.currentUser.id == this.currentViewProperty.tenantUserId){
          this.userType ="Tenant";
          this.ownerInfoForm.disable();
          this.isConsentGiven = this.currentViewProperty.tenantConsentStatus == 'Approved';
          this.feedbackUrl = "owner/" + this.currentViewProperty.id + '/' + this.currentViewProperty.tenantUserId + '/' + this.currentViewProperty.ownerUserId
        }
        else if(this.currentUser.id == this.currentViewProperty.brokerUserId){
          this.userType = "Broker";
        }
        else if(this.currentUser.id == this.currentViewProperty.createdBy){
          this.userType ="";
        }
        else{
          this.onExit();
        }
        
      }
      
    });
  }

  getAdditionalPropertyDetail(){
    this.propertyFacade.queryPropertyDetailsByPropertyId(this.propertyId);
    this.propertyFacade.currentAdditionalPropertyDetails$.subscribe((data) => {
      this.additionalDetails = data;
    })
  }

  private checkRouteForCurrentProperty(){
    this.route.queryParams.pipe(
      filter(params => params.propertyIdentifier)
    ).subscribe((params) => {
      if(params.propertyIdentifier == null)
      {
        this.router.navigate(['view-property']);
      }
      this.propertyId = params.propertyIdentifier
      this.currentViewPropertyLoading$ = this.propertyFacade.currentViewPropertyLoading$;
      this.propertyFacade.queryViewPropertyById(this.propertyId);
    })
  }
  public isfeatureEnabled() {
    return this.featureToggleService.isFeatureEnabled('uploadFeature');
  }
  onPropertyTabChange(event) {
    if(event.index === 2){
      this.feedbackService.previousData(null);
      this.feedbackService.getPreviousRating(this.feedbackUrl).subscribe(data => {
        this.feedbackService.previousData(data);
      },
      (error) => {
        this.feedbackService.previousData("No Results Found");
      })
    }
  }

  viewNoticeEndDate(status){
    return status === 'Termination in Progress' || status === 'Notice Period';
  }

  getNoticePeriod(noticePeriod){
    if(noticePeriod && noticePeriod > 0)
    {
      return noticePeriod + ' days'
    }
    else {
      return '30 days'
    }
  }

  viewTerminateProperty(userType: string){
    if(this.currentViewProperty.dealStatus == 'Completed'  && 
    (this.currentUser.id == this.currentViewProperty.ownerUserId ||
    this.currentUser.id == this.currentViewProperty.tenantUserId))
      return true;
    
    if(this.currentViewProperty.dealStatus == 'Termination in Progress'){
      if(userType == 'Owner' && this.currentViewProperty.ownerConsentStatus == 'Pending')
      {
        return true;
      }
      if(userType == 'Tenant' && this.currentViewProperty.tenantConsentStatus == 'Pending')
      {
        return true;
      }
    }
     
  }

  viewCancelTerminate(userType: string){
    if((this.currentViewProperty.dealStatus == 'Notice Period' ||
    this.currentViewProperty.dealStatus == 'Termination in Progress')  && 
    (this.currentUser.id == this.currentViewProperty.ownerUserId ||
    this.currentUser.id == this.currentViewProperty.tenantUserId))
      return true;
    
    if(this.currentViewProperty.dealStatus == 'Termination in Progress'){
      if(userType == 'Owner' && this.currentViewProperty.ownerConsentStatus == 'Pending')
      {
        return true;
      }
      if(userType == 'Tenant' && this.currentViewProperty.tenantConsentStatus == 'Pending')
      {
        return true;
      }
    }
     
  }

  terminate(userType: string): void{
    this.terminateAlert(userType);
  }

  terminateAlert(userType: string): void{
    if(this.currentViewProperty.dealStatus == 'Termination in Progress'){
      const confirmDialog = this.dialog.open(ConfirmBoxComponent, {
        disableClose: false,
      });
      confirmDialog.componentInstance.confirmMessageHeader = `Terminate`;
      confirmDialog.componentInstance.confirmMessageContent = `Do you want to terminate the lease on the property on ${this.getFormattedDate(this.currentViewProperty.noticeEndDate)}?`;
      confirmDialog.componentInstance.confirmButtonText = "Terminate";
      confirmDialog.afterClosed().subscribe(result => {
        if(result)
        {
          if(this.currentViewProperty.dealStatus == 'Termination in Progress')
          {
            this.approveTermination(userType, this.currentViewProperty.noticeEndDate);
            // this.onExit();
          }
          combineLatest([this.editingDeal$, this.editingProperty$])
          .subscribe(([dealEdit, propertyEdit]) => {
            if(!dealEdit && !propertyEdit)
            {
              this.onExit();
            }
          })
        }
      });

      return;
    }
    const confirmDialog = this.dialog.open(ConfirmDateBoxComponent, {
      disableClose: false,
    });
    confirmDialog.componentInstance.confirmMessageHeader = `Terminate`;
    confirmDialog.componentInstance.confirmMessageContent = `Do you want to terminate the lease on the property?`;
    var noticeEndDate = new Date();
    noticeEndDate.setDate(noticeEndDate.getDate() + this.currentViewProperty.noticePeriod);
    confirmDialog.componentInstance.dateFieldValue = noticeEndDate;
    confirmDialog.componentInstance.confirmButtonText = "Terminate";
    confirmDialog.afterClosed().subscribe(result => {
      if(result)
      {
        if(this.currentViewProperty.dealStatus == 'Completed')
        {
          this.terminateLease(userType, result.noticeEndDate);
          // this.onExit();
        }
        combineLatest([this.editingDeal$])
        .subscribe(([dealEdit]) => {
          if(!dealEdit)
          {
            this.onExit();
          }
        })
      }
    });
  }

  approveTermination(userType: string, noticeEndDate: Date){
    var todayDate = new Date();
    var noticeEndDateFormatted = new Date(noticeEndDate);
    if(noticeEndDateFormatted.getDate() <= todayDate.getDate()){
      this.ownerFacade.updateOwnerConsent(this.currentViewProperty.ownerId, 'Approved');
      this.tenantFacade.updateTenantConsent(this.currentViewProperty.tenantId, 'Approved');
      this.propertyFacade.terminateProperty(this.currentViewProperty.id);
      return;
    }
    let subject = `Rentofly - ${userType} Approved Termination on Property`;
    if(userType == 'Owner')
    {
      this.ownerFacade.updateOwnerConsent(this.currentViewProperty.ownerId, 'Approved');
      this.dealFacade.updateDealStatus(this.currentViewProperty.dealId, 'Notice Period', this.getEmailsForTeminationProcess(userType, subject, 'Approved'))
    }

    if(userType == 'Tenant')
    {
      this.tenantFacade.updateTenantConsent(this.currentViewProperty.tenantId, 'Approved');
      this.dealFacade.updateDealStatus(this.currentViewProperty.dealId, 'Notice Period', this.getEmailsForTeminationProcess(userType, subject, 'Approved'))
    }
  }

  terminateLease(userType: string, noticeEndDate: Date){
    let subject = `Rentofly - ${userType} Initiated Termination on Property`
    if(userType == 'Owner')
    {
      this.tenantFacade.updateTenantConsent(this.currentViewProperty.tenantId, 'Pending');
      let updateDeal: UpdateDeal = {
        dealStatus: 'Termination in Progress',
        noticeEndDate: noticeEndDate
      }
      this.dealFacade.updateNoticeDateAndStatus(this.currentViewProperty.dealId, updateDeal, this.getEmailsForTeminationProcess(userType, subject, 'Initiated'))
    }

    if(userType == 'Tenant')
    {
      let updateDeal: UpdateDeal = {
        dealStatus: 'Termination in Progress',
        noticeEndDate: noticeEndDate
      }
      this.ownerFacade.updateOwnerConsent(this.currentViewProperty.ownerId, 'Pending');
      this.dealFacade.updateNoticeDateAndStatus(this.currentViewProperty.dealId, updateDeal, this.getEmailsForTeminationProcess(userType, subject, 'Initiated'))
    }
  }

  cancelTermination(userType: string)
  {
    this.cancelTerminateAlert(userType);
  }

  cancelTerminateAlert(userType: string): void{
    const confirmDialog = this.dialog.open(ConfirmBoxComponent, {
      disableClose: false,
    });
    confirmDialog.componentInstance.confirmMessageHeader = `Cancel Termination`;
    confirmDialog.componentInstance.confirmMessageContent = `Do you want to cancel termination on the property?`;
    confirmDialog.afterClosed().subscribe(result => {
      if(result)
      {
        let subject = `Rentofly - ${userType} Cancelled Termination on Property`;
        this.ownerFacade.updateOwnerConsent(this.currentViewProperty.ownerId, 'Approved');
        this.tenantFacade.updateTenantConsent(this.currentViewProperty.tenantId, 'Approved');
        let updateDeal: UpdateDeal = {
          dealStatus: 'Completed',
          noticeEndDate: null
        }
        this.dealFacade.updateNoticeDateAndStatus(this.currentViewProperty.dealId, updateDeal, this.getEmailsForTeminationProcess(userType, subject, 'Cancelled'))
        // this.onExit();
        combineLatest([this.editingDeal$, this.editingProperty$])
        .subscribe(([dealEdit, propertyEdit]) => {
          if(!dealEdit && !propertyEdit)
          {
            this.onExit();
          }
        })
      }
    });
  }

  getEmailBodyForTerminationProcess(terminationStatus: string){
    // let noticePeriod = '30 days';
    // if(this.currentViewProperty?.noticePeriod)
    // {
    //   noticePeriod = this.currentViewProperty?.noticePeriod + ' days'
    // }
    // let terminationEmailBody;
    if(terminationStatus == 'Initiated')
    {
      return 'lease_termination_template';
      //terminationEmailBody = LeaseTerminationTemplate;
    }
    if(terminationStatus == 'Approved')
    {
      return 'termination_approval_template';
      //terminationEmailBody = TerminationApprovalTemplate;
    }
    if(terminationStatus == 'Cancelled')
    {
      return 'termination_cancelled_template';
      //terminationEmailBody = TerminationCancelledTemplate;
    }
    // return terminationEmailBody.replace('{Property Code}', this.currentViewProperty.propertyCode)
    // .replace('{Property Name}', this.currentViewProperty.propertyName)
    // .replace('{City}', this.currentViewProperty.city)
    // .replace('{State}', this.currentViewProperty.state)
    // .replace('{Zip Code}', this.currentViewProperty.zipCode.toString())
    // .replace('{Website URl}', environment.url)
    // .replace('{User Type}', userType)
    // .replace('{User Mobile}', phone)
    // .replace('{UserName}', name)
    // .replace('{User}', userType)
    // .replace('{Notice Period}', noticePeriod)
  }

  getEmailsForTeminationProcess(userType: string, emailSubject: string, terminationStatus: string): EmailWhatsapp[]{
    let emails: EmailWhatsapp[] = [];
    if(this.currentViewProperty.brokerEmail){
      // let brokerEmail: Email = {
      //   to: this.currentViewProperty.brokerEmail,
      //   from: environment.emailUsers.infoEmail,
      //   subject: emailSubject,
      //   htmlBody: this.getEmailBodyForTerminationProcess(userType, this.currentViewProperty.brokerPhone, this.currentViewProperty.brokerName, terminationStatus)
      // }
      let brokerEmail: EmailWhatsapp = {
        senderName: 'Team rentofly',
        receiverName: this.currentViewProperty.brokerName,
        receiverEmail: this.currentViewProperty.brokerEmail,
        phoneNumber: this.currentViewProperty.brokerPhone,
        type: "",
        template: this.getEmailBodyForTerminationProcess(terminationStatus),
        messageParameters: {
          subjectTextValue: emailSubject,
          userName: this.currentViewProperty.brokerName,
          userType: userType,
          propertyCode: this.currentViewProperty.propertyCode,
          propertyName: this.currentViewProperty.propertyName,
          City: this.currentViewProperty.city,
          State: this.currentViewProperty.state,
          zipCode: this.currentViewProperty.zipCode.toString(),
          userMobile: this.currentViewProperty.brokerPhone,
        }
      }
      emails.push(brokerEmail);
    }
    
    if(userType != 'Owner')
    {
      // let ownerEmail: Email = {
      //   to: this.currentViewProperty.ownerEmail,
      //   from: environment.emailUsers.infoEmail,
      //   subject: emailSubject,
      //   htmlBody: this.getEmailBodyForTerminationProcess(userType, this.currentViewProperty.ownerPhone, this.currentViewProperty.ownerName, terminationStatus)
      // }
      let ownerEmail: EmailWhatsapp = {
        senderName: 'Team rentofly',
        receiverName: this.currentViewProperty.ownerName,
        receiverEmail: this.currentViewProperty.ownerEmail,
        phoneNumber: this.currentViewProperty.ownerPhone,
        type: "",
        template: this.getEmailBodyForTerminationProcess(terminationStatus),
        messageParameters: {
          subjectTextValue: emailSubject,
          userName: this.currentViewProperty.ownerName,
          userType: userType,
          propertyCode: this.currentViewProperty.propertyCode,
          propertyName: this.currentViewProperty.propertyName,
          City: this.currentViewProperty.city,
          State: this.currentViewProperty.state,
          zipCode: this.currentViewProperty.zipCode.toString(),
          userMobile: this.currentViewProperty.ownerPhone,
        }
      }
      emails.push(ownerEmail);
    }
    if(userType != 'Tenant')
    {
      // let tenantEmail: Email = {
      //   to: this.currentViewProperty.tenantEmail,
      //   from: environment.emailUsers.infoEmail,
      //   subject: emailSubject,
      //   htmlBody: this.getEmailBodyForTerminationProcess(userType, this.currentViewProperty.tenantPhone, this.currentViewProperty.tenantName, terminationStatus)
      // }
      let tenantEmail: EmailWhatsapp = {
        senderName: 'Team rentofly',
        receiverName: this.currentViewProperty.tenantName,
        receiverEmail: this.currentViewProperty.tenantEmail,
        phoneNumber: this.currentViewProperty.tenantPhone,
        type: "",
        template: this.getEmailBodyForTerminationProcess(terminationStatus),
        messageParameters: {
          subjectTextValue: emailSubject,
          userName: this.currentViewProperty.tenantName,
          userType: userType,
          propertyCode: this.currentViewProperty.propertyCode,
          propertyName: this.currentViewProperty.propertyName,
          City: this.currentViewProperty.city,
          State: this.currentViewProperty.state,
          zipCode: this.currentViewProperty.zipCode.toString(),
          userMobile: this.currentViewProperty.tenantPhone,
        }
      }
      emails.push(tenantEmail);
    }
    if(this.currentViewProperty.tenantPhone != this.currentViewProperty.creatorPhone
      && this.currentViewProperty.ownerPhone != this.currentViewProperty.creatorPhone
      && this.currentViewProperty.brokerPhone != this.currentViewProperty.creatorPhone)
      {
        // let creatorEmail: Email = {
        //   to: this.currentViewProperty.creatorEmail,
        //   from: environment.emailUsers.infoEmail,
        //   subject: emailSubject,
        //   htmlBody: this.getEmailBodyForTerminationProcess(userType, this.currentViewProperty.creatorPhone, this.currentViewProperty.creatorName, terminationStatus)
        // }
        let creatorEmail: EmailWhatsapp = {
          senderName: 'Team rentofly',
          receiverName: this.currentViewProperty.creatorName,
          receiverEmail: this.currentViewProperty.creatorEmail,
          phoneNumber: this.currentViewProperty.creatorPhone,
          type: "",
          template: this.getEmailBodyForTerminationProcess(terminationStatus),
          messageParameters: {
            subjectTextValue: emailSubject,
            userName: this.currentViewProperty.creatorName,
            userType: userType,
            propertyCode: this.currentViewProperty.propertyCode,
            propertyName: this.currentViewProperty.propertyName,
            City: this.currentViewProperty.city,
            State: this.currentViewProperty.state,
            zipCode: this.currentViewProperty.zipCode.toString(),
            userMobile: this.currentViewProperty.creatorPhone,
          }
        }
        emails.push(creatorEmail);
      }
    return emails;
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  displayPayment(){
    return (this.currentViewProperty?.dealStatus == 'Completed' ||
    this.currentViewProperty?.dealStatus == 'Termination in Progress' ||
    this.currentViewProperty?.dealStatus == 'Notice Period' ||
    this.currentViewProperty?.dealStatus == 'Terminated') && (
      this.currentViewProperty?.ownerUserId == this.currentUser.id ||
      this.currentViewProperty?.tenantUserId == this.currentUser.id
    )
  }
}
