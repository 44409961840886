import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CreateDashboardState } from "..";
import { tenantAdapter, TenantState } from "../reducers";

export const getCreateDashboardState = createFeatureSelector<CreateDashboardState>('dashboard');
export const getTenantState = createSelector(
    getCreateDashboardState,
    (state: CreateDashboardState) => state.tenant
);

export const currentTenant$ = createSelector(getTenantState, (state: TenantState) => state.currentTenant);
export const currentTenantLoading$ = createSelector(getTenantState, (state: TenantState) => state.currentTenantLoading);
export const currentUserForTenant$ = createSelector(getTenantState, (state: TenantState) => state.currentUserForTenant);
export const currentUserForTenantLoading$ = createSelector(getTenantState, (state: TenantState) => state.currentUserForTenantLoading);
export const editingTenant$ = createSelector(getTenantState, (state: TenantState) => state.editingTenant);
export const tenantFinances$ = createSelector(getTenantState, (state: TenantState) => state.tenantFinances);