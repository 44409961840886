<head>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="description" content="Manage your rental property with ease using our AI-powered platform. Get personalized recommendations, flexible rent payment options, automated reminders, and centralized document management. Trust and transparency are at the core of our platform, making each rental transaction secure and hassle-free. Simplify your rental journey today.">
    <meta name="keywords" content="Rental finance management, Property management software, Tenant management system, Rent payment processing, Property listing management, Lease agreement management, Online rent payment, Rental property accounting, Tenant screening, Rent collection automation, Maintenance request management, Property owner dashboard, Real estate software, Online property management, Property management platform, Rental management app, Property management tool, Automated rent collection, Tenant communication platform">
    <meta name="robots" content="index, follow">
</head>
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-Y3D2LBP9KV"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-Y3D2LBP9KV');
</script>
<!-- Google tag (gtag.js) -->
<div class="row form-row">
    <div class="col-md-12" *ngIf="
    (currentOwnerLoading$ | async) || 
    (currentTenantLoading$ | async) || 
    (currentBrokerLoading$ | async) ||
    (editingBroker$ | async) ||
    (editingTenant$ | async) ||
    (editingOwner$ | async) ||
    (editingProperty$ | async)">
        <rentofly-loading></rentofly-loading>
    </div>
    <div class="col-md-12" *ngIf="!(currentOwnerLoading$ | async) && !(currentTenantLoading$ | async) && !(currentBrokerLoading$ | async)">
        <div class="question-dev">
            <p class="info-header radio-div">You are : </p>
            <div class="p-field-radiobutton radio-div">
                <p-radioButton name="personId" value="0" [(ngModel)]="personId" inputId="owner" (onClick)="radioBtnDetail()"></p-radioButton>
                <label for="owner" class="radio-label">Owner</label>
            </div>
            <div class="p-field-radiobutton radio-div">
                <p-radioButton name="personId" value="1" [(ngModel)]="personId" inputId="broker" (onClick)="radioBtnDetail()"></p-radioButton>
                <label for="broker" class="radio-label">Broker</label>
            </div>
        </div>
        <div class="question-dev" *ngIf="showBroker">
            <p class="info-header2 radio-div">Is any broker helping you with this property? : </p>
            <div class="p-field-radiobutton radio-div">
                <p-radioButton name="brokerDetail" value="2" [(ngModel)]="brokerDetail" inputId="brokerYes" (onClick)="getBrokerDetail(2)"></p-radioButton>
                <label for="brokerYes" class="radio-label">Yes</label>
            </div>
            <div class="p-field-radiobutton radio-div">
                <p-radioButton name="brokerDetail" value="0" [(ngModel)]="brokerDetail" inputId="brokerNo" (onClick)="getBrokerDetail(0)"></p-radioButton>
                <label for="brokerNo" class="radio-label">No</label>
            </div>
        </div>
        <p-accordion class="stack-accordian">
            <p-accordionTab header="Broker Details" [disabled]="detailComplete" [(selected)]="activeState[0]" *ngIf="stakeholderType== '1' || stakeholderType== '2'">
                <form [formGroup]="brokerInfoForm" class="stakeholder-info">
                    <div class="form-group mobile-number-group">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <span>+91</span>
                            </span>
                            <span class="p-float-label">
                                <input type="text" id="inputgroup brokerMobileNo" class="contact-input" pInputText formControlName="brokerMobileNo" 
                                    onKeyPress="if(this.value.length==10) return false;" OnlyNumber="true"  (blur)="getUserByMobileNumber(brokerMobileNo.value, 'broker')">  
                                <label for="inputgroup">Broker Mobile #</label>
                            </span>
                        </div>
                        <div *ngIf="brokerMobileNo!.invalid && showStakeholderInfoValidationMessage" class="rentofly-validation-msg">
                            <div>
                                Broker Mobile # is invalid.
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="(currentUserForBrokerLoading$ | async)">
                        <rentofly-loading></rentofly-loading>
                    </div>
                    <div class="form-group" *ngIf="!(currentUserForBrokerLoading$ | async)">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="float-input-name "  class="contact-input" type="text" formControlName="brokerName" pInputText [readonly]="brokerReadonly && !brokerMobileNo"> 
                                <label for="float-input-name">Broker Name</label>
                            </span>
                        </div>
                        <div *ngIf="brokerName!.invalid && showStakeholderInfoValidationMessage" class="rentofly-validation-msg">
                            <div>
                                Broker Name is invalid.
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="!(currentUserForBrokerLoading$ | async)">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="float-input-email " class="contact-input" type="text" formControlName="brokerEmail" pInputText [readonly]="brokerReadonly && !brokerMobileNo"> 
                                <label for="float-input-email">Broker Email</label>
                            </span>
                        </div>
                        <div *ngIf="brokerEmail!.invalid && showStakeholderInfoValidationMessage" class="rentofly-validation-msg">
                            <div>
                                Broker Email is invalid.
                            </div>
                        </div>
                    </div>
                    <!-- <div class="form-group" *ngIf="!(currentUserForBrokerLoading$ | async)">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="float-input-broker-pan " class="contact-input" type="text" formControlName="brokerPanNo" pInputText [readonly]="brokerReadonly && !brokerMobileNo"> 
                                <label for="float-input-broker-pan">Broker PAN Card</label>
                            </span>
                        </div>
                        <div *ngIf="brokerPanNo!.invalid && showStakeholderInfoValidationMessage" class="rentofly-validation-msg">
                            <div>
                                Broker PAN Card is invalid.
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="form-group" *ngIf="!(currentUserForBrokerLoading$ | async)">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="float-input-ba " class="contact-input" type="text" formControlName="brokerAadhar" pInputText [readonly]="brokerReadonly && !brokerMobileNo"> 
                                <label for="float-input-ba">Broker Aadhar Card</label>
                            </span>
                        </div>
                        <div *ngIf="brokerAadhar!.invalid && showStakeholderInfoValidationMessage" class="rentofly-validation-msg">
                            <div>
                                Broker Aadhar is invalid.
                            </div>
                        </div>
                    </div> -->

                    <div class="form-group" *ngIf="!(currentUserForBrokerLoading$ | async)">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="float-input-bi brokerId" class="contact-input" type="text" formControlName="brokerId" pInputText [readonly]="!brokerMobileNo"> 
                                <label for="float-input-bi">Broker ID</label>
                            </span>
                        </div>
                    </div>
                </form>
            </p-accordionTab>
            <p-accordionTab header="Owner Details" [disabled]="detailComplete" [(selected)]="activeState[1]">
                <form [formGroup]="ownerInfoForm" class="stakeholder-info">
                    <!-- <div class="form-group">
                        <p class="info-header">Owner Details</p>
                    </div> -->
                    <div class="form-group mobile-number-group">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <span>+91</span>
                            </span>
                            <span class="p-float-label">
                                <input type="text" id="inputgroup ownerMobileNo" class="contact-input" pInputText formControlName="ownerMobileNo"
                                    onKeyPress="if(this.value.length==10) return false;" OnlyNumber="true" (blur)="getUserByMobileNumber(ownerMobileNo.value, 'owner')">  
                                <label for="inputgroup">Owner Mobile #</label>
                            </span>
                        </div>
                        <div *ngIf="ownerMobileNo!.invalid && showStakeholderInfoValidationMessage" class="rentofly-validation-msg">
                            <div>
                                Owner Mobile # is invalid.
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="(currentUserForOwnerLoading$ | async)">
                        <rentofly-loading></rentofly-loading>
                    </div>
                    <div class="form-group" *ngIf="!(currentUserForOwnerLoading$ | async)">
                        <span class="p-float-label">
                            <input id="float-input-owne-email ownerEmailAddress" class="contact-input" type="text" formControlName="ownerEmailAddress" pInputText [readonly]="ownerReadonly"> 
                            <label for="float-input-owner-email">Owner Email</label>
                        </span>
                        <div *ngIf="ownerEmailAddress!.invalid && showStakeholderInfoValidationMessage" class="rentofly-validation-msg">
                            <div>
                                Owner Email is invalid.
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="!(currentUserForOwnerLoading$ | async)">
                        <span class="p-float-label">
                            <input id="float-input-owner-name ownerName" class="contact-input" type="text" formControlName="ownerName" pInputText [readonly]="ownerReadonly"> 
                            <label for="float-input-owner-name">Owner Name</label>
                        </span>
                        <div *ngIf="ownerName!.invalid && showStakeholderInfoValidationMessage" class="rentofly-validation-msg">
                            <div>
                                Owner Name is required.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <span class="p-float-label">
                            <input id="float-input-owner-pan ownerPanNo" class="contact-input" type="text" maxlength="10" formControlName="ownerPanNo" pInputText> 
                            <label for="float-input-owner-pan" class="input-label">Owner PAN Card #</label>
                        </span>
                        <div *ngIf="!(ownerPanNo!.invalid && showStakeholderInfoValidationMessage)" class="maskedInformation"><span>PAN # will be masked and not visible to others</span></div>
                        <div *ngIf="ownerPanNo!.invalid && showStakeholderInfoValidationMessage" class="rentofly-validation-msg">
                            <div>
                                Owner PAN # is invalid.
                            </div>
                        </div>
                    </div>

                    <!-- <div class="form-group">
                        <span class="p-float-label">
                            <input id="float-inpu-oa ownerAadhar" class="contact-input" type="text" formControlName="ownerAadhar" pInputText> 
                            <label for="float-input-oa" class="input-label">Owner Aadhar Card #</label>
                        </span>
                        <div *ngIf="ownerAadhar!.invalid && showStakeholderInfoValidationMessage" class="rentofly-validation-msg">
                            <div>
                                Owner Aadhar # is invalid.
                            </div>
                        </div>
                    </div> -->
                </form>
            </p-accordionTab>
            <p-accordionTab header="Tenant Details" [disabled]="detailComplete" [(selected)]="activeState[2]">
                <form [formGroup]="tenantInfoForm" class="stakeholder-info">
                    <!-- <div class="form-group">
                        <p class="info-header">Tenant Details</p>
                    </div> -->
                    <div class="form-group mobile-number-group">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <span>+91</span>
                            </span>
                            <span class="p-float-label">
                                <input type="text" id="inputgroup tenantMobileNo" class="contact-input" pInputText formControlName="tenantMobileNo" 
                                    onKeyPress="if(this.value.length==10) return false;" OnlyNumber="true" (blur)="getUserByMobileNumber(tenantMobileNo.value, 'tenant')">  
                                <label for="inputgroup">Tenant Mobile #</label>
                            </span>
                        </div>
                        <div *ngIf="tenantMobileNo!.invalid && showStakeholderInfoValidationMessage" class="rentofly-validation-msg">
                            <div>
                                Tenant Mobile # is invalid.
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="(currentUserForTenantLoading$ | async)">
                        <rentofly-loading></rentofly-loading>
                    </div>
                    <div class="form-group" *ngIf="!(currentUserForTenantLoading$ | async)">
                        <span class="p-float-label">
                            <input id="float-input-tenant-email tenantEmailAddress" class="contact-input" type="text" formControlName="tenantEmailAddress" pInputText [readonly]="tenantReadonly"> 
                            <label for="float-input-tenant-email">Tenant Email</label>
                        </span>
                        <div *ngIf="tenantEmailAddress!.invalid && showStakeholderInfoValidationMessage" class="rentofly-validation-msg">
                            <div>
                                Tenant Email is invalid.
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="!(currentUserForTenantLoading$ | async)">
                        <span class="p-float-label">
                            <input id="float-input-tenant-name tenantName" class="contact-input" type="text" formControlName="tenantName" pInputText [readonly]="tenantReadonly"> 
                            <label for="float-input-tenant-name">Tenant Name</label>
                        </span>
                        <div *ngIf="tenantName!.invalid && showStakeholderInfoValidationMessage" class="rentofly-validation-msg">
                            <div>
                                Tenant Name is required.
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="!(currentUserForTenantLoading$ | async)">
                        <span class="p-float-label">
                            <input id="float-input-tp tenantPanNo" class="contact-input" type="text" maxlength="10" formControlName="tenantPanNo" pInputText> 
                            <label for="float-input-tp" class="input-label">Tenant PAN Card #</label>
                        </span>
                        <div *ngIf="!(tenantPanNo!.invalid && showStakeholderInfoValidationMessage)" class="maskedInformation"><span>PAN # will be masked and not visible to others</span></div>
                        <div *ngIf="tenantPanNo!.invalid && showStakeholderInfoValidationMessage" class="rentofly-validation-msg">
                            <div>
                                Tenant PAN Card # is invalid.
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="!(currentUserForTenantLoading$ | async)">
                        <span class="p-float-label">
                            <input id="float-input-ta tenantAadharNo" class="contact-input" type="text" maxlength="12"
                                pKeyFilter="num" formControlName="tenantAadharNo" pInputText>
                            <label for="float-input-ta" class="input-label">Tenant Aadhar</label>
                        </span>
                        <div *ngIf="!(tenantAadharNo!.invalid && showStakeholderInfoValidationMessage)"class="maskedInformation"><span>Aadhar # will be masked and not visible to others</span></div>
                        <div *ngIf="tenantAadharNo!.invalid && showStakeholderInfoValidationMessage" class="rentofly-validation-msg">
                            <div>
                                Tenant Aadhar # is invalid.
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="!(currentUserForTenantLoading$ | async)">
                        <span class="p-float-label">
                            <input id="float-input tenantDetails" class="contact-input" type="text" formControlName="tenantDetails" pInputText> 
                            <label for="float-input" class="input-label">Tenant Details</label>
                        </span>
                    </div>
                </form>
            </p-accordionTab>
        </p-accordion>
        <div class="action">
            <button class="rentofly-tertiary" (click)="onContinue(ownerInfoForm.value, tenantInfoForm.value, brokerInfoForm.value)">Continue</button><br />
            <!-- <button class="rentofly-secondary" (click)="onSaveAndExit(ownerInfoForm.value, tenantInfoForm.value, brokerInfoForm.value)">Save & Exit</button><br /> -->
            <a (click)="onBack()">Back</a>|<a (click)="onExit()">Exit</a>
        </div>
    </div>
</div>