import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UUID } from 'angular2-uuid';

import { Observable } from 'rxjs';
import { Property, AdditionalPropertyDetail } from 'src/app/shared/models';

import { LocalStorageService } from 'src/app/shared/services';
import { PropertyStoreFacade } from 'src/app/store/facades/property.store-facade';
import { PropertyService } from 'src/app/shared/services/property.service';
import { AddressStoreFacade } from 'src/app/store/facades';
import { Address } from 'src/app/shared/models';
import { ForumRounded } from '@material-ui/icons';

@Component({
  selector: 'rentofly-property-info',
  templateUrl: './property-info.component.html',
  styleUrls: ['./property-info.component.scss']
})
export class PropertyInfoComponent implements OnInit {

  @Input()
  currentProperty: Observable<Property>;

  @Input()
  currentAdditionalPropertyDetails: Observable<AdditionalPropertyDetail>;

  @Input()
  propertyId: string;

  @Output()
  continue: EventEmitter<any> = new EventEmitter();

  @Output()
  back: EventEmitter<any> = new EventEmitter();

  @Output()
  saveAndExit: EventEmitter<any> = new EventEmitter();

  @Output()
  exit: EventEmitter<any> = new EventEmitter();

  property: Property;
  additionalProperty: AdditionalPropertyDetail;
  propertyInfoForm: FormGroup;
  additionalPropertyInfoForm: FormGroup;
  showPropertyInfoValidationMessage = false;
  currentUser:any;
  currentAddress: Address;
  currentAddressLoading$: Observable<boolean>;
  propertyEditing$: Observable<boolean>
  propertyType = 'residential';
  currentPin;
  subType = [];
  visitorParkingAvailable = false;
  parkingAvailable = false;
  furnished = false;
  furnitureData;
  propertySelected = true;
  electricBoard = [
    {name:"Reliance Power Ltd", code:"Reliance Power Ltd"},
    {name:"Adani Power Ltd", code:"Adani Power Ltd"},
    {name:"Tata Power Ltd", code:"Tata Power Ltd"},
    {name:"Others", code:"Others"}
  ];
  activeState: boolean[] = [true, false];
  constructor(
    private readonly propertyFacade: PropertyStoreFacade,
    private readonly localStorageService: LocalStorageService,
    public readonly router: Router,
    private readonly route: ActivatedRoute,
    private propertyService : PropertyService,
    private readonly addressFacade : AddressStoreFacade
  ) {
  }

  get propertyName() {
    return this.propertyInfoForm!.get('propertyName');
  }

  get propertyZipCode() {
    return this.propertyInfoForm!.get('propertyZipCode');
  }

  get propertyCity() {
    return this.propertyInfoForm!.get('propertyCity');
  }

  get propertyState() {
    return this.propertyInfoForm!.get('propertyState');
  }

  ngOnInit(): void {
    if(!this.propertyId)
    {
      this.router.navigate(['dashboard/view-property'])
    }
    this.currentUser = this.localStorageService.getObjectFromLocalStorage('current-user');
    this.initForm();
    this.initAdditionalProperty();
    this.propertyEditing$ = this.propertyFacade.propertyEditing$;
    
    this.getFurnitureTypeData();
  }

  onExit(): void {
    this.exit.emit(1);
  }

  getFurnitureTypeData(){
    this.propertyService.getFurnitureTypes().subscribe(data =>{
      this.furnitureData = data;
    })
  }

  onContinue(formValue, additionalFormValue): void{
    this.showPropertyInfoValidationMessage = false;
    if(this.propertyInfoForm.invalid)
    {
      this.showPropertyInfoValidationMessage = true;
      return;
    }
    this.onSave(formValue, additionalFormValue);
    this.propertyEditing$.subscribe((data) => {
      if(data == false)
      {
        this.continue.emit(2);
      }
    })
    
  }

  onBack(): void{
    this.back.emit(1);
  }

  onSaveAndExit(formValue, additionalFormValue): void{
    this.showPropertyInfoValidationMessage = false;
    if(this.propertyInfoForm.invalid)
    {
      this.showPropertyInfoValidationMessage = true;
      return;
    }
    this.onSave(formValue, additionalFormValue);
    this.propertyEditing$.subscribe((data) => {
      if(data == false)
      {
        this.saveAndExit.emit(1);
      }
    })
  }

  private onSave(formValue, additionalFormValue): void{
    
    var currentUser = this.localStorageService.getObjectFromLocalStorage('current-user');

    this.saveProperty(formValue, currentUser);
    this.saveAdditionalDetails(additionalFormValue);
  }

  private saveProperty(formValue, currentUser): void{
    if(!this.propertyInfoForm.dirty)
    {
      return;
    }

    if(this.property == null)
    {
      var newProperty: Property = {
        id: this.propertyId,
        propertyName: formValue.propertyName,
        zipCode: +formValue.propertyZipCode,
        city: formValue.propertyCity,
        state: formValue.propertyState,
        landmark: formValue.propertyLandmark,
        buildingName: formValue.propertyArea,
        streetDetails: formValue.propertyStreet,
        flatNumber: formValue.propertyHouseNo,
        propertyType: this.propertyType,
        createdDate: new Date(),
        createdBy: currentUser.id
      }
      this.propertyFacade.addProperty(newProperty);
    }
    else
    {
      var updateProperty: Property = {
        id: this.propertyId,
        propertyName: formValue.propertyName,
        zipCode: +formValue.propertyZipCode,
        city: formValue.propertyCity,
        state: formValue.propertyState,
        landmark: formValue.propertyLandmark,
        buildingName: formValue.propertyArea,
        streetDetails: formValue.propertyStreet,
        flatNumber: formValue.propertyHouseNo,
        propertyType: this.propertyType
      };
      this.propertyFacade.updateProperty(updateProperty);
    }
  }

  private saveAdditionalDetails(additionalFormValue): void {
    if(!this.additionalPropertyInfoForm.dirty)
    {
      return;
    }
    if(this.additionalProperty == null)
    {
      let newAdditionalProperty: AdditionalPropertyDetail = {
        id: UUID.UUID(),
        propertyId: this.propertyId,
        propertyType: additionalFormValue.propertyType,
        subPropertyType: additionalFormValue.subPropertyType.code,
        numBedrooms: additionalFormValue.numBedrooms,
        area: additionalFormValue.area,
        numBathroom: additionalFormValue.numBathroom,
        numBalcony: additionalFormValue.numBalcony,
        accomodates: additionalFormValue.accomodates,
        parking: additionalFormValue.parking,
        parkingType: additionalFormValue.parkingType,
        visitorParking: additionalFormValue.visitorParking,
        visitorParkingType: additionalFormValue.visitorParkingType,
        furnishing: additionalFormValue.furnishing,
        selectedFurniture: this.selectedPropertyIntoString(additionalFormValue.selectedFurniture),
        electricBoard: additionalFormValue.electricBoard.code,
        consumernumber: additionalFormValue.consumernumber,
        powerBackup: additionalFormValue.powerBackup
      };
      this.propertyFacade.addPropertyDetails(newAdditionalProperty);
    }
    else
    {
      let updatedAdditionalProperty: AdditionalPropertyDetail = {
        id: this.additionalProperty.id,
        propertyId: this.propertyId,
        propertyType: additionalFormValue.propertyType,
        subPropertyType: additionalFormValue.subPropertyType?.code,
        numBedrooms: additionalFormValue.numBedrooms,
        area: additionalFormValue.area,
        numBathroom: additionalFormValue.numBathroom,
        numBalcony: additionalFormValue.numBalcony,
        accomodates: additionalFormValue.accomodates,
        parking: additionalFormValue.parking,
        parkingType: additionalFormValue.parking == 'yes' ? additionalFormValue.parkingType : '',
        visitorParking: additionalFormValue.visitorParking,
        visitorParkingType: additionalFormValue.visitorParking == 'yes' ? additionalFormValue.visitorParkingType : '',
        furnishing: additionalFormValue.furnishing,
        selectedFurniture: additionalFormValue.furnishing !== 'unfurnished'? this.selectedPropertyIntoString(additionalFormValue.selectedFurniture) : '',
        electricBoard: additionalFormValue.electricBoard?.code,
        consumernumber: additionalFormValue.consumernumber,
        powerBackup: additionalFormValue.powerBackup
      };
      this.propertyFacade.updatePropertyDetails(updatedAdditionalProperty);
    }
  }

  private initForm(): void{
    this.currentProperty.subscribe((property) => {
      this.property = property;
      this.propertyInfoForm = new FormGroup({
        propertyName: new FormControl(property?.propertyName ? 
          property?.propertyName : '', [
          Validators.required
        ]),
        propertyZipCode: new FormControl(property?.zipCode ? 
          property?.zipCode : '', [
          Validators.required,
          Validators.maxLength(6)
        ]),
        propertyCity: new FormControl(property?.city ? 
          property?.city : '', [
          Validators.required
        ]),
        propertyState: new FormControl(property?.state ? 
          property?.state : '', [
          Validators.required
        ]),
        propertyLandmark: new FormControl(property?.landmark ? 
          property?.landmark : '', []),
        propertyArea: new FormControl(property?.buildingName ? 
          property?.buildingName : '', []),
        propertyStreet: new FormControl(property?.streetDetails ? 
          property?.streetDetails : '', []),
        propertyHouseNo: new FormControl(property?.flatNumber ? 
          property?.flatNumber : '', [])
      });
      if(property !== null){
          this.propertyType = property.propertyType;
      }
    })
    
  }

  private initAdditionalProperty(): void{
    this.currentAdditionalPropertyDetails.subscribe((additionalProperty) => {
      this.additionalProperty = additionalProperty;
      if(additionalProperty){
        this.propertySelected = false;
        this.selectPropertyType(additionalProperty.propertyType);
        this.pAvailable(additionalProperty.parking);
        this.vpAvailable(additionalProperty.visitorParking);
        this.furnishedType(additionalProperty.furnishing);
      }
      this.additionalPropertyInfoForm = new FormGroup({
        propertyType: new FormControl(additionalProperty?.propertyType ? 
          additionalProperty?.propertyType : '', []),
        subPropertyType: new FormControl(additionalProperty?.subPropertyType ? 
          {name: additionalProperty?.subPropertyType, code: additionalProperty?.subPropertyType} : '', []),
        numBedrooms: new FormControl(additionalProperty?.numBedrooms ? 
          additionalProperty?.numBedrooms : '', []),
        area: new FormControl(additionalProperty?.area ? 
          additionalProperty?.area : '', []),
        numBathroom: new FormControl(additionalProperty?.numBathroom ? 
          additionalProperty?.numBathroom : '', []),
        numBalcony: new FormControl(additionalProperty?.numBalcony ? 
          additionalProperty?.numBalcony : '', []),
        accomodates: new FormControl(additionalProperty?.accomodates ? 
          additionalProperty?.accomodates : '', []),
        parking: new FormControl(additionalProperty?.parking ? 
          additionalProperty?.parking : '', []),
        parkingType: new FormControl(additionalProperty?.parkingType && additionalProperty?.parking !== 'no' ? 
          additionalProperty?.parkingType : '', []),
        visitorParking: new FormControl(additionalProperty?.visitorParking ? 
          additionalProperty?.visitorParking : '', []),
        visitorParkingType: new FormControl(additionalProperty?.visitorParkingType  && additionalProperty?.visitorParking !== 'no' ? 
          additionalProperty?.visitorParkingType : '', []),
        furnishing: new FormControl(additionalProperty?.furnishing ? 
          additionalProperty?.furnishing : '', []),
        selectedFurniture: new FormControl(additionalProperty?.selectedFurniture &&  additionalProperty?.furnishing !== 'unfurnished' ? 
          this.bindSelectedProperty(additionalProperty.selectedFurniture) : '', []),
        electricBoard: new FormControl(additionalProperty?.electricBoard ? 
          {name: additionalProperty?.electricBoard, code: additionalProperty?.electricBoard} : '', []),
        consumernumber: new FormControl(additionalProperty?.consumernumber ? 
          additionalProperty?.consumernumber : '', []),
        powerBackup: new FormControl(additionalProperty?.powerBackup ? 
          additionalProperty?.powerBackup : '', [])
      })
    })
  }

  bindSelectedProperty(property){
    let selectedItems = [];
    for(let item of property.split(",")){
      let obj = {name: item}
      selectedItems.push(obj);
    }
    return selectedItems;
  }

  selectedPropertyIntoString(data){
    let selectedFurnitures = [];
    for(let item of data){
      selectedFurnitures.push(item.name);
    }
    return selectedFurnitures.toString();
  }
  selectPropertyType(type){
    if(type === 'residential'){
      this.subType = [
        {name: 'Row house', code: 'Row house'},
        {name: 'Apartment', code: 'Apartment'},
        {name: 'Stand-alone', code: 'Stand-alone'},
        {name: 'PG', code: 'PG'},
        {name: 'Others', code: 'Others'}
      ]
      this.propertySelected = false;
    } else{
      this.subType = [
        {name: 'Office', code: 'Office'},
        {name: 'Shop', code: 'Shop'},
        {name: 'Hotel', code: 'Hotel'},
        {name: 'Hostel', code: 'Hostel'},
        {name: 'Bank', code: 'Bank'},
        {name: 'Educational', code: 'Educational'},
        {name: 'Others', code: 'Others'}
      ]
      this.propertySelected = false;
    }
  }

  queryAddressByZipCode(propertyZipCode){
    if(this.currentPin == propertyZipCode)
    {
      return;
    }
    this.currentPin = propertyZipCode;
    if(this.propertyZipCode.valid)
    {
      this.addressFacade.queryAddressByPincode(propertyZipCode);
      this.currentAddressLoading$ = this.addressFacade.currentAddressLoading$;
      this.addressFacade.currentAddress$.subscribe((data) => {
        if(data)
        {
          this.currentAddress = data;
          this.propertyCity.setValue(data.District);
          this.propertyState.setValue(data.State);
        }
      })
    }
  }
  
  pAvailable(parking){
    if(parking == true || parking == 'yes'){
      this.parkingAvailable = true;
    } else{
      this.parkingAvailable = false;
    }
  }
  vpAvailable(parking){
    if(parking == true || parking == 'yes'){
      this.visitorParkingAvailable = true;
    } else{
      this.visitorParkingAvailable = false;
    }
  }
  furnishedType(type){
    if(type == true || type == 'fully' || type == 'semi'){
      this.furnished = true;
    } else{
      this.furnished = false;
    }
  }
}
