import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { ApprovePartnerTemplate } from 'src/app/shared/emailTemplates';
import { Email, Partner } from 'src/app/shared/models';
import { EmailWhatsapp } from 'src/app/shared/models/email-whatsapp.model';
import { EmailService, LocalStorageService } from 'src/app/shared/services';
import { PartnerStoreFacade } from 'src/app/store/facades';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'rentofly-admin-partner-view',
  templateUrl: './admin-partner-view.component.html',
  styleUrls: ['./admin-partner-view.component.scss']
})
export class AdminPartnerViewComponent implements OnInit {
  partnerId: string;
  currentPartner: Partner;
  currentPartnerLoading$: Observable<boolean>;
  editPartnerForm: FormGroup;
  currentUser: any;
  updatePartnerLoading$: Observable<boolean>;
  showPartnerValidationMessage;
  showPartnerAadharValidationMessage;
  showPartnerPanValidationMessage;
  showPartnerBrokerRegValidationMessage;

  constructor(
    private readonly partnerFacade: PartnerStoreFacade,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly localStorageService: LocalStorageService,
    private readonly emailService: EmailService,
    private readonly snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.pipe(
      filter(params => params.partnerIdentifier)
    ).subscribe((params) => {
      this.partnerId = params.partnerIdentifier
      this.currentPartnerLoading$ = this.partnerFacade.currentPartnerLoading$;
      this.partnerFacade.queryPartnerById(this.partnerId);
      this.partnerFacade.currentPartner$.subscribe((data) => {
        if(data)
        {
          // console.log(data);
          this.currentPartner = data;
          this.initEditPartnerForm(data);
        }
      })
    });
  }

  get partnerName() {
    return this.editPartnerForm!.get('partnerName');
  }

  get phoneNumber() {
    return this.editPartnerForm!.get('phoneNumber');
  }

  get emailAddress() {
    return this.editPartnerForm!.get('emailAddress');
  }

  get partnerPinCode() {
    return this.editPartnerForm!.get('partnerPinCode');
  }

  get panNumber() {
    return this.editPartnerForm!.get('panNumber');
  }

  get aadharNumber() {
    return this.editPartnerForm!.get('aadharNumber');
  }

  get brokerRegNumber() {
    return this.editPartnerForm!.get('brokerRegNumber');
  }

  get approvalStatus() {
    return this.editPartnerForm!.get('approvalStatus');
  }

  initEditPartnerForm(partner: Partner){
    this.editPartnerForm = new FormGroup({
      partnerName: new FormControl(partner?.name ?? '', [
        Validators.required
      ]),
      phoneNumber: new FormControl(partner?.phoneNumber ?? '', [
        // Validators.required,
        // Validators.maxLength(10)
      ]),
      emailAddress: new FormControl(partner?.emailAddress ?? '', [
        Validators.required,
        Validators.email
      ]),
      partnerPinCode: new FormControl(partner?.pinCode ?? '', [
        Validators.required,
        Validators.minLength(6)
      ]),
      panNumber: new FormControl(partner?.panNumber ?? '', [
        // Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]),
      aadharNumber: new FormControl(partner?.aadharNumber ?? '', [
        // Validators.required,
        Validators.minLength(12),
        Validators.maxLength(12)
      ]),
      brokerRegNumber: new FormControl(partner?.brokerRegNumber ?? '', [
        Validators.required
      ]),
      approvalStatus: new FormControl(partner?.status ?? '', [
        Validators.required,
      ]),
    });
  }

  savePartner(partnerForm)
  {
    this.showPartnerValidationMessage = false;
    this.showPartnerAadharValidationMessage = false;
    this.showPartnerBrokerRegValidationMessage = false;
    this.showPartnerPanValidationMessage = false;
    if(this.editPartnerForm.invalid)
    {
      if(this.partnerName.invalid || 
        this.phoneNumber.invalid || 
        this.emailAddress.invalid || 
        this.partnerPinCode.invalid){
          this.showPartnerValidationMessage = true;
          return;
        }
      if((!this.aadharNumber.value && !this.brokerRegNumber.value && !this.panNumber.value)){
        this.showPartnerValidationMessage = true;
        return;
      }
      if(this.aadharNumber.value && this.aadharNumber.invalid){
        this.showPartnerAadharValidationMessage = true;
        return;
      }
      if(this.brokerRegNumber.value && this.brokerRegNumber.invalid){
        this.showPartnerBrokerRegValidationMessage = true;
        return;
      }
      if(this.panNumber.value && this.panNumber.invalid){
        this.showPartnerPanValidationMessage = true;
        return;
      }
    }
    this.currentUser = this.localStorageService.getObjectFromLocalStorage('current-user');
    let updatedPartner: Partner = {
      ...this.currentPartner,
      name: partnerForm.partnerName,
      emailAddress: partnerForm.emailAddress,
      pinCode: +(partnerForm.partnerPinCode),
      panNumber: partnerForm.panNumber,
      aadharNumber: partnerForm.aadharNumber,
      brokerRegNumber: partnerForm.brokerRegNumber,
      status: partnerForm.approvalStatus,
      // approvedBy: partnerForm.approvalStatus === 'Approved'? this.currentUser.id : null
    }
    this.partnerFacade.updatePartner(updatedPartner);
    this.updatePartnerLoading$ = this.partnerFacade.updatePartnerLoading$;
    this.updatePartnerLoading$.subscribe((data) => {
      if(!data)
      {
        if(updatedPartner.status === 'Approved')
        {
          this.sendEmail(updatedPartner);
        }
        this.exit();
      }
    })
  }

  private sendEmail(partner: Partner){
    let user = JSON.parse(this.localStorageService.getFromLocalStorage('dashboard-user'));
    // let partnerEmail: Email = {
    //   to: partner.emailAddress,
    //   from: environment.emailUsers.infoEmail,
    //   fromName: environment.emailUsers.infoEmailName,
    //   subject: 'Rentofly - Partner Verification Completed',
    //   htmlBody: this.getEmailBody(ApprovePartnerTemplate, partner),
    // }
    let partnerEmail : EmailWhatsapp = {
      senderName: 'Team rentofly',
      receiverName: partner.name,
      receiverEmail: partner.emailAddress,
      phoneNumber: partner.phoneNumber,
      type: "",
      template: "approve_partner_template",
      messageParameters: {
        subjectTextValue: "Rentofly - Partner Verification Completed",
        userName: partner.name,
        userMobile: partner.phoneNumber,
      }
  }
    this.emailService.sendEmail(partnerEmail).pipe(
      map(
        (response) =>{
          this.snackBar.open('Verification Complete and Approved.', "X", {
            duration: 10000,
            panelClass: ['snackbar-success']
          });
        }),
        catchError((error) => {
          this.snackBar.open('There was something wrong. Please try again.', "X", {
            duration: 10000,
            panelClass: ['snackbar-failure']
          });
          return error;
        })
      ).subscribe();
  }

  private getEmailBody(template: string, partner: Partner)
  {  
    return template.replace('{UserName}', partner.name)
    .replace('{Website URl}', environment.url)
    .replace('{User Mobile}', partner.phoneNumber)
  }

  exit(){
    if(this.currentPartner.status === "Approved")
    {
      this.router.navigate(['/admin-approved-partner-dashboard']);
    } else{
      this.router.navigate(['/admin-pending-partner-dashboard']);
    }
    
  }

  isPartnerApproved(){
    return this.currentPartner.status === "Approved";
  }

}
