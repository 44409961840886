<head>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="description" content="Manage your rental property with ease using our AI-powered platform. Get personalized recommendations, flexible rent payment options, automated reminders, and centralized document management. Trust and transparency are at the core of our platform, making each rental transaction secure and hassle-free. Simplify your rental journey today.">
    <meta name="keywords" content="Rental finance management, Property management software, Tenant management system, Rent payment processing, Property listing management, Lease agreement management, Online rent payment, Rental property accounting, Tenant screening, Rent collection automation, Maintenance request management, Property owner dashboard, Real estate software, Online property management, Property management platform, Rental management app, Property management tool, Automated rent collection, Tenant communication platform">
    <meta name="robots" content="index, follow">
</head>
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-Y3D2LBP9KV"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-Y3D2LBP9KV');
</script>
<!-- Google tag (gtag.js) -->

<div class="row">
    <div class="col-md-12 welcomeDiv">
        <h4 class="welcomeText">Welcome to rentofly.com</h4>
    </div>
</div>

<div class="row">
    <div class="col-md-12 welcomeDiv">
        <h4 class="sub-text">Enter referral code to start availing the benefits.</h4>
    </div>
</div>

<div class="row">
    <div class="col-md-12 welcomeDiv">
        <div class="main-input-div">
            <input type="text" [(ngModel)]="referralCode" placeholder="Enter referral code" class="referral-code-field">
            <div class="login-icon-btn" (click)="utiliseReferralCode()">
                <i class="pi pi-caret-right" style="font-size: 2rem; margin-left: 10px"></i>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12 welcomeDiv">
        <h4 class="info-text" *ngIf="!emailBtn">
            Don’t have a referral code?<br>
            Please click on referral code request button to get the code.
            <!-- You can still access the rentofly features by paying one time fees.   -->
        </h4>
        <h4 class="info-text" *ngIf="emailBtn" style="color: green;">
            Thank you for your request. Team rentofly will reach out to you soon for evaluating the request
        </h4>
    </div>
</div>

<div class="row">
    <div class="col-md-12 welcomeDiv">
        <button type="button" class="get-referral-btn" [disabled]="emailBtn" (click)="sendEmail()">Referral code request</button>
    </div>
</div>