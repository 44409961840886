import { ActionReducerMap } from '@ngrx/store';

import { usersReducer, UserState } from './users.reducer';
import { propertyReducer, PropertyState } from './property.reducer';
import { ownerReducer, OwnerState } from './owner.reducer';
import { tenantReducer, TenantState } from './tenant.reducer';
import { dealReducer, DealState } from './deal.reducer';
import { brokerReducer, BrokerState } from './broker.reducer';
import { addressReducer, AddressState } from './address.reducer'
import { attachmentsReducer, AttachmentsState } from './attachments.reducer';
import { partnerReducer, PartnerState } from './partner.reducer';

export * from './users.reducer';
export * from './property.reducer';
export * from './owner.reducer';
export * from './tenant.reducer';
export * from './deal.reducer';
export * from './broker.reducer';
export * from './address.reducer';
export * from './attachments.reducer';
export * from './partner.reducer';

export interface CreateDashboardState {
    users: UserState,
    property: PropertyState,
    owner: OwnerState,
    tenant: TenantState,
    deal: DealState,
    broker: BrokerState,
    address: AddressState,
    attachments: AttachmentsState,
    partner: PartnerState
}

export const createDashboardReducers: ActionReducerMap<CreateDashboardState, any> = {
    users: usersReducer,
    property: propertyReducer,
    owner: ownerReducer,
    tenant: tenantReducer,
    deal: dealReducer,
    broker: brokerReducer,
    address: addressReducer,
    attachments: attachmentsReducer,
    partner: partnerReducer
}