<head>
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <meta
    name="description"
    content="Manage your rental property with ease using our AI-powered platform. Get personalized recommendations, flexible rent payment options, automated reminders, and centralized document management. Trust and transparency are at the core of our platform, making each rental transaction secure and hassle-free. Simplify your rental journey today."
  />
  <meta
    name="keywords"
    content="Rental finance management, Property management software, Tenant management system, Rent payment processing, Property listing management, Lease agreement management, Online rent payment, Rental property accounting, Tenant screening, Rent collection automation, Maintenance request management, Property owner dashboard, Real estate software, Online property management, Property management platform, Rental management app, Property management tool, Automated rent collection, Tenant communication platform"
  />
  <meta name="robots" content="index, follow" />
</head>
<!-- Google tag (gtag.js) -->
<script
  async
  src="https://www.googletagmanager.com/gtag/js?id=G-Y3D2LBP9KV"
></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag("js", new Date());

  gtag("config", "G-Y3D2LBP9KV");
</script>
<!-- Google tag (gtag.js) -->
<!-- <hr>
<div class="container">
    <div class="row form-row section-four">
        <div class="col-md-5" style="padding-left: 20px;">
            <h3 class="header-title">
                <img class="logo" src="assets/images/rentofly-new-logo.png"><span class="separator-logo">|</span>
                <a class="header-title" routerLink="/dashboard/view-property" routerLinkActive="active">
                    <span>rentofly</span>
                </a>
            </h3>
            <p class="footer-para">Rentofly is a unified platform built <br>to streamline & transform rental market.</p>
            <i class="fa fa-envelope footer-icon"></i>
            <a href="mailto:contact@rentofly.com" class="email-link">
                contact@rentofly.com
            </a>
            <div>
                <i class="fa fa-mobile mobile-icon"></i>
                <a href="tel:7700990949" class="email-link">
                    +91-7700990949
                </a>
            </div>
        </div>
        <div class="col-md-3 column-width" style="padding-left: 20px;">
            <h5 class="footer-link-header">Product</h5>
            <div>
                <ul class="menu">
                    <li class="footer-li">
                        <a routerLink="/faq" class="footer-links">FAQ</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-md-3 column-width" style="padding-left: 20px;">
            <h5 class="footer-link-header">Company</h5>
            <div>
                <ul class="menu">
                    <li class="footer-li">
                        <a routerLink="/contact-us" class="footer-links">Contact Us</a>
                    </li>
                    <li class="footer-li">
                        <a href="/terms-of-service" target="_blank" class="footer-links">Terms of Service</a>
                    </li>
                    <li class="footer-li">
                        <a href="/privacy-policy" target="_blank" class="footer-links">Privacy Statement</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div> -->
<!-- <div class="row social">
    <div class="title">Social Links</div>
    <a href="https://www.facebook.com/Olacabs" target="_blank"><img src="../../../../assets/images/yt.png"></a>
    <a href="https://www.instagram.com/olacabs" target="_blank"><img src="../../../../assets/images/twitter.png"></a>
    <a href="https://www.youtube.com/user/OfficialOlacabs" target="_blank"><img src="../../../../assets/images/fb.png"></a>
    <a href="https://twitter.com/Olacabs" target="_blank"><img src="../../../../assets/images/linkedin.png"></a>
    <a href="https://twitter.com/Olacabs" target="_blank"><img src="../../../../assets/images/Instagram.svg"></a>
</div> -->

<!-- For New Footer -->
<!-- <div class="container-fluid mainDiv">
    <div class="container">
        <div class="row footer-row">
            <div class="col-md-2 mobile-header">
                <h4 class="header-4">Discover Rentofly</h4>
            </div>
            <div class="col-md-1 mobile-link">
                <a routerLink="/about-us" class="footer-links">About</a>
            </div>
            <div class="col-md-1  mobile-link">
                <a routerLink="/faq" class="footer-links">FAQ</a>
            </div>
            <div class="col-md-2  mobile-link">
                <a routerLink="/contact-us" class="footer-links">Contact us</a>
            </div>

        </div>
        <div class="row footer-row">
            <div class="col-md-2 mobile-header">
                <h4 class="header-4">Social Links</h4>
            </div>
            <div class="col-md-1 mobile-view">
                <a href="https://www.youtube.com/channel/UCeAKf0Or8Tx_UlKegdEXpag" class="social-link" target="_blank">
                    <img src="../../../../assets/images/yt.png" width="40" height="30">
                </a>
            </div>
            <div class="col-md-1 mobile-view">
                <a href="https://mobile.twitter.com/rentofly" class="social-link" target="_blank">
                    <img src="../../../../assets/images/twitter.png" width="35" height="35" style="margin-right: 5px;">
                </a>
            </div>
            <div class="col-md-1 mobile-view">
                <a href="https://www.facebook.com/rentofly/" class="social-link" target="_blank">
                    <img src="../../../../assets/images/fb.png" width="30" height="30">
                </a>
            </div> 
            <div class="col-md-1 mobile-view">
                <a href="https://www.linkedin.com/company/rentofly" class="social-link" target="_blank">
                    <img src="../../../../assets/images/linkedin.png" width="40" height="40">
                </a>
            </div>
            <div class="col-md-1 mobile-view">
                <a href="https://www.instagram.com/rentofly/" class="social-link" target="_blank">
                    <img src="../../../../assets/images/Instagram.svg" width="35" height="37">
                </a>
            </div>
        </div>
        <div class="row footer-row-2">
            <div class="col-md-6 mobile-header">
                <img class="logo" src="assets/images/rentofly-new-logo.png"><span class="separator-logo">|</span>
                <a class="header-title" routerLink="/dashboard/view-property" routerLinkActive="active">
                    <span>rentofly</span>
                </a>
            </div>
            <div class="col-md-6 column-align">
                <a href="/terms-of-service" target="_blank" class="footer-links links-align link-space">Terms of Service</a>
                <a href="/privacy-policy" target="_blank" class="footer-links links-align">Privacy Statement</a>
                <p class="copy-right"><i class="fa fa-copyright"></i> 2021 | Sona Tech Labs Pvt Ltd. All rights reserved. | Beta Version</p>
            </div>
        </div>
    </div>
</div> -->
<div class="container footer-container">
  <div class="row">
    <div class="col-md-4 img-mob-view">
      <img class="logo" src="/assets/images/new-img/Horizontal logo.svg" />
      <p class="footer-para-1">
        Delve into enriching, interactive small-group experiences. Broaden your
        perspectives and enhance your renting journey with us...
      </p>
      <p class="footer-header">Download the app on</p>
      <a
        href="https://apps.apple.com/us/app/rentofly/id6473688330"
        target="_blank"
      >
        <img src="/assets/images/new-img/appstore.svg" width="140" />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.rentoflymobile"
        target="_blank"
      >
        <img src="/assets/images/new-img/googleplay.svg" width="140" />
      </a>
    </div>
    <div class="col-md-2 gen-mobile-viw">
      <h4 class="footer-header">Quick Links</h4>
      <a routerLink="/" class="footer-links">Home</a>
      <a routerLink="/about-us" class="footer-links">About</a>
      <a routerLink="/faq" class="footer-links">FAQ</a>
      <a routerLink="/contact-us" class="footer-links">Contact us</a>
    </div>
    <div class="col-md-2 legal-mob-view">
      <h4 class="footer-header">Legal</h4>
      <a
        href="/terms-of-service"
        target="_blank"
        class="footer-links links-align link-space"
        >Terms of Service</a
      >
      <a href="/privacy-policy" target="_blank" class="footer-links links-align"
        >Privacy Statement</a
      >
    </div>
    <div class="col-md-4 social-mob-view">
      <h4 class="footer-header">For More Updates Subscribe Below</h4>
      <div class="email-field">
        <input
          type="text"
          placeholder="  Email Address"
          class="mobile-num-field"
        />
        <div class="login-icon-btn">
          <i
            class="pi pi-arrow-right"
            style="font-size: 20px; margin-left: 8px; margin-top: 16px"
          ></i>
        </div>
      </div>
      <h4 class="footer-header follow-us-header">Follow Us on</h4>
      <a
        href="https://www.youtube.com/channel/UCeAKf0Or8Tx_UlKegdEXpag"
        class="social-link"
        target="_blank"
      >
        <img
          src="../../../../assets/images/new-img/Youtube.svg"
          width="20"
          height="30"
        />
      </a>
      <a
        href="https://mobile.twitter.com/rentofly"
        class="social-link"
        target="_blank"
      >
        <img
          src="../../../../assets/images/new-img/carbon_logo-x.svg"
          width="20"
          height="35"
        />
      </a>
      <a
        href="https://www.facebook.com/rentofly/"
        class="social-link"
        target="_blank"
      >
        <img
          src="../../../../assets/images/new-img/Fb.svg"
          width="20"
          height="30"
        />
      </a>
      <a
        href="https://www.linkedin.com/company/rentofly"
        class="social-link"
        target="_blank"
      >
        <img
          src="../../../../assets/images/new-img/In.svg"
          width="20"
          height="40"
        />
      </a>
      <a
        href="https://www.instagram.com/rentofly/"
        class="social-link"
        target="_blank"
      >
        <img
          src="../../../../assets/images/new-img/Insta.svg"
          width="20"
          height="37"
        />
      </a>
    </div>
  </div>
  <!-- <div class="row">
        <div class="col-md-3">

        </div>
        <div class="col-md-2">
            <a routerLink="/about-us" class="footer-links">About</a>
            <a routerLink="/faq" class="footer-links">FAQ</a>
            <a routerLink="/contact-us" class="footer-links">Contact us</a>
        </div>
        <div class="col-md-2">
            <a href="/terms-of-service" target="_blank" class="footer-links links-align link-space">Terms of Service</a>
            <a href="/privacy-policy" target="_blank" class="footer-links links-align">Privacy Statement</a>
        </div>
        <div class="col-md-5">
            <a href="https://www.youtube.com/channel/UCeAKf0Or8Tx_UlKegdEXpag" class="social-link" target="_blank">
                <img src="../../../../assets/images/youtube.svg" width="20" height="30">
            </a>
            <a href="https://mobile.twitter.com/rentofly" class="social-link" target="_blank">
                <img src="../../../../assets/images/twitter.svg" width="20" height="35">
            </a>
            <a href="https://www.facebook.com/rentofly/" class="social-link" target="_blank">
                <img src="../../../../assets/images/facebook.svg" width="20" height="30">
            </a>
            <a href="https://www.linkedin.com/company/rentofly" class="social-link" target="_blank">
                <img src="../../../../assets/images/linkedin.svg" width="20" height="40">
            </a>
            <a href="https://www.instagram.com/rentofly/" class="social-link" target="_blank">
                <img src="../../../../assets/images/Instagram.svg" width="20" height="37">
            </a>
        </div>
    </div> -->
  <!-- <hr class="footer-hr-row">
        <div class="row">
            <div class="col-md-12 footer-text">
                <p class="copy-right"><i class="fa fa-copyright"></i> 2023 | All rights reserved.<br>Yuvajunction Services Pvt. Ltd.</p>
            </div>
        </div> -->
</div>
