<head>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="description" content="Manage your rental property with ease using our AI-powered platform. Get personalized recommendations, flexible rent payment options, automated reminders, and centralized document management. Trust and transparency are at the core of our platform, making each rental transaction secure and hassle-free. Simplify your rental journey today.">
    <meta name="keywords" content="Rental finance management, Property management software, Tenant management system, Rent payment processing, Property listing management, Lease agreement management, Online rent payment, Rental property accounting, Tenant screening, Rent collection automation, Maintenance request management, Property owner dashboard, Real estate software, Online property management, Property management platform, Rental management app, Property management tool, Automated rent collection, Tenant communication platform">
    <meta name="robots" content="index, follow">
</head>
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-Y3D2LBP9KV"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-Y3D2LBP9KV');
</script>
<!-- Google tag (gtag.js) -->
<div class="container full-page">
    <div class="row">
        <div class="col-md-12">
            <h1>Privacy Policy</h1>
            <p>This web site (the “<b>Site</b>“) is operated by Yuvajunction Services Pvt. Ltd. (“<b>YSPL</b>“). The Privacy Policy that follows (the “<b>Policy</b>“) describes YSPL's collection, use and disclosure of information practices in connection with
                the Site. This Policy applies to the national version of the Site, located at Rentofly.com, as well as to any other versions or pages of the Site that YSPL operates, including mobile versions and pages that YSPL co-brands or private
                labels with its newspaper and broadcast affiliates and other partners (collectively, “<b>Affiliates</b>”). For example, you may have arrived at a co-branded version of the Site from an Affiliate’s web site, in which case, that Affiliate
                will be identified at the top of the home page of this Site. In this Policy YSPL may refer to those versions of the Site as an “<b>Affiliate Version</b>.” Please be aware that YSPL may share any information you submit through this Site
                with Affiliates, as described below. During your visit to any Affiliate Version, the Affiliate may independently collect other information from you.</p>
            <p>
                This Policy does not describe YSPL's collection, use, and disclosure of information through means other than this Site (e.g., offline) or the collection, use, and disclosure practices of any third party, including Affiliates. For information on a third
                party’s information collection, use, and disclosure practices, please review the privacy policy on the applicable third party’s web site. Also, please note that this Site contains links to other web sites. YSPL is not responsible for
                the web sites, content or privacy practices of any third party, including Affiliates. By using this Site, you acknowledge that you have read this Policy and agree that you are subject to the provisions of this Policy and the YSPL Terms
                of Use located at the following URL:  <a target="_blank" routerLink="/terms-of-service">Terms of Service</a>, in each case without modification. If you do not accept any portion of this Policy or the Terms of Use, your
                only option is not to use the Site.
            </p>
            <p>
                <b>Collection of Personally Identifiable Information.</b> YSPL collects personally identifiable information on the Site only when you make it available through your use of the Site. You may be required to provide personally identifiable
                information to use certain services on this Site. In addition, you may submit personally identifiable information through the Site in other ways, including: (a) participating in online surveys, promotions, contests and/or sweepstakes;
                (b) subscribing to newsletters, editorial content, and/or promotional content sent via email; (c) in connection with other products, services, features, and/or requests made available through the Site; and (d) submission of purchase and/or
                billing related information including credit card numbers. The types of personally identifiable information collected will depend on the nature of the submission or the features of the Site used, but could include your name, address, phone
                number, email address and credit card information. In the event that you purchase an ad in a print/online package through a newspaper Affiliate, the Affiliate is required to share your name, phone number and email address with YSPL in
                order to allow YSPL to process, maintain, and contact you regarding your ad.
            </p>
            <p>
                <b>Usage Data Collection.</b> The Site automatically collects passive information and may also customize your visit through technical means, including the following:
            </p>
            <ul>
                <li>
                    <b>Cookies</b>: Cookies are small text files that are placed on your computer’s hard drive by computers (or servers) to identify your computer.
                </li>
                <li>
                    <b>Web Beacons</b>: Web beacons (also known as pixel tags, Internet tags, clear GIFs, or single-pixel GIFs) are electronic images embedded into a web page.
                </li>
                <li>
                    <b>IP Address Logs</b>: YSPL also logs Internet Protocol (IP) addresses ? the location of your computer on the Internet.
                </li>
            </ul>
            <p>
                Cookies allow YSPL to recognize you when you visit the Site, to personalize your experience and generally to serve you better. For example, if you enter your name, address or ZIP code on one of the Site’s forms, it may be stored in a cookie so you do
                not have to enter it again on other forms on the Site. Cookies and web beacons can tell us what advertising you have seen on the Site, which allows YSPL to offer you a more diverse selection of advertising information and may also be
                used together to measure the effectiveness of YSPL advertising campaigns. Cookies, web beacons and IP addresses are also used to compile data regarding Site usage (what web site you came from, what pages of the Site you visit, how long
                you were on the Site, what type of browser you are using, what web site did you go to, etc.). Web beacons may also be included in emails sent by YSPL to determine when and if such emails are opened or forwarded. You may disable cookies
                or otherwise manage them through tools provided with your browser software and with third-party software packages. If you turn off cookies, many of the Site’s features will not work as well or at all. Anonymous information regarding usage
                of the Site and services that is collected and/or aggregated by or on behalf of YSPL through the use of such technical means or otherwise is referred to in this Policy as “<b>Usage Data</b>”.
            </p>
            <br />
            <br />
            <h1>Information Use</h1>
            <p>
                <b>Personally, Identifiable Information</b>: Personally, identifiable information collected by YSPL through your use of the Site may be stored on servers or in a cookie on your computer and may be used by YSPL for any of the following
                purposes:
            </p>
            <ul>
                <li>
                    To confirm, fulfill, or follow-up a request that you make or to communicate with you regarding the YSPL service.
                </li>
                <li>
                    To contact you by email regarding account status, changes to any account agreement, and/or to confirm account registration. In addition, if you provide your email address or other contact information in connection with a request for product information,
                    YSPL, an advertiser, affiliate, partner and/or other third party or parties, as applicable, may use that information to contact you.
                </li>
                <li>
                    To send promotional materials, newsletters, or other content via email or otherwise, unless you have indicated that you do not wish to receive such materials.
                </li>
                <li>
                    To permit third parties to whom YSPL may disclose personally identifiable information specifically for direct marketing purposes to send you promotional or informational messages, but only if you have first affirmatively agreed to receive such messages.
                </li>
                <li>
                    To improve your user experience and/or to measure interest in various areas of the Site.
                </li>
                <li>
                    To manage its relationships with Affiliates, customers, and other third parties to which YSPL may disclose personally identifiable information pursuant to this Policy.
                </li>
                <li>
                    To facilitate Site administration, analysis, research, reporting, development, optimization and other internal purposes, including, without limitation, to compile Usage Data.
                </li>
            </ul>
            <p>
                <b>Usage Data</b>: Usage Data is used by YSPL for administrative, analytical, research, optimization and other purposes. For example, YSPL may use statistical analyses of Usage Data in order to measure interest in the various areas of
                the Site, for development purposes and to inform advertisers and partners in aggregate and anonymous terms about consumers who click on their advertising or other messages. IP Addresses are also used for systems administration and for
                related troubleshooting purposes.
            </p>
            <p>
                With respect to promotional materials and newsletters that YSPL may send via email from time to time, YSPL will not continue to send you such communications if you ask us not to. However, you agree that YSPL may send to you, and you cannot opt out
                of, communications of a transactional or relationship nature, including, but not limited to, communications regarding fraudulent or illegal activity, account status, changes to this Policy or the YSPL Terms of Service, as well as communications
                to confirm, fulfill, and follow up on your registration, service, or a request submitted through the Site.
            </p>
            <p>
                <b>Information Sharing</b>. YSPL may share personally identifiable information that you provide with third parties in the following cases:
            </p>
            <p>
                <b>Affiliates</b>. YSPL may share personally identifiable information with its Affiliates on the condition that the Affiliates’ disclosure and use of such information is consistent with all applicable laws, rules, and regulations and
                this Policy.
            </p>
            <p>
                <b>Requests</b>. STLPL maintains relationships with third party providers for some of the products and services offered through the Site. If you request information or services through the Site, you agree that YSPL may share personally
                identifiable information with third parties as may be necessary to provide such information to or perform such services for you. For example, if you request information about a property advertised on the Site, YSPL will share the information
                collected through such request with the owner(s) and/or agent(s) for property (or applicable designee) and any other parties providing that listing (e.g., if a third-party real estate company or real estate agent provides the listing to
                us on behalf of the property owner(s)). In some cases, the information that you provide through the Site may be collected directly by a third party or its designee (e.g., through forms hosted by such third party or on a page framed with
                YSPL branding). Personally, identifiable information also may be shared with the specific third parties that can respond to your requests in the form of reporting and other follow-up communications. The subsequent use or disclosure of
                any information collected by or provided to third parties is subject to their privacy policies and practices.
            </p>
            <p>
                <b>Direct Marketing</b>. Except as provided herein, it is YSPL's policy not to disclose personally identifiable information of its users to third parties specifically for the third parties’ direct marketing purposes. If you are 18 years
                of age or older and have affirmatively agreed to receive third party direct marketing materials, YSPL may share your personally identifiable information with third parties for direct marketing purposes. Although YSPL will endeavor to
                notify all third-party direct marketers that the information provided to them is confidential, the subsequent use or disclosure of information is subject to those third parties’ privacy policies and practices.
            </p>
            <p>
                <b>Service Providers</b>. STLPL may work with related and unrelated third parties that provide services on its behalf. Examples include providers of analytical, developmental, promotional, research, credit card processing, technology,
                support and other services. For example, YSPL may work with third parties who host or operate the Site or may ask that a third party conduct an online survey or sweepstakes or send newsletters or other email communications on its behalf.
                Similarly, if you make a payment to YSPL by credit card, YSPL may share personally identifiable information and credit card information with a third party in order to process credit card transactions, or such information may be collected
                directly by the credit card processing service provider on YSPL's behalf. In such cases, YSPL shall endeavor to require that the third party maintain personally identifiable information as confidential and use the information only to
                perform the services specified by YSPL.
            </p>
            <p>
                <b>Sweepstakes/Promotions</b>. In the event that you participate in a sweepstakes, contest, or other promotion on the Site, YSPL reserves the right to share personally identifiable information collected in conjunction with the sweepstakes,
                contest or promotion with any third-party sponsor or co-sponsor. After the information is provided to such third parties, the subsequent use or disclosure of such information is subject to those third parties’ privacy policies and practices.
            </p>
            <p>
                <b>Other.</b> STLPL expressly reserves the right to release any personally identifiable information or other information you provide to third parties under the following circumstances: (a) when required by law or legal process, or if otherwise
                requested by a legitimate law enforcement body to cooperate in their investigations, (b) to investigate and/or take action against illegal activity, suspected abuse, or unauthorized use of the Site and/or YSPL services, (c) to protect
                the property or safety of YSPL users or others, (d) to enforce the Site’s Terms of Use and (e) in connection with any sale or other transfer of ownership of all or a part of YSPL or the reorganization of any of its businesses or related
                businesses. If you make any personally identifiable information publicly available on the Site (e.g., by posting a listing which contains personally identifiable information), anyone may see and use such information and YSPL is not responsible
                for such use. YSPL reserves the right to share any personally identifiable information you post on the Site with third parties. To the extent YSPL does so, the subsequent use and disclosure of such information is subject to the privacy
                policies and practices of those third parties.
            </p>
            <p>
                Please note that the messages that YSPL sends to you to confirm, fulfill, or follow up on a request may incidentally include promotional material or other content from YSPL or its partners. In the case of these messages, YSPL is not sharing your personally
                identifiable information with those third parties. YSPL reserves the right to share Usage Data with Affiliates and any other third party for any reason.
            </p>
            <p>
                <b>Collection of Information by Third Parties</b>. As noted in this Policy, Affiliates and other third parties may collect information about you when using the Site through the use of cookies and web beacons and/or other similar technologies.
                For example, we work with companies that collect statistical information regarding Site usage and provide related analytical services. Also, YSPL uses third-party companies to serve advertisements when you visit the Site, to collect statistical
                information regarding Site usage, and to generate metrics related to the delivery of such advertisements. Third-party advertising companies may collect non-personally identifiable information about your visits to the Site and other web
                sites in order to provide targeted advertisements about goods and services which may be of interest to you across their networks of web sites (which may or may not include this Site). These third parties also may use this information for
                determining or predicting the characteristics and preferences of their respective advertising audiences, for measuring the effectiveness of their advertising, and for other purposes. In addition, YSPL may share statistical and behavioral
                data about visitors to the Site with these third-party advertising companies for the purpose of managing and targeting advertisements on the Site and/or on other web sites. For example, we use Remarketing with Google Analytics to advertise
                YSPL across the Internet, in particular on the Google Search Network, to people who have previously visited the Site. These third-party advertising companies may note some of the pages you visit on the Site through the use of web beacons.
                In the course of serving advertisements to you, these third-party advertising companies may also place or recognize a unique cookie on your computer to allow them to recognize web beacons and otherwise improve advertising effectiveness.
            </p>
            <!-- <p>
                If you would like more information about the information collection practices of some of these third party advertising companies and learn which of these companies allow you to opt-out of this type of information collection, please click  here .
            </p> -->
            <!-- <p>
                To prevent your data from being used by Google Analytics,  click here for instructions. To opt out of Google interest-based advertising,  click hereto see your Google ad settings.
            </p> -->
            <p>
                We are using the Google Maps API(s) and incorporate by reference the Google Privacy Policy.
            </p>
            <p>
                <b>
                Sell a Home Ads</b>. When purchasing a Sell a Home online classified listing (also referred to as a  <b>Single Listing Package</b> or <b>SLP</b>, or a  <b>Sell Your Home Ad</b>), it is important to note that any information
                you choose to include in a SLP may be made publicly available via the Site and other media in which YSPL listings are published, including third party web sites and print publications. For example, you may include a phone number and/or
                an email address to allow users to contact you regarding your SLP. As such, you may receive unsolicited phone calls and emails from third parties, even after your SLP is no longer on the Site. Except for required information, you should
                not include any contact or other information in a SLP that you do not want other parties to see or use.
            </p>
            <p>
                <b>
                Forums</b>. YSPL also may offer message boards, blogs, bulletin boards, chat rooms, comments areas, or other forums (“Forums”) on the Site that are intended for use by visitors who are 18 years of age or older. Any information
                you disclose when posting a message in these Forums may become public. You should not include any information in your posting that you do not want other parties to see or use. Moreover, users who post a message in the Site’s Forums make
                their email addresses available to others, and if you post any messages in the Forums that include contact information (e.g. name, phone number) you may receive unsolicited email or calls from third parties.
            </p>
            <p>
                <b>Email</b> this Listing. The Site offers you the ability to email certain pages or content of the Site to a friend or other third party. If you choose to use the Email this Listing functionality, your name, email address and any message
                you are allowed to include will be visible to the recipient of the email. YSPL does not use the names and addresses stored through the Email this Listing functionality for any purpose other than sending a one-time email to your friend.
                The Email this Listing functionality is for informational purposes only and you may not use it for any unlawful purpose, including any harassment or threats.
            </p>
            <p>
                <b>Changes to this Policy</b>. Any changes to this Policy will be posted on the Site in this location and this Policy will be identified as “Last updated on __________, 20__”. Your continued use of the Site after such notice constitutes
                acceptance of such changes without modification. It is your responsibility to check back and review this location regularly to ensure that you are familiar with the most current terms. Should you object to any such changes to this Policy,
                your only option is to discontinue use of the Site.
            </p>
            <p>
                <b>Security</b>. The security of your personally identifiable information is extremely important to us. We take steps to keep it physically secure in data centers and it is accessible only to authorized personnel as needed to perform operations
                governed by this Policy. In addition, when collecting credit card information, the Site uses Secure Socket Layer (SSL) encryption, the industry standard for protecting privacy in web transactions. Please keep in mind that despite these
                measures, YSPL cannot guarantee or warrant 100% security of its servers and databases. Moreover, YSPL cannot guarantee or warrant that personally identifiable information that you submit through the Site will not be intercepted or otherwise
                disclosed.
            </p>
            <p>
                <b>Children</b>. STLPL believes that it is especially important to protect children’s privacy online. YSPL does not knowingly collect or use any personally identifiable information from children under the age of 13 and YSPL does not
                knowingly provide any personally identifiable information, regardless of its source, to any third party for any purpose whatsoever from visitors younger than 13 years old. No information should be submitted to or posted at YSPL by visitors
                younger than 13 years old. If we discover we have received any information from a child under 13 in violation of this Policy, we will delete that information immediately. If you believe we have any information from or about anyone under
                13, please contact us at the address listed below.
            </p>
            <p>
                <b>Links</b>. This Site contains links to other sites. While YSPL encourages its business partners to adopt privacy policies and practices that build users’ trust and confidence in the Internet, YSPL is not responsible for the privacy
                policies or practices of third parties or any of the content of web sites linked to the Site or any third-party products or services offered through the Site. You should use common sense when you navigate the Internet and be aware of when
                you travel outside the Site. In addition, you should carefully review the applicable privacy policies of each web site you visit and each web service you use.
            </p>
            <p>
                <b>National/International users</b>. If you are an international user, by using the Site, you agree that YSPL may collect, use and transfer your personal information as described in this Policy, including transfers outside the country
                where you live. If you reside in a country in the European Union or other jurisdiction that provides access rights under law, you may access your personal information or have it corrected or updated by using the interface available at http://Rentofly.com
            </p>
            <p>If you have any questions about this Privacy Policy, please <a routerLink="/contact-us" target="_blank">contact us.</a></p>
            
        </div>
    </div>
</div>