import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CreateDashboardState } from "..";
import { brokerAdapter, BrokerState } from "../reducers";

export const getCreateDashboardState = createFeatureSelector<CreateDashboardState>('dashboard');
export const getBrokerState = createSelector(
    getCreateDashboardState,
    (state: CreateDashboardState) => state.broker
);

export const currentBroker$ = createSelector(getBrokerState, (state: BrokerState) => state.currentBroker);
export const currentBrokerLoading$ = createSelector(getBrokerState, (state: BrokerState) => state.currentBrokerLoading);
export const currentUserForBroker$ = createSelector(getBrokerState, (state: BrokerState) => state.currentUserForBroker);
export const currentUserForBrokerLoading$ = createSelector(getBrokerState, (state: BrokerState) => state.currentUserForBrokerLoading);
export const editingBroker$ = createSelector(getBrokerState, (state: BrokerState) => state.editingBroker);
export const brokerage$ = createSelector(getBrokerState, (state: BrokerState) => state.brokerage);